import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import { getToken } from "../../utility/utilityFunctions";
import axios from "axios";
import GoBackButton from "../../component/GoBackButton";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/users`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { users } = response.data;
        setUsers(users);
      });
  }, []);

  const setLeaves = () => {
    console.log("teacherId:", selectedTeacherId);
    if (window.confirm("You really want to save leaves ?")) {
      const medical_leaves = document.getElementById("medical_leaves").value;
      const casual_leaves = document.getElementById("casual_leaves").value;
      console.log("medical_leaves:", medical_leaves);
      console.log("casual_leaves:", casual_leaves);

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/admin/teacher/updateLeaves`,
          {
            CL: casual_leaves,
            ML: medical_leaves,
            user_id: selectedTeacherId,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          document.getElementById("transfer-popup-close-btn").click();
          window.notif({
            msg: "<b>Woohoo!!</b> teacher leaves assigned Successfully",
            type: "success",
          });
          navigate(`/admin/users`);
        });
    }
  };
  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <GoBackButton />
              <h1 className="page-title">Users</h1>
              <Link
                type="button"
                to="/admin/users/new"
                class="btn btn-danger text-light"
              >
                <i class="fe fe-user-plus"></i>&nbsp;Add User
              </Link>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Name</b>
                                </th>
                                <th>
                                  <b>Role</b>
                                </th>
                                <th>
                                  <b>Branch</b>
                                </th>
                                <th>
                                  <b>Mobile</b>
                                </th>
                                <th>
                                  <b>Edit</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {users.map((user) => {
                                return (
                                  <tr key={user._id}>
                                    <td>
                                      {user.name}
                                      {user.role == "central_head" ? (
                                        <>
                                          <br></br>
                                          <div>
                                            <span class="badge bg-success badge-sm  me-1 mb-1 mt-1 ml-10 text-white">
                                              <button
                                                type="button"
                                                class="btn "
                                                data-bs-toggle="modal"
                                                data-bs-target="#input-modal"
                                                data-bs-whatever="@mdo"
                                                onClick={() => {
                                                  setSelectedTeacherId(
                                                    user._id
                                                  ); // Set the selected teacher's ID
                                                }}
                                              >
                                                Manage Leaves
                                              </button>
                                            </span>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                    <td>{user.role}</td>
                                    <td>{user.branch[0].name}</td>
                                    <td>{user.mobile}</td>
                                    <td>
                                      {" "}
                                      {user.role == "central_head" ? (
                                        <>
                                          {" "}
                                          <span class="badge btn bg-warning badge-sm  me-1 mb-1 mt-1 ml-10 text-white">
                                            <Link
                                              to={`/admin/users/${user._id}`}
                                            >
                                              <span class="text-white">
                                                Edit
                                              </span>
                                            </Link>
                                          </span>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="input-modal">
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">Manage Central Head Leaves</h6>

              <button
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="transfer-popup-close-btn"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              <form>
                <div className="form-group col-md-12">
                  <label htmlFor="casual_leaves">Casual Leaves</label>
                  <input
                    type="number"
                    className="form-control"
                    id="casual_leaves"
                    required
                    placeholder="Casual Leaves"
                  />
                </div>
                <div className="form-group col-md-12">
                  <label htmlFor="medical_leaves">Medical Leaves</label>
                  <input
                    type="number"
                    className="form-control"
                    id="medical_leaves"
                    required
                    placeholder="Medical Leaves"
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                className="btn ripple btn-success"
                type="button"
                onClick={() => {
                  setLeaves();
                }}
              >
                Submit
              </button>
              <button
                className="btn ripple btn-danger"
                data-bs-dismiss="modal"
                type="button"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
