import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import {
  getToken,
  timezoneDateToddMMyyyy,
} from "../../utility/utilityFunctions";

const BreakageRecordDetail = () => {
  const data = {};

  const [breakageRecord, setBreakageRecord] = useState(data);
  const params = useParams();

  console.log(params,'params')

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/breakageRecord`,
        { id: params.id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        const { breakageRecord } = response.data;
        setBreakageRecord(breakageRecord);
        console.log("breakageRecord:", breakageRecord);
      });
  }, []);

  const updateInventoryRecord = async (status) => {
    if (window.confirm("Are you sure want to process this action ?")) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/updateBreakageRecord`,
          {
            status,
            id: params.id,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          const { breakageRecord } = response.data;
          setBreakageRecord(breakageRecord);
        });
    }
  };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <h1 className="page-title">Breakage Detail</h1>
            </div>
            <div className="col-xl-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <form className="form-horizontal">
                    <div className=" row mb-4">
                      <label className="col-md-3 form-label">
                        Breakage Category
                      </label>
                      <div className="col-md-9">
                        <label className="form-label">
                          {breakageRecord?.breakageCategory?.name}
                        </label>
                      </div>
                    </div>
                    <div className=" row mb-4">
                      <label className="col-md-3 form-label">Subject</label>
                      <div className="col-md-9">
                        <label className="form-label">
                          {breakageRecord?.subject}
                        </label>
                      </div>
                    </div>
                    <div className=" row mb-4">
                      <label className="col-md-3 form-label">Description</label>
                      <div className="col-md-9">
                        <label className="form-label">
                          {breakageRecord?.note}
                        </label>
                      </div>
                    </div>
                    <div className=" row mb-4">
                      <label className="col-md-3 form-label">
                        Raised On Date
                      </label>
                      <div className="col-md-9">
                        <label className="form-label">
                          {breakageRecord?.resolved_till_date && timezoneDateToddMMyyyy(
                            breakageRecord?.raised_on_date
                          )}
                        </label>
                      </div>
                    </div>
                    <div className=" row mb-4">
                      <label className="col-md-3 form-label">
                        Resolved Till Date
                      </label>
                      <div className="col-md-9">
                        <label className="form-label">
                          {breakageRecord?.resolved_till_date && timezoneDateToddMMyyyy(
                            breakageRecord?.resolved_till_date
                          )}
                        </label>
                      </div>
                    </div>
                    <div className=" row mb-4">
                      <label className="col-md-3 form-label">Raised By</label>
                      <div className="col-md-9">
                        <label className="form-label">
                          {breakageRecord?.raised_by?.name} ({" "}
                          {breakageRecord?.raised_by?.mobile} )
                        </label>
                      </div>
                    </div>
                    <div className=" row mb-4">
                      <label className="col-md-3 form-label">Status</label>
                      <div className="col-md-9">
                        <label className="form-label">
                          {breakageRecord?.status}
                        </label>
                      </div>
                    </div>
                    <div className=" row mb-4">
                      <label className="col-md-3 form-label">Image</label>
                      <div className="col-md-9">
                        <img
                          width={"400px"}
                          src={
                            breakageRecord?.image
                              ? `data:image/png;base64,${breakageRecord?.image}`
                              : ""
                          }
                        ></img>
                      </div>
                    </div>
                    {breakageRecord?.status == "active" ? (
                      <>
                        <div className="row mb-4">
                          <div className="d-flex">
                            <button
                              type="button"
                              class="btn btn-success text-light"
                              onClick={() => {
                                updateInventoryRecord("resolved");
                              }}
                            >
                              <i class="fe fe-thumbs-up"></i>&nbsp;Resolved
                            </button>

                            <button
                              type="button"
                              class="btn btn-danger text-light"
                              style={{ marginLeft: "5px" }}
                              onClick={() => {
                                updateInventoryRecord("rejected");
                              }}
                            >
                              <i class="fe fe-thumbs-down"></i>&nbsp;Reject
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </form>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BreakageRecordDetail;
