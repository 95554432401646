import axios from "axios";
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import {
  getToken,
  timezoneDateToddMMyyyy,
} from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";

function MissingDob() {
  
  const { id } = useParams();
  const [userStudentData, setStudents] = useState([]);
   
  useEffect(() => {
    const loaderElement = document.getElementsByClassName("loader")[0];
    loaderElement.style.display = "block";
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/admin/mark/missing-dob`;
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { userStudentData } = response.data;
        setStudents(userStudentData);
        loaderElement.style.display = "none";
      });

      return () => {
        loaderElement.style.display = "none";
      };

  }, []);

  const updatedob = (newDob,user_id) => {
     
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/admin/mark/update-dob`,
          {
            userId: user_id,
            dob: newDob,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          window.notif({
            msg: "<b>Woohoo!!</b> Record updated successfully",
            type: "success",
          });
        });
     
  };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
            <GoBackButton />
 
            </div>
            
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                      <div className="loader" style={{ display: "none" }}>
                          <h2>Hold On !!</h2>
                          <h3>We are generating report....</h3>
                          <div
                            className="spinner-border text-danger"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                        <div className="table-responsive"> 
      <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
      <thead>
                              <tr>
                              <th>
                                  <b>Sr No</b>
                                </th> 
                                <th>
                                  <b>Name</b>
                                </th> 
                                <th>
                                  <b>Father Name</b>
                                </th>
                                <th>
                                  <b>Mobile</b>
                                </th>
                                <th>
                                  <b>Gender</b>
                                </th> 
                                <th>
                                  <b>Grade</b>
                                </th>
                                <th>
                                  <b>Section</b>
                                </th>
                                <th>
                                  <b>DOB</b>
                                </th> 
                              </tr>
                            </thead>
        <tbody>
      {userStudentData?.map((kay) => (
        <tr key={kay.user._id}>
          <td>{kay.student.sr_no}</td>
          <td>{kay.user.name}</td>
              <td>{kay.user.father_name}</td>
              <td>{kay.user.mobile}</td>
              <td>{kay.student.gender}</td>
              <td>{kay.student.grade.name}</td>
              <td>{kay.student.section}</td>
          <td>
          <input
            type="date"
            value={kay.user.dob}
            onChange={(e) => {
              const newDob = e.target.value;
              const updatedData = userStudentData.map((data) =>
                data.user._id === kay.user._id
                  ? { ...data, user: { ...data.user, dob: newDob } }
                  : data
              );
              setStudents(updatedData);
              updatedob(newDob, kay.user._id); // Call updatedob here as well
            }}
          />
          </td>
        </tr>
      ))}
      </tbody>
      </table> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MissingDob;