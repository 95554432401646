import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { getToken } from "../../utility/utilityFunctions";
import ButtonComponent from "../../component/ButtonComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parse, isValid } from "date-fns";
const AddTeachertForm = () => {
  const [showLoader, setShowLoader] = useState(false);

  const validationSchema = yup.object({
    name: yup.string().required("please enter name"),
    email: yup.string().email("Please enter a valid email address"), // Add email validation
    mobile: yup
      .string()
      .required("Please enter an phone number.")
      .max(12, "Maximum allowed character is 12")
      .matches(/^[0-9]\d{9}$/, "Please enter valid phone number."),
    paycode: yup.string().required("please enter paycode"),
    dob: yup.string().required("please enter date of birth"),
    gender: yup.string().required("please select gender"),
    role: yup.string().required("please define role"),
    address: yup.string(),
    qualification: yup.string(),
    experience: yup.string(),
    designation: yup.string(),
    doj: yup.string().required("please enter date of birth"),
    blood_group: yup.string(),
    // class_teacher: yup
    //     .string()
    //     .required("please choose section"),
    // teacher_arriving_time: yup
    //   .string()
    //   .required("please define teacher arriving time"),
    // teacher_return_time: yup
    //   .string()
    //   .required("please define teacher return time"),
    teachingSubjects: yup.array().of(
      yup.object().shape({
        grade: yup.string().required("Please select grade"),
        subject: yup.string().required("Please select subject"),
        section: yup.string().required("please select section"),
      })
    ),
  });
  const {
    register,
    setValue,
    setError,
    reset,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      teachingSubjects: [
        {
          section: "",
        },
      ],
    },
  });
  const {
    fields: subjectsfields,
    append,
    remove,
  } = useFieldArray({ name: "teachingSubjects", control });
  const [allSubjects, setAllSubjects] = useState([]);
  const [allGrades, setAllGrades] = useState([]);

  const [teacherArrivingTime, setTeacherArrivingTime] = useState(
    new Date().setHours(9, 0)
  );
  const [teacherReturnTime, setTeacherReturnTime] = useState(
    new Date().setHours(9, 0)
  );

  const handleArrivingTimeChange = (date) => {
    if (date instanceof Date && !isNaN(date.getTime())) {
      setTeacherArrivingTime(date);
    } else {
      console.error("Invalid date");
    }
  };

  const handleReturnTimeChange = (date) => {
    if (date instanceof Date && !isNaN(date.getTime())) {
      setTeacherReturnTime(date);
    } else {
      console.error("Invalid date");
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/add_teacher_data`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { subjects, grades } = response.data;
        setAllSubjects(subjects);
        setAllGrades(grades);
      });
  }, []);

  const createTeacher = (data) => {
    setShowLoader(true);

    data.profile_pic = data.profile_pic[0];
    data.role = "teacher";
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/teacher/new`, data, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setShowLoader(false);

        if (response?.data.success) {
          window.notif({
            msg: "<b>Woohoo!!</b> Teacher Record Created Successfully",
            type: "success",
          });
        } else {
          window.notif({
            msg: response?.data.error,
            type: "error",
            position: "center",
          });
        }
      });
    reset();
  };

  return (
    <>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <h4 className="card-title">Fill following information</h4>
            <div className="col-xl-2 px-3 px-xl-1 m-0"></div>
          </div>
          <div className="card-body">
            <form
              className="form-horizontal"
              onSubmit={handleSubmit(createTeacher)}
            >
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Name<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    id="name"
                    {...register("name")}
                  />
                  <div className="form-error-div">
                    {errors.name?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.name?.message}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-md-3 form-label" htmlFor="example-email">
                  Email
                </label>
                <div className="col-md-9">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    autoComplete="off"
                    id="email"
                    {...register("email")}
                  />
                  <div className="form-error-div">
                    {errors.email?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.email?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Mobile<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    className="form-control"
                    type="number"
                    // name="tel"
                    placeholder="Mobile"
                    id="mobile"
                    {...register("mobile")}
                    onChange={(e) => {
                      setValue("password", e.target.value);
                    }}
                  />
                  <div className="form-error-div">
                    {errors.mobile?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.mobile?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Password</label>
                <div className="col-md-9">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Passowrd"
                    id="password"
                    {...register("password")}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Paycode<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Paycode"
                    id="paycode"
                    {...register("paycode")}
                  />
                  <div className="form-error-div">
                    {errors.paycode?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.paycode?.message}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-md-3 form-label">
                  DOB<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <DatePicker
                    className="form-control"
                    placeholderText="Date Of Birth"
                    id="dob"
                    maxDate={new Date()} // Set the maxDate prop to the current date
                    selected={watch("dob") ? new Date(watch("dob")) : null} // Parse the 'dob' value to a Date object
                    onChange={(date) => setValue("dob", date)} // Use setValue to update the 'dob' field
                    dateFormat="dd-MM-yyyy" // Set the desired date format
                  />
                  <div className="form-error-div">
                    {errors.dob?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.dob?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Gender<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <select
                    className="form-control"
                    id="gender"
                    {...register("gender")}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                  <div className="form-error-div">
                    {errors.gender?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.gender?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Role<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <select
                    className="form-control"
                    id="role"
                    {...register("role")}
                  >
                    <option value="teacher">Teacher</option>
                  </select>
                  <div className="form-error-div">
                    {errors.role?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.role?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Address</label>
                <div className="col-md-9">
                  <textarea
                    className="form-control"
                    id="address"
                    placeholder="Address"
                    {...register("address")}
                  ></textarea>
                  <div className="form-error-div">
                    {errors.address?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.address?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Profile Picture</label>
                <div className="col-md-9">
                  <input
                    className="form-control"
                    type="file"
                    id="profile_pic"
                    {...register("profile_pic")}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Qualification</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Qualification"
                    id="qualification"
                    {...register("qualification")}
                  />
                  <div className="form-error-div">
                    {errors.qualification?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.qualification?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Experience</label>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Experience"
                    id="experience"
                    {...register("experience")}
                  />
                  <div className="form-error-div">
                    {errors.experience?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.experience?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Designation</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Designation"
                    id="designation"
                    {...register("designation")}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-md-3 form-label">
                  DOJ<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <DatePicker
                    className="form-control"
                    placeholderText="Date Of Joining"
                    id="doj"
                    maxDate={new Date()} // Set the maxDate prop to the current date
                    selected={watch("doj") ? new Date(watch("doj")) : null} // Parse the 'dob' value to a Date object
                    onChange={(date) => setValue("doj", date)} // Use setValue to update the 'dob' field
                    dateFormat="dd-MM-yyyy" // Set the desired date format
                  />
                  <div className="form-error-div">
                    {errors.doj?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.doj?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Blood Group</label>
                <div className="col-md-9">
                  <select
                    className="form-control"
                    name="blood_group"
                    id="blood_group"
                    {...register("blood_group")}
                  >
                    <option value="">Select Blood Group</option>
                    <option value="A+">A+</option>
                    <option value="A-">A-</option>
                    <option value="B+">B+</option>
                    <option value="B-">B-</option>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                    <option value="AB+">AB+</option>
                    <option value="AB-">AB-</option>
                  </select>
                  <div className="form-error-div">
                    {errors.blood_group?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.blood_group?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Class Teacher</label>
                <div className="col-md-9 d-flex">
                  <select
                    className="form-control col-md-4 grade"
                    id="class_teacher"
                    {...register("class_teacher")}
                  >
                    <option value={""}>None</option>
                    {allGrades.map((grade) => (
                      <option key={grade._id} value={grade._id}>
                        {grade.name} ({grade.branch.name})
                      </option>
                    ))}
                  </select>

                  <select
                    className="form-select"
                    id="section"
                    {...register("section")}
                  >
                    <option value="">Choose Section</option>
                    <option value="a">A</option>
                    <option value="b">B</option>
                    <option value="c">C</option>
                    <option value="d">D</option>
                  </select>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Teacher Arriving Time<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <DatePicker
                    id="teacher_arriving_time"
                    {...register("teacher_arriving_time")}
                    name="teacher_arriving_time"
                    onChange={handleArrivingTimeChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    selected={teacherArrivingTime}
                  />
                  <div className="form-error-div">
                    {errors.teacher_arriving_time?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.teacher_arriving_time?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Teacher Return Time<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <DatePicker
                    id="teacher_return_time"
                    {...register("teacher_return_time")}
                    name="teacher_return_time"
                    onChange={handleReturnTimeChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    selected={teacherReturnTime}
                  />
                  <div className="form-error-div">
                    {errors.teacher_return_time?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.teacher_return_time?.message}
                  </div>
                </div>
              </div>
              <div className="row mb-4" id="0">
                <label className="col-md-3 form-label">Teaching Subjects</label>
                <div className="col-md-9">
                  {subjectsfields.map((item, i) => {
                    return (
                      <>
                        <div className="mb-3 row" key={item.id}>
                          <div className="col-md-3">
                            <label className="form-label">Grade</label>

                            <select
                              className="form-select"
                              {...register(`teachingSubjects.${i}.grade`)}
                            >
                              <option value="">Choose Grade</option>
                              {allGrades.map((grade, key) => (
                                <option value={grade._id} key={key}>
                                  {grade.name} ({grade.branch.name})
                                </option>
                              ))}
                            </select>
                            <div className="form-error-div">
                              {errors.teachingSubjects?.[i]?.grade?.message && (
                                <span>
                                  <i className="bi bi-exclamation-circle-fill me-1"></i>
                                </span>
                              )}
                              {errors.teachingSubjects?.[i]?.grade?.message}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <label className="form-label">Subject</label>
                            <select
                              className="form-select"
                              {...register(`teachingSubjects.${i}.subject`)}
                            >
                              <option value="">Choose...</option>
                              {allSubjects.map((subject, key) => (
                                <option value={subject._id} key={key}>
                                  {subject.name}
                                </option>
                              ))}
                            </select>
                            <div className="form-error-div">
                              {errors.teachingSubjects?.[i]?.subject
                                ?.message && (
                                <span>
                                  <i className="bi bi-exclamation-circle-fill me-1"></i>
                                </span>
                              )}
                              {errors.teachingSubjects?.[i]?.subject?.message}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <label className="form-label">Section</label>
                            <select
                              className="form-select"
                              {...register(`teachingSubjects.${i}.section`)}
                            >
                              <option value="">Choose Section</option>
                              <option value="A">A</option>
                              <option value="B">B</option>
                              <option value="C">C</option>
                              <option value="D">D</option>
                            </select>
                            <div className="form-error-div">
                              {errors.teachingSubjects?.[i]?.section
                                ?.message && (
                                <span>
                                  <i className="bi bi-exclamation-circle-fill me-1"></i>
                                </span>
                              )}
                              {errors.teachingSubjects?.[i]?.section?.message}
                            </div>
                          </div>
                          <div className="col-md-1">
                            <i
                              className="fa fa-minus-circle text-danger mb-4"
                              style={{ fontSize: "20px", float: "right" }}
                              onClick={() => remove(i)}
                            ></i>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>

              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    append({ section: "" });
                  }}
                >
                  Add Teaching Subject
                </button>
              </div>
              <div className=" row mb-4">
                <div className="col-md-2">
                  <ButtonComponent
                    customClass="btn btn-danger text-light"
                    text="
                    Add Teacher"
                    loading={showLoader}
                    disabled={showLoader}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTeachertForm;
