import React from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import FormReciept from "../reciepts/FormReciept";
import { getToken } from "../../utility/utilityFunctions";
import placeholder from "../../assets/images/brand/placeholder.png";
import AdmissionForm from "../admissionForm/AdmissionForm";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormData from "form-data";
import ButtonComponent from "../../component/ButtonComponent";
// import FormReciept from "../reciepts/FormReciept";
import Sidebar from "../../component/Sidebar";
import GoBackButton from "../../component/GoBackButton";

const ChangeStatus = () => {
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const validationSchema = yup.object({
    grade: yup.string().required("Please choose correct grade."),
    reason: yup
    .string()
    .required("Reason is required.")
    .min(50, "Reason must be at least 30 characters long."),
  });
  const {
    register,
    setError,

    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      tution_fee_concession: 0,
      admission_fee_concession: 0,
    },
  });

  // const form_fee_check = watch("form_fee");
  const isRteStudent_check = watch("isRteStudent");
  const studentData = watch();

  const params = useParams();
  const [profilePicInAdmissionForm, setProfilePicInAdmissionForm] =
    useState("");
  // console.log("studentData:", studentData);

  const [isRecordSaved, setIsRecordSaved] = useState(false);
  const [prevTutionFeeConcessionCheck, setPrevTutionFeeConcessionCheck] =
    useState(0);

  const [screenData, setScreenData] = useState({
    branches: [],
    roles: [],
    grades: [],
    houses: [],
    student: {},
    gradesOfBranch: [],
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/student/${params.id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { student } = response.data;
        const { branches } = response.data;
        const { grades } = response.data;
        const { houses } = response.data;
        setScreenData({
          ...screenData,
          branches,
          grades,
          houses,
          student,
        });

        student.grade = student?.grade?._id;
        if (student.status == "registeredArchive") {
          // console.log("Sss");
          student.disgrade = student?.grade;
          delete student.grade;
          student.newStatus = "promoted";
          student.student_id = student.student_id;
        }

        reset(student);
      });
  }, [isRecordSaved]);

  const updateStudent = (data, event) => {
    // setShowLoader(true);

    // console.log("changeStatusData:", data);

    if (window.confirm("Are you sure you want to update this student ?")) {
      // console.log("changeStatusData:", data);

      axios
        .post(`${process.env.REACT_APP_BASE_URL}/admin/change_status`, data, {
          headers: {
            //   "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then((response) => {
          setTimeout(() => {
            setShowLoader(false);
          }, 1000);

          // Handle the API response here as needed
          if (response?.data.status) {
            window.notif({
              msg: "<b>Woohoo!!</b> Student Record Updated Successfully",
              type: "success",
            })};
            
        // Redirect to another page
        navigate(`/admin/students/${params.id}`);
        })
        .catch((error) => {
          // Handle any errors that occur during the API request
        })
        .finally(() => {
          // Reset the form after submission, whether it succeeded or failed
          reset();
        });
      // Prevent the default form submission
      // Note: This should be inside the if block
      event.preventDefault();
    }
  };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <GoBackButton />
              <h1 className="page-title">
                Transfer Registered Archive Student to Promoted
              </h1>
            </div>
            <div className="col-xl-12 col-lg-12">
              <div className="card">

                <div className="card-header d-flex justify-content-between">
              <h1> This section will be use for a student which current status is 'RegisteredArchive,' This will update the student status to Promoted</h1>

                </div>

                <div className="card-body">
                  <form
                    className="form-horizontal "
                    onSubmit={handleSubmit(updateStudent)}
                  >
                    <div className=" row mb-4">
                      <label className="col-md-3 form-label">
                        Name<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        <input
                          disabled="true"
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          {...register("name")}
                          id="name"
                        />
                      </div>
                    </div>
                    {/* Hidden Field */}
                    <input
                      type="hidden"
                      {...register("student_id", { value: `${params.id}` })} // You can provide a value here if needed
                      id="student"
                    />

                    <div className=" row mb-4">
                      <label className="col-md-3 form-label">
                        Mobile<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        <input
                          disabled="true"
                          autoComplete="off"
                          className="form-control"
                          type="number"
                          placeholder="Mobile"
                          {...register("mobile")}
                          id="mobile"
                        />
                        <div className="form-error-div">
                          {errors.mobile?.message && (
                            <span>
                              <i className="bi bi-exclamation-circle-fill me-1"></i>
                            </span>
                          )}
                          {errors.mobile?.message}
                        </div>
                      </div>
                    </div>

                    <div className=" row mb-4">
                      <label className="col-md-3 form-label">
                        Old Status<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        <input
                          disabled="true"
                          type="text"
                          className="form-control"
                          placeholder="Old Status"
                          {...register("status")}
                          id="status"
                        />
                      </div>
                    </div>
                    <div className=" row mb-4">
                      <label className="col-md-3 form-label">
                        New Status<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        <input
                          disabled="true"
                          type="text"
                          className="form-control"
                          placeholder="New Status"
                          {...register("newStatus")}
                          id="newStatus"
                        />
                      </div>
                    </div>
                    <div className=" row mb-4">
                      <label className="col-md-3 form-label">
                        {" "}
                        Current Grade
                      </label>
                      <div className="col-md-9">
                        <select
                          className="form-control"
                          {...register("disgrade")}
                          disabled="true"
                        >
                          <option>Select Grade</option>
                          {screenData.grades.map((grade) => (
                            <option key={grade._id} value={grade._id}>
                              {grade.name} ({grade.branch.name})
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className=" row mb-4">
                      <label className="col-md-3 form-label">
                        {" "}
                        New Grade<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        <select
                          className="form-control"
                          {...register("grade")}
                          id="grade"
                          disabled={
                            studentData?.status == "registered" ? true : false
                          }
                        >
                          <option value="">Select Grade</option>
                          {screenData.grades.map((grade) => (
                            <option key={grade._id} value={grade._id}>
                              {grade.name} ({grade.branch.name})
                            </option>
                          ))}
                        </select>
                        Select the grade in which you want to promote student in
                        this year
                        <div className="form-error-div">
                          {errors.grade?.message && (
                            <span>
                              <i className="bi bi-exclamation-circle-fill me-1"></i>
                            </span>
                          )}
                          {errors.grade?.message}
                        </div>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <label className="col-md-3 form-label">
                        Reason<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        <textarea
                          className="form-control"
                          placeholder="Reason"
                          {...register("reason")} // Register the textarea field with the name "reason"
                          id="reason"
                        />
                         <div className="form-error-div">
                          {errors.reason?.message && (
                            <span>
                              <i className="bi bi-exclamation-circle-fill me-1"></i>
                            </span>
                          )}
                          {errors.reason?.message}
                        </div>
                      </div>
                    </div>

                    <div className="row mb-4 d-flex justify-content-between">
                      <div className="col-md-2">
                        {studentData?.status == "registeredArchive" ? ( <button
                          type="submit"
                          className="btn btn-danger text-light"
                          disabled={showLoader}
                        >
                          {showLoader ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              />
                              Updating...
                            </>
                          ) : (
                            "Update Status"
                          )}
                        </button>) : (<></>)}
                       
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-12"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeStatus;
