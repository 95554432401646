import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import { getToken } from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";
import Pagination from "react-paginate";

const InventoryItem = () => {
  const [inventoryItems, setInventoryItems] = useState([]);
  const [categoryItemID, setCategoryItemID] = useState("all");
  const [inventoryCategories, setInventoryCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [studentsCounts, setStudentsCount] = useState();
  const [categoryItemStatus, setCategoryItemStatus] = useState("all");


  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/inventoryCategories`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { inventoryCategories } = response.data;

        setInventoryCategories(inventoryCategories);
      });
  }, []);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/inventoryItems`,
        {
          category_item_id: categoryItemID,
          currentPage, // Add currentPage here
          itemsPerPage,
          categoryItemStatus
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        const { inventoryItems, countOfInventoryItems } = response.data;
        setInventoryItems(inventoryItems);
        setStudentsCount(countOfInventoryItems);

        // console.log("inventoryItems:", inventoryItems);
      });
  }, [categoryItemID, currentPage ,categoryItemStatus]);

  function handleStatusClick(inventoryId) {
    console.log("id:", inventoryId);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/changeInventoryItemsStatus`,
        { item_id: inventoryId },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          // Success message
          const successMessage = response.data.message;
          if (successMessage) {
            window.notif({
              msg: `<b>Success:</b>  ${successMessage}`,
              type: "success",
            });
          }

          // Reset the form after successful submission
          window.location.reload();
        } else {
          // Error message
          console.log("in else");
          const errorMessage = response.data.message;
          if (errorMessage) {
            window.notif({
              msg: `<b>Error:</b> ${errorMessage}`,
              type: "error",
            });
          }
        }
      });
  }
  let count = 1;
  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <GoBackButton />
              <h1 className="page-title">Inventory Items</h1>
              <div className="text-center">
                <label htmlFor="branch" className="form-label">
                  Select Category
                </label>
                <select
                  className="form-control"
                  name=""
                  id="branch"
                  // value="all"
                  onChange={(e) => {
                    setCategoryItemID(e.target.value);
                  }}
                >
                  <option value="all" selected>
                    All
                  </option>
                  {inventoryCategories.map((inventoryCategory) => {
                    return (
                      <option value={inventoryCategory.slug}>
                        {inventoryCategory.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="text-center">
                <label htmlFor="branch" className="form-label">
                  Select Status
                </label>
                <select
                  className="form-control"
                  name=""
                  id="status"
                  // value="all"
                  onChange={(e) => {
                    setCategoryItemStatus(e.target.value);
                  }}
                >
                  <option value="all" selected>
                    All
                  </option>
                  <option value="true">
                    Active
                  </option>
                  <option value="false">
                    Inactive
                  </option>
                </select>
              </div>
              <Link
                type="button"
                to="/admin/InventoryItem/new"
                class="btn btn-danger text-light"
              >
                <i class="fe fe-user-plus"></i>&nbsp;Add Inventory Item
              </Link>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Sr. No.</b>
                                </th>
                                <th>
                                  <b>Inventory Item</b>
                                </th>
                                <th>
                                  <b>Category</b>
                                </th>
                                <th>
                                  <b>Specifications</b>
                                </th>
                                <th>
                                  <b>Action</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {inventoryItems.map((inventoryItem) => {
                                return (
                                  <tr key={inventoryItem._id}>
                                    <td>{count++}</td>
                                    <td>
                                      <div>{inventoryItem.name} </div>
                                      <div>
                                        <Link
                                          to={`/admin/inventory-items/${inventoryItem._id}`}
                                        >
                                          <span className="badge bg-success badge-sm  me-1 mb-1 mt-1 ml-10">
                                            On Branches : {inventoryItem.count}
                                          </span>
                                          <span className="badge bg-orange badge-sm  me-1 mb-1 mt-1 ml-10">
                                            Total : {inventoryItem.totalItems}
                                          </span>
                                        </Link>
                                      </div>
                                    </td>
                                    <td>{inventoryItem.category}</td>
                                    <td>{inventoryItem.specifications}</td>
                                    <td>
                                      <span
                                        className={`badge ${
                                          inventoryItem.action
                                            ? "bg-success"
                                            : "bg-danger"
                                        } badge-sm me-1 mb-1 mt-1 ml-10`}
                                        onClick={() =>
                                          handleStatusClick(inventoryItem._id)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {inventoryItem.action
                                          ? "Active"
                                          : "Inactive"}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <Pagination
                      pageCount={Math.ceil(studentsCounts / itemsPerPage)} // Calculate the total number of pages based on the number of students and items per page
                      pageRangeDisplayed={5}
                      marginPagesDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName="pagination"
                      activeClassName="active"
                      initialPage={currentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryItem;
