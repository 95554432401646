import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import {
  getToken,
  timezoneDateToddMMyyyy,
} from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";
import { useParams } from "react-router-dom";

const StudentsDetailsGradeWise = () => {
  const [students, setStudent] = useState(null); // Initialize state as an empty array

  const { grade, status, role ,branch, session} = useParams();
  // console.log("grade:", grade);
  // console.log("status:", status);
  // console.log("role:", role);
  // console.log("branch:", branch);
  // console.log("session:", session);

  useEffect(() => {
    const loaderElement = document.getElementsByClassName("loader")[0];
    if (loaderElement) {
      // If the loader element exists, you can modify its display style
      loaderElement.style.display = "block"; // or "block" or any other desired value
    }
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/studentDetail/${grade}/${status}/${role}/${branch}/${session}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        const { finalStudnets } = response.data;
        setStudent(finalStudnets); // Set the exam data to the state
        if (loaderElement) {
          // If the loader element exists, you can modify its display style
          loaderElement.style.display = "none"; // or "block" or any other desired value
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              {/* <GoBackButton /> */}

              <div className="col-md-4 d-flex">
                <div className="">
                  <h3>
                    {`${
                      status === "RegisteredPromoted"
                        ? "Registered & Promoted"
                        : status.charAt(0).toUpperCase() + status.slice(1)
                    } students of ${grade} grade`}
                  </h3>
                </div>
              </div>
            </div>
            <div className="row ">
            <div className="col-sm-4">
              <div className="loader" style={{ display: "none" }}>
                <h2>Hold On !!</h2>
                <h3>We are generating report....</h3>
                <div className="spinner-border text-danger" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            {students ? (   <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Sr.No</b>
                                </th>
                                <th>
                                  <b>Name</b>
                                </th>
                                <th>
                                  <b>Mobile</b>
                                </th>
                                <th>
                                  <b>Father</b>
                                </th>
                                <th>
                                  <div
                                    style={{
                                      whiteSpace: "normal", // or "break-spaces" depending on your requirements
                                      wordWrap: "break-word",
                                      width: "100px",
                                    }}
                                  >
                                    <b>Admission Fee Paid</b>
                                  </div>
                                </th>
                                <th>
                                  <div
                                    style={{
                                      whiteSpace: "normal", // or "break-spaces" depending on your requirements
                                      wordWrap: "break-word",
                                      width: "100px",
                                    }}
                                  >
                                    <b>Tution Fee Paid</b>
                                  </div>
                                </th>
                                <th>
                                  <div
                                    style={{
                                      whiteSpace: "normal", // or "break-spaces" depending on your requirements
                                      wordWrap: "break-word",
                                      width: "100px",
                                    }}
                                  >
                                    <b> Fee Paid Session</b>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {students?.map((student, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      <strong>
                                        {" "}
                                        <Link
                                          to={`/admin/students/${student.userStudent?._id}`}
                                        >
                                          {student?.userStudent?.name}
                                        </Link>
                                      </strong>

                                      <br></br>
                                      <span className="badge bg-success badge-sm  me-1 mb-1 mt-1 ml-10 text-white">
                                        <Link
                                          to={`/admin/collect-fee/${student.userStudent?._id}`}
                                        >
                                          <i
                                            className="icon icon-wallet ml-3"
                                            style={{ color: "white" }}
                                          />{" "}
                                          <span className="text-white">
                                            Collect fee
                                          </span>
                                        </Link>
                                      </span>
                                      {student.userStudent?.status ==
                                      "registered" ? (
                                        <>
                                          <span className="badge bg-success badge-sm  me-1 mb-1 mt-1 ml-10 text-white">
                                            {student.userStudent?.status}
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <span className="badge bg-warning badge-sm  me-1 mb-1 mt-1 ml-10 text-white">
                                            {student.userStudent?.status}
                                          </span>
                                        </>
                                      )}
                                    </td>
                                    <td>{student?.userStudent?.mobile}</td>
                                    <td>{student?.userStudent?.father_name}</td>
                                    <td>{student?.admmissionFeePaid}</td>
                                    <td>{student?.tutionFeePaid}</td>
                                    <td>{student?.session}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>) : ""}
         
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentsDetailsGradeWise;
