import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";

import { getDate } from "../../helpers/DateHelper";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import {
  convertDateToYYYYMMDD,
  getToken,
} from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";

const CleanlinessReport = () => {
  const [cleanlinessRecords, setCleanlinessRecords] = useState([]);
  const [branches, setBranches] = useState([]);
  let userInfo = localStorage.getItem("user");
  userInfo = JSON.parse(userInfo);
  const [filter, setFilter] = useState({
    branch: userInfo?.role === "admin" ? "all" : userInfo?.branch[0]?._id,
    // start_date: convertDateToYYYYMMDD(new Date()),
    start_date: new Date(),

    end_date: new Date(),
  });

  useEffect(() => {
    console.log(filter);
    document.getElementsByClassName("card-body")[0].style.display = "none";

    document.getElementsByClassName("loader")[0].style.display = "block";

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/cleanlinessRecords`,
        {
          ...filter,
          start_date: getDate({ Date: filter.start_date }),
          end_date: getDate({ Date: filter.end_date }),
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        const { cleanlinessRecords } = response.data;
        setCleanlinessRecords(cleanlinessRecords);

        console.log(cleanlinessRecords);

        document.getElementsByClassName("loader")[0].style.display = "none";
        document.getElementsByClassName("card-body")[0].style.display = "block";
      });

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/getBranches`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        console.log("response", response);
        const { branches } = response.data;
        setBranches(branches);
      });
  }, [filter]);

  // const handleImageClick = (imageBase64) => {
  //   window.open(`data:image/png;base64,${imageBase64}`, "_blank");
  // };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <h1 className="page-title">Cleanliness Records</h1>
              <GoBackButton />
            </div>
            <div className="row">
              {userInfo?.role == "admin" ? (
                <div className="col-md-3">
                  <label htmlFor="branches">Branch</label>
                  <select
                    className="form-control"
                    name="branch"
                    value={filter.branch}
                    id=""
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        branch: e.target.value,
                      });
                    }}
                  >
                    <option value="all">All Branches</option>
                    {branches.map((branch) => (
                      <option value={branch._id}>{branch.name}</option>
                    ))}
                  </select>
                </div>
              ) : (
                ""
              )}

              <div className="col-md-3">
                <label htmlFor="branches">Start Date</label>
                <DatePicker
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  selected={filter.start_date}
                  onChange={(date) => {
                    setFilter({
                      ...filter,
                      start_date: date,
                    });
                    console.log(date);
                  }}
                />
              </div>

              <div className="col-md-3">
                <label htmlFor="branches">End Date</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={filter.end_date}
                  className="form-control"
                  onChange={(date) => {
                    setFilter({
                      ...filter,
                      end_date: date,
                    });
                    console.log(date);
                  }}
                />
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div
                        className="card-body d-flex"
                        style={{ "flex-wrap": "wrap" }}
                      >
                        <div class="loader" style={{ display: "none" }}>
                          <h2>Hold On !!</h2>
                          <h3>We are generating report....</h3>
                          <div class="spinner-border text-danger" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                        {cleanlinessRecords.map((cleanlinessRecord) => {
                          return (
                            <div key={cleanlinessRecord._id}>
                              <div>
                                <a
                                  href={
                                    cleanlinessRecord?.image
                                      ? `data:image/png;base64,${cleanlinessRecord.image}`
                                      : ""
                                  }
                                  data-fancybox
                                  data-caption="Single image"
                                >
                                  <img
                                    height={"250px"}
                                    style={{ padding: "5px" }}
                                    src={
                                      cleanlinessRecord?.image
                                        ? `data:image/png;base64,${cleanlinessRecord.image}`
                                        : ""
                                    }
                                    alt="Cleanliness Image"
                                  />
                                </a>
                              </div>
                              <div style={{ textAlign: "center" }}><h4>
                              {cleanlinessRecord.branch.name}</h4></div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CleanlinessReport;
