import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  addTokenInCookies,
  removeCookies,
  getToken,
} from "../../utility/utilityFunctions";

const AddSettingForm = () => {
  const data = {
    name: JSON.parse(localStorage.getItem("user"))?.name,
    password: "",
    mobile: JSON.parse(localStorage.getItem("user"))?.mobile,
  };

  const [formData, setformData] = useState(data);

  useEffect(() => {
    // axios
    //   .get(`${process.env.REACT_APP_BASE_URL}/targets`, {
    //     headers: {
    //       Authorization: `Bearer ${getToken()}`,
    //     },
    //   })
    //   .then((response) => {
    //     console.log("response", response);
    //     const { branches, targets } = response.data;
    //   });
  }, []);

  const updateSetting = () => {
    if (formData.password.length > 0 && formData.password.length < 10) {
      window.notif({
        msg: "Password must be equal/greater than 10 characters.",
        type: "error",
        position: "center",
      });
      return false;
    }

    if (formData.mobile.length > 0 && formData.mobile.length < 10) {
        window.notif({
          msg: "Please 10 characters.",
          type: "error",
          position: "center",
        });
        return false;
      }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/update_user`, formData, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          localStorage.removeItem("jwt");
          localStorage.removeItem("user");
          document.cookie.split(";").forEach(function (c) {
            var date = new Date();
            date.setTime(date.getTime() - 60 * 60 * 1000);
            document.cookie = c
              .replace(/^ +/, "")
              .replace(/=.*/, "=;expires=" + date.toUTCString() + ";path=/");
          });

          const { token } = response.data;
          console.log("token", token);
          // Save JWT and user details to local storage
          localStorage.setItem("jwt", token);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          removeCookies();
          addTokenInCookies(token);
          window.notif({
            msg: "Settings Updated Successfully..",
            type: "success",
            position: "right",
          });
        } else {
          window.notif({
            msg: response.data.message,
            type: "error",
          });
        }
      });
  };

  return (
    <>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Fill following information</h4>
          </div>
          <div className="card-body">
            <form className="form-horizontal">
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Name</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    id="name"
                    value={formData.name}
                    onChange={(e) => {
                      setformData({
                        ...formData,
                        name: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className=" row mb-4">
                <label className="col-md-3 form-label">Mobile</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mobile"
                    id="mobile"
                    value={formData.mobile}
                    onChange={(e) => {
                      setformData({
                        ...formData,
                        mobile: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className=" row mb-4">
                <label className="col-md-3 form-label">New Password</label>
                <div className="col-md-9">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="New Password"
                    id="password"
                    value={formData.password}
                    onChange={(e) => {
                      setformData({
                        ...formData,
                        password: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className=" row mb-4">
                <div className="col-md-3">
                  <button
                    type="button"
                    class="btn btn-danger text-light"
                    onClick={() => {
                      updateSetting();
                    }}
                  >
                    <i class="fe fe-user-plus"></i>&nbsp;Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSettingForm;
