import React, { useState } from "react";
import logo from "../../assets/images/brand/logo.png";
import axios from "axios";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getToken } from "../../utility/utilityFunctions";

const Login = () => {
  const [userDetail, setUserDetail] = useState({
    mobile: "",
    password: "",
  });

  const [isPassword, setIsPassword] = useState(true);

  const navigate = useNavigate();

  const login = () => {
    if (
      !userDetail.mobile ||
      userDetail.mobile === "" ||
      !userDetail.password ||
      userDetail.password === ""
    ) {
      window.notif({
        msg: "Mobile and Password Both Are Mandatory",
        type: "error",
      });
    } else {
      //console.log(process.env.REACT_APP_BASE_URL);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/admin/login`, userDetail)
        .then((response) => {
          if (response.data.status) {
            localStorage.removeItem("jwt");
            localStorage.removeItem("user");
            const { token } = response.data;
            // Save JWT and user details to local storage
            localStorage.setItem("jwt", token);
            localStorage.setItem("user", JSON.stringify(response.data.user));

            navigate("/admin");
          } else {
            window.notif({
              msg: "Invalid Credentials",
              type: "error",
            });
          }
        })
        .catch((err) => {
          window.notif({
            msg: "Invalid Credentials",
            type: "error",
          });
        });
    }
  };


  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_BASE_URL}/admin/cron/attendance`, {
  //       headers: {
  //         Authorization: `Bearer ${getToken()}`,
  //       },
  //     })
  //     .then((response) => {
  //       console.log(response);
  //     });
  // }, []);


  return (
    <div className="page">
      <div className>
        <div className="col col-login mx-auto mt-7">
          <div className="text-center">
            <img
              style={{ width: "120px" }}
              src={logo}
              className="header-brand-img m-0"
              alt=""
            />
          </div>
        </div>
        <div className="container-login100">
          <div className="wrap-login100 p-6">
            <div className="login100-form validate-form">
              <span className="login100-form-title">WeCan Login</span>
              <div className="wrap-input100 validate-input input-group">
                <a
                  href="/admin/login"
                  className="input-group-text bg-white text-muted"
                  style={{ paddingLeft: "15px", paddingRight: "16px" }}
                >
                  <i className="fa fa-mobile-phone" aria-hidden="true" />
                </a>
                <input
                  className="input100 border-start-0 ms-0 form-control"
                  type="text"
                  placeholder="Mobile"
                  value={userDetail.mobile}
                  onChange={(e) => {
                    setUserDetail({
                      ...userDetail,
                      mobile: e.target.value,
                    });
                  }}
                />
              </div>
              <div
                className="wrap-input100 validate-input input-group"
                id="Password-toggle"
              >
                {/* <a
                  href="/admin/login"
                  className="input-group-text bg-white text-muted"
                >
                  <i className="zmdi zmdi-eye" aria-hidden="true" onClick={()=>{setIsPassword(!isPassword)}} />
                </a> */}
                <span className="input-group-text bg-white text-muted">
                  <i
                    className={`zmdi ${
                      isPassword ? "zmdi-eye" : "zmdi-eye-off"
                    }`}
                    aria-hidden="true"
                    onClick={() => {
                      setIsPassword(!isPassword);
                    }}
                  />
                </span>
                <input
                  className="input100 border-start-0 ms-0 form-control"
                  type={isPassword ? "password" : "text"}
                  placeholder="Password"
                  value={userDetail.password}
                  onChange={(e) => {
                    setUserDetail({
                      ...userDetail,
                      password: e.target.value,
                    });
                  }}
                />
              </div>
              <div
                className="wrap-input100 validate-input input-group"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <b>
                  <Link to='/admin/emailscreen'>Forgot Password?</Link>
                </b>
              </div>

              <div className="container-login100-form-btn">
                <button
                  className="login100-form-btn btn-primary"
                  onClick={() => {
                    login();
                  }}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
