import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import {
  getToken,
  getCurrentFullSession,
} from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";

const Syllabus = () => {
  const [syllabuses, setSyllabuses] = useState([]);
  const [Session, setSession] = useState(getCurrentFullSession());
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    console.log("Session:", Session);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/syllabuses`,
        { Session },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        const { syllabuses, sessions } = response.data;
        console.log("syllabus", syllabuses);
        setSyllabuses(syllabuses);
        setSessions(sessions);
      });
  }, [Session]);

  const deleteSyllabus = async (eventId) => {
    if (window.confirm("Are you sure want to delete the Syllabus ?")) {
      axios
        .delete(`${process.env.REACT_APP_BASE_URL}/admin/syllabus/${eventId}`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then((response) => {
          if (response?.data.success) {
            window.notif({
              msg: "<b>Woohoo!!</b> Syllabus Deleted Successfully",
              type: "success",
            });

            window.location.reload();
          } else {
            window.notif({
              msg: response?.data.error,
              type: "error",
              position: "center",
            });
          }
        });
    }
  };

  const copySyllabus = async (eventId) => {
    if (window.confirm("Are you sure want to copy the syllabus in new session ?")) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/admin/copy-syllabus/`, {
          syllabus_id: eventId
        },{
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then((response) => {
          if (response?.data.success) {
            window.notif({
              msg: "<b>Woohoo!!</b> Syllabus Copied Successfully",
              type: "success",
            });

            window.location.reload();
          } else {
            window.notif({
              msg: response?.data.error,
              type: "error",
              position: "center",
            });
          }
        });
    }
  };

  let count = 1;
  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <GoBackButton />

              <div className="col-md-4 d-flex">
                <div className="mb-3 col-6">
                  {/* <select
                    className="form-control"
                    name=""
                    value={Session}
                    id="session"
                    onChange={(e) => {
                      setSession(e.target.value);
                    }}
                  >
                    <option value="2022">Session 2022 - 2023</option>
                    <option value="2023">Session 2023 - 2024</option>
                  </select> */}

                  <label htmlFor="example-session" className="col-6 form-label">
                    Session
                  </label>

                  <select
                    className="form-control"
                    name=""
                    value={Session}
                    id="Session"
                    onChange={(e) => {
                      setSession(e.target.value);
                    }}
                  >
                    <option value="">Select Session</option>
                    {sessions.map((session) => {
                      return (
                        <option value={session.session_name}>
                          {session.session_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <Link
                type="button"
                to="/admin/syllabus/new"
                class="btn btn-danger text-light"
              >
                <i class="fe fe-user-plus"></i>&nbsp;Add Syllabus
              </Link>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Sr. No.</b>
                                </th>
                                <th>
                                  <b>Grade</b>
                                </th>
                                <th>
                                  <b>Subject</b>
                                </th>
                                <th>
                                  <b>Added By</b>
                                </th>
                                <th>
                                  <b>Session</b>
                                </th>
                                <th>
                                  <b>Action</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {syllabuses.map((syllabus) => {
                                return (
                                  <tr key={syllabus._id}>
                                    <td>{count++}</td>
                                    <td>
                                      <Link
                                        to={`/admin/syllabus/${syllabus._id}/chapters`}
                                      >
                                        {syllabus.common_grade.name}
                                      </Link>
                                    </td>
                                    <td>{syllabus?.subject?.name}</td>
                                    <td>{syllabus?.created_by?.name}</td>
                                    <td>{syllabus?.session}</td>
                                    <td>
                                      <button
                                        type="button"
                                        class="btn btn-danger text-light me-3"
                                        onClick={() =>
                                          deleteSyllabus(syllabus._id)
                                        }
                                      >
                                        Delete
                                      </button>
                                      <button
                                        type="button"
                                        class="btn btn-success text-light"
                                        onClick={() =>
                                          copySyllabus(syllabus._id)
                                        }
                                      >
                                        Copy
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Syllabus;
