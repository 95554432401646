import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getToken } from "../../utility/utilityFunctions";

const AddChapterForm = () => {
  const [chapters, setChapters] = useState([
    {
      chNo: "",
      startDate: "",
      endDate: "",
      name: "",
      copyCheckingDate: "",
      daysNeeded: "",
      tasks: [],
    },
  ]);
  const [screenData, setScreenData] = useState({
    grades: [],
    subjects: [],
  });
  const params = useParams();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/syllabusAddScrren`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        let { grades, subjects } = response.data;

        const acaemicHeadName = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).name;
        if(acaemicHeadName === "Academic Head 1"){
          grades = grades.filter(grade => ["NUR", "K.G", "PREP", "PG"].includes(grade.name));
        }else if(acaemicHeadName === "Academic Head 2"){
          grades = grades.filter(grade => ["I", "II", "III", "IV", "V"].includes(grade.name));
        }else{
          grades = grades.filter(grade => ["VI", "VII", "VIII", "IX", "X", "XI", "XII"].includes(grade.name));
        }

        setScreenData({
          ...screenData,
          grades,
          subjects,
        });
      });
  }, []);

  const handleChapterAdd = () => {
    setChapters([
      ...chapters,
      {
        chNo: "",
        startDate: "",
        endDate: "",
        name: "",
        copyCheckingDate: "",
        daysNeeded: "",
        tasks: [],
      },
    ]);
  };

  const handleChapterRemove = (index) => {
    setChapters(chapters.filter((chapter, i) => i !== index));
  };

  const handleTaskAdd = (chapterIndex) => {
    setChapters(
      chapters.map((chapter, index) => {
        if (index === chapterIndex) {
          return {
            ...chapter,
            tasks: [...chapter.tasks, { date: "", id : uuidv4(), name: "" }],
          };
        }
        return chapter;
      })
    );
  };

  const handleTaskRemove = (chapterIndex, taskIndex) => {
    setChapters(
      chapters.map((chapter, index) => {
        if (index === chapterIndex) {
          return {
            ...chapter,
            tasks: chapter.tasks.filter((task, i) => i !== taskIndex),
          };
        }
        return chapter;
      })
    );
  };

  const handleInputChange = (chapterIndex, taskIndex, key, value) => {
    setChapters(
      chapters.map((chapter, index) => {
        if (index === chapterIndex) {
          if (taskIndex === undefined) {
            return {
              ...chapter,
              [key]: value,
            };
          }
          return {
            ...chapter,
            tasks: chapter.tasks.map((task, i) => {
              if (i === taskIndex) {
                return {
                  ...task,
                  [key]: value,
                };
              }
              return task;
            }),
          };
        }
        return chapter;
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Hit POST API with the complete data    
    const syllabus_id = params.syllabusId;
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/chapter/new`, {
        syllabus_id,
        chapters
      }, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        console.log(response);
        if (response?.data.success) {
          window.notif({
            msg: "<b>Woohoo!!</b> Chapter Added Successfully",
            type: "success",
          });

          setTimeout(() => {
             window.location.href = `/admin/syllabus/${syllabus_id}/chapters`; 
          }, 1000);
        } else {
          window.notif({
            msg: response?.data.error,
            type: "error",
            position: "center",
          });
        }
      });
  };

  return (
    <form onSubmit={handleSubmit}>      
      {chapters.map((chapter, index) => (
        <div className="card mb-3" key={index}>
          <div className="card-body">
          {index > 0 && (
        <i
          className="fa fa-minus-circle text-danger mb-4"
          style={{ fontSize: "20px", float: "right" }}
          onClick={() => handleChapterRemove(index)}
        ></i>
      )}
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor={`chNo-${index}`}>Chapter Number</label>
                <input
                required 
                  type="text"
                  className="form-control"
                  id={`chNo-${index}`}
                  name={`chNo-${index}`}
                  value={chapter.chNo}
                  onChange={(e) =>
                    handleInputChange(index, undefined, "chNo", e.target.value)
                  }
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor={`startDate-${index}`}>Chapter Start Date</label>
                <input
                required 
                  type="date"
                  className="form-control"
                  id={`startDate-${index}`}
                  name={`startDate-${index}`}
                  value={chapter.startDate}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      undefined,
                      "startDate",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor={`endDate-${index}`}>Chapter End Date</label>
                <input
                required 
                  type="date"
                  className="form-control"
                  id={`endDate-${index}`}
                  name={`endDate-${index}`}
                  value={chapter.endDate}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      undefined,
                      "endDate",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor={`name-${index}`}>Chapter Name</label>
              <input
              required 
                type="text"
                className="form-control"
                id={`name-${index}`}
                name={`name-${index}`}
                value={chapter.name}
                onChange={(e) =>
                  handleInputChange(index, undefined, "name", e.target.value)
                }
              />
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor={`copyCheckingDate-${index}`}>
                  Copy Checking Date
                </label>
                <input
                required 
                  type="date"
                  className="form-control"
                  id={`copyCheckingDate-${index}`}
                  name={`copyCheckingDate-${index}`}
                  value={chapter.copyCheckingDate}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      undefined,
                      "copyCheckingDate",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor={`daysNeeded-${index}`}>
                  Days Needed for Completion
                </label>
                <input
                required 
                  type="number"
                  className="form-control"
                  id={`daysNeeded-${index}`}
                  name={`daysNeeded-${index}`}
                  value={chapter.daysNeeded}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      undefined,
                      "daysNeeded",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
            {chapter.tasks.map((task, taskIndex) => (
              <div
                className="card mb-3"
                style={{ backgroundColor: "#ecf1f8" }}
                key={taskIndex}
              >
                <div className="card-body" style={{ padding: "10px" }}>
                  <i
                    className="fa fa-minus-circle text-danger mb-4"
                    style={{ fontSize: "20px", float: "right" }}
                    onClick={() => handleTaskRemove(index, taskIndex)}
                  ></i>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor={`taskDate-${index}-${taskIndex}`}>
                        Task Date
                      </label>
                      <input
                      required 
                        type="date"
                        className="form-control"
                        id={`taskDate-${index}-${taskIndex}`}
                        name={`taskDate-${index}-${taskIndex}`}
                        value={task.date}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            taskIndex,
                            "date",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor={`taskType-${index}-${taskIndex}`}>
                        Task Type
                      </label>
                      <select
                      required 
                        className="form-control"
                        id={`taskType-${index}-${taskIndex}`}
                        name={`taskType-${index}-${taskIndex}`}
                        value={task.type}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            taskIndex,
                            "type",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select Type</option>
                        <option value="HW">HW</option>
                        <option value="CW">CW</option>
                        <option value="Activity">Activity</option>
                      </select>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor={`taskName-${index}-${taskIndex}`}>
                        Task Name
                      </label>
                      <input
                      required 
                        type="text"
                        className="form-control"
                        id={`taskName-${index}-${taskIndex}`}
                        name={`taskName-${index}-${taskIndex}`}
                        value={task.name}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            taskIndex,
                            "name",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="form-group">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleTaskAdd(index)}
              >
                Add Task
              </button>
            </div>
          </div>
        </div>
      ))}
      <div className="form-group">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleChapterAdd}
        >
          Add Chapter
        </button>
      </div>
      <div className="form-group">
        <button type="submit" className="btn btn-success">
          Update Syllabus
        </button>
      </div>
    </form>
  );
};
export default AddChapterForm;