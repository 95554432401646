exports.getToken = () => {
  // let token;
  
  let token = localStorage.getItem("jwt");
  // console.log(jwt,'jwt')
  // token = jwt.substring(4);
  // console.log(token,'tokenlocal')

  // const cookies = document.cookie.split("; ");
  // cookies.forEach((cookie) => {
  //   if (cookie.startsWith("jwt=")) {
  //     console.log(cookie,'cookiejwt')
  //     token = cookie.substring(4);
  //     console.log(token,'tokencookie')
  //   }
  // });
  return token;
};

exports.timezoneDateToddMMyyyy = (dateObj) => {
  const dateParts2 = new Date(dateObj).toISOString().split('T')[0];
  const dateParts = dateParts2.split("-");
  return `${dateParts[2]}-${dateParts[1]}-${dateParts[0].substring(2)}`; 
};

exports.getTimeFromDateTime = (dateString) => {
  // const dateString = '2023-04-19T07:36:11.000Z';
  const date = new Date(dateString);
  const options = {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
    timeZone: "UTC",
  };
  const timeString = new Intl.DateTimeFormat("default", options).format(date);
  return timeString;
};

exports.getCurrentDate = () => {
  const date = new Date();
  const dateString = date.toLocaleDateString().split("/");
  return `${dateString[1]}/${dateString[0]}/${dateString[2]}`;
};

exports.getSelectedOptionNameByID = (id) => {
  var select = document.getElementById(id);
  if(select.selectedIndex != '-1') {
    var selectedOption = select.options[select.selectedIndex];
    return selectedOption.text;
  } else {
    return '';
  }
};

exports.getCurrentDateTime = () => {
  const date = new Date();
  const dateString = date.toLocaleDateString().split("/");
  return (
    `${dateString[1]}/${dateString[0]}/${dateString[2]}` +
    " " +
    date.toLocaleTimeString()
  );
};

exports.getCurrentSession = () => {
  let currentDate = new Date();
  let currentMonth = currentDate.getMonth(); // returns a number between 0-11, 0 being January and 11 being December
  let currentYear = new Date().getFullYear();
  return currentMonth < 3 ? currentYear - 1 : currentYear;
};

exports.getCurrentFullSession = () => {
  let currentDate = new Date();
  let currentMonth = currentDate.getMonth(); // returns a number between 0-11, 0 being January and 11 being December
  let currentYear = new Date().getFullYear();
  var sessionStarts = currentMonth < 3 ? currentYear - 1 : currentYear;
  var sessionEnds = sessionStarts + 1;
  return `${sessionStarts}-${sessionEnds}`;
};

exports.getLoggedInUserInfo = () => {
  return localStorage.getItem("user") || false;
};

exports.getLoggedInUserBranchInfo = () => {
  const user = localStorage.getItem("user") || false;
  if (user) {
    return JSON.parse(user).branch[0];
  }
  return user;
};

exports.removeCookies = () => {
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};

exports.addTokenInCookies = (token) => {
  // Set a cookie that expires in one day
  var expires = new Date();
  expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000);
  document.cookie = `jwt=${token}; expires=${token}` + expires.toUTCString();
};

exports.digitToWords = (num) => {
  if (num === 0) {
    return "zero";
  }

  const ones = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  const scales = ["", "thousand", "million", "billion", "trillion"];

  let result = "";
  let scaleIndex = 0;

  while (num > 0) {
    let part = num % 1000;
    if (part > 0) {
      let partResult = "";

      if (part >= 100) {
        partResult += ones[Math.floor(part / 100)] + " hundred ";
        part = part % 100;
      }

      if (part >= 20) {
        partResult += tens[Math.floor(part / 10)] + " ";
        part = part % 10;
      }

      partResult += ones[part];

      result = partResult + " " + scales[scaleIndex] + " " + result;
    }

    num = Math.floor(num / 1000);
    scaleIndex++;
  }

  return result.trim();
};

exports.convertDateToYYYYMMDD = (currentDate) => {
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  console.log(formattedDate);
  return formattedDate;
};

exports.formatNumberIndianStyle = (number) => {
  var formattedNumber = number?.toLocaleString("en-IN");
  return formattedNumber;
};

exports.firstDateOfMonth = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed in JavaScript
  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-01`;
  return formattedDate;
};

exports.digitToWords = (num) => {
  if (num === 0) {
    return "zero";
  }

  const ones = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  const scales = ["", "thousand", "million", "billion", "trillion"];

  let result = "";
  let scaleIndex = 0;

  while (num > 0) {
    let part = num % 1000;
    if (part > 0) {
      let partResult = "";

      if (part >= 100) {
        partResult += ones[Math.floor(part / 100)] + " hundred ";
        part = part % 100;
      }

      if (part >= 20) {
        partResult += tens[Math.floor(part / 10)] + " ";
        part = part % 10;
      }

      partResult += ones[part];

      result = partResult + " " + scales[scaleIndex] + " " + result;
    }

    num = Math.floor(num / 1000);
    scaleIndex++;
  }

  return result.trim();
};