import React, { useEffect, useState } from "react";
import axios from "axios";
import { getToken } from "../../utility/utilityFunctions";

const AddExamForm = () => {
  const [examName, setExamName] = useState("");
  const [date, setDate] = useState("");
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [branches, setBranches] = useState([]);
  const [testType, setTestType] = useState("");
  const [session, setSession] = useState("");
  const [description, setDescription] = useState("");
  const [totalMarks, setTotalMarks] = useState(0);

  const [screenData, setScreenData] = useState({
    grades: [],
    subjects: [],
    branches: [],
    sessionses:[]
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/examAddScreen`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        let { subjects, grades, branches ,sessionses} = response.data;

        const acaemicHeadName = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).role;
        if(acaemicHeadName === "pre_primary_academic_incharge"){
          grades = grades.filter(grade => ["NUR", "K.G", "PREP", "PG"].includes(grade.name));
        }else if(acaemicHeadName === "primary_academic_incharge"){
          grades = grades.filter(grade => ["I", "II", "III", "IV", "V"].includes(grade.name));
        }else{
          grades = grades.filter(grade => ["VI", "VII", "VIII", "IX", "X", "XI", "XII"].includes(grade.name));
        }

        setScreenData({
          ...screenData,
          grades,
          subjects,
          branches,
          sessionses,
        });
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name: examName,
      date,
      grades,
      branches,
      subjects,
      description,
      session,
      totalMarks,
      testType
    };
console.log("exam data:",data)
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/exam/new`, data, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        console.log(response);
        if (response?.data.success) {
          window.notif({
            msg: "<b>Woohoo!!</b> Exam Created Successfully",
            type: "success",
          });
          setExamName("");
          setDate("");
          setDescription("");
        } else {
          window.notif({
            msg: response?.data.error,
            type: "error",
            position: "center",
          });
        }
      });
  };

  const handleGradesChange = (e) => {
    const options = e.target.options;
    const selectedGrades = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedGrades.push(options[i].value);
      }
    }
    setGrades(selectedGrades);
  };

  const handleBranchChange = (e) => {
    const options = e.target.options;
    const selectedBranch = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedBranch.push(options[i].value);
      }
    }
    setBranches(selectedBranch);
  };

  const handleSubjectChange = (e) => {
    const options = e.target.options;
    const selectedSubjects = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedSubjects.push(options[i].value);
      }
    }
    setSubjects(selectedSubjects);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="examName">Exam Name</label>
        <input
          type="text"
          className="form-control"
          id="examName"
          value={examName}
          onChange={(e) => setExamName(e.target.value)}
          required
        />
      </div>
      <div className="d-flex">
        <div className="form-group col-md-3">
          <label htmlFor="date">Date</label>
          <input
            type="date"
            className="form-control"
            id="date"
            min={new Date().toISOString().split("T")[0]}
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        </div>

        <div className="form-group col-md-3">
          <label htmlFor="branches">Branches</label>
          <select
            multiple
            className="form-control"
            id="branches"
            value={branches}
            onChange={handleBranchChange}
            required
          >
            {/* <option value="all_branches" selected>
              All Branches
            </option> */}
            {screenData.branches.map((branch) => (
              <option value={branch._id}>{branch.name}</option>
            ))}
          </select>
        </div>

        <div className="form-group col-md-3">
          <label htmlFor="grades">Grades</label>
          <select
            multiple
            className="form-control"
            id="grades"
            value={grades}
            onChange={handleGradesChange}
            required
          >
            {/* <option value="all_grades" selected>
              All Grades
            </option> */}
            {screenData.grades.map((grade) => (
              <option value={grade._id}>{grade.name}</option>
            ))}
          </select>
        </div>

        <div className="form-group col-md-3">
          <label htmlFor="subjects">Subjects</label>
          <select
            multiple
            className="form-control"
            id="subjects"
            value={subjects}
            onChange={handleSubjectChange}
            required
          >
            {/* <option value="all_subjects" selected>
              Select Subject
            </option> */}
            {screenData.subjects.map((subject) => (
              <option value={subject._id}>{subject.name}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="d-flex">
        <div className="form-group col-md-3">
          <label htmlFor="subjects">Type</label>
          <select
            className="form-control"
            id="clyclic-1"
            value={testType}
            onChange={(e) => {
              setTestType(e.target.value);
            }}
            required
          >
            <option value="" selected>
              Select Test Type
            </option>
            <option value="cyclic_test_1">Cyclic Test 1</option>
            <option value="cyclic_test_2">Cyclic Test 2</option>
            <option value="cyclic_test_3">Cyclic Test 3</option>
            <option value="cyclic_test_4">Cyclic Test 4</option>
            <option value="half_yearly">Half Yearly</option>
            <option value="yearly">Yearly</option>
            <option value="unplanned">Unplanned</option>
          </select>
        </div>

        <div className="form-group col-md-3">
          <label htmlFor="subjects">Total Marks</label>
          <input
            type="number"
            className="form-control"
            id="marks"
            value={totalMarks}
            onChange={(e) => setTotalMarks(e.target.value)}
            required
          />
        </div>

        <div className="form-group col-md-3">
        <label htmlFor="subjects">Session</label>
          <select
            className="form-control"
            id="session"
            value={session}
            onChange={(e) => {
              console.log("Selected value:", e.target.value);
              setSession(e.target.value);
            }}
            required
          >
             <option value="" selected>
              Select Session
            </option>
            {screenData.sessionses.map((sessions) => (
              <option value={sessions.session_name}>{sessions.session_name}</option>
            ))}
            {/* <option value="" selected>
              Select Session
            </option>
            <option value="2022">2022</option> 
            <option value="2023">2023</option> */}
          </select>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="description">Description</label>
        <textarea
          className="form-control"
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <button type="submit" className="btn btn-primary">
        Create Exam
      </button>
    </form>
  );
};
export default AddExamForm;
