import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Nav, Accordion } from "react-bootstrap";
import {
  FaUserTie,
  FaUsers,
  FaBookOpen,
  FaRupeeSign,
  FaRegClock,
  FaRegEdit,
  FaWhmcs,
  FaUniversity,
  FaSearchengin,
  FaRegListAlt,
  FaSlack,
  FaChartBar,
  FaUserFriends,
  FaBuffer,
  FaBroom,
} from "react-icons/fa";

const SidebarForAdmin = () => {
  return (
    <>
      {/* Student */}
      <li className="slide">
        <a
          className="side-menu__item"
          data-bs-toggle="slide"
          href="javascript:void(0)"
        >
          <FaUsers className="side-menu__icon" />

          <span className="side-menu__label">Student</span>
          <i className="angle fe fe-chevron-right" />
        </a>
        <ul className="slide-menu">
          <li className="panel sidetab-menu">
            <div className="panel-body tabs-menu-body p-0 border-0">
              <div className="tab-content">
                <div className="tab-pane" id="side9" role="tabpanel">
                  <ul className="sidemenu-list">
                    <li>
                      <Link className="slide-item" to="/admin/students">
                        <span className="side-menu__label">Students List</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/student_report_one"
                      >
                        <span className="side-menu__label">
                          Student Report
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/reports/studentStrength"
                      >
                        <span className="side-menu__label">
                          Student Strength Dashboard
                        </span>
                      </Link>
                    </li>
                    {/* <li>
                      <Link className="slide-item" to="/admin/mark/feesRecord">
                        <span className="side-menu__label">
                          Fees Correction Request
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/mark/feesCorrection"
                      >
                        <span className="side-menu__label">
                          Fees Correction Logs
                        </span>
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
      {/*  */}
      {/* Fee correction */}
      <li className="slide">
        <a
          className="side-menu__item"
          data-bs-toggle="slide"
          href="javascript:void(0)"
        >
          <FaRupeeSign className="side-menu__icon" />

          <span className="side-menu__label">Fees Correction</span>
          <i className="angle fe fe-chevron-right" />
        </a>
        <ul className="slide-menu">
          <li className="panel sidetab-menu">
            <div className="panel-body tabs-menu-body p-0 border-0">
              <div className="tab-content">
                <div className="tab-pane" id="side9" role="tabpanel">
                  <ul className="sidemenu-list">
                    <li>
                      <Link className="slide-item" to="/admin/mark/feesRecord">
                        <span className="side-menu__label">
                          Fees Correction Request
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/mark/feesCorrection"
                      >
                        <span className="side-menu__label">
                          Fees Correction Logs
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/duplicateInvoiceStudents"
                      >
                        <span className="side-menu__label">
                          Duplicate Invoice
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/getDeletedInvoices"
                      >
                        <span className="side-menu__label">
                          Deleted Invoice
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/getPaidRTEStudents"
                      >
                        <span className="side-menu__label">
                          Paid RTE Students
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
      {/*  */}
      {/* Teacher */}
      <li className="slide">
        <a
          className="side-menu__item"
          data-bs-toggle="slide"
          href="javascript:void(0)"
        >
          <FaUserTie className="side-menu__icon" />

          <span className="side-menu__label">Teacher</span>
          <i className="angle fe fe-chevron-right" />
        </a>
        <ul className="slide-menu">
          <li className="panel sidetab-menu">
            <div className="panel-body tabs-menu-body p-0 border-0">
              <div className="tab-content">
                <div className="tab-pane" id="side9" role="tabpanel">
                  <ul className="sidemenu-list">
                    <li>
                      <Link className="slide-item" to="/admin/teachers">
                        <span className="side-menu__label">Teachers List</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/admin/teacherReport">
                        <span className="side-menu__label">
                          Teacher Reports
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/admin/centralHeadLeave">
                        <span className="side-menu__label">
                          Central Head Leaves
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/teacherArchiveList"
                      >
                        <span className="side-menu__label">
                          Archive Teachers
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
      {/*  */}

      {/* user */}
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/users"
        >
          <FaUserFriends className="side-menu__icon" />
          <span className="side-menu__label">User</span>
        </Link>
      </li>
      {/*  */}
      {/* Exam report */}
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/mark"
        >
          <FaBuffer className="side-menu__icon" />
          <span className="side-menu__label">Exam Report</span>
        </Link>
      </li>
      {/*  */}

      {/* {missing section report} */}
      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/teacherMissingSection"
        >
          <FaRegEdit className="side-menu__icon " />

          <span className="side-menu__label">Missing Secton Report</span>
        </Link>
      </li> */}
      {/* {} */}

      {/* Syllabus */}
      <li className="slide">
        <a
          className="side-menu__item"
          data-bs-toggle="slide"
          href="javascript:void(0)"
        >
          <FaBookOpen className="side-menu__icon" />

          <span className="side-menu__label">Syllabus</span>
          <i className="angle fe fe-chevron-right" />
        </a>
        <ul className="slide-menu">
          <li className="panel sidetab-menu">
            <div className="panel-body tabs-menu-body p-0 border-0">
              <div className="tab-content">
                <div className="tab-pane" id="side9" role="tabpanel">
                  <ul className="sidemenu-list">
                    {/* <li>
                      <Link className="slide-item" to="/admin/grades">
                        <span className="side-menu__label">Grades</span>
                      </Link>
                    </li> */}
                    <li>
                      <Link className="slide-item" to="/admin/syllabus_preview">
                        <span className="side-menu__label">Syllabus</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/admin/subjects">
                        <span className="side-menu__label">
                          Manage Subjects
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
      {/*  */}
      {/* Master */}
      <li className="slide">
        <a
          className="side-menu__item"
          data-bs-toggle="slide"
          href="javascript:void(0)"
        >
          <FaUniversity className="side-menu__icon " />

          <span className="side-menu__label">Masters</span>
          <i className="angle fe fe-chevron-right" />
        </a>
        <ul className="slide-menu">
          <li className="panel sidetab-menu">
            <div className="panel-body tabs-menu-body p-0 border-0">
              <div className="tab-content">
                <div className="tab-pane" id="side9" role="tabpanel">
                  <ul className="sidemenu-list">
                    <li>
                      <Link className="slide-item" to="/admin/sessionManager">
                        <span className="side-menu__label">
                          Session Manager
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/admin/grades">
                        <span className="side-menu__label">Grades & fees</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/admin/roles">
                        <span className="side-menu__label">Roles</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/admin/branch">
                        <span className="side-menu__label">Branches</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/admin/houses">
                        <span className="side-menu__label">House</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/admin/events">
                        <span className="side-menu__label">Events</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
      {/*  */}
      {/* Attendance Report */}
      <li className="slide">
        <a
          className="side-menu__item"
          data-bs-toggle="slide"
          href="javascript:void(0)"
        >
          <FaRegEdit className="side-menu__icon " />

          <span className="side-menu__label">Attendance Report</span>
          <i className="angle fe fe-chevron-right" />
        </a>
        <ul className="slide-menu">
          <li className="panel sidetab-menu">
            <div className="panel-body tabs-menu-body p-0 border-0">
              <div className="tab-content">
                <div className="tab-pane" id="side9" role="tabpanel">
                  <ul className="sidemenu-list">
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/student_attendance_report"
                      >
                        <span className="side-menu__label">
                          Student Attendance Report
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/teacher_attendance_report"
                      >
                        <span className="side-menu__label">
                          Teacher Attendance Report
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
      {/*  */}

      {/*Finance Reports*/}

      <li className="slide">
        <a
          className="side-menu__item"
          data-bs-toggle="slide"
          href="javascript:void(0)"
        >
          <FaRupeeSign className="side-menu__icon" />
          <span className="side-menu__label">Finance Reports</span>
          <i className="angle fe fe-chevron-right" />
        </a>
        <ul className="slide-menu">
          <li className="panel sidetab-menu">
            <div className="panel-body tabs-menu-body p-0 border-0">
              <div className="tab-content">
                <div className="tab-pane" id="side9" role="tabpanel">
                  <ul className="sidemenu-list">
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/finance_report_one"
                      >
                        <span className="side-menu__label">
                          Students Fee Record
                        </span>
                      </Link>
                    </li>
                    <li className="slide">
                      <Link
                        className="slide-item"
                        to="/admin/finance_report_two"
                      >
                        <span className="side-menu__label">Report 02</span>
                      </Link>
                    </li>
                    <li className="slide">
                      <Link
                        className="slide-item"
                        to="/admin/finance_report_three"
                      >
                        <span className="side-menu__label">
                          Daily Fee Collection Record
                        </span>
                      </Link>
                    </li>

                    {/* <li className="sub-slide">
                      <a
                        className="sub-side-menu__item"
                        data-bs-toggle="sub-slide"
                        href="javascript:void(0)"
                      >
                        <span className="sub-side-menu__label">Forms</span>
                        <i className="sub-angle fe fe-chevron-right" />
                      </a>
                      <ul className="sub-slide-menu">
                        <li>
                          <a
                            href="form-elements.html"
                            className="sub-slide-item"
                          >
                            {" "}
                            Form Elements
                          </a>{" "}
                        </li>
                        <li>
                          <a
                            href="form-layouts.html"
                            className="sub-slide-item"
                          >
                            {" "}
                            Form Layouts
                          </a>{" "}
                        </li>
                        <li>
                          <a
                            href="form-input-spinners.html"
                            className="sub-slide-item"
                          >
                            {" "}
                            Form Input Spinners
                          </a>
                        </li>
                      </ul>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>

      {/* Reviews */}
      <li className="slide">
        <a
          className="side-menu__item"
          data-bs-toggle="slide"
          href="javascript:void(0)"
        >
          <FaSearchengin className="side-menu__icon " />

          <span className="side-menu__label">Reviews</span>
          <i className="angle fe fe-chevron-right" />
        </a>
        <ul className="slide-menu">
          <li className="panel sidetab-menu">
            <div className="panel-body tabs-menu-body p-0 border-0">
              <div className="tab-content">
                <div className="tab-pane" id="side9" role="tabpanel">
                  <ul className="sidemenu-list">
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/student_reviews_report"
                      >
                        <span className="side-menu__label">
                          Student Reviews
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/teacher_reviews_report"
                      >
                        <span className="side-menu__label">
                          Teacher Reviews
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
      {/*  */}

      {/* Inventory */}
      <li className="slide">
        <a
          className="side-menu__item"
          data-bs-toggle="slide"
          href="javascript:void(0)"
        >
          <FaSlack className="side-menu__icon " />

          <span className="side-menu__label">Inventory</span>
          <i className="angle fe fe-chevron-right" />
        </a>
        <ul className="slide-menu">
          <li className="panel sidetab-menu">
            <div className="panel-body tabs-menu-body p-0 border-0">
              <div className="tab-content">
                <div className="tab-pane" id="side9" role="tabpanel">
                  <ul className="sidemenu-list">
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/inventory-items-category"
                      >
                        <span className="side-menu__label">
                          Inventory Category
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/admin/inventory-items">
                        <span className="side-menu__label">
                          Inventory Items
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
      {/*  */}

      {/* Breakage */}
      <li className="slide">
        <a
          className="side-menu__item"
          data-bs-toggle="slide"
          href="javascript:void(0)"
        >
          <FaRegListAlt className="side-menu__icon " />

          <span className="side-menu__label">Breakage</span>
          <i className="angle fe fe-chevron-right" />
        </a>
        <ul className="slide-menu">
          <li className="panel sidetab-menu">
            <div className="panel-body tabs-menu-body p-0 border-0">
              <div className="tab-content">
                <div className="tab-pane" id="side9" role="tabpanel">
                  <ul className="sidemenu-list">
                    <li>
                      <Link className="slide-item" to="/admin/breakage-records">
                        <span className="side-menu__label">
                          Manage Breakage Records
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/breakage-items-category"
                      >
                        <span className="side-menu__label">
                          Breakage Category
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
      {/*  */}

      {/* Circular */}
      <li className="slide">
        <a
          className="side-menu__item"
          data-bs-toggle="slide"
          href="javascript:void(0)"
        >
          <FaRegClock className="side-menu__icon " />

          <span className="side-menu__label">Circular</span>
          <i className="angle fe fe-chevron-right" />
        </a>
        <ul className="slide-menu">
          <li className="panel sidetab-menu">
            <div className="panel-body tabs-menu-body p-0 border-0">
              <div className="tab-content">
                <div className="tab-pane" id="side9" role="tabpanel">
                  <ul className="sidemenu-list">
                    <li>
                      <Link className="slide-item" to="/admin/circular">
                        <span className="side-menu__label">Circular</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
      {/*  */}

      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/ch_targets"
        >
          <FaChartBar className="side-menu__icon " />
          <span className="side-menu__label">Manage CH Targets</span>
        </Link>
      </li>

      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/cleanliness-report"
        >
          <FaBroom className="side-menu__icon " />
          <span className="side-menu__label">Cleanliness Report</span>
        </Link>
      </li>

      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/generalSettings"
        >
          <FaWhmcs className="side-menu__icon " />

          <span className="side-menu__label">General Settings</span>
        </Link>
      </li>

      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/teachers"
        >
          <FaUserTie className="side-menu__icon" />

          <span className="side-menu__label">Teachers</span>
        </Link>
      </li> */}
      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/students"
        >
          <FaUsers className="side-menu__icon" />

          <span className="side-menu__label">Students</span>
        </Link>
      </li>

      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/reports/studentStrength"
        >
          <FaUserTie className="side-menu__icon" />

          <span className="side-menu__label">Student Strength Dashboard</span>
        </Link>
      </li>

      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/students-follow-up"
        >
          <FaHeadphonesAlt className="side-menu__icon" />

          <span className="side-menu__label">Students Follow-Up</span>
        </Link>
      </li> */}

      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/syllabus_preview"
        >
          <FaBookOpen className="side-menu__icon" />

          <span className="side-menu__label">Syllabus</span>
        </Link>
      </li> */}

      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/circular"
        >
          <FaRegClock className="side-menu__icon " />
          <span className="side-menu__label">Circular</span>
        </Link>
      </li> */}
      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/roles"
        >
          <FaRavelry className="side-menu__icon " />
          <span className="side-menu__label">Roles</span>
        </Link>
      </li> */}
      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/branch"
        >
          <FaArchway className="side-menu__icon " />
          <span className="side-menu__label">Branches</span>
        </Link>
      </li> */}
      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/grades"
        >
          <FaAward className="side-menu__icon" />
          <span className="side-menu__label">Grades</span>
        </Link>
      </li> */}

      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/houses"
        >
          <FaUniversity className="side-menu__icon " />
          <span className="side-menu__label">House</span>
        </Link>
      </li> */}

      {/* 
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/teacherReport"
        >
          <FaRegEdit className="side-menu__icon fe fe-home" />
          <span className="side-menu__label">Teacher Reports</span>
        </Link>
      </li> */}

      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/events"
        >
          <FaGifts className="side-menu__icon " />
          <span className="side-menu__label">Events</span>
        </Link>
      </li> */}
      {/* 
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/student_report_one"
        >
          <FaRegEdit className="side-menu__icon fe fe-home" />

          <span className="side-menu__label">Student Report 01</span>
        </Link>
      </li> */}

      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/student_reviews_report"
        >
          <FaSearchengin className="side-menu__icon " />
          <span className="side-menu__label">Student Reviews</span>
        </Link>
      </li>

      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/teacher_reviews_report"
        >
          <FaSearchengin className="side-menu__icon " />
          <span className="side-menu__label">Teacher Reviews</span>
        </Link>
      </li> */}

      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/student_attendance_report"
        >
          <FaRegEdit className="side-menu__icon " />
          <span className="side-menu__label">Student Attendance Report</span>
        </Link>
      </li> */}

      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/teacher_attendance_report"
        >
          <FaRegEdit className="side-menu__icon " />

          <span className="side-menu__label">Teacher Attendance Report</span>
        </Link>
      </li> */}

      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/breakage-records"
        >
          <FaRegListAlt className="side-menu__icon " />
          <span className="side-menu__label">Manage Breakage Records</span>
        </Link>
      </li> */}

      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/inventory-items-category"
        >
          <FaRegNewspaper className="side-menu__icon" />
          <span className="side-menu__label">Inventory Category</span>
        </Link>
      </li>
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/inventory-items"
        >
          <FaSlack className="side-menu__icon " />
          <span className="side-menu__label">Inventory Items</span>
        </Link>
      </li> */}
      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/subjects"
        >
          <FaAddressBook className="side-menu__icon " />
          <span className="side-menu__label">Manage Subjects</span>
        </Link>
      </li> */}
      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/breakage-items-category"
        >
          <FaRegNewspaper className="side-menu__icon" />

          <span className="side-menu__label">Breakage Category</span>
        </Link>
      </li> */}

      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/finance_report_one"
        >
          <FaRupeeSign className="side-menu__icon" />
          <span className="side-menu__label">Finance Report 01</span>
        </Link>
      </li>
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/finance_report_two"
        >
          <FaRupeeSign className="side-menu__icon" />

          <span className="side-menu__label">Finance Report 02</span>
        </Link>
      </li>
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/finance_report_three"
        >
          <FaRupeeSign className="side-menu__icon" />

          <span className="side-menu__label">Finance Report 03</span>
        </Link>
      </li> */}
    </>
  );
};

export default SidebarForAdmin;
