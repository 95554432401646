import React from 'react';
import axios from "axios";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getDate } from "../../helpers/DateHelper";
import {
    getToken,
} from "../../utility/utilityFunctions";

const AdminDashboard = () => {
    // for admin dashboard
    const [registeredStudents, setRegisteredStudents] = useState(null);
    const [absentStudents, setAbsentStudents] = useState(null);
    const [absentTeachers, setAbsentTeachers] = useState(null);
    const [breakageReports, setBreakageReports] = useState(null);



    const [absentCentralHeads, setAbsentCentralHeads] = useState([]);

    const [studentsRemarks, setStudentsRemark] = useState(null);
    const [studentsAppreciation, setStudentsAppreciation] = useState(null);
    const [lateArrivingTeachers, setLateArrivingTeachers] = useState(null);
    const [lateArrivingCentralHeads, setLateArrivingCentralHeads] = useState([]);

    const [cleannessReports, setCleannessReports] = useState(null);
    const [syllabusDelay, setSyllabusDelay] = useState(null);
    const [syllabusNotAdded, setSyllabusNotAdded] = useState(null);
    const [totalFeeCollection, setTotalFeeCollection] = useState(null);
    const [dueFee, setDueFee] = useState(null);


    const [totalTeachers, setTotalTeachers] = useState(null);

    const today = getDate();
    const tomorrow = getDate({ addDate: { days: 1 }, Date: today });

    const [start_date, setStartDate] = useState(today);
    const [end_date, setEndDate] = useState(tomorrow);
    const [session, setSession] = useState(2023);


    const [recordType, setRecordType] = useState("active");


    useEffect(() => {
        const loaderElement = document.getElementsByClassName("loader")[0];
        if (loaderElement) {
            loaderElement.style.display = "block";
        }

        if (
            localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).role === "admin"
        ) {
            console.log("in admin dashboard");

            (async () => {
                let response = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/dashboard`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${getToken()}`,
                        },
                    }
                )

                const { student_count, total_absent_students, total_absent_teachers, breakage_records } = response.data;

                setRegisteredStudents(student_count);
                setAbsentStudents(total_absent_students);
                setAbsentTeachers(total_absent_teachers);
                setBreakageReports(breakage_records);
                // setTotalStudents(student_count);
                // setTeachersWithoutSection(teacher_without_section);
                // setLeaveData(teacher_leaves);
                // setBreakageRecords(breakage_records);
                // setTotalMissingDobStudents(student_missing_dob);
                // setStudentsWithoutSection(students_without_section);
                // setTeacherswithoutPaycode(teachers_without_paycode);
                // setPromotedStudnets(students_paid_less_amount);
            })();
            
            const apiPromises = [

                // axios.post(
                //   `${process.env.REACT_APP_BASE_URL}/admin/totalAbsentCentralHeades`,
                //   {},
                //   {
                //     headers: {
                //       Authorization: `Bearer ${getToken()}`,
                //     },
                //   }
                // ), 
                axios.post(
                    `${process.env.REACT_APP_BASE_URL}/admin/studentsRemarks`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${getToken()}`,
                        },
                    }
                ),
                axios.post(
                    `${process.env.REACT_APP_BASE_URL}/admin/studentsAppreciation`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${getToken()}`,
                        },
                    }
                ),
                axios.post(
                    `${process.env.REACT_APP_BASE_URL}/admin/lateArrivingTeachers`,
                    {
                        start_date,
                        end_date,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${getToken()}`,
                        },
                    }
                ),
                axios.post(
                    `${process.env.REACT_APP_BASE_URL}/admin/cleannessReports`,
                    {
                        start_date,
                        end_date,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${getToken()}`,
                        },
                    }
                ),
                axios.post(
                    `${process.env.REACT_APP_BASE_URL}/admin/totalFeeCollection`,
                    {
                        start_date,
                        end_date,
                        session,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${getToken()}`,
                        },
                    }
                ),
                // axios.post(
                //   `${process.env.REACT_APP_BASE_URL}/admin/syllabusDelay`,
                //   {},
                //   {
                //     headers: {
                //       Authorization: `Bearer ${getToken()}`,
                //     },
                //   }
                // ),
                axios.post(
                    `${process.env.REACT_APP_BASE_URL}/admin/syllabusNotAdded`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${getToken()}`,
                        },
                    }
                ),
                axios.post(
                    `${process.env.REACT_APP_BASE_URL}/admin/dueFee`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${getToken()}`,
                        },
                    }
                ),
            ];

            Promise.all(apiPromises)
                .then(
                    ([
                        // totalAbsentCentralHeadesResponse,
                        studentsRemarksResponse,
                        studentsAppreciationResponse,
                        lateArrivingTeachersResponse,
                        cleannessReportsResponse,
                        totalFeeCollectionResponse,
                        // syllabusDelayResponse,
                        syllabusNotAddedResponse,
                        dueFeeResponse,
                    ]) => {


                        const { studentsRemarks } = studentsRemarksResponse.data;
                        setStudentsRemark(studentsRemarks);
                        // console.log("studentsRemarks:", studentsRemarks);
                        const { studentsAppreciation } = studentsAppreciationResponse.data;
                        setStudentsAppreciation(studentsAppreciation);
                        // console.log("studentsRemarks:", studentsRemarks);

                        const { lateArrivingTeachers } = lateArrivingTeachersResponse.data;
                        setLateArrivingTeachers(lateArrivingTeachers);
                        // console.log("lateArrivingTeachers:", lateArrivingTeachers);

                        const { cleannessReports } = cleannessReportsResponse.data;
                        setCleannessReports(cleannessReports);
                        // console.log("cleannessReports:", cleannessReports);
                        const { totalFeeCollection } = totalFeeCollectionResponse.data;
                        setTotalFeeCollection(totalFeeCollection);
                        // console.log("totalFeeCollection:", totalFeeCollection);
                        // const { syllabusDelay } = syllabusDelayResponse.data;
                        // setSyllabusDelay(syllabusDelay);
                        // console.log("syllabusDelay:", syllabusDelay);
                        const { syllabusNotAdded } = syllabusNotAddedResponse.data;
                        setSyllabusNotAdded(syllabusNotAdded);
                        // console.log("syllabusNotAdded:", syllabusNotAdded);
                        const { dueFee } = dueFeeResponse.data;
                        setDueFee(dueFee);
                        // console.log("dueFee:", dueFee);
                        if (loaderElement) {
                            loaderElement.style.display = "none";
                        }
                    }
                )
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    if (loaderElement) {
                        loaderElement.style.display = "none";
                    }
                });
        }
    }, []);

    return (
        <div className="main-content app-content mt-0">
            <div className="side-app">
                <div className="main-container container-fluid">
                    <div className="page-header">
                        <h1 className="page-title">Dashboard 01</h1>
                        <div>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="javascript:void(0)">Home</a>
                                </li>
                                <li
                                    className="breadcrumb-item active"
                                    aria-current="page"
                                >
                                    Dashboard 01
                                </li>
                            </ol>
                        </div>
                    </div>
                    {/* PAGE-HEADER END */} {/* ROW-1 */}
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12 mb-2">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                                    <div className="card overflow-hidden h-100">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="mt-2">
                                                    <h6 className="">Total Registered Students</h6>
                                                    {registeredStudents != null ? (
                                                        <Link to="/admin/students">
                                                            <h2 className="mb-0 number-font">
                                                                {registeredStudents}
                                                            </h2>
                                                        </Link>
                                                    ) : (
                                                        <div>
                                                            <div
                                                                className="spinner-border text-primary"
                                                                role="status"
                                                            >
                                                                <span className="sr-only">
                                                                    Loading...
                                                                </span>
                                                            </div>
                                                            <h4 className="text-primary">
                                                                {" "}
                                                                Loading...
                                                            </h4>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                                    <div className="card overflow-hidden h-100">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="mt-2">
                                                    <h6 className="">Today's Absent Students</h6>
                                                    {absentStudents != null ? (
                                                        <Link to="/admin/absentStudents">
                                                            <h2 className="mb-0 number-font">
                                                                {absentStudents}
                                                            </h2>
                                                        </Link>
                                                    ) : (
                                                        <div>
                                                            <div
                                                                className="spinner-border text-primary"
                                                                role="status"
                                                            >
                                                                <span className="sr-only">
                                                                    Loading...
                                                                </span>
                                                            </div>
                                                            <h4 className="text-primary">
                                                                {" "}
                                                                Loading...
                                                            </h4>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                                    <div className="card overflow-hidden h-100">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="mt-2">
                                                    <h6 className="">
                                                        {" "}
                                                        Students with Outstanding Tuition Fees for
                                                        this Month{" "}
                                                    </h6>
                                                    {dueFee ? (
                                                        <Link to="/admin/getStudentsDueFeeStudents">
                                                            <h2 className="mb-0 number-font">
                                                                {dueFee.length}
                                                            </h2>
                                                        </Link>
                                                    ) : (
                                                        <div>
                                                            <div
                                                                className="spinner-border text-primary"
                                                                role="status"
                                                            >
                                                                <span className="sr-only">
                                                                    Loading...
                                                                </span>
                                                            </div>
                                                            <h4 className="text-primary">
                                                                {" "}
                                                                Loading...
                                                            </h4>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                                    <div className="card overflow-hidden h-100">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="mt-2">
                                                    <h6 className=""> Total Fee Collection </h6>
                                                    {totalFeeCollection ? (
                                                        <Link to="/admin/finance_report_three">
                                                            <h2 className="mb-0 number-font">
                                                                {totalFeeCollection[0]?.totalAmount}
                                                            </h2>
                                                        </Link>
                                                    ) : (
                                                        <div>
                                                            <div
                                                                className="spinner-border text-primary"
                                                                role="status"
                                                            >
                                                                <span className="sr-only">
                                                                    Loading...
                                                                </span>
                                                            </div>
                                                            <h4 className="text-primary">
                                                                {" "}
                                                                Loading...
                                                            </h4>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* PAGE-HEADER END */} {/* ROW-2 */}
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12 mb-2">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                                    <div className="card overflow-hidden h-100">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="mt-2">
                                                    <h6 className="">Student Remark</h6>
                                                    {studentsRemarks ? (
                                                        <Link to="/admin/teachersattendances">
                                                            <h2 className="mb-0 number-font">
                                                                {studentsRemarks?.length}
                                                            </h2>
                                                        </Link>
                                                    ) : (
                                                        <div>
                                                            <div
                                                                className="spinner-border text-primary"
                                                                role="status"
                                                            >
                                                                <span className="sr-only">
                                                                    Loading...
                                                                </span>
                                                            </div>
                                                            <h4 className="text-primary">
                                                                {" "}
                                                                Loading...
                                                            </h4>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                                    <div className="card overflow-hidden h-100">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="mt-2">
                                                    <h6 className="">Student Appreciation</h6>
                                                    {studentsAppreciation ? (
                                                        <Link to="/admin/studentsAppreciation">
                                                            <h2 className="mb-0 number-font">
                                                                {studentsAppreciation?.length}
                                                            </h2>
                                                        </Link>
                                                    ) : (
                                                        <div>
                                                            <div
                                                                className="spinner-border text-primary"
                                                                role="status"
                                                            >
                                                                <span className="sr-only">
                                                                    Loading...
                                                                </span>
                                                            </div>
                                                            <h4 className="text-primary">
                                                                {" "}
                                                                Loading...
                                                            </h4>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                                    <div className="card overflow-hidden h-100">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="mt-2">
                                                    <h6 className="">Late Arriving Teachers.</h6>
                                                    {lateArrivingTeachers ? (
                                                        <Link to="/admin/lateArrivalTeachers">
                                                            <h2 className="mb-0 number-font">
                                                                {lateArrivingTeachers?.length}
                                                            </h2>
                                                        </Link>
                                                    ) : (
                                                        <div>
                                                            <div
                                                                className="spinner-border text-primary"
                                                                role="status"
                                                            >
                                                                <span className="sr-only">
                                                                    Loading...
                                                                </span>
                                                            </div>
                                                            <h4 className="text-primary">
                                                                {" "}
                                                                Loading...
                                                            </h4>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                                    <div className="card overflow-hidden h-100">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="mt-2">
                                                    <h6 className=""> Today's Absent Teachers</h6>
                                                    {absentTeachers != null ? (
                                                        <Link to="/admin/absentTeachers">
                                                            <h2 className="mb-0 number-font">
                                                                {absentTeachers}
                                                            </h2>
                                                        </Link>
                                                    ) : (
                                                        <div>
                                                            <div
                                                                className="spinner-border text-primary"
                                                                role="status"
                                                            >
                                                                <span className="sr-only">
                                                                    Loading...
                                                                </span>
                                                            </div>
                                                            <h4 className="text-primary">
                                                                {" "}
                                                                Loading...
                                                            </h4>
                                                        </div>
                                                    )}

                                                    <h2 className="mb-0 number-font"></h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* PAGE-HEADER END */} {/* ROW-3 */}
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12 mb-2">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                                    <div className="card overflow-hidden h-100">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="mt-2">
                                                    <h6 className="">Breakage Report</h6>
                                                    {breakageReports != null ? (
                                                        <Link to="/admin/breakage-records">
                                                            <h2 className="mb-0 number-font">
                                                                {breakageReports}
                                                            </h2>
                                                        </Link>
                                                    ) : (
                                                        <div>
                                                            <div
                                                                className="spinner-border text-primary"
                                                                role="status"
                                                            >
                                                                <span className="sr-only">
                                                                    Loading...
                                                                </span>
                                                            </div>
                                                            <h4 className="text-primary">
                                                                {" "}
                                                                Loading...
                                                            </h4>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                                    <div className="card overflow-hidden h-100">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="mt-2">
                                                    <h6 className="">Cleanness Report</h6>
                                                    {cleannessReports ? (
                                                        <Link to="/admin/cleanliness-report">
                                                            <h2 className="mb-0 number-font">
                                                                {cleannessReports?.length}
                                                            </h2>
                                                        </Link>
                                                    ) : (
                                                        <div>
                                                            <div
                                                                className="spinner-border text-primary"
                                                                role="status"
                                                            >
                                                                <span className="sr-only">
                                                                    Loading...
                                                                </span>
                                                            </div>
                                                            <h4 className="text-primary">
                                                                {" "}
                                                                Loading...
                                                            </h4>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                                    <div className="card overflow-hidden h-100">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="mt-2">
                                                    <h6 className="">Syllabus without chapters</h6>
                                                    {syllabusNotAdded ? (
                                                        <Link to="/admin/syllabusWithoutChaptes">
                                                            <h2 className="mb-0 number-font">
                                                                {syllabusNotAdded.length}
                                                            </h2>
                                                        </Link>
                                                    ) : (
                                                        <div>
                                                            <div
                                                                className="spinner-border text-primary"
                                                                role="status"
                                                            >
                                                                <span className="sr-only">
                                                                    Loading...
                                                                </span>
                                                            </div>
                                                            <h4 className="text-primary">
                                                                {" "}
                                                                Loading...
                                                            </h4>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* PAGE-HEADER END */} {/* ROW-4 */}
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                            <div className="row"></div>
                        </div>
                    </div>
                    <div className="page-header"></div>
                    <div className="page-header"></div>
                    <div className="row ">
                        <div className="col-md-12"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;