import Sidebar from "../../component/Sidebar";
import axios from "axios";
import { Link } from "react-router-dom";
import { getDate } from "../../helpers/DateHelper";
import React, { useEffect, useState } from "react";
import {
  getToken,
  getCurrentSession,
} from "../../utility/utilityFunctions";
import AdminDashboard from "./AdminDashboard";

const Dashboard = () => {
  const [
    teachersWithSameGradeSameSection,
    setTeachersWithSameGradeSameSection,
  ] = useState(null);
   

  const [commonGrades, setCommonGrades] = useState([]);
  const [savedSections, setSavedSections] = useState({});
  const [filteredSections, setfilteredSections] = useState({});
   
  const today = getDate();
  const tomorrow = getDate({ addDate: { days: 1 }, Date: today });

  const [attendanceData, setAttendanceData] = useState(null);
  const [start_date, setStartDate] = useState(today);
  const [end_date, setEndDate] = useState(tomorrow);
  const [withoutSRnumberStudents, setWithoutSRnumberStudents] = useState(null);




  /** Cenrtral Head **/
  const [totalTeachers, setTotalTeachers] = useState(null);
  const [totalStudents, setTotalStudents] = useState(null);
  const [teachersWithoutSection, setTeachersWithoutSection] = useState(null);
  const [leaveData, setLeaveData] = useState(null);
  const [breakageRecords, setBreakageRecords] = useState(null);
  const [totalMissingDobStudents, setTotalMissingDobStudents] = useState(null);
  const [studentsWithoutSection, setStudentsWithoutSection] = useState(null);
  const [teacherswithoutPaycode, setTeacherswithoutPaycode] = useState(null);
  const [promotedStudnets, setPromotedStudnets] = useState(null);

  const [upcomingSessionStudents, setUpcomingSessionStudents] = useState(null);
  const [backSessionStudents, setBackSessionStudents] = useState(null);
  /** End Cenrtral Head **/ 

  useEffect(() => {
    const loaderElement = document.getElementsByClassName("loader")[0];
    if (loaderElement) {
      loaderElement.style.display = "block";
    }

    if (
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).role === "central_head"
    ) {

      (async () => {
        let response = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/dashboard/ch`,
          {},
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )

        const { teacher_count, student_count, teacher_without_section, teacher_leaves, breakage_records, student_missing_dob, students_without_section, teachers_without_paycode, students_paid_less_amount } = response.data;

        setTotalTeachers(teacher_count);
        setTotalStudents(student_count);
        setTeachersWithoutSection(teacher_without_section);
        setLeaveData(teacher_leaves);
        setBreakageRecords(breakage_records);
        setTotalMissingDobStudents(student_missing_dob);
        setStudentsWithoutSection(students_without_section);
        setTeacherswithoutPaycode(teachers_without_paycode);
        setPromotedStudnets(students_paid_less_amount);
      })();

      (async () => {
        let response = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/getBackSessionStudents/ch`,
          {},
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )

        const { back_session_students } = response.data;
        setBackSessionStudents(back_session_students);
      })();

      (async () => {
        let response = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/getUpComingSessionStudents/ch`,
          {},
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )

        const { upcoming_session_students } = response.data;
        setUpcomingSessionStudents(upcoming_session_students);
      })();

      // console.log("cenral head");
      const apiPromises = [
        axios.post(
          `${process.env.REACT_APP_BASE_URL}/teachersAssignedSameClassSection`,
          {},
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        ),
        axios.post(
          `${process.env.REACT_APP_BASE_URL}/admin/teacher_attendances`,
          {
            start_date,
            end_date,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        ),
        axios.get(
          `${process.env.REACT_APP_BASE_URL}/admin/section/Create`,

          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        ),
        axios.get(
          `${process.env.REACT_APP_BASE_URL}/admin/withoutSRnumberStudents`,

          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        ),
      ];

      Promise.all(apiPromises)
        .then(
          ([
            teachersAssignedSameClassSectionResponse,
            attendanceResponse,
            sectionCreateResponse,
            withoutSRnumberStudentsResponse,
          ]) => {

            const { uniqueFlattenedRepeatedSylbbs } = teachersAssignedSameClassSectionResponse.data;
            setTeachersWithSameGradeSameSection(uniqueFlattenedRepeatedSylbbs);


            const { lateArrivals } = attendanceResponse.data.data;
            setAttendanceData(lateArrivals);


            const { commonGrades, savedSections, filteredSections } = sectionCreateResponse.data;
            setCommonGrades(commonGrades);
            setSavedSections(savedSections);
            setfilteredSections(filteredSections);


            const { withoutSRnumberStudents } = withoutSRnumberStudentsResponse.data;
            setWithoutSRnumberStudents(withoutSRnumberStudents);
            if (loaderElement) {
              loaderElement.style.display = "none";
            }
          }
        )
        .catch((error) => {
          console.error("Error fetching data:", error);
          if (loaderElement) {
            loaderElement.style.display = "none";
          }
        });
    }
  }, []);

  return (
    <>
      <Sidebar />

      {localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).role === "central_head" ? (
        <>
          <div className="main-content app-content mt-0">
            <div className="side-app">
              {/* CONTAINER */}
              <div className="main-container container-fluid">
                <div className="page-header">
                  <h1 className="page-title">Dashboard 01</h1>
                  <div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="javascript:void(0)">Home</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Dashboard 01
                      </li>
                    </ol>
                  </div>
                </div>
                {/* PAGE-HEADER END */} {/* ROW-1 */}
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12 mb-2">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                        <div className="card overflow-hidden h-100">
                          <div className="card-body">
                            <div className="d-flex">
                              <div className="mt-2">
                                <h6 className="">Total Teachers</h6>
                                {totalTeachers != null ? (
                                  <Link to="/admin/teachers">
                                    <h2 className="mb-0 number-font">
                                      {totalTeachers}
                                    </h2>
                                  </Link>
                                ) : (
                                  <div>
                                    <div
                                      className="spinner-border text-primary"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                    <h4 className="text-primary">
                                      {" "}
                                      Loading...
                                    </h4>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                        <div className="card overflow-hidden h-100">
                          <div className="card-body">
                            <div className="d-flex">
                              <div className="mt-2">
                                <h6 className="">Total Registered Students</h6>
                                {totalStudents != null ? (
                                  <Link to="/admin/students">
                                    <h2 className="mb-0 number-font">
                                      {totalStudents}
                                    </h2>
                                  </Link>
                                ) : (
                                  <div>
                                    <div
                                      className="spinner-border text-primary"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                    <h4 className="text-primary">
                                      {" "}
                                      Loading...
                                    </h4>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                        <div className="card overflow-hidden h-100">
                          <div className="card-body">
                            <div className="d-flex">
                              <div className="mt-2">
                                <h6 className="">
                                  Total Teachers Without Section
                                </h6>
                                {teachersWithoutSection != null ? (
                                  <Link to="/admin/teacherMissingSection">
                                    <h2 className="mb-0 number-font">
                                      {teachersWithoutSection}
                                    </h2>
                                  </Link>
                                ) : (
                                  <div>
                                    <div
                                      className="spinner-border text-primary"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                    <h4 className="text-primary">
                                      {" "}
                                      Loading...
                                    </h4>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                        <div className="card overflow-hidden h-100">
                          <div className="card-body">
                            <div className="d-flex">
                              <div className="mt-2">
                                <h6 className="">
                                  Teachers assigned same garde, section and
                                  subject
                                </h6>
                                {teachersWithSameGradeSameSection ? (
                                  <Link to="/admin/teachersamegradesection">
                                    <h2 className="mb-0 number-font">
                                      {teachersWithSameGradeSameSection?.length}
                                    </h2>
                                  </Link>
                                ) : (
                                  <div>
                                    <div
                                      className="spinner-border text-primary"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                    <h4 className="text-primary">
                                      {" "}
                                      Loading...
                                    </h4>
                                  </div>
                                )}

                                <h2 className="mb-0 number-font"></h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* PAGE-HEADER END */} {/* ROW-2 */}
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12 mb-2">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                        <div className="card overflow-hidden h-100">
                          <div className="card-body">
                            <div className="d-flex">
                              <div className="mt-2">
                                <h6 className="">Teachers late Arrive</h6>
                                {attendanceData ? (
                                  <Link to="/admin/teacherslateattendances">
                                    <h2 className="mb-0 number-font">
                                      {attendanceData?.length}
                                    </h2>
                                  </Link>
                                ) : (
                                  <div>
                                    <div
                                      className="spinner-border text-primary"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                    <h4 className="text-primary">
                                      {" "}
                                      Loading...
                                    </h4>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                        <div className="card overflow-hidden h-100">
                          <div className="card-body">
                            <div className="d-flex">
                              <div className="mt-2">
                                <h6 className="">Teachers Apply Leave</h6>
                                {leaveData != null ? (
                                  <Link to="/admin/teachersLeave">
                                    <h2 className="mb-0 number-font">
                                      {leaveData}
                                    </h2>
                                  </Link>
                                ) : (
                                  <div>
                                    <div
                                      className="spinner-border text-primary"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                    <h4 className="text-primary">
                                      {" "}
                                      Loading...
                                    </h4>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                        <div className="card overflow-hidden h-100">
                          <div className="card-body">
                            <div className="d-flex">
                              <div className="mt-2">
                                <h6 className="">
                                  Teachers have reported breakage issue.
                                </h6>
                                {breakageRecords != null ? (
                                  <Link to="/admin/breakage-records">
                                    <h2 className="mb-0 number-font">
                                      {breakageRecords}
                                    </h2>
                                  </Link>
                                ) : (
                                  <div>
                                    <div
                                      className="spinner-border text-primary"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                    <h4 className="text-primary">
                                      {" "}
                                      Loading...
                                    </h4>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                        <div className="card overflow-hidden h-100">
                          <div className="card-body">
                            <div className="d-flex">
                              <div className="mt-2">
                                <h6 className="">Students Missing DOB</h6>
                                {totalMissingDobStudents != null ? (
                                  <Link to="/admin/mark/missing-dob">
                                    <h2 className="mb-0 number-font">
                                      {totalMissingDobStudents}
                                    </h2>
                                  </Link>
                                ) : (
                                  <div>
                                    <div
                                      className="spinner-border text-primary"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                    <h4 className="text-primary">
                                      {" "}
                                      Loading...
                                    </h4>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* PAGE-HEADER END */} {/* ROW-3 */}
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12 mb-2">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                        <div className="card overflow-hidden h-100">
                          <div className="card-body">
                            <div className="d-flex">
                              <div className="mt-2">
                                <h6 className="">
                                  <b>{getCurrentSession()-1}-{getCurrentSession()}</b> Session Students{" "}
                                </h6>
                                {backSessionStudents != null ? (
                                  <Link to="/admin/backSessionStudents">
                                    <h2 className="mb-0 number-font">
                                      {backSessionStudents}
                                    </h2>
                                  </Link>
                                ) : (
                                  <div>
                                    <div
                                      className="spinner-border text-primary"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                    <h4 className="text-primary">
                                      {" "}
                                      Loading...
                                    </h4>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                        <div className="card overflow-hidden h-100">
                          <div className="card-body">
                            <div className="d-flex">
                              <div className="mt-2">
                                <h6 className="">
                                  <b>{getCurrentSession()+1}-{getCurrentSession()+2}</b> Session Students
                                </h6>
                                {upcomingSessionStudents != null ? (
                                  <Link to="/admin/upComingSessionStudents">
                                    <h2 className="mb-0 number-font">
                                      {upcomingSessionStudents}
                                    </h2>
                                  </Link>
                                ) : (
                                  <div>
                                    <div
                                      className="spinner-border text-primary"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                    <h4 className="text-primary">
                                      {" "}
                                      Loading...
                                    </h4>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {commonGrades ? (
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                          <div className="card overflow-hidden h-100">
                            <div className="card-body">
                              <div className="d-flex">
                                <div className="mt-2">
                                  <h6 className="">
                                    Not assigened section some gardes{" "}
                                  </h6>
                                  {commonGrades?.length >
                                    Object.keys(savedSections)?.length ||
                                    commonGrades?.length >=
                                    filteredSections?.length ? (
                                    <Link to="/admin/section/Create">
                                      <h2 className="mb-0 number-font">
                                        {commonGrades?.length -
                                          filteredSections?.length}
                                      </h2>
                                    </Link>
                                  ) : (
                                    <div>
                                      <div
                                        className="spinner-border text-primary"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                      <h4 className="text-primary">
                                        {" "}
                                        Loading...
                                      </h4>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                        <div className="card overflow-hidden h-100">
                          <div className="card-body">
                            <div className="d-flex">
                              <div className="mt-2">
                                <h6 className="">
                                  {" "}
                                  Students With out section{" "}
                                </h6>
                                {studentsWithoutSection != null ? (
                                  <Link to="/admin/WithoutSectionStudnets">
                                    <h2 className="mb-0 number-font">
                                      {studentsWithoutSection}
                                    </h2>
                                  </Link>
                                ) : (
                                  <div>
                                    <div
                                      className="spinner-border text-primary"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                    <h4 className="text-primary">
                                      {" "}
                                      Loading...
                                    </h4>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* PAGE-HEADER END */} {/* ROW-4 */}
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                        <div className="card overflow-hidden h-100">
                          <div className="card-body">
                            <div className="d-flex">
                              <div className="mt-2">
                                <h6 className=""> Teachers without Paycode </h6>
                                {teacherswithoutPaycode != null ? (
                                  <Link to="/admin/teachersWithoutPaycode">
                                    <h2 className="mb-0 number-font">
                                      {teacherswithoutPaycode}
                                    </h2>
                                  </Link>
                                ) : (
                                  <div>
                                    <div
                                      className="spinner-border text-primary"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                    <h4 className="text-primary">
                                      {" "}
                                      Loading...
                                    </h4>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                        <div className="card overflow-hidden h-100">
                          <div className="card-body">
                            <div className="d-flex">
                              <div className="mt-2">
                                <h6 className="">
                                  {" "}
                                  Less than 1500 INR paid students{" "}
                                </h6>
                                {promotedStudnets != null ? (
                                  <Link to="/admin/AllStudentsPaidLessAmount">
                                    <h2 className="mb-0 number-font">
                                      {promotedStudnets}
                                    </h2>
                                  </Link>
                                ) : (
                                  <div>
                                    <div
                                      className="spinner-border text-primary"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                    <h4 className="text-primary">
                                      {" "}
                                      Loading...
                                    </h4>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                        <div className="card overflow-hidden h-100">
                          <div className="card-body">
                            <div className="d-flex">
                              <div className="mt-2">
                                <h6 className="">
                                  {" "}
                                  Without Sr. number students{" "}
                                </h6>
                                {withoutSRnumberStudents ? (
                                  <Link to="/admin/withOutSrNumberStudents">
                                    <h2 className="mb-0 number-font">
                                      {withoutSRnumberStudents.length}
                                    </h2>
                                  </Link>
                                ) : (
                                  <div>
                                    <div
                                      className="spinner-border text-primary"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                    <h4 className="text-primary">
                                      {" "}
                                      Loading...
                                    </h4>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ROW-4 END */}
              </div>
              {/* CONTAINER END */}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}




      {localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).role === "admin" ? (
        <>
          <AdminDashboard />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Dashboard;
