import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import {
  getToken,
  timezoneDateToddMMyyyy,
} from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";
import { useParams } from 'react-router-dom';

const MarkDetail = () => {
  const [matchedStudents, setStudent] = useState([]); // Initialize state as an empty array
  const [exams, setExam] = useState([]); // Initialize state as an empty array
  const [subjects, setSubject] = useState([]); // Initialize state as an empty array
  const [grades, setGrade] = useState([]); // Initialize state as an empty array
  

  const { exam,grade, subject } = useParams();
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/admin/mark/mark-detail/${exam}/${grade}/${subject}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }) 
    .then((response) => {
          console.log(response.data);
          const { matchedStudents } = response.data;
          setStudent(matchedStudents); // Set the exam data to the state

          const { exams } = response.data;
          setExam(exams); // Set the exam data to the state

          const { subjects } = response.data;
          setSubject(subjects); // Set the exam data to the state

          const { grades } = response.data;
          setGrade(grades); // Set the exam data to the state
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
            <GoBackButton />

             
              <div className="col-md-4 d-flex">
                <div className="">
                 
                </div>
              </div> 
            </div>
            <div class="row ">
            <div class="col-md-12">
                <div class="row">
                  <div class="col-lg-12">
                      <div class="card">
                        <div class="card-body">
                            <div class="table-responsive">
                              <table class="table table-bordered border text-nowrap mb-0" id="basic-edit">
                                  <thead>
                                    <tr>
                                        <th><b>Test Name</b></th>
                                        <th><b>Grade Name</b></th>
                                        <th><b>Subject</b></th>
                                    </tr>
                                    <tr>
                                        <th><b>{exams.name}</b></th>
                                        <th><b>{grades.name}</b></th>
                                        <th><b>{subjects.name}</b></th>
                                    </tr>
                                  </thead> 
                              </table>
                            </div>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
          </div>

            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Student name</b>
                                </th>
                                <th>
                                  <b>Section</b>
                                </th> 
                                <th>
                                  <b>Marks</b>
                                </th> 
                              </tr>
                            </thead>
                            <tbody>
                            {matchedStudents?.map((studentKay) => {
                                return (
                                  <tr> 
                                    <td>{studentKay.name}</td> 
                                    <td>{studentKay.section}</td> 
                                    <td>{studentKay.marks}</td>  
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarkDetail;