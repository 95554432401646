import axios from "axios";
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar";
import { getToken } from "../../utility/utilityFunctions";

function Create() {
  const { id } = useParams();
  const [commonGrades, setCommonGrades] = useState([]);
  const [sections, setSections] = useState([]);
  const [savedSections, setSavedSections] = useState({}); // Use an object for savedSections

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/admin/section/create`;
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { commonGrades, sections, savedSections } = response.data;
        setCommonGrades(commonGrades);
        setSections(sections);
        console.log("savedSections:",Object.keys(savedSections).length)
        console.log("commonGrades:",commonGrades.length)

        setSavedSections(savedSections);
      });
  }, []);

  // Function to handle checkbox change
  const handleCheckboxChange = (gradeId, section) => {
    // Create a copy of savedSections
    const updatedSavedSections = { ...savedSections };

    // Check if the section is in savedSections for the grade
    if (updatedSavedSections[gradeId]?.includes(section)) {
      // Section is already saved, so remove it
      updatedSavedSections[gradeId] = updatedSavedSections[gradeId].filter(
        (item) => item !== section
      );
    } else {
      // Section is not saved, so add it
      updatedSavedSections[gradeId] = [
        ...(updatedSavedSections[gradeId] || []),
        section,
      ];
    }

    // Update the savedSections state
    setSavedSections(updatedSavedSections);
  };

  // Function to handle form submission
  const updateSection = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Prepare the data to be sent
    const postData = {
      selectedSections: savedSections,
    };

    // Send an HTTP POST request to your server with updatedSavedSections
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/section/saveSection`, postData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        window.notif({
          msg: "<b>Woohoo!!</b> Record updated successfully",
          type: "success",
        });
        // Handle the server's response data here, if needed
       // console.log('Server response:', response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div style={{ backgroundColor: "white", padding: "25px", marginBottom: "10px" }}>
              <div className="">
                <h1
                  className="page-title"
                  style={{ marginLeft: "12px", marginBottom: "15px" }}
                >
                  Update Center Section
                </h1>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="loader" style={{ display: "none" }}>
                <h2>Hold On !!</h2>
                <h3>We are generating report....</h3>
                <div
                  className="spinner-border text-danger"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <form onSubmit={updateSection}>
                            <table className="table table-bordered border text-nowrap mb-0" id="basic-edit">
                              <thead>
                                <tr>
                                  <th>
                                    <b>Grade</b>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {commonGrades?.map((GradeKey) => {
                                  return (
                                    <React.Fragment key={GradeKey._id}>
                                      <tr>
                                        <td>{GradeKey.name}</td>
                                      </tr>
                                      {/* Add a single <tr> for sections under the common grade */}
                                      <tr>
                                        <td>
                                          {sections.map((section) => (
                                            <React.Fragment key={section}>
                                              <input
                                                type="checkbox"
                                                id={section}
                                                name={section}
                                                onChange={() => handleCheckboxChange(GradeKey._id, section)}
                                                checked={savedSections[GradeKey._id]?.includes(section) || false}
                                              />
                                              <label>{section}</label>
                                            </React.Fragment>
                                          ))}
                                        </td> 
                                      </tr>
                                    </React.Fragment>
                                  );
                                })}
                              </tbody>
                            </table>
                            <button className="btn btn-danger text-light mt-3" type="submit">Submit</button>

                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Create;
