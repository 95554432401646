import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import {
  getToken,
  timezoneDateToddMMyyyy,
} from "../../utility/utilityFunctions";

const BreakageReport = () => {
  const [breakageRecords, setBreakageRecords] = useState([]);
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState("all");
  const [recordType, setRecordType] = useState("active");
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    document.getElementsByClassName("table-responsive")[0].style.display =
    "none";
    document.getElementsByClassName("loader")[0].style.display =
    "block";
    setBreakageRecords([]);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/getBranches`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => { 
        const { branches } = response.data;
        setBranches(branches);

        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/breakageRecords`,
            { recordType, branch },
            {
              headers: {
                Authorization: `Bearer ${getToken()}`,
              },
            }
          )
          .then((response) => {
            const { breakageRecords } = response.data;
            console.log("breakageRecords:",breakageRecords)
            setBreakageRecords(breakageRecords);
            setIsAdmin(
              localStorage.getItem("user") &&
                JSON.parse(localStorage.getItem("user"))?.role === "admin"
            );
            document.getElementsByClassName("loader")[0].style.display =
            "none";
            document.getElementsByClassName("table-responsive")[0].style.display =
              "block";
          });
      });
  }, [recordType, branch]);

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <h1 className="page-title">Breakage Records</h1>
              <div className="d-flex">
                {isAdmin ? (
                  <>
                    <div className="">
                      <select
                        className="form-control"
                        name="branch"
                        value={branch}
                        id=""
                        onChange={(e) => {
                          setBranch(e.target.value);
                        }}
                      >
                        <option value="all">All Branches</option>
                        {branches.map((branchData) => (
                          <option value={branchData._id}>
                            {branchData.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className="">
                  <select
                    className="form-control"
                    name=""
                    value={recordType}
                    id="session"
                    onChange={(e) => {
                      setRecordType(e.target.value);
                    }}
                  >
                    <option value="active">No Action Taken</option>
                    <option value="resolved">Resolved</option>
                    <option value="rejected">Rejected</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                      <div class="loader" style={{display : "none"}}>
                          <h2>Hold On !!</h2>
                          <h3>We are generating report....</h3>
                          <div
                            class="spinner-border text-danger"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Breakage Category</b>
                                </th>
                                <th>
                                  <b>Subject</b>
                                </th>
                                <th>
                                  <b>Raised By</b>
                                </th>
                                <th>
                                  <b>Raised On</b>
                                </th>
                                <th>
                                  <b>Resolve Till</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {breakageRecords.map((breakageReport) => {
                                return (
                                  <tr key={breakageReport._id}>
                                    {/* <td>{breakageReport.breakageCategory.name}</td> */}
                                    <td>
                                      <Link
                                        to={`/admin/breakage-record/${breakageReport._id}`}
                                      >
                                        {breakageReport?.breakageCategory?.name}
                                      </Link>
                                    </td>
                                    <td>{breakageReport.subject}</td>
                                    <td>
                                      {breakageReport?.raised_by?.name} ({" "}
                                      {breakageReport?.raised_by?.mobile} )
                                    </td>
                                    <td>
                                      {breakageReport?.raised_on_date && timezoneDateToddMMyyyy(
                                        breakageReport?.raised_on_date
                                      )}
                                    </td>
                                    <td>
                                      {breakageReport?.resolved_till_date && timezoneDateToddMMyyyy(
                                        breakageReport?.resolved_till_date
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BreakageReport;
