// import React from "react";
// import axios from "axios";
// import { useState } from "react";
// import { useEffect } from "react";
// import { getToken } from "../../utility/utilityFunctions";
// import GoBackButton from "../../component/GoBackButton";

// const AddUserForm = () => {
//   const data = {
//     name: "",
//     email: "",
//     password: "",
//     mobile: "",
//     branch: [],
//     role: "",
//     profile_pic: "",
//     paycode: "",
//     total_points_given: "",
//   };

//   const [formData, setformData] = useState(data);

//   const [screenData, setScreenData] = useState({
//     branches: [],
//     roles: [],
//   });

//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_BASE_URL}/admin/addUserData`, {
//         headers: {
//           Authorization: `Bearer ${getToken()}`,
//         },
//       })
//       .then((response) => {
//         const { branches, roles } = response.data;
//         setScreenData({
//           ...screenData,
//           branches,
//           roles,
//         });
//       });
//   }, []);

//   const updateValue = (e) => {
//     const field = e.target.id;
//     let value = e.target.value;

//     // console.log(field+" ",value);
//     if (field == "branch") {
//       // console.log("formData.branch", formData.branch);
//       // if (!formData.branch.includes(value)) {
//       //   value = formData.branch.push(value);
//       // } else {
//       //   console.log("formData.branch", formData.branch);
//       //   value = formData.branch.filter((singleBranch) => {
//       //     console.log("singleBranch", singleBranch);
//       //     return singleBranch !== value;
//       //   });
//       // }
//       value = Array.from(e.target.selectedOptions, (option) => option.value);
//     }

//     setformData({
//       ...formData,
//       [field]: value,
//     });
//   };

//   const createUser = () => {
//     console.log("formData:", formData);
//     axios
//       .post(`${process.env.REACT_APP_BASE_URL}/admin/user/new`, formData)
//       .then((response) => {
//         console.log(response);
//         if (response?.data.success) {
//           window.notif({
//             msg: "<b>Woohoo!!</b> User Record Created Successfully",
//             type: "success",
//           });

//           setformData(data);
//         } else {
//           window.notif({
//             msg: response?.data.error,
//             type: "error",
//             position: "center",
//           });
//         }
//       });
//   };

//   return (
//     <>
//       <div className="col-xl-12 col-lg-12">
//         <div className="card">
//           <div className="card-header">
//             <h4 className="card-title">Fill following information</h4>
//             {/* <GoBackButton /> */}
//           </div>
//           <div className="card-body">
//             <form className="form-horizontal">
//               <div className=" row mb-4">
//                 <label className="col-md-3 form-label">Name</label>
//                 <div className="col-md-9">
//                   <input
//                     type="text"
//                     className="form-control"
//                     placeholder="Name"
//                     value={formData.name}
//                     id="name"
//                     onChange={(e) => {
//                       updateValue(e);
//                     }}
//                     required
//                   />
//                 </div>
//               </div>
//               <div className="row mb-4">
//                 <label className="col-md-3 form-label" htmlFor="example-email">
//                   Email
//                 </label>
//                 <div className="col-md-9">
//                   <input
//                     type="email"
//                     className="form-control"
//                     placeholder="Email"
//                     id="email"
//                     value={formData.email}
//                     onChange={(e) => {
//                       updateValue(e);
//                     }}
//                     required
//                   />
//                 </div>
//               </div>
//               <div className=" row mb-4">
//                 <label className="col-md-3 form-label">Password</label>
//                 <div className="col-md-9">
//                   <input
//                     type="password"
//                     className="form-control"
//                     placeholder="Passowrd"
//                     value={formData.password}
//                     id="password"
//                     onChange={(e) => {
//                       updateValue(e);
//                     }}
//                     required
//                   />
//                 </div>
//               </div>
//               <div className=" row mb-4">
//                 <label className="col-md-3 form-label">Mobile</label>
//                 <div className="col-md-9">
//                   <input
//                     className="form-control"
//                     type="tel"
//                     name="tel"
//                     placeholder="Mobile"
//                     value={formData.mobile}
//                     id="mobile"
//                     onChange={(e) => {
//                       updateValue(e);
//                     }}
//                     maxLength="10"
//                     required
//                   />
//                 </div>
//               </div>
//               <div className=" row mb-4">
//                 <label className="col-md-3 form-label">Branch</label>
//                 <div className="col-md-9">
//                   <select
//                     multiple="multiple"
//                     className="form-control multi-select"
//                     name=""
//                     value={formData.branch}
//                     id="branch"
//                     onChange={(e) => {
//                       updateValue(e);
//                     }}
//                     required
//                   >
//                     {/* <option value="1">Select Branch</option> */}
//                     {screenData.branches.map((branch) => {
//                       return (
//                         <option key={branch._id} value={branch._id}>
//                           {branch.name}
//                         </option>
//                       );
//                     })}
//                   </select>
//                 </div>
//               </div>
//               <div className=" row mb-4">
//                 <label className="col-md-3 form-label">Role</label>
//                 <div className="col-md-9">
//                   <select
//                     className="form-control"
//                     name=""
//                     value={formData.role}
//                     id="role"
//                     onChange={(e) => {
//                       updateValue(e);
//                     }}
//                     required
//                   >
//                     <option>Select Role</option>
//                     {screenData.roles.map((role) => (
//                       <option value={role.slug}>{role.name}</option>
//                     ))}
//                     {/* <option value="1">Select Role</option>
//                     <option value="2">Teacher</option>
//                     <option value="3">Central Head</option> */}
//                   </select>
//                 </div>
//               </div>
//               {formData.role == "central_head" ? (
//                 <>
//                   <div className=" row mb-4">
//                     <label className="col-md-3 form-label">Paycode</label>
//                     <div className="col-md-9">
//                       <input
//                         type="text"
//                         className="form-control"
//                         placeholder="paycode"
//                         value={formData.paycode}
//                         id="paycode"
//                         onChange={(e) => {
//                           updateValue(e);
//                         }}
//                       />
//                     </div>
//                   </div>
//                   <div className=" row mb-4">
//                     <label className="col-md-3 form-label">
//                       Appraisal Points
//                     </label>
//                     <div className="col-md-9">
//                       <input
//                         type="number"
//                         className="form-control"
//                         placeholder="Appraisal Points"
//                         value={formData.total_points_given}
//                         id="total_points_given"
//                         onChange={(e) => {
//                           updateValue(e);
//                         }}
//                       />
//                     </div>
//                   </div>
//                 </>
//               ) : (
//                 ""
//               )}

//               <div className=" row mb-4">
//                 <label className="col-md-3 form-label">Profile Picture</label>
//                 <div className="col-md-9">
//                   <input
//                     class="form-control"
//                     type="file"
//                     id="profile_pic"
//                     onChange={(e) => {
//                       updateValue(e);
//                     }}
//                   />
//                 </div>
//               </div>
//               <div className=" row mb-4">
//                 <div className="col-md-3">
//                   <button
//                     type="submit"
//                     class="btn btn-danger text-light"
//                     onClick={() => {
//                       createUser();
//                     }}
//                   >
//                     <i class="fe fe-user-plus"></i>&nbsp;Add User
//                   </button>
//                 </div>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AddUserForm;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getToken } from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";

const validationSchema = yup.object({
  name: yup.string().required("Please enter a name"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Please enter an email address"),
  password: yup.string().required("Please enter a password"),
  mobile: yup
    .string()
    .required("Please enter a phone number.")
    .max(10, "Maximum allowed character is 10")
    .matches(/^[0-9]\d{9}$/, "Please enter a valid phone number"),
  branch: yup
    .array()
    .of(yup.string())
    .required("Please select at least one branch"),
  role: yup.string().required("Please select a role"),
  paycode: yup.string(),
  doj: yup.string().required("please enter date of birth"),
  dob: yup.string().required("Please enter your DOB."),
  gender: yup.string().required("Please choose gender."),
  address: yup.string().required("Please enter proper address."),
  paycode: yup.string().when(["role"], {
    is: (role) => ["central_head"].includes(role),
    then: () => yup.string().required("paycode is required"),
  }),
  total_points_given: yup.string().when(["role"], {
    is: (role) => ["central_head"].includes(role),
    then: () => yup.string().required("Appraisal points are required"),
  }),
});

const AddUserForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const studentFormData = watch();

  const [screenData, setScreenData] = useState({
    branches: [],
    roles: [],
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/addUserData`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { branches, roles } = response.data;
        setScreenData({
          ...screenData,
          branches,
          roles,
        });
      });
  }, []);

  const onSubmit = (data) => {
    console.log("Form errors ta:", errors);

    console.log("Form data:", data);
  
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/user/new`, data)
      .then((response) => {
        console.log(response);
        if (response?.data.success) {
          window.notif({
            msg: "<b>Woohoo!!</b> User Record Created Successfully",
            type: "success",
          });
        reset();

        } else {
          window.notif({
            msg: response?.data.error,
            type: "error",
            position: "center",
          });
        }
      });
  };

  return (
    <>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Fill following information</h4>
            {/* <GoBackButton /> */}
          </div>
          <div className="card-body">
            <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-4">
                <label className="col-md-3 form-label">
                  Name<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    {...register("name")}
                  />
                  <div className="form-error-div">
                    {errors.name?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.name?.message}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-md-3 form-label" htmlFor="example-email">
                  Email<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    {...register("email")}
                  />
                  <div className="form-error-div">
                    {errors.email?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.email?.message}
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <label className="col-md-3 form-label">
                  Mobile<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder="Mobile"
                    maxLength={10}
                    {...register("mobile")}
                  />
                  <div className="form-error-div">
                    {errors.mobile?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.mobile?.message}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-md-3 form-label">
                  Password<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    {...register("password")}
                  />
                  <div className="form-error-div">
                    {errors.password?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.password?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Gender<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <select
                    className="form-control"
                    id="gender"
                    {...register("gender")}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                  <div className="form-error-div">
                    {errors.gender?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.gender?.message}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-md-3 form-label">
                  DOB<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="date"
                    id="dob"
                    className="form-control"
                    placeholder="Date Of Birth"
                    {...register("dob")}
                    max={new Date().toISOString().split("T")[0]} // Set the max attribute to the current date
                  />
                  <div className="form-error-div">
                    {errors.dob?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.dob?.message}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-md-3 form-label">
                  DOJ<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="date"
                    id="doj"
                    className="form-control"
                    placeholder="Date Of Birth"
                    {...register("doj")}
                  />
                  <div className="form-error-div">
                    {errors.doj?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.doj?.message}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-md-3 form-label">
                  Branch<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <select
                    multiple="multiple"
                    className="form-control"
                    {...register("branch")}
                  >
                    {screenData.branches.map((branch) => {
                      // Exclude the "Jaipur" branch
                      if (branch.name !== "Jaipur") {
                        return (
                          <option key={branch._id} value={branch._id}>
                            {branch.name}
                          </option>
                        );
                      }
                      return null; // Skip "Jaipur" branch
                    })}
                  </select>
                  <div className="form-error-div">
                    {errors.branch?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.branch?.message}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-md-3 form-label">
                  Role<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <select className="form-control" {...register("role")}>
                    <option value="">Select Role</option>
                    {screenData.roles
                      .filter(
                        (role) =>
                          role.name !== "Student" && role.name !== "Teacher"
                      )
                      .map((role) => (
                        <option key={role.slug} value={role.slug}>
                          {role.name}
                        </option>
                      ))}
                  </select>
                  <div className="form-error-div">
                    {errors.role?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.role?.message}
                  </div>
                </div>
              </div>
              {studentFormData.role === "central_head" && (
                <>
                  <div className="row mb-4">
                    <label className="col-md-3 form-label">
                      Paycode<span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Paycode"
                        {...register("paycode")}
                      />
                      <div className="form-error-div">
                        {errors.paycode?.message && (
                          <span>
                            <i className="bi bi-exclamation-circle-fill me-1"></i>
                          </span>
                        )}
                        {errors.paycode?.message}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-md-3 form-label">
                      Appraisal Points<span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Appraisal Points"
                        {...register("total_points_given")}
                      />
                      <div className="form-error-div">
                        {errors.total_points_given?.message && (
                          <span>
                            <i className="bi bi-exclamation-circle-fill me-1"></i>
                          </span>
                        )}
                        {errors.total_points_given?.message}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Address<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <textarea
                    className="form-control"
                    id="address"
                    placeholder="Address"
                    {...register("address")}
                  ></textarea>
                  <div className="form-error-div">
                    {errors.address?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.address?.message}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-md-3 form-label">Profile Picture</label>
                <div className="col-md-9">
                  <input
                    className="form-control"
                    type="file"
                    {...register("profile_pic")}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-3">
                  <button type="submit" className="btn btn-danger text-light">
                    <i className="fe fe-user-plus"></i>&nbsp;Add User
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUserForm;
