import axios from "axios";
import * as XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../../component/Sidebar";
import { getToken } from "../../../utility/utilityFunctions";

const TeacherReviewReport = () => {
  const today = new Date().toISOString().substr(0, 10);

  const [teachersData, setTeacherReviewReports] = useState([]);

  const [branches, setBranches] = useState([]);
  const [reviewCount, setReviewCount] = useState(1);

  const [branch, setBranch] = useState("all");
  const [reviewType, setReviewType] = useState("R");

  const [totalRecords, setRecords] = useState(0);
  const [filterClicked, setFilterClicked] = useState(0);

  const [start_date, setStartDate] = useState(today);
  const [end_date, setEndDate] = useState(today);

  useEffect(() => {
    document.getElementsByClassName("table-responsive")[0].style.display =
      "none";

    document.getElementsByClassName("loader")[0].style.display = "block";

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/teacherReviewReportScreen`,
        {
          branch,
          reviewCount,
          start_date,
          end_date,
          reviewType,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        document.getElementsByClassName("loader")[0].style.display = "none";

        document.getElementsByClassName("table-responsive")[0].style.display =
          "block";
        const { teachersData, branches } = response.data;
        setBranches(branches);
        setTeacherReviewReports(teachersData);
        setRecords(teachersData?.length ? teachersData?.length : 0);
      });
  }, [filterClicked]);

  const handleExport = () => {
    const table = document.getElementById("myTable");
    const workbook = XLSX.utils.table_to_book(table);
    XLSX.writeFile(workbook, "teacher.xlsx");
  };

  // const onUpdate = (e) => {
  //   const key = e.target.id;
  //   const value = e.target.value;
  //   console.log(key, value);
  // };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>

            <div style={{ backgroundColor: "white", padding: "25px" }}>
              <div className="">
                <h1
                  className="page-title"
                  style={{ marginLeft: "12px", marginBottom: "15px" }}
                >
                  Teacher Reviews Report
                </h1>
                <div className="col-md-8 d-flex flex-wrap">
                  <div className="">
                    <select
                      className="form-control"
                      name=""
                      value={branch}
                      id="branch"
                      onChange={(e) => {
                        console.log(e.target);
                        setBranch(e.target.value);
                      }}
                    >
                      <option value="all">All Branches</option>
                      {branches.map((branch) => (
                        <option value={branch._id.toString()}>
                          {branch.name}
                        </option>
                      ))}
                    </select>
                  </div>
                 
                  <div className="">
                    <select
                      className="form-control"
                      name=""
                      value={reviewType}
                      id="reviewType"
                      onChange={(e) => {
                        setReviewType(e.target.value);
                      }}
                    >
                      <option value="R">Remarks</option>
                      <option value="A">Appreciations</option>
                    </select>
                  </div>

                  <div className="">
                    <input
                      type="number"
                      className="form-control"
                      name="reviewCount"
                      value={reviewCount}
                      placeholder="Review Count"
                      id="reviewCount"
                      onChange={(e) => {
                        setReviewCount(e.target.value);
                      }}
                    >
                    </input>
                  </div>

                </div>
              </div>

              <div className="mt-3">
                <div className="col-md-12 d-flex">
                  <div className="col-md-6">
                    <label htmlFor="start_date">Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                      value={start_date}
                      name=""
                      id="start_date"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="end_date">End Date</label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => {
                        setEndDate(e.target.value);
                      }}
                      value={end_date}
                      name=""
                      id="end_date"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-3" style={{ padding: "5px 12px" }}>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={(e) => {
                    setFilterClicked((prevCount) => prevCount + 1);
                  }}
                >
                  <i className="side-menu__icon fe fe-filter" /> Filter
                </button>
                <button
                  className="btn btn-sm btn-green"
                  style={{ marginLeft: "5px" }}
                  onClick={handleExport}
                >
                  Export Data
                </button>
              </div>

              <div style={{ padding: "15px" }}>
                <div className="mt-3">Total Records : {totalRecords}</div>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="loader" style={{ display: "none" }}>
                          <h2>Hold On !!</h2>
                          <h3>We are generating report....</h3>
                          <div
                            className="spinner-border text-danger"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                        <div className="table-responsive">
                          {totalRecords > 0 ? (
                            <>
                              <h1 className="my-5">Filtered Results</h1>
                              <table
                                className="table table-bordered border text-nowrap mb-0"
                                id="myTable"
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <b>Name</b>
                                    </th>
                                    <th>
                                      <b>Branch</b>
                                    </th>
                                    <th>
                                      <b>Mobile</b>
                                    </th>
                                    <th>
                                      <b>Count</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {teachersData.map((teacherOneReport) => {
                                    return (
                                      <tr key={teacherOneReport._id}>
                                        <td>
                                          <Link
                                            target="blank"
                                            to={`/admin/teachers/${teacherOneReport._id}`}
                                          >
                                            {teacherOneReport.name}
                                          </Link>
                                        </td>
                                        <td>{teacherOneReport.branch}</td>
                                        <td>{teacherOneReport.mobile}</td>
                                        <td>{teacherOneReport.count}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </>
                          ) : (
                            <h1>No record found.</h1>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherReviewReport;
