import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Sidebar from '../../component/Sidebar';
import { getToken } from '../../utility/utilityFunctions';
import { useTable } from 'react-table';
import ReactPaginate from 'react-paginate';
import { format, parseISO } from 'date-fns';


function Appraisal() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const columnsToSearch = {
    name: 'user_id.name',
    mobile: 'user_id.mobile',
    branch: 'branch.name',
    description: 'description',
  };
  const params = useParams();
  const itemsPerPage = 10;

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/admin/mark/appraisal/`+params.id;
  
    axios
      .get(apiUrl, { 
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { data } = response.data;
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        // Handle error if necessary
        console.error(error);
        setLoading(false);
      });
  }, [params.id]); // Make sure to include params.id as a dependency
  

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  // Calculate the start and end index for the current page
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Define columns for the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'user_id.name',
      },
      {
        Header: 'Mobile',
        accessor: 'user_id.mobile',
      },
      {
        Header: 'Branch',
        accessor: 'branch.name',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Points Deducted',
        accessor: 'points_deducted',
      },
      {
        Header: 'Points Gained',
        accessor: 'points_gained',
      },
      {
        Header: 'Day',
        accessor: 'dayName', // Use a unique accessor
        Cell: ({ row }) => {
          const createdAt = row.original.createdAt;
          const date = parseISO(createdAt);
          const dayName = format(date, 'EEEE'); // 'EEEE' format returns the full day name
          return <div>{dayName}</div>;
        },
      },
      {
        Header: 'Date',
        accessor: 'createdAt',
      },
      {
        Header: 'Actions',
        accessor: '_id',
        Cell: ({ row }) => (
          <button className="btn btn-sm btn-danger" onClick={() => handleDelete(row.original._id)}>
            Delete
          </button>
        ),
      },
    ],
    []
  );

  const filteredData = data.filter((row) => {
    return Object.keys(columnsToSearch).some((columnName) => {
      const value = row[columnsToSearch[columnName]];

      if (value && typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())) {
        return true;
      }

      if (value && typeof value !== 'string' && value.toString().toLowerCase().includes(searchQuery.toLowerCase())) {
        return true;
      }

      return false;
    });
  });

  const itemsToDisplay = filteredData.slice(startIndex, endIndex);

  const handleDelete = (recordId) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      const apiUrl = `${process.env.REACT_APP_BASE_URL}/admin/mark/appraisal-delete/${recordId}`;

      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then((response) => {
          window.notif({
            msg: "<b>Woohoo!!</b> Record deleted successfully",
            type: "success",
          });

          setData((prevData) =>
            prevData.map((record) =>
              record._id === recordId ? { ...record, deleted: true } : record
            )
          );
        })
        .catch((error) => {
          console.error("Error deleting record:", error);
        });
    }
  };

  const tableInstance = useTable({
    columns,
    data: itemsToDisplay.map((appraisal, index) => ({
      ...appraisal,
      index: index + 1,
    })),
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div style={{ backgroundColor: "white", padding: "25px", marginBottom: "10px" }}>
              <div className="">
                <h1 className="page-title" style={{ marginLeft: "12px", marginBottom: "15px" }}>
                Attendance Logs
                </h1>
              </div>
            </div>
            {loading ? (
              <div className="loader">
                <h2>Hold On !!</h2>
                <h3>We are generating report....</h3>
                <div className="spinner-border text-danger" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table {...getTableProps()} className="table table-bordered border text-nowrap mb-0" id="basic-edit">
                              <thead>
                                {headerGroups.map(headerGroup => (
                                  <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                    ))}
                                  </tr>
                                ))}
                              </thead>
                              <tbody {...getTableBodyProps()}>
                                {rows
                                  .filter((row) => !row.original.deleted)
                                  .map((row) => {
                                    prepareRow(row);
                                    return (
                                      <tr key={row.original._id} {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                          return (
                                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                          );
                                        })}
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="pagination-controls">
              <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                pageCount={Math.ceil(data.length / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Appraisal;
