import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import { getToken } from "../../utility/utilityFunctions";

const AddSyllabusForm = () => {
  const [chapters, setChapters] = useState([
    {
      chNo: "",
      startDate: "",
      endDate: "",
      name: "",
      copyCheckingDate: "",
      daysNeeded: "",
      tasks: [],
    },
  ]);
  const [screenData, setScreenData] = useState({
    grades: [],
    subjects: [],
    sessions: [],
  });

  const rawCommonData = {
    subject: "",
    grade: "",
    session: "",
  };
  const [commonData, setCommonData] = useState(rawCommonData);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/syllabusAddScrren`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        let { grades, subjects, sessions } = response.data;

        const acaemicHeadRole =
          localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).role;
        if (acaemicHeadRole === "pre_primary_academic_incharge") {
          grades = grades.filter((grade) =>
            ["NUR", "K.G", "PREP", "PG"].includes(grade.name)
          );
        } else if (acaemicHeadRole === "primary_academic_incharge") {
          grades = grades.filter((grade) =>
            ["I", "II", "III", "IV", "V"].includes(grade.name)
          );
        } else if (acaemicHeadRole === "middle_academic_incharge") {
          grades = grades.filter((grade) =>
            ["VI", "VII", "VIII", "IX", "X", "XI", "XII"].includes(grade.name)
          );
        }

        setScreenData({
          ...screenData,
          grades,
          subjects,
          sessions,
        });
      });
  }, []);

  const handleChapterAdd = () => {
    setChapters([
      ...chapters,
      {
        chNo: "",
        startDate: "",
        endDate: "",
        name: "",
        copyCheckingDate: "",
        daysNeeded: "",
        tasks: [],
      },
    ]);
  };

  const handleChapterRemove = (index) => {
    setChapters(chapters.filter((chapter, i) => i !== index));
  };

  const handleTaskAdd = (chapterIndex) => {
    setChapters(
      chapters.map((chapter, index) => {
        if (index === chapterIndex) {
          return {
            ...chapter,
            tasks: [...chapter.tasks, { date: "", id: uuidv4(), name: "" }],
          };
        }
        return chapter;
      })
    );
  };

  const handleTaskRemove = (chapterIndex, taskIndex) => {
    setChapters(
      chapters.map((chapter, index) => {
        if (index === chapterIndex) {
          return {
            ...chapter,
            tasks: chapter.tasks.filter((task, i) => i !== taskIndex),
          };
        }
        return chapter;
      })
    );
  };

  const handleInputChange = (chapterIndex, taskIndex, key, value) => {
    setChapters(
      chapters.map((chapter, index) => {
        if (index === chapterIndex) {
          if (taskIndex === undefined) {
            return {
              ...chapter,
              [key]: value,
            };
          }
          return {
            ...chapter,
            tasks: chapter.tasks.map((task, i) => {
              if (i === taskIndex) {
                return {
                  ...task,
                  [key]: value,
                };
              }
              return task;
            }),
          };
        }
        return chapter;
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Hit POST API with the complete data

    if (
      commonData.subject == "" ||
      commonData.grade == "" ||
      commonData.session == ""
    ) {
      window.notif({
        msg: "Please Provide all required fields",
        type: "error",
        position: "center",
      });
      return;
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/syllabus/new`,
        {
          ...commonData,
          chapters,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response?.data.success) {
          window.notif({
            msg: "<b>Woohoo!!</b> Syllabus Added Successfully",
            type: "success",
          });

          setCommonData(rawCommonData);
        } else {
          window.notif({
            // msg: response?.data.error,
            msg: "Syllabus Added already",
            type: "error",
            position: "center",
          });
        }
      });
  };
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="col-md-12 d-flex p-0">
        <div className="form-group col-md-6">
          <label htmlFor="subject">Subject</label>
          <select
            className="form-control"
            id="subject"
            name="subject"
            value={commonData.subject}
            required
            onChange={(e) => {
              setCommonData({
                ...commonData,
                subject: e.target.value,
              });
            }}
          >
            <option>Select Subject</option>
            {screenData.subjects.map((sub) => (
              <option value={sub._id}>{sub.name}</option>
            ))}
          </select>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="grade">Grade</label>
          <select
            className="form-control"
            id="grade"
            name="grade"
            value={commonData.grade}
            required
            onChange={(e) => {
              setCommonData({
                ...commonData,
                grade: e.target.value,
              });
            }}
          >
            <option>Select Grade</option>
            {screenData.grades.map((grade) => (
              <option value={grade._id}>{grade.name}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="col-md-12 d-flex p-0">
        <div className="form-group col-md-6">
          <label htmlFor="start_date">Start Date</label>
          <input
            type="date"
            className="form-control"
            id="start_date"
            name="start_date"
            value={commonData.start_date}
            onChange={(e) => {
              setCommonData({
                ...commonData,
                start_date: e.target.value,
              });
            }}
            min={getCurrentDate()}
          ></input>
        </div>

        <div className="form-group col-md-6">
          <label htmlFor="end_date">End Date</label>
          <input
            type="date"
            className="form-control"
            id="end_date"
            name="end_date"
            value={commonData.end_date}
            onChange={(e) => {
              setCommonData({
                ...commonData,
                end_date: e.target.value,
              });
            }}
            min={commonData.start_date}
          ></input>
        </div>
      </div>

      <div className="form-group p-2">
        <label htmlFor="session">Session</label>
        <select
          className="form-control"
          id="session"
          name="session"
          value={commonData.session}
          required
          onChange={(e) => {
            setCommonData({
              ...commonData,
              session: e.target.value,
            });
          }}
        >
          <option value="">Select Session</option>
          {screenData.sessions.map((session) => {
            return (
              <option value={session.session_name}>
                {session.session_name}
              </option>
            );
          })}
        </select>
      </div>
      {chapters.map((chapter, index) => (
        <div className="card mb-3" key={index}>
          <div className="card-body">
            {index > 0 && (
              <i
                className="fa fa-minus-circle text-danger mb-4"
                style={{ fontSize: "20px", float: "right" }}
                onClick={() => handleChapterRemove(index)}
              ></i>
            )}
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor={`chNo-${index}`}>Chapter Number</label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id={`chNo-${index}`}
                  name={`chNo-${index}`}
                  value={chapter.chNo}
                  onChange={(e) =>
                    handleInputChange(index, undefined, "chNo", e.target.value)
                  }
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor={`startDate-${index}`}>Chapter Start Date</label>
                <input
                  required
                  type="date"
                  className="form-control"
                  id={`startDate-${index}`}
                  name={`startDate-${index}`}
                  value={chapter.startDate}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      undefined,
                      "startDate",
                      e.target.value
                    )
                  }
                  min={getCurrentDate()}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor={`endDate-${index}`}>Chapter End Date</label>
                <input
                  required
                  type="date"
                  className="form-control"
                  id={`endDate-${index}`}
                  name={`endDate-${index}`}
                  value={chapter.endDate}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      undefined,
                      "endDate",
                      e.target.value
                    )
                  }
                  min={getCurrentDate()}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor={`name-${index}`}>Chapter Name</label>
              <input
                required
                type="text"
                className="form-control"
                id={`name-${index}`}
                name={`name-${index}`}
                value={chapter.name}
                onChange={(e) =>
                  handleInputChange(index, undefined, "name", e.target.value)
                }
              />
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor={`copyCheckingDate-${index}`}>
                  Copy Checking Date
                </label>
                <input
                  required
                  type="date"
                  className="form-control"
                  id={`copyCheckingDate-${index}`}
                  name={`copyCheckingDate-${index}`}
                  value={chapter.copyCheckingDate}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      undefined,
                      "copyCheckingDate",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor={`daysNeeded-${index}`}>
                  Days Needed for Completion
                </label>
                <input
                  required
                  type="number"
                  className="form-control"
                  id={`daysNeeded-${index}`}
                  name={`daysNeeded-${index}`}
                  value={chapter.daysNeeded}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      undefined,
                      "daysNeeded",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
            {chapter.tasks.map((task, taskIndex) => (
              <div
                className="card mb-3"
                style={{ backgroundColor: "#ecf1f8" }}
                key={taskIndex}
              >
                <div className="card-body" style={{ padding: "10px" }}>
                  <i
                    className="fa fa-minus-circle text-danger mb-4"
                    style={{ fontSize: "20px", float: "right" }}
                    onClick={() => handleTaskRemove(index, taskIndex)}
                  ></i>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor={`taskDate-${index}-${taskIndex}`}>
                        Task Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id={`taskDate-${index}-${taskIndex}`}
                        name={`taskDate-${index}-${taskIndex}`}
                        value={task.date}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            taskIndex,
                            "date",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor={`taskType-${index}-${taskIndex}`}>
                        Task Type
                      </label>
                      <select
                        required
                        className="form-control"
                        id={`taskType-${index}-${taskIndex}`}
                        name={`taskType-${index}-${taskIndex}`}
                        value={task.type}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            taskIndex,
                            "type",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select Type</option>
                        <option value="HW">HW</option>
                        <option value="CW">CW</option>
                        <option value="Activity">Activity</option>
                      </select>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor={`taskName-${index}-${taskIndex}`}>
                        Task Name
                      </label>
                      <input
                        required
                        type="text"
                        className="form-control"
                        id={`taskName-${index}-${taskIndex}`}
                        name={`taskName-${index}-${taskIndex}`}
                        value={task.name}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            taskIndex,
                            "name",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="form-group">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleTaskAdd(index)}
              >
                Add Task
              </button>
            </div>
          </div>
        </div>
      ))}
      <div className="form-group">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleChapterAdd}
        >
          Add Chapter
        </button>
      </div>
      <div className="form-group">
        <button type="submit" className="btn btn-success">
          Add Syllabus
        </button>
      </div>
    </form>
  );
};
export default AddSyllabusForm;
