import axios from "axios";
import React, { useEffect, useState } from "react";
import { getToken } from "../../utility/utilityFunctions";

const AddCHTargetForm = () => {
  const data = {
    branch: "",
    student_target: 0,
    session: "",
  };

  const [formData, setformData] = useState(data);
  const [branches, setBranches] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(false);

  const [targets, setTargets] = useState([]);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/targets`,
        { selectedSession },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        // console.log("response", response);
        const { branches, targets, sessions } = response.data;
        setBranches(branches);
        setTargets(targets);
        setSessions(sessions);
      });
  }, [selectedSession]);

  const addCHTarget = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/chtarget/new`, formData, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        if (response?.data.success) {
          window.notif({
            msg: "<b>Woohoo!!</b> Target Added Successfully",
            type: "success",
            position: "center",
          });
          const { targets } = response.data;
          setTargets(targets);
          setformData(data);
          // Delay the page reload by 2 seconds (adjust as needed)
          setTimeout(() => {
            window.location.reload(); // Reload the page
          }, 2000);
        } else if (
          response?.data.message ===
          "Target for this branch and session already exists."
        ) {
          window.notif({
            msg: "Target for this branch and session already exists.",
            type: "warning",
            position: "center",
          });
          // Delay the page reload by 2 seconds (adjust as needed)
          setTimeout(() => {
            window.location.reload(); // Reload the page
          }, 2000);
        } else {
          window.notif({
            msg: response?.data.error,
            type: "error",
            position: "center",
          });
        }
      });
  };

  let count = 1;
  return (
    <>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Fill following information</h4>
          </div>
          <div className="card-body">
            <form className="form-horizontal">
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Session</label>
                <div className="col-md-9">
                  <select
                    className="form-control"
                    name="session"
                    id="session"
                    value={formData.session}
                    onChange={(e) => {
                      setformData({
                        ...formData,
                        session: e.target.value,
                      });
                    }}
                    required
                  >
                    <option value="">Select Session</option>
                    {sessions.map((session) => {
                      return (
                        <option value={session.session_name}>
                          {session.session_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Branch</label>
                <div className="col-md-9">
                  <select
                    className="form-control"
                    name="branch"
                    id="branch"
                    value={formData.branch}
                    onChange={(e) => {
                      setformData({
                        ...formData,
                        branch: e.target.value,
                      });
                    }}
                  >
                    {branches.map((branch) => (
                      <option value={branch._id}>{branch.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Student Target</label>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Student Target"
                    id="student_target"
                    value={formData.student_target}
                    onChange={(e) => {
                      setformData({
                        ...formData,
                        student_target: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className=" row mb-4">
                <div className="col-md-3">
                  <button
                    type="submit"
                    class="btn btn-danger text-light"
                    onClick={() => {
                      addCHTarget();
                    }}
                  >
                    <i class="fe fe-user-plus"></i>&nbsp;Add Target
                  </button>
                </div>
              </div>
            </form>

            <div className="col-lg-12">
              <div className="card">
                <div></div>
                <div className="mb-3 col-4 d-flex align-items-center justify-content-end">
                  <label
                    htmlFor="example-session"
                    className="col-6 form-label text-end"
                  >
                    Acadmic Session
                  </label>
                  <select
                    className="form-control"
                    name=""
                    value={selectedSession}
                    id="selectedSession"
                    onChange={(e) => {
                      setSelectedSession(e.target.value);
                    }}
                  >
                    <option value="">Select Session</option>
                    {sessions.map((session) => {
                      return (
                        <option value={session.session_name}>
                          {session.session_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      className="table table-bordered border text-nowrap mb-0"
                      id="basic-edit"
                    >
                      <thead>
                        <tr>
                          <th>
                            <b>Sr.No</b>
                          </th>
                          <th>
                            <b>Branch</b>
                          </th>
                          <th>
                            <b>targets</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {targets.map((target) => {
                          return (
                            <tr key={target._id}>
                              <td>{count++}</td>
                              <td>{target.branch.name}</td>
                              <td>{target.student_target}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCHTargetForm;
