import React from "react";
import { Link } from "react-router-dom";
import {
//   FaHouseUser,
//   FaUserTie,
//   FaUsers,
//   FaHeadphonesAlt,
  FaBookOpen,
//   FaRupeeSign,
//   FaRegClock,
//   FaHandHoldingUsd,
//   FaRegClipboard,
//   FaUserSlash,
//   FaUserEdit,
//   FaRegEdit,
//   FaWhmcs,
//   FaRavelry,
//   FaArchway,
//   FaAward,
//   FaUserFriends,
//   FaUniversity,
  FaGifts,
//   FaSearchengin,
//   FaRegListAlt,
//   FaRegNewspaper,
//   FaSlack,
  FaAddressBook,
//   FaBroom,
//   FaChartBar,
} from "react-icons/fa";

function SidebarForAcademicIncharge() {
  return (
    <>
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/syllabus"
        >
          <FaBookOpen className="side-menu__icon" />

          <span className="side-menu__label">Syllabus</span>
        </Link>
      </li>
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/exam"
        >
          <FaAddressBook className="side-menu__icon " />

          <span className="side-menu__label">Manage Exams</span>
        </Link>
      </li>
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/events"
        >
          <FaGifts className="side-menu__icon " />

          <span className="side-menu__label">Manage Events</span>
        </Link>
      </li>
    </>
  );
}

export default SidebarForAcademicIncharge;
