import axios from "axios";
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import {
  getToken,
  timezoneDateToddMMyyyy,
} from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";

function Student() {
  
  const { id } = useParams();
  const [studentData, setStudents] = useState([]);
 
  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/admin/mark/getStudent`;
    const loaderElement = document.getElementsByClassName("loader")[0]; 
    if (loaderElement) {
      // If the loader element exists, you can modify its display style
      loaderElement.style.display = "block"; // or "block" or any other desired value
    }
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { studentData } = response.data; 
        console.log(studentData);
        setStudents(studentData);
  
        if (loaderElement) {
          // If the loader element exists, you can modify its display style
          loaderElement.style.display = "none"; // or "block" or any other desired value
        }
      });
  }, []);

  const updateSection = (section,student_id) => {
     
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/admin/mark/update-section`,
          {
            studentId: student_id,
            section: section,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          window.notif({
            msg: "<b>Woohoo!!</b> Record updated successfully",
            type: "success",
          });
        });
     
  };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div> 
            <div style={{ backgroundColor: "white", padding: "25px",marginBottom:"10px" }}>
              <div className="">
                <h1
                  className="page-title"
                  style={{ marginLeft: "12px", marginBottom: "15px" }}
                >
                  Update Student Section
                </h1> 
              </div>
            </div>
            <div class="col-sm-4">
                  <div className="loader" style={{ display: "none" }}>
                  <h2>Hold On !!</h2>
                  <h3>We are generating report....</h3>
                  <div
                    className="spinner-border text-danger"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div> 
              </div> 
            
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive"> 
      <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
      <thead>
                              <tr>
                                  <th>
                                  <b>Sr No.</b>
                                </th> 
                                <th>
                                  <b>Name</b>
                                </th> 
                                <th>
                                  <b>Father Name</b>
                                </th>
                                <th>
                                  <b>Mobile</b>
                                </th>
                                <th>
                                  <b>Gender</b>
                                </th> 
                                <th>
                                  <b>Grade</b>
                                </th>
                                <th>
                                  <b>Current Section</b>
                                </th> 
                                <th>
                                  <b>Update Section</b>
                                </th>
                                
                              </tr>
                            </thead>
        <tbody>
      {studentData?.map((kay) => (
        <tr key={kay._id}>
          <td>{kay.student.sr_no}</td>
          <td>{kay.name}</td>
          <td>{kay.father_name}</td>
          <td>{kay.mobile}</td>
          <td>{kay.student.gender}</td>
          <td>{kay.student.grade_name}</td>
          <td>{kay.student.section}</td>
         <td>
         <Link to={`/admin/mark/fees-detail/${kay._id}`} >
              <i class="icon icon-wallet ml-2" />
              <span>Collect</span>
            </Link>
         </td>

        </tr>
      ))}
      </tbody>
      </table> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Student;