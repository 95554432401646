import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import axios from "axios";
import GoBackButton from "../../component/GoBackButton";
import Sidebar from "../../component/Sidebar";
import { getToken } from "../../utility/utilityFunctions";
import { Navigate } from "react-router-dom";

const SessionManager = () => {
  const { register, handleSubmit, setValue, reset } = useForm();
  const [sessions, setSession] = useState(null);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/getSessions`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { session } = response.data;
        console.log("session", session);
        setSession(session);
      });
  }, []);
  // Handle form submission
  const onSubmit = async (data) => {
    console.log("data:", data);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/session_manager`,
        data,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`, // Add Authorization header
          },
        }
      );

      if (response) {
        if (response.data.success) {
          // Success message
          const successMessage = response.data.message;
          if (successMessage) {
            window.notif({
              msg: `<b>Success:</b>  ${successMessage}`,
              type: "success",
            });
          }

          // Reset the form after successful submission
          window.location.reload();
          reset();
        } else {
          // Error message
          console.log("in else")
          const errorMessage = response.data.message;
          if (errorMessage) {
            window.notif({
              msg: `<b>Error:</b> ${errorMessage}`,
              type: "error",
            });
          }
        }
      }
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };
  let count = 1;
  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <GoBackButton />
              <h1 className="page-title">Manage Session</h1>
            </div>
            <div className="col-xl-12 col-lg-12">
              <div className="card">
                <div className="card-header">
                  
                  <div class="alert alert-info" role="alert">
                    <ul>
                      <li>
                        <b>Default:</b> - Create new "Default" session for all activities that will take place in that session. On every 1st april 00:00 you need to create new session eg. 2024-2025.
                      </li>
                      <li>
                        <b>Default Admission:</b> - Create new "Default Admission" session when you would like to take new admission for next session. Eg. admission for 24-25 session you want to take in 23-24 then you need to create new session.
                      </li>
                     
                    </ul>
                  </div>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mb-4">
                      <label className="col-md-3 form-label">Session:</label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          placeholder=" eg. 2022-2023"
                          {...register("session_name", { required: true })}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <label className="col-md-3 form-label">Default:</label>
                      <div className="col-md-9">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          {...register("default_session")}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">

                      <label className="col-md-3 form-label">Default admission:</label>

                      <div className="col-md-9">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          {...register("default_session_admission")}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-3">
                        <button
                          type="submit"
                          className="btn btn-danger text-light"
                        >
                          <i className="fe fe-user-plus"></i>&nbsp;Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>S.no</b>
                                </th>
                                <th>
                                  <b>Session</b>
                                </th>
                                <th>
                                  <b>Default Session</b>
                                </th>
                                <th>
                                  <b>Admission Session</b>
                                </th>
                                <th>
                                  <b>Edit Session</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {sessions?.map((session) => {
                                return (
                                  <tr key={session?._id}>
                                    <td>{count++}</td>
                                    <td>{session?.session_name}</td>
                                    <td>
                                      {session.default_session
                                        ? "Active"
                                        : "Inactive"}
                                    </td>
                                    <td>
                                      {session.default_session_admission
                                        ? "Active"
                                        : "Inactive"}
                                    </td>
                                    <td>
                                      {" "}
                                      <a
                                        // target="_blank"
                                        className="btn btn-blue"
                                        href={`/admin/sessionManager/${session?._id}`}
                                      >
                                        Edit Session
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SessionManager;
