import axios from "axios";
import * as XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../../component/Sidebar";
import {
  formatNumberIndianStyle,
  getToken,
} from "../../../utility/utilityFunctions";

const PaidEnquired = () => {
  const today = new Date().toISOString().substr(0, 10);

  const [financeThreeReports, setFinanceThreeReports] = useState([]);

  const [branches, setBranches] = useState([]);
  const [commonGrades, setCommonGrades] = useState([]);
// const [amountvalue, setValue] = useState(0);
  const [branch, setBranch] = useState("all");
  const [session, setSession] = useState(2023);
  const [twoHunRupeeCount, set200RupeeCpunt] = useState(0);
  const [grade, setGrade] = useState("all");
  const [totalRecords, setRecords] = useState(0);
  const [totalAmount, setAmount] = useState(0);
  const [start_date, setStartDate] = useState(today);
  const [end_date, setEndDate] = useState(today);

  const [filterClicked, setFilterClicked] = useState(0);
  let userInfo = localStorage.getItem("user");
  userInfo = JSON.parse(userInfo);
  console.log(userInfo, "userInfo");

  useEffect(() => {
    document.getElementsByClassName("table-responsive")[0].style.display =
      "none";

    document.getElementsByClassName("loader")[0].style.display = "block";

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/paidenquiredreport`,
        {
          branch:
            userInfo?.role !== "admin" && userInfo?.role !== "financial_head"
              ? userInfo.branch[0]._id
              : branch,

          session,
          grade,
          start_date,
          end_date,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        document.getElementsByClassName("loader")[0].style.display = "none";

        document.getElementsByClassName("table-responsive")[0].style.display =
          "block";
        const { financeReportData, branches, commonGrades, totalAmount } =
          response.data;
        setBranches(branches);
        setAmount(totalAmount);
        setCommonGrades(commonGrades);
        setFinanceThreeReports(financeReportData);
        setRecords(financeReportData.length);
    
      });
  }, [filterClicked, branch]);
  const handleExport = () => {
    const table = document.getElementById("myTable");
    const workbook = XLSX.utils.table_to_book(table);
    XLSX.writeFile(workbook, "finance.xlsx");
  };

  let temp200Count = 0;
  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>

            <div style={{ backgroundColor: "white", padding: "25px" }}>
              <div className="">
                <h1
                  className="page-title"
                  style={{ marginLeft: "12px", marginBottom: "15px" }}
                >
                  All Paid Enquired Students
                </h1>
                <div className="col-md-6 d-flex flex-wrap">
                  {
                    userInfo?.role !== "admin" && userInfo?.role !== "financial_head" ? "" : <div className="">
                    <select
                      className="form-control"
                      name=""
                      value={branch}
                      id="branch"
                      onChange={(e) => {
                        setBranch(e.target.value);
                      }}
                    >
                      <option value="all">All Branches</option>
                      {branches.map((branch) => (
                        <option value={branch._id.toString()}>
                          {branch?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  } 
                 

                  <div className="">
                    <select
                      className="form-control"
                      name=""
                      value={grade}
                      id="grade"
                      onChange={(e) => {
                        setGrade(e.target.value);
                      }}
                    >
                      <option value="all">All Grades</option>
                      {commonGrades.map((data) => (
                        <option value={data._id}>{data?.name}</option>
                      ))}
                    </select>
                  </div>

                  <div className="">
                    <select
                      className="form-control"
                      name=""
                      value={session}
                      id="session"
                      onChange={(e) => {
                        setSession(e.target.value);
                      }}
                    >
                      <option value="2023">Session 2023 - 2024</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="mt-3" style={{ display: "flex" }}>
                <div className="col-md-6">
                  <label htmlFor="start_date">Date</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                    value={start_date}
                    name=""
                    id="start_date"
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="end_date">End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                    value={end_date}
                    name=""
                    id="end_date"
                  />
                </div>
              </div>

              <div className="mt-3" style={{ padding: "5px 12px" }}>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={(e) => {
                    setFilterClicked((prevCount) => prevCount + 1);
                  }}
                >
                  <i class="side-menu__icon fe fe-filter" /> Filter
                </button>
                <button
                  className="btn btn-sm btn-green"
                  style={{ marginLeft: "5px" }}
                  onClick={handleExport}
                >
                  Export Data
                </button>
              </div>

              <div style={{ padding: "15px" }}>
                <div className="mt-3">Total Records : {totalRecords}</div>
                <div className="mt-3">
                  Total Amount : &#8377;{formatNumberIndianStyle(totalAmount)}
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div class="loader" style={{ display: "none" }}>
                          <h2>Hold On !!</h2>
                          <h3>We are generating report....</h3>
                          <div class="spinner-border text-danger" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>

                        <div className="table-responsive">
                          {totalRecords > 0 ? (
                            <>
                              <table
                                className="table table-bordered border text-nowrap mb-0"
                                id="myTable"
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <b>Reciept</b>
                                    </th>
                                    <th>
                                      <b>Enrollment No.</b>
                                    </th>
                                    <th>
                                      <b>Student</b>
                                    </th>
                                    <th>
                                      <b>Father/Guardian Name</b>
                                    </th>
                                    <th>
                                      <b>Mobile</b>
                                    </th>
                                    <th>
                                      <b>Branch</b>
                                    </th>
                                    <th>
                                      <b>Grade</b>
                                    </th>
                                    <th>
                                      <b>Amount</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {financeThreeReports.map(
                                    (financeThreeReport) => {
                                      if (
                                        financeThreeReport?.amount &&
                                        financeThreeReport.amount == 200
                                      ) {
                                        ++temp200Count;
                                        // setValue(temp200Count);
                                        
                                        return (
                                            <tr key={financeThreeReport._id}>
                                              <td>
                                                {" "}
                                                <a
                                                  target="blank"
                                                  href={
                                                    process.env.REACT_APP_BASE_URL +
                                                    "/admin/invoice/" +
                                                    financeThreeReport.slug
                                                  }
                                                >
                                                  {financeThreeReport?.name}
                                                </a>
                                              </td>
                                              <td>{financeThreeReport.sr_no}</td>
                                              <td>{financeThreeReport.userName}</td>
                                              <td>
                                                {financeThreeReport.father_name}
                                              </td>
                                              <td>{financeThreeReport.mobile}</td>
                                              <td>{financeThreeReport.branch}</td>
                                              <td>{financeThreeReport.grade}</td>
                                              <td>
                                                &#8377;{financeThreeReport.amount}
                                              </td>
                                            </tr>
                                          );
                                      }

                                    
                                    }
                                  )}
                                </tbody>
                              </table>
                              <div className="mt-5">
                                <span style={{ fontWeight: "bold" }}>
                                  Total Paid Enquired Students :
                                </span>{" "}
                                <span style={{ fontWeight: "bolder" }}>
                                  {temp200Count}
                                </span>
                              </div>
                            </>
                          ) : (
                            <h1>No record found.</h1>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaidEnquired;
