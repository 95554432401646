import React from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import FormReciept from "../reciepts/FormReciept";
import { getToken } from "../../utility/utilityFunctions";
import placeholder from "../../assets/images/brand/placeholder.png";
import AdmissionForm from "../admissionForm/AdmissionForm";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormData from "form-data";
import ButtonComponent from "../../component/ButtonComponent";
// import FormReciept from "../reciepts/FormReciept";

const AddStudentDetailForm = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [srExists, setSrExists] = useState([]);

  const validationSchema = yup.object({
    mobile: yup
      .string()
      .required("Please enter an phone number.")
      .max(10, "Maximum allowed character is 10")
      .matches(/^[0-9]\d{9}$/, "Please enter valid phone number."),
    dob: yup.string().required("Please enter your DOB."),
    gender: yup.string().required("Please choose   gender."),
    payment_method: yup
      .string()
      .test("payment-method", "Please choose payment method", function (value) {
        if (studentData.status === "enquired" && !studentData.isRteStudent) {
          if (!value) {
            // Payment method is required
            return false;
          }
        }

        return true; // Allow other cases
      }),

    grade: yup.string().required("Please choose correct grade."),
    address: yup.string().required("Please enter proper address."),
    father_name: yup.string().required("Please enter  father/guardian name."),
    father_email: yup.string().email("Please enter a valid email."),
    mother_email: yup.string().email("Please enter a valid email."),
    tution_fee_concession: yup.string().required("please enter Zero atleast"),
    admission_fee_concession: yup
      .string()
      .required("please enter Zero atleast"),
    transaction_id: yup.string().when(["payment_method"], {
      is: (paymentMethod) => ["online", "cheque"].includes(paymentMethod),
      then: () => yup.string().required("Transaction ID is required"),
    }),
  });
  const {
    register,
    setError,

    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      tution_fee_concession: 0,
      admission_fee_concession: 0,
    },
  });

  // const form_fee_check = watch("form_fee");
  const isRteStudent_check = watch("isRteStudent");
  const payment_method_check = watch("payment_method");

  const studentData = watch();

  const params = useParams();
  const navigate = useNavigate();
  const [profilePicInAdmissionForm, setProfilePicInAdmissionForm] =
    useState("");
  // console.log("params:", params);

  function previewImage(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (event) {
      setProfilePicInAdmissionForm(event.target.result);
    };
  }
  // console.log("studentData:", studentData);

  const [isRecordSaved, setIsRecordSaved] = useState(false);
  const [prevTutionFeeConcessionCheck, setPrevTutionFeeConcessionCheck] =
    useState(0);

  const [screenData, setScreenData] = useState({
    branches: [],
    roles: [],
    grades: [],
    houses: [],
    student: {},
    gradesOfBranch: [],
    studentChangeStatus: [],
  });

  const downloadPDF = (filename) => {
    const input = document.getElementById("admission_form");
    html2canvas(input, { scale: 0.83 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);
      // pdf.save("studentform.pdf");
      pdf.save(`${filename}.pdf`);
    });
  };

  const downloadForm = () => {
    const input = document.getElementById("admission_form_for_user");
    html2canvas(input, { scale: 0.83 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);
      // pdf.save("studentform.pdf");
      // pdf.save(`${studentData.name}_form_reciept.pdf`);
    });
  };
  useEffect(() => {
    // Create an array of promises for both API calls
    const apiCalls = [
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/student/${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      ),
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/changestatus/${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      ),
    ];

    // Use Promise.all to execute both API calls concurrently
    Promise.all(apiCalls)
      .then((responses) => {
        const [firstApiResponse, secondApiResponse] = responses;

        // Extract data from the first API response
        const { student, branches, grades, houses } = firstApiResponse.data;
        // console.log("student:", student);
        // Extract data from the second API response
        const { studentChangeStatus } = secondApiResponse.data;
        console.log("grades:", grades);
        // Update your screen data with the data from both API calls
        setScreenData({
          ...screenData,
          branches,
          grades,
          houses,
          student,
          studentChangeStatus,
        });

        setProfilePicInAdmissionForm(
          process.env.REACT_APP_BASE_URL +
            "/admin/images/" +
            student.profile_pic
        );
        function formatDateToDdMmYyyy(inputDate) {
          const date = new Date(inputDate);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          return `${year}-${month}-${day}`;
        }
        if (student.proposed_doe) {
          const formattedproposed_doe = formatDateToDdMmYyyy(
            student.proposed_doe
          );
          student.proposed_doe = formattedproposed_doe;
        }
        if (student.dob) {
          const formattedproposed_dob = formatDateToDdMmYyyy(student.dob);
          student.dob = formattedproposed_dob;
        }
        // const formatteddob = formatDateToDdMmYyyy(student.dob);

        // student.dob = formatteddob;

        student.grade = student?.grade?._id;
        if (student.status == "promoted") {
          // student.disgrade = student?.grade;
          // delete student.grade;
        }
        // const formattedStudent = {
        //   ...student,
        //   dob: formatToDdMmYyyy(student.dob),
        //   proposed_doe: formatToDdMmYyyy(student.proposed_doe),
        // };
        reset(student);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [isRecordSaved]);
  console.error(errors);

  const updateStudent = (e) => {
    // setShowLoader(true);
    console.log("e:", e);
    let concession = false;

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/student/checkconcession`,
        {
          grade: e.grade,
          admission_fee_concession: e.admission_fee_concession,
          tution_fee_concession: e.tution_fee_concession,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        // console.log("response", response.data);
        const { tution_fee } = response.data;
        const { admission_fee } = response.data;
        // console.log("tution_fee:", tution_fee);
        // console.log("admission_fee:", admission_fee);

        if (response?.data.success) {
          concession = true;
          if (concession) {
            e.profile_pic = e.profile_pic[0];

            if (
              window.confirm("Are you sure you want to update this student ?")
            ) {
              axios
                .put(
                  `${process.env.REACT_APP_BASE_URL}/admin/student`,
                  {
                    ...e,
                    id: params.id,
                  },
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                      Authorization: `Bearer ${getToken()}`,
                    },
                  }
                )
                .then((response) => {
                  //   setTimeout(() => {
                  // setShowLoader(false)
                  // }, 1000)

                  if (response?.data.success) {
                    window.notif({
                      msg: "<b>Woohoo!!</b> Student Record Updated Successfully",
                      type: "success",
                    });
                    if (studentData.status == "enquired") {
                      const reciptNumber = response.data?.reciptNumberObj?.name;
                      document.getElementById("recieptNo1").innerText =
                        reciptNumber;
                      document.getElementById("recieptNo2").innerText =
                        reciptNumber;
                      downloadPDF(response.data?.reciptNumberObj?.slug);
                    }
                    downloadForm();
                    setIsRecordSaved(!isRecordSaved);
                  } else {
                    window.notif({
                      msg: response?.data.error,
                      type: "error",
                      position: "center",
                    });
                  }
                });
            }
          }
        } else {
          alert(
            `Your Concession amount is greater of real admission fee (${admission_fee}) and tution fee (${tution_fee})`
          );
        }
      });
  };

  const promteStudent = () => {
    if (window.confirm("You really want to promote student ?")) {
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/admin/student/promteStudent`,
          {
            id: params.id,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          if (response?.data.success) {
            window.notif({
              msg: "<b>Woohoo!!</b> Student Promted Successfully",
              type: "success",
            });

            setIsRecordSaved(!isRecordSaved);
          } else {
            window.notif({
              msg: response?.data.error,
              type: "error",
              position: "center",
            });
          }
        });
    }
  };

  const handleBranchesChange = (branch) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/getGradesOfBranches`,
        { branch },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        const { gradesOfBranch } = response.data;
        setScreenData({
          ...screenData,
          gradesOfBranch,
        });
      });
  };

  const transferStudent = () => {
    if (window.confirm("You really want to transfer student ?")) {
      const transfer_to_grade =
        document.getElementById("transfer_to_grade").value;
      const transfer_to_branch = document.getElementById(
        "transfer_to_branches"
      ).value;
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/admin/student/changeBranchOfStudent`,
          {
            grade: transfer_to_grade,
            branch: transfer_to_branch,
            user_id: params.id,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          document.getElementById("transfer-popup-close-btn").click();
          window.notif({
            msg: "<b>Woohoo!!</b> Student Transferred Successfully",
            type: "success",
          });
          navigate(`/admin/students/`);
        });
    }
  };

  const archive = () => {
    if (window.confirm("You really want to archive student ?")) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/admin/student/archiveStudent`,
          {
            user_id: params.id,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          document.getElementById("transfer-popup-close-btn").click();
          window.notif({
            msg: "<b>Woohoo!!</b> Student Archived Successfully",
            type: "success",
          });
          navigate(`/admin/students/`);
        });
    }
  };

  const checkFees = (currentvalue) => {
    const grade = document.getElementById("grade").value;

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/student/checkfees`,
        {
          currentvalue: currentvalue,
          grade: grade,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        const data = response.data;

        const admissionFeeConcessionElement = document.getElementById(
          "admission_fee_concession"
        );
        if (data.status == "error") {
          admissionFeeConcessionElement.style.color = "red";
          admissionFeeConcessionElement.value = 0;
          window.notif({
            msg: "<b>Woohoo!!</b> " + data.message,
            type: data.status,
          });
        } else {
          admissionFeeConcessionElement.style.color = "black";
        }
      });
  };

  function check_admin_fees() {
    const admissionFeeConcessionElement = document.getElementById(
      "admission_fee_concession"
    );
    admissionFeeConcessionElement.value = 0;
  }
  // Function to toggle the accordion state
  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  console.log("student:", studentData);
  // Function to make the API request
  const checkRecordExistsWishSrNumber = async (SrNumber) => {
    try {
      // Make an API request to your server using Axios
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/getSameSrNumberStudent?SrNumber=${SrNumber}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      // Extract the data from the Axios response
      const { studentSameSrNumber } = response.data;
      // Update the state variable based on the API response
      setSrExists(studentSameSrNumber);
    } catch (error) {
      // Handle errors
      console.error("An error occurred:", error);
    }
  };
  

  const moveToRegistered = () => {
    if (window.confirm("You really want to register student ?")) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/admin/student/moveToRegistered`,
          {
            id: params.id,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          if (response?.data.success) {
            window.notif({
              msg: "<b>Woohoo!!</b> Student Registered Successfully",
              type: "success",
            });

            setIsRecordSaved(!isRecordSaved);
          } else {
            window.notif({
              msg: response?.data.error,
              type: "error",
              position: "center",
            });
          }
        });
    }
  };
  

  const moveToPromoted = () => {
    if (window.confirm("You really want to promote student ?")) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/admin/student/moveToPromoted`,
          {
            id: params.id,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          if (response?.data.success) {
            window.notif({
              msg: "<b>Woohoo!!</b> Student Promoted Successfully",
              type: "success",
            });

            setIsRecordSaved(!isRecordSaved);
          } else {
            window.notif({
              msg: response?.data.error,
              type: "error",
              position: "center",
            });
          }
        });
    }
  };

  return (
    <>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <div>
              <h4 className="card-title">
                {studentData?.status === "enquired" ? (
                  <span className="badge bg-danger badge-sm  me-1 mb-1 mt-1 ml-10">
                    Enquired
                  </span>
                ) : studentData?.status === "paid-enquired" ? (
                  <span className="badge bg-warning badge-sm  me-1 mb-1 mt-1 ml-10">
                    Paid Enquired
                  </span>
                ) : studentData?.status === "promoted" ? (
                  <span className="badge bg-primary badge-sm  me-1 mb-1 mt-1 ml-10">
                    Promoted
                  </span>
                ) : studentData?.status === "registeredArchive" ? (
                  <span className="badge bg-secondary badge-sm  me-1 mb-1 mt-1 ml-10">
                    registered Archive
                  </span>
                ) : studentData?.status === "promotedArchive" ? (
                  <span className="badge bg-secondary badge-sm  me-1 mb-1 mt-1 ml-10">
                    promoted Archive
                  </span>
                ) : studentData?.status === "completed" ? (
                  <span className="badge bg-secondary badge-sm  me-1 mb-1 mt-1 ml-10">
                    Completed
                  </span>
                ) : (
                  <span className="badge bg-success badge-sm  me-1 mb-1 mt-1 ml-10">
                    Registered
                  </span>
                )}
                {studentData.isRteStudent ? (
                  <span className="badge bg-warning badge-sm  me-1 mb-1 mt-1 ml-10">
                    RTE
                  </span>
                ) : (
                  <span className="badge bg-warning badge-sm  me-1 mb-1 mt-1 ml-10">
                    Non RTE
                  </span>
                )}
                {studentData?.session ? (
                  <span className="badge bg-danger badge-sm  me-1 mb-1 mt-1 ml-10">
                    Enrollment Session-{studentData?.session}
                  </span>
                ) : (
                  <></>
                )}
                {studentData?.transfer ? (
                  <span className="badge bg-danger badge-sm  me-1 mb-1 mt-1 ml-10">
                    Transfered Student
                  </span>
                ) : (
                  <></>
                )}
                {studentData?.fee_session ? (
                  <span className="badge bg-info badge-sm  me-1 mb-1 mt-1 ml-10">
                    Fee Session-{studentData?.fee_session}-
                    {studentData?.fee_session + 1}
                  </span>
                ) : (
                  <></>
                )}

                <Link to={`/admin/collect-fee/${params.id}`}>
                  <span className="badge bg-danger badge-sm  me-1 mb-1 mt-1 ml-10">
                    Go to Fee-Collect
                  </span>
                </Link>
              </h4>
            </div>

            {studentData?.profile_pic ? (
              <>
                <div>
                  <img
                    width={"100px"}
                    src={
                      process.env.REACT_APP_BASE_URL +
                      "/admin/images/" +
                      studentData?.profile_pic
                    }
                    alt={studentData?.name}
                  />
                  <br></br><br></br>
                  <span class="badge bg-success badge-sm  me-1 mb-1 mt-1 ml-10 text-white">
                    <Link to={`/admin/updatePassword/${params.id}`}>
                      <span class="text-white">Update Password</span>
                    </Link>
                  </span>
                </div>
              </>
            ) : (
              <>
                <div>
                  <img width={"100px"} src={placeholder} />
                  <br></br><br></br>
                  <span class="badge bg-success badge-sm  me-1 mb-1 mt-1 ml-10 text-white">
                    <Link to={`/admin/updatePassword/${params.id}`}>
                      <span class="text-white">Update Password</span>
                    </Link>
                  </span>
                </div>
              </>
            )}
          </div>
          {studentData?.status === "paid-enquired" &&
          studentData.isRteStudent ? (
            <>
              <div class="card-header d-flex justify-content-between">
                <div class="alert alert-info " role="alert">
                  - This student is currently categorized under both "Paid
                  Enquired" and "RTE." The reason for this classification is
                  that the student was initially registered as a "Non RTE"
                  student but later switched to the RTE category.<br></br>- If
                  any fees have been collected, whether they are in the form of
                  tuition, admission, or annual fees, the only available option
                  to rectify this is to cancel the fees from the fees collection
                  page.
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          <div className="card-body">
            <form
              className="form-horizontal "
              onSubmit={handleSubmit(updateStudent)}
            >
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Name<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    {...register("name")}
                    id="name"
                  />
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-md-3 form-label" htmlFor="example-email">
                  Email
                </label>
                <div className="col-md-9">
                  <input
                    autoComplete="off"
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    id="email"
                    {...register("email")}
                  />
                  <div className="form-error-div">
                    {errors.email?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.email?.message}
                  </div>
                </div>
              </div>

              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Mobile<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="tel"
                    autoComplete="off"
                    className="form-control"
                    // type="number"
                    placeholder="Mobile"
                    {...register("mobile")}
                    id="mobile"
                    maxLength={10}
                  />
                  <div className="form-error-div">
                    {errors.mobile?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.mobile?.message}
                  </div>
                </div>
              </div>
              {/* <div className=" row mb-4">
                <label className="col-md-3 form-label">Password</label>
                <div className="col-md-9">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Passowrd"
                    {...register("password")}
                    id="password"
                  />
                </div>
              </div> */}
              <div className="row mb-4">
                <label className="col-md-3 form-label">
                  DOB<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Date Of Birth"
                    id="dob"
                    {...register("dob")}
                    max={new Date().toISOString().split("T")[0]} // Set the max attribute to the current date
                  />
                  <div className="form-error-div">
                    {errors.dob?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.dob?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Gender<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <select
                    className="form-control"
                    {...register("gender")}
                    id="gender"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                  <div className="form-error-div">
                    {errors.gender?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.gender?.message}
                  </div>
                </div>
              </div>
              
              {/* {studentData?.status == "promoted" ? (
                <div className=" row mb-4">
                  <label className="col-md-3 form-label"> Current Grade</label>
                  <div className="col-md-9">
                    <select
                      className="form-control"
                      {...register("disgrade")}
                      disabled="true"
                    >
                      <option>Select Grade</option>
                      {screenData.grades.map((grade) => (
                        <option key={grade._id} value={grade._id}>
                          {grade.name} ({grade.branch.name})
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : (
                ""
              )} */}

              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Grade<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  {/* Define the 'grade' variable based on your condition */}
                  {/* const grade = screenData.studentChangeStatus && screenData.studentChangeStatus[0].new_grade._id === someValue; */}
                  <select
                    className="form-control"
                    {...register("grade")}
                    id="grade"
                    // disabled={
                    //   studentData?.status === "registered" ||
                    //   studentData?.status === "registeredArchive" ||
                    //   (studentData?.status !== "promoted" &&
                    //     studentData?.requiredFeeData?.total_paid > 0) ||
                    //   (studentData?.status !== "promoted" &&
                    //     studentData?.admission_fee > 0)
                    // }
                    // value={grade ? ({screenData.studentChangeStatus[0].new_grade.name}) : ""}
                  >
                    <option value="">Select Grade</option>
                    {screenData.grades.map((grade) => (
                      <option
                        key={grade._id}
                        value={grade._id}
                        selected={
                          screenData?.studentChangeStatus &&
                          screenData?.studentChangeStatus[0]?.new_grade?._id ===
                            grade._id
                        }
                      >
                        {grade.name} ({grade.branch.name})
                      </option>
                    ))}
                  </select>

                  <div class="alert alert-info mt-1" role="alert">
                    -Select the grade in which you want to promote the student
                    in this session.<br></br>- If enrollment year is "23-24" and
                    fee collection year is also "23-24" then no need to select
                    any grade. You can keep that blank if current grade is the
                    right.<br></br>- If you have collected any amount apart from
                    Admission from then you will not be able to change class.
                  </div>
                  <div className="form-error-div">
                    {errors.grade?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.grade?.message}
                  </div>
                </div>
              </div>

              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Father/Guardian Name<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Father/Guardian Name"
                    {...register("father_name")}
                    id="father_name"
                  />
                  <div className="form-error-div">
                    {errors.father_name?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.father_name?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Address<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <textarea
                    className="form-control"
                    {...register("address")}
                    id="address"
                    placeholder="Address"
                  ></textarea>
                  <div className="form-error-div">
                    {errors.address?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.address?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Profile Picture</label>
                <div className="col-md-9">
                  <input
                    className="form-control"
                    type="file"
                    id="profile_pic"
                    {...register("profile_pic")}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">SR No</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="SR No"
                    id="sr_no"
                    {...register("sr_no")}
                    onChange={(e) => {
                      // Call the function to check if the record exists
                      checkRecordExistsWishSrNumber(e.target.value);
                    }}
                  />
                  {/* Conditional rendering for displaying recordExists */}
                  {srExists.length > 0 && (
                    <div>
                      {/* Conditional rendering for displaying recordExists */}
                      {srExists.length > 0 && (
                        <div>
                          <h2>Users present with this Sr. number</h2>{" "}
                          {/* Heading added when condition is true */}
                          {srExists.map((record, index) => (
                            <div
                              key={index}
                              style={{
                                border:
                                  "1px solid #ccc" /* Add a border to each record container */,
                                padding:
                                  "10px" /* Add some padding for spacing */,
                                marginBottom:
                                  "10px" /* Add margin between records */,
                              }}
                            >
                              <p>Name: {record?.user_id?.name}</p>
                              <p>Mobile: {record?.user_id?.mobile}</p>
                              <p>Sr.number: {record?.sr_no}</p>
                              <p>Status: {record?.user_id?.status}</p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Father/Guardian Email
                </label>
                <div className="col-md-9">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Father/Guardian Email"
                    id="father_email"
                    {...register("father_email")}
                  />
                  <div className="form-error-div">
                    {errors.father_email?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.father_email?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Mother's Name</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mother's Name"
                    id="mother_name"
                    {...register("mother_name")}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Mother's Email</label>
                <div className="col-md-9">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Mother's Email"
                    id="mother_email"
                    {...register("mother_email")}
                  />
                  <div className="form-error-div">
                    {errors.mother_email?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.mother_email?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Mother Mobile</label>
                <div className="col-md-9">
                  <input
                    className="form-control"
                    type="tel"
                    id="mother_mobile"
                    placeholder="Mother Mobile"
                    {...register("mother_mobile")}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Blood Group</label>
                <div className="col-md-9">
                  <select
                    className="form-control"
                    {...register("blood_group")}
                    id="blood_group"
                  >
                    <option value="">Select Blood Group</option>
                    <option value="A+">A+</option>
                    <option value="A-">A-</option>
                    <option value="B+">B+</option>
                    <option value="B-">B-</option>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                    <option value="AB+">AB+</option>
                    <option value="AB-">AB-</option>
                  </select>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Is RTE Student</label>
                <div className="col-md-9 d-flex">
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      {...register("isRteStudent")}
                      id="isRteStudent"
                      disabled={
                        studentData?.status == "registered" ||
                        studentData?.status == "paid-enquired"
                          ? true
                          : false
                      }
                      checked={studentData?.isRteStudent}
                    />
                    <span class="custom-control-label"></span>
                  </label>
                </div>
                <br></br>
                {studentData?.status == "paid-enquired" &&
                !studentData?.isRteStudent ? (
                  <div>
                    <span className="text-danger">*</span>This is a Paid
                    enquired students and you can not be mark this student as
                    RTE students. If you still want to make it RTE then go to
                    fees collection panel and select cancel fee option.
                  </div>
                ) : (
                  ""
                )}

                {studentData?.status == "paid-enquired" &&
                studentData?.isRteStudent ? (
                  <div className="alert alert-info" role="alert">
                    You cannot uncheck this option because 200 rupees were
                    collected for this student at the time of inquiry. <br></br>
                    - If you wish to move this student to the regular category,
                    you must first cancel the fees. After canceling the fees,
                    you will be able to uncheck the RTE status.<br></br>- If you
                    intend to retain this student in the RTE category, you will
                    need to refund the 200 rupees by canceling the fees.{" "}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {studentData.status === "enquired" &&
              !studentData.isRteStudent ? (
                <>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">
                      Form Fee Collect Mode
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <select
                        className="form-control"
                        id="payment_method"
                        {...register("payment_method")}
                        // onChange={(e) => {
                        //   setPaymentMode(e.target.value);
                        // }}
                      >
                        <option value="">Select Payment Mode</option>
                        <option value="cash">Cash</option>
                        <option value="online">Online</option>
                        <option value="cheque">Cheque</option>
                      </select>
                      <div className="form-error-div">
                        {errors.payment_method?.message && (
                          <span>
                            <i className="bi bi-exclamation-circle-fill me-1"></i>
                          </span>
                        )}
                        {errors.payment_method?.message}
                      </div>
                      <div className="mt-2">
                        {payment_method_check === "online" ||
                        payment_method_check === "cheque" ? (
                          <>
                            <input
                              type="text"
                              className="form-control"
                              // onChange={(e) => {
                              //   setTransactionId(e.target.value);
                              // }}
                              {...register("transaction_id")}
                              placeholder="Enter Transaction ID"
                            />
                            <div className="form-error-div">
                              {errors.transaction_id?.message && (
                                <span>
                                  <i className="bi bi-exclamation-circle-fill me-1"></i>
                                </span>
                              )}
                              {errors.transaction_id?.message}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Tuition Fee Concession<span className="text-danger">*</span>
                </label>
                <div className="col-md-9 d-flex">
                  <input
                    className="form-control"
                    {...register("tution_fee_concession")}
                    disabled={
                      studentData?.status === "registered" ||
                      studentData?.requiredFeeData?.total_paid > 200 ||
                      studentData.isRteStudent
                    }
                    id="tution_fee_concession"
                  />
                </div>
                <div className="form-error-div">
                  {errors.tution_fee_concession?.message && (
                    <span>
                      <i className="bi bi-exclamation-circle-fill me-1"></i>
                    </span>
                  )}
                  {errors.tution_fee_concession?.message}
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Admission Fee Concession<span className="text-danger">*</span>
                </label>
                <div className="col-md-9 d-flex">
                  <input
                    className="form-control"
                    id="admission_fee_concession"
                    {...register("admission_fee_concession")}
                    disabled={
                      studentData?.status === "registered" ||
                      studentData?.status === "promoted" ||
                      studentData?.isRteStudent ||
                      studentData?.requiredFeeData?.total_paid > 200
                    }
                    onBlur={(event) => checkFees(event.target.value)} // Call checkFees function when onBlur event occurs
                    onKeyUp={(event) => checkFees(event.target.value)} // Call checkFees function onKeyUp
                  />
                  <div className="form-error-div">
                    {errors.admission_fee_concession?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.admission_fee_concession?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Is Boarding</label>
                <div className="col-md-9 d-flex">
                  <label class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="boarding"
                      {...register("boarding")}
                    />
                    <span class="custom-control-label"></span>
                  </label>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">House</label>
                <div className="col-md-9">
                  <select
                    className="form-control"
                    id="house"
                    {...register("house")}
                  >
                    <option>Select House</option>
                    {screenData.houses.map((house) => (
                      <option key={house._id} value={house._id}>
                        {house.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Section</label>
                <div className="col-md-9 d-flex">
                  <select
                    className="form-control"
                    id="section"
                    {...register("section")}
                  >
                    <option>Select Section</option>
                    <option vlaue="a">A</option>
                    <option vlaue="b">B</option>
                    <option vlaue="c">C</option>
                    <option vlaue="d">D</option>
                  </select>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Nationality</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nationality"
                    id="nationality"
                    {...register("nationality")}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Proposed DOE</label>
                <div className="col-md-9">
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Proposed DOE"
                    id="proposed_doe"
                    {...register("proposed_doe")}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Age At Proposed DOE
                </label>
                <div className="col-md-9">
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Age At Proposed DOE"
                    id="age_at_proposed_doe"
                    {...register("age_at_proposed_doe")}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Home Tel</label>
                <div className="col-md-9">
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Home Tel"
                    id="home_tel"
                    {...register("home_tel")}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Father's/Guardian Occupation
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Father/Guardian Occupation"
                    id="father_occupation"
                    {...register("father_occupation")}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Father's/Guardian Office Address
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Father/Guardian Office Address"
                    id="father_office_address"
                    {...register("father_office_address")}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Father's/Guardian Office Tel
                </label>
                <div className="col-md-9">
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Father/Guardian Office Tel"
                    id="father_office_tel"
                    {...register("father_office_tel")}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Mother's Occupation
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mother Occupation"
                    id="mother_occupation"
                    {...register("mother_occupation")}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Mother's Office Address
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mother Office Address"
                    id="mother_office_address"
                    {...register("mother_office_address")}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Mother's Office Tel
                </label>
                <div className="col-md-9">
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Mother Office Tel"
                    id="mother_office_tel"
                    {...register("mother_office_tel")}
                  />
                </div>
              </div>

              <div className=" row mb-4">
                <label className="col-md-3 form-label">Aadhar Card</label>
                <div className="col-md-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Aadhar Card"
                    id="aadhar_card"
                    {...register("aadhar_card")}
                  />
                </div>
              </div>

              <div className=" row mb-4">
                <label className="form-label">Siblings Information</label>
                <div
                  className="col-md-12 d-flex"
                  style={{ "justify-content": "space-evenly" }}
                >
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Sibling Name"
                      id="sibling_1_name"
                      {...register("sibling_1_name")}
                    />
                  </div>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Age"
                      id="sibling_1_age"
                      {...register("sibling_1_age")}
                    />
                  </div>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="School Attendant"
                      id="sibling_1_schoolAttendant"
                      {...register("sibling_1_schoolAttendant")}
                    />
                  </div>
                </div>
                <div
                  className="col-md-12 d-flex mt-5"
                  style={{ "justify-content": "space-evenly" }}
                >
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Sibling Name"
                      id="sibling_2_name"
                      {...register("sibling_2_name")}
                    />
                  </div>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Age"
                      id="sibling_2_age"
                      {...register("sibling_2_age")}
                    />
                  </div>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="School Attendant"
                      id="sibling_2_schoolAttendant"
                      {...register("sibling_2_schoolAttendant")}
                    />
                  </div>
                </div>
              </div>

              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Previous School And Address
                </label>
                <div className="col-md-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Previous School And Address"
                    id="previous_school"
                    {...register("previous_school")}
                  />
                </div>
              </div>

              {(localStorage.getItem("user") &&
                JSON.parse(localStorage.getItem("user")).role ===
                  "central_head") ||
              JSON.parse(localStorage.getItem("user")).role ===
                "coordinator" ? (
                <div className="row mb-4 d-flex justify-content-between">
                  <div className="col-md-2">
                    {studentData.status !== "registeredArchive" &&
                      studentData.status !== "promotedArchive" && (
                        <ButtonComponent
                          customClass="btn btn-danger text-light"
                          text={
                            studentData.status === "enquired" &&
                            !studentData.isRteStudent
                              ? "Convert To Paid Enquiry And Download Receipt"
                              : "Update Details"
                          }
                          loading={showLoader}
                          disabled={showLoader}
                        />
                      )}
                    {/* {studentData.status == "registeredArchive" ? (
                      <Link to={`/admin/changestauts/${params.id}`}>
                        <ButtonComponent
                          customClass="btn btn-danger text-light"
                          text={"Update Student sataus"}
                          loading={showLoader}
                          disabled={showLoader}
                        />
                      </Link>
                    ) : null} */}
                  </div>

                  <br></br>
                  <br></br>
                  <div>
                    <hr
                      style={{
                        backgroundColor: "black",
                        height: "2px", // Set the height to control the thickness of the line
                        border: "none",
                        margin: "10px 0", // Adjust the margin as needed for spacing
                      }}
                    />
                  </div>
                  <br></br>
                  <br></br>
                

                  <div
                    style={{
                      background: "white",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    {
                    // studentData.status !== "registeredArchive" &&
                      // studentData.status !== "promotedArchive" &&
                      // studentData.status !== "paid-enquired" &&
                      studentData.status !== "completed" &&
                      studentData.status !== "enquired" && ( 
                        <div
                          onClick={toggleAccordion}
                          className="accordion-header"
                        >
                          <h4>
                            <span
                              style={{ marginRight: "10px", cursor: "pointer" }}
                            >
                              Click for other options, Use only in special cases
                              (Promote, Transfer and Archive student )
                            </span>
                            <i
                              className={`fa ${
                                isAccordionOpen
                                  ? "fa-chevron-up"
                                  : "fa-chevron-down"
                              }`}
                            ></i>
                          </h4>
                        </div>
                      )}
                    {/* <br></br>
                    <br></br>
                    <div style={{ background: "white", padding: "10px", borderRadius: "5px" }}>
                        {studentData.status === "registeredArchive" && (
                          <div>
                            <div onClick={toggleAccordion} className="accordion-header">
                              <h4>
                                <span style={{ marginRight: "10px", cursor: "pointer" }}>
                                  Click for other options, Use only in special cases (Promote,
                                  Transfer, and Archive student)
                                </span>
                                <i
                                  className={`fa ${
                                    isAccordionOpen ? "fa-chevron-up" : "fa-chevron-down"
                                  }`}
                                ></i>
                              </h4>
                            </div>
                            <div className="col-md-2">
                              <button
                                type="button"
                                className="btn btn-success text-light"
                                onClick={() => {
                                  promteStudent();
                                }}
                              >
                                <i className="fe fe-trending-up"></i>
                                &nbsp;Promote Student
                              </button>
                            </div>
                          </div>
                        )}
                        <br />
                        <br />
                      </div> */}

                    {isAccordionOpen && (
                      <div className="row">
                        {studentData?.status == "registered" ||
                        studentData?.status == "promoted" ? (
                          <>
                            <div className="col-md-2">
                              <button
                                className="btn ripple btn-danger"
                                type="button"
                                onClick={() => {
                                  archive();
                                }}
                              >
                                Archive Student
                              </button>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {/* {studentData?.status == "registered" ||
                        studentData?.status == "promoted" ? (
                          <div className="col-md-2">
                            <button
                              type="button"
                              class="btn btn-green"
                              data-bs-toggle="modal"
                              data-bs-target="#input-modal"
                              data-bs-whatever="@mdo"
                            >
                              Transfer Student
                            </button>
                          </div>
                        ) : (
                          <></>
                        )} */}


                        {studentData?.isEligibleToPromote
                        //  && studentData?.session !== `${studentData?.fee_session}-${parseInt(studentData?.fee_session) + 1}` 
                        ? (
                          <>
                            <div className="col-md-2">
                              <button
                                type="button"
                                className="btn btn-success text-light"
                                onClick={() => {
                                  promteStudent();
                                }}
                              >
                                <i className="fe fe-trending-up"></i>
                                &nbsp;Promote Student
                              </button>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {(studentData?.status == "enquired" ||
                          studentData?.status == "paid-enquired") &&
                          !studentData?.isRteStudent ? (
                          <>
                            <div className="col-md-2">
                              <button
                                type="button"
                                className="btn btn-primary text-light"
                                onClick={() => {
                                  downloadForm();
                                }}
                              >
                                <i className="fe fe-user-plus"></i>&nbsp; Download
                                Form
                              </button>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {studentData?.status == "enquired" &&
                        !studentData?.isRteStudent ? (
                          <>
                            <FormReciept formData={studentData} />
                            <h2 className="my-5">Student Form Preview</h2>
                            <AdmissionForm
                              formData={studentData}
                              profilePicInAdmissionForm={profilePicInAdmissionForm}
                            />
                          </>
                        ) : (
                          <></>
                        )}


                        {(studentData?.status == "enquired" ||
                          studentData?.status == "paid-enquired") &&
                          !studentData?.isRteStudent ? (
                          <>
                            <h2 className="my-5">Student Form Preview</h2>
                            <AdmissionForm
                              formData={studentData}
                              profilePicInAdmissionForm={profilePicInAdmissionForm}
                            />
                          </>
                        ) : (
                          <></>
                        )}

                        {(studentData?.status == "registeredArchive" || studentData?.status == "promotedArchive" || studentData?.status == "paid-enquired") ? (
                          <>
                            <div className="col-md-2">
                              <button
                                className="btn ripple btn-warning"
                                type="button"
                                onClick={() => {
                                  moveToRegistered();
                                }}
                              >
                               Move to Registered Student
                              </button>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {studentData?.status == "promotedArchive" ? (
                          <>
                            <div className="col-md-2">
                              <button
                                className="btn ripple btn-info"
                                type="button"
                                onClick={() => {
                                  moveToPromoted();
                                }}
                              >
                               Move to Promoted Student
                              </button>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        
                      </div>
                    )}
                  </div>
                  
                </div>
              ) : (
                <></>
              )}
              
            </form>
          </div>
        </div>
      </div>
      <div className="modal fade" id="input-modal">
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">Transfer Student</h6>

              <button
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="transfer-popup-close-btn"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="alert alert-info" role="alert">
              If, before transferring this student, you collect all types of
              fees and invoices, consider the current branch rather than the
              transferring branch.{" "}
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group col-md-12">
                  <label htmlFor="transfer_to_branches">To Branch</label>
                  <select
                    className="form-control"
                    id="transfer_to_branches"
                    onChange={(e) => {
                      handleBranchesChange(e.target.value);
                    }}
                    required
                  >
                    {screenData.branches.map((branch) => (
                      <option value={branch._id}>{branch.name}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-12">
                  <label htmlFor="transfer_to_grade">To Grade</label>
                  <select
                    className="form-control"
                    id="transfer_to_grade"
                    required
                  >
                    {screenData.gradesOfBranch.map((grade) => (
                      <option value={grade._id}>{grade.name}</option>
                    ))}
                  </select>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                className="btn ripple btn-success"
                type="button"
                onClick={() => {
                  transferStudent();
                }}
              >
                Transfer
              </button>
              <button
                className="btn ripple btn-danger"
                data-bs-dismiss="modal"
                type="button"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddStudentDetailForm;
