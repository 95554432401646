import axios from "axios";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { Link, useParams } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import {
  getToken,
  timezoneDateToddMMyyyy,
} from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";

const ChapterExcercise = () => {
  const [chapters, setChapters] = useState([]);
  const params = useParams();
  const syllabus_id = params.syllabusId;
  const chapter_id = params.chapterId;

  useEffect(() => {
    console.log("syllabus_id", syllabus_id);

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/getChaptersExcercise/`,
        { syllabus_id, chapter_id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        console.log("response.data", response.data);
        const { chapter } = response.data;
        console.log(chapter);
        setChapters(chapter);
      });
  }, []);

  const handleHwAdd = (chapterIndex) => {
    setChapters(
      chapters.map((chapter, index) => {
        if (index === chapterIndex) {
          return {
            ...chapter,
            hw: [...chapter.hw, { date: "", id: uuidv4(), name: "" }],
          };
        }
        return chapter;
      })
    );
  };

  const handleCwAdd = (chapterIndex) => {
    setChapters(
      chapters.map((chapter, index) => {
        if (index === chapterIndex) {
          return {
            ...chapter,
            cw: [...chapter.cw, { date: "", id: uuidv4(), name: "" }],
          };
        }
        return chapter;
      })
    );
  };

  const handleActivityAdd = (chapterIndex) => {
    setChapters(
      chapters.map((chapter, index) => {
        if (index === chapterIndex) {
          return {
            ...chapter,
            activity: [...chapter.activity, { date: "", id: uuidv4(), name: "" }],
          };
        }
        return chapter;
      })
    );
  };

  const handleHwRemove = (chapterIndex, taskIndex) => {
    setChapters(
      chapters.map((chapter, index) => {
        if (index === chapterIndex) {
          return {
            ...chapter,
            hw: chapter.hw.filter((task, i) => i !== taskIndex),
          };
        }
        return chapter;
      })
    );
  };

  const handleCwRemove = (chapterIndex, taskIndex) => {
    setChapters(
      chapters.map((chapter, index) => {
        if (index === chapterIndex) {
          return {
            ...chapter,
            cw: chapter.cw.filter((task, i) => i !== taskIndex),
          };
        }
        return chapter;
      })
    );
  };

  const handleActivityRemove = (chapterIndex, taskIndex) => {
    setChapters(
      chapters.map((chapter, index) => {
        if (index === chapterIndex) {
          return {
            ...chapter,
            activity: chapter.activity.filter((task, i) => i !== taskIndex),
          };
        }
        return chapter;
      })
    );
  };

  const handleHwChange = (chapterIndex, taskIndex, key, value) => {
    setChapters(
      chapters.map((chapter, index) => {
        if (index === chapterIndex) {
          if (taskIndex === undefined) {
            return {
              ...chapter,
              [key]: value,
            };
          }
          return {
            ...chapter,
            hw: chapter.hw.map((task, i) => {
              if (i === taskIndex) {
                return {
                  ...task,
                  [key]: value,
                };
              }
              return task;
            }),
          };
        }
        return chapter;
      })
    );
  };

  const handleCwChange = (chapterIndex, taskIndex, key, value) => {
    setChapters(
      chapters.map((chapter, index) => {
        if (index === chapterIndex) {
          if (taskIndex === undefined) {
            return {
              ...chapter,
              [key]: value,
            };
          }
          return {
            ...chapter,
            cw: chapter.cw.map((task, i) => {
              if (i === taskIndex) {
                return {
                  ...task,
                  [key]: value,
                };
              }
              return task;
            }),
          };
        }
        return chapter;
      })
    );
  };

  const handleActivityChange = (chapterIndex, taskIndex, key, value) => {
    setChapters(
      chapters.map((chapter, index) => {
        if (index === chapterIndex) {
          if (taskIndex === undefined) {
            return {
              ...chapter,
              [key]: value,
            };
          }
          return {
            ...chapter,
            activity: chapter.activity.map((task, i) => {
              if (i === taskIndex) {
                return {
                  ...task,
                  [key]: value,
                };
              }
              return task;
            }),
          };
        }
        return chapter;
      })
    );
  };

  const handleInputChange = (chapterIndex, taskIndex, key, value) => {
    setChapters(
      chapters.map((chapter, index) => {
        if (index === chapterIndex) {
          if (taskIndex === undefined) {
            return {
              ...chapter,
              [key]: value,
            };
          }
          return {
            ...chapter,
            tasks: chapter.tasks.map((task, i) => {
              if (i === taskIndex) {
                return {
                  ...task,
                  [key]: value,
                };
              }
              return task;
            }),
          };
        }
        return chapter;
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Hit POST API with the complete data

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/syllabus/update`,
        { syllabus_id, chapter_id, chapter: chapters },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        if (response?.data.success) {
          window.notif({
            msg: "<b>Woohoo!!</b> Syllabus Updated Successfully",
            type: "success",
          });
        } else {
          window.notif({
            msg: response?.data.error,
            type: "error",
            position: "center",
          });
        }
      });
  };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
            <GoBackButton />
              <h1 className="page-title">Manage Chapter Excercise</h1>              
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <form onSubmit={handleSubmit}>
                          {chapters.map((chapter, index) => (
                            <div className="card mb-3" key={index}>
                              <div className="card-body">
                                <div className="form-row">
                                  <div className="form-group col-md-4">
                                    <label htmlFor={`ch_no-${index}`}>
                                      Chapter Number
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id={`ch_no-${index}`}
                                      name={`ch_no-${index}`}
                                      value={chapter?.ch_no}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          undefined,
                                          "ch_no",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label htmlFor={`start_date-${index}`}>
                                      Chapter Start Date
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id={`start_date-${index}`}
                                      name={`start_date-${index}`}
                                      value={chapter?.start_date?.split("T")[0]}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          undefined,
                                          "start_date",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label htmlFor={`end_date-${index}`}>
                                      Chapter End Date
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id={`end_date-${index}`}
                                      name={`end_date-${index}`}
                                      value={chapter?.end_date?.split("T")[0]}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          undefined,
                                          "end_date",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label htmlFor={`name_of_ch-${index}`}>
                                    Chapter Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id={`name_of_ch-${index}`}
                                    name={`name_of_ch-${index}`}
                                    value={chapter?.name_of_ch}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        undefined,
                                        "name_of_ch",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <label
                                      htmlFor={`copy_checking-${index}`}
                                    >
                                      Copy Checking Date
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id={`copy_checking-${index}`}
                                      name={`copy_checking-${index}`}
                                      value={
                                        chapter.copy_checking && chapter.copy_checking.split("T")[0]
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          undefined,
                                          "copy_checking",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label htmlFor={`days_needed-${index}`}>
                                      Days Needed for Completion
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id={`days_needed-${index}`}
                                      name={`days_needed-${index}`}
                                      value={chapter?.days_needed}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          undefined,
                                          "days_needed",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>

                               
 {localStorage.getItem("user") &&
              JSON.parse(localStorage.getItem("user")).role ===
                "central_head" ? (
                <></>):<>
                 {/* Home work */}
                 <h2 className="mt-5">Home work</h2>
                                {chapter.hw.map((task, taskIndex) => (
                                  <div
                                    className="card mb-3"
                                    style={{ backgroundColor: "#ecf1f8" }}
                                    key={taskIndex}
                                  >
                                    <div
                                      className="card-body"
                                      style={{ padding: "10px" }}
                                    >
                                      <i
                                        className="fa fa-minus-circle text-danger mb-4"
                                        style={{
                                          fontSize: "20px",
                                          float: "right",
                                        }}
                                        onClick={() =>
                                          handleHwRemove(index, taskIndex)
                                        }
                                      ></i>
                                      <div className="form-row">
                                        <div className="form-group col-md-3">
                                          <label
                                            htmlFor={`taskDate-${index}-${taskIndex}`}
                                          >
                                            Task Date
                                          </label>
                                          <input
                                            type="date"
                                            className="form-control"
                                            id={`taskDate-${index}-${taskIndex}`}
                                            name={`taskDate-${index}-${taskIndex}`}
                                            value={task?.date.split("T")[0]}
                                            onChange={(e) =>
                                              handleHwChange(
                                                index,
                                                taskIndex,
                                                "date",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-md-3">
                                          <label
                                            htmlFor={`taskType-${index}-${taskIndex}`}
                                          >
                                            Task Type
                                          </label>
                                          <select
                                            className="form-control"
                                            id={`taskType-${index}-${taskIndex}`}
                                            name={`taskType-${index}-${taskIndex}`}
                                            value={task.type}
                                            onChange={(e) =>
                                              handleHwChange(
                                                index,
                                                taskIndex,
                                                "type",
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="HW" selected>
                                              HW
                                            </option>
                                          </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                          <label
                                            htmlFor={`taskName-${index}-${taskIndex}`}
                                          >
                                            Task Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id={`taskName-${index}-${taskIndex}`}
                                            name={`taskName-${index}-${taskIndex}`}
                                            value={task.name}
                                            onChange={(e) =>
                                              handleHwChange(
                                                index,
                                                taskIndex,
                                                "name",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                 <div className="form-group">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleHwAdd(index)}
                                  >
                                    Add Home Work
                                  </button>
                                </div>

                                {/* Class work */}
                                <h2 className="mt-5">Class work</h2>
                                {chapter.cw.map((task, taskIndex) => (
                                  <div
                                    className="card mb-3"
                                    style={{ backgroundColor: "#ecf1f8" }}
                                    key={taskIndex}
                                  >
                                    <div
                                      className="card-body"
                                      style={{ padding: "10px" }}
                                    >
                                      <i
                                        className="fa fa-minus-circle text-danger mb-4"
                                        style={{
                                          fontSize: "20px",
                                          float: "right",
                                        }}
                                        onClick={() =>
                                          handleCwRemove(index, taskIndex)
                                        }
                                      ></i>
                                      <div className="form-row">
                                        <div className="form-group col-md-3">
                                          <label
                                            htmlFor={`taskDate-${index}-${taskIndex}`}
                                          >
                                            Task Date
                                          </label>
                                          <input
                                            type="date"
                                            className="form-control"
                                            id={`taskDate-${index}-${taskIndex}`}
                                            name={`taskDate-${index}-${taskIndex}`}
                                            value={task?.date.split("T")[0]}
                                            onChange={(e) =>
                                              handleCwChange(
                                                index,
                                                taskIndex,
                                                "date",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-md-3">
                                          <label
                                            htmlFor={`taskType-${index}-${taskIndex}`}
                                          >
                                            Task Type
                                          </label>
                                          <select
                                            className="form-control"
                                            id={`taskType-${index}-${taskIndex}`}
                                            name={`taskType-${index}-${taskIndex}`}
                                            value={task.type}
                                            onChange={(e) =>
                                              handleCwChange(
                                                index,
                                                taskIndex,
                                                "type",
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="CW" selected>
                                              CW
                                            </option>
                                          </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                          <label
                                            htmlFor={`taskName-${index}-${taskIndex}`}
                                          >
                                            Task Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id={`taskName-${index}-${taskIndex}`}
                                            name={`taskName-${index}-${taskIndex}`}
                                            value={task.name}
                                            onChange={(e) =>
                                              handleCwChange(
                                                index,
                                                taskIndex,
                                                "name",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}

                                <div className="form-group">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleCwAdd(index)}
                                  >
                                    Add Class Work
                                  </button>
                                </div>

                                {/* Activities */}
                                <h2 className="mt-5">Activities</h2>
                                {chapter.activity.map((task, taskIndex) => (
                                  <div
                                    className="card mb-3"
                                    style={{ backgroundColor: "#ecf1f8" }}
                                    key={taskIndex}
                                  >
                                    <div
                                      className="card-body"
                                      style={{ padding: "10px" }}
                                    >
                                      <i
                                        className="fa fa-minus-circle text-danger mb-4"
                                        style={{
                                          fontSize: "20px",
                                          float: "right",
                                        }}
                                        onClick={() =>
                                          handleActivityRemove(index, taskIndex)
                                        }
                                      ></i>
                                      <div className="form-row">
                                        <div className="form-group col-md-3">
                                          <label
                                            htmlFor={`taskDate-${index}-${taskIndex}`}
                                          >
                                            Task Date
                                          </label>
                                          <input
                                            type="date"
                                            className="form-control"
                                            id={`taskDate-${index}-${taskIndex}`}
                                            name={`taskDate-${index}-${taskIndex}`}
                                            value={task?.date.split("T")[0]}
                                            onChange={(e) =>
                                              handleActivityChange(
                                                index,
                                                taskIndex,
                                                "date",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-md-3">
                                          <label
                                            htmlFor={`taskType-${index}-${taskIndex}`}
                                          >
                                            Task Type
                                          </label>
                                          <select
                                            className="form-control"
                                            id={`taskType-${index}-${taskIndex}`}
                                            name={`taskType-${index}-${taskIndex}`}
                                            value={task.type}
                                            onChange={(e) =>
                                              handleActivityChange(
                                                index,
                                                taskIndex,
                                                "type",
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="Activity" selected>
                                              Activity
                                            </option>
                                          </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                          <label
                                            htmlFor={`taskName-${index}-${taskIndex}`}
                                          >
                                            Task Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id={`taskName-${index}-${taskIndex}`}
                                            name={`taskName-${index}-${taskIndex}`}
                                            value={task.name}
                                            onChange={(e) =>
                                              handleActivityChange(
                                                index,
                                                taskIndex,
                                                "name",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}

                                <div className="form-group">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleActivityAdd(index)}
                                  >
                                    Add Activity
                                  </button>
                                </div>

                                <div className="form-group">
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                  >
                                    Update Syllabus
                                  </button>
                                </div></>}
                               
                              </div>
                            </div>
                          ))}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChapterExcercise;
