import "./App.css";
import "./assets/plugins/bootstrap/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/plugins.css";
import "./assets/css/icons.css";
import "./assets/switcher/css/switcher.css";
import "./assets/switcher/demo.css";
import "./assets/css/select2.css";

import {  
  useLocation,
  useNavigate,
} from "react-router-dom";


import Header from "./component/Header";
import AppRoutes from "./routes/routes";
import UserContext from "./userContext";

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    localStorage.removeItem("jwt");
    localStorage.removeItem("user");
    document.cookie.split(";").forEach(function (c) {
      var date = new Date();
      date.setTime(date.getTime() - (60 * 60 * 1000))
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + date.toUTCString() + ";path=/");
    });
    navigate("/admin/login");
  };

  const userInfo = localStorage.getItem("user");
  if (userInfo) {
    if (JSON.parse(userInfo).role !== "admin") {
      if (
        [
          "/admin/roles",
          "/admin/branch",
          "/admin/grades",
          "/admin/users",
          "/admin/houses",
        ].includes(location.pathname)
      ) {

        if (location.pathname == "/admin/grades" && JSON.parse(userInfo).role === "financial_head") {

        } else {
          logout();
        }
      }
    }
  } else {
    // navigate("/admin/login");
  }

  return (
    <>
      <div className="page">
        <div className="page-main">
          <UserContext.Provider value={{ logout }}>
            <Header />
            <AppRoutes />
          </UserContext.Provider>
        </div>
      </div>
      <a href="#top" id="back-to-top">
        <i className="fa fa-angle-up" />
      </a>
    </>
  );
}

export default App;