import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import { getToken, getCurrentFullSession } from "../../utility/utilityFunctions";
import Pagination from "react-paginate";
import UserContext from "../../userContext";
import placeholder from "../../assets/images/brand/placeholder.png";

const Students = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [studentsCounts, setStudentsCount] = useState();
  const [userType, setUserType] = useState("all");
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [branch, setBranch] = useState("all");
  const [branches, setBranches] = useState([]);
  const [search, setSearch] = useState("");
  const [section, setSection] = useState("all");
  const [session, setSession] = useState(getCurrentFullSession());
  const [sessions, setSessions] = useState([]);
  const [students, setStudents] = useState([]);
  const [houses, setHouses] = useState([]);
  const [grades, setGrades] = useState([]);
  const [keyword, setKeyword] = useState("");
  // const [userType, setUserType] = useState("all");
  const [selectedGrade, setSelectedGrade] = useState("all");
  const [selectedHouse, setSelectedHouse] = useState("all");
  const [isRte, setIsRte] = useState("all");
  const { logout } = useContext(UserContext);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/students`,
        {
          userType,
          selectedGrade,
          selectedHouse,
          isRte,
          // search: search,
          branch,
          section,
          session,
          // isChecked,
          currentPage, // Add currentPage here
          itemsPerPage,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        const { students, studentsCount, branches, grades, houses, sessions } =
          response.data;
        setStudentsCount(studentsCount);
        setStudents(students);
        setGrades(grades);
        setBranches(branches);
        setHouses(houses);
        setSessions(sessions);
      })
      .catch((err) => {
        logout();
      });
  }, [
    userType,
    selectedGrade,
    selectedHouse,
    isRte,
    // search,
    section,
    session,
    branch,
    //isChecked,
    currentPage,
  ]);
  // After you set your students data in the first useEffect
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const studentsToDisplay = students;
  const handleSearch = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/students`,
        {
          userType,
          selectedGrade,
          selectedHouse,
          isRte,
          search,
          branch,
          section,
          session,
          isChecked,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        const { students, branches, grades, houses, sessions } = response.data;
        setStudents(students);
        setGrades(grades);
        setBranches(branches);
        setHouses(houses);
        setSessions(sessions);
      })
      .catch((err) => {
        logout();
      });
  };

  useEffect(() => {
    // Your existing useEffect code here.
  }, [
    userType,
    selectedGrade,
    selectedHouse,
    isRte,
    search,
    section,
    session,
    branch,
    isChecked,
  ]);

  const unArchiveStudent = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/students/unarchiveStudent`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        window.notif({
          msg: "<b>Woohoo!!</b> Student Archived Successfully",
          type: "success",
        });
        window.location.reload();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fixRTEStudents = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/student/fixRTEStudents`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        window.notif({
          msg: "<b>Woohoo!!</b> Student Fixed Successfully",
          type: "success",
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handelCheckedboxChange = () => {
    setIsChecked(!isChecked);
  };

  let count = 0;

  // Function to toggle the accordion state
  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };
  // console.log("students:", students);

  return (
    <>
      <Sidebar />

      <div className="main-content app-content mt-120">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            {/* Header Area */}
            <div className="col-sm-12 card pb-2 pt-2">
              <div className="col-sm-12">
                <div className="card">
                  <h1 className="page-title p-2">Students List</h1>
                  <div class="alert alert-info" role="alert">
                    If you want to search with student's serial number then
                    select Sr. Number check box and type serial number into
                    search box. In case of search by student name do not select
                    Sr. Number check box.
                  </div>
                </div>
              </div>
              <div className="col-sm-9 mt-2">
                <div className="row">
                  <div className="col-sm-3">
                    <input
                      type="checkbox"
                      id="checkbox"
                      checked={isChecked}
                      onChange={handelCheckedboxChange}
                      style={{ transform: "scale(1.8)" }}
                    />
                    <label for="checkbox">Sr. Number</label>
                  </div>

                  <div className="col-sm-6">
                    <input
                      className="form-control"
                      type="text"
                      name="search"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      placeholder="Enter Serial number or student name"
                    />
                  </div>
                  <div className="col-sm-3">
                    <button onClick={handleSearch} className="btn btn-primary">
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 mt-2 d-flex card pb-2 pt-2">
              {/*  */}

              {/*  */}
              <div className="col-sm-12 d-flex">
                <div className="mx-2 align-middle">
                  {" "}
                  <b>Student Filters</b>
                </div>
                <div className="mx-2">
                  <select
                    className="form-control"
                    name=""
                    value={userType}
                    id="branch"
                    onChange={(e) => {
                      setStudents([]);
                      setUserType(e.target.value);
                    }}
                  >
                    {/* {localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user")).role ===
                      "admin" ? null : (
                      <option value="all">All Students</option>
                    )} */}
                    <option value="all">All Students</option>

                    <option value="enquired">Enquired</option>
                    <option value="paid-enquired">Paid Enquired</option>
                    <option value="registered" selected>
                      Registered
                    </option>
                    <option value="promoted">Promoted</option>
                    <option value="registeredArchive">
                      {" "}
                      Registered Archive
                    </option>
                    <option value="promotedArchive"> Promoted Archive</option>
                    <option value="completed"> Alumni</option>
                  </select>
                </div>
                {localStorage.getItem("user") &&
                JSON.parse(localStorage.getItem("user")).role === "admin" ? (
                  <>
                    <div className="mx-2">
                      <select
                        className="form-control"
                        name=""
                        value={branch}
                        id="branch"
                        onChange={(e) => {
                          setBranch(e.target.value);
                        }}
                      >
                        <option value="all">All Branches</option>
                        {branches.map((branch) => (
                          <option value={branch._id.toString()}>
                            {branch.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="mx-2">
                  <select
                    className="form-control"
                    name=""
                    value={selectedGrade}
                    id="selectedGrade"
                    onChange={(e) => {
                      setStudents([]);
                      setSelectedGrade(e.target.value);
                    }}
                  >
                    <option value={"all"}>All Grades</option>
                    {grades.map((grade) => {
                      return <option value={grade._id}>{grade.name}</option>;
                    })}
                  </select>
                </div>
                <div className="mx-2">
                  <select
                    className="form-control"
                    name=""
                    value={section}
                    onChange={(e) => {
                      setStudents([]);
                      setSection(e.target.value);
                    }}
                  >
                    <option value="all" selected>
                      All Section
                    </option>
                    <option value="A">Section A</option>
                    <option value="B">Section B</option>
                    <option value="C">Section C</option>
                    <option value="D">Section D</option>
                    <option value="E">Section E</option>
                    <option value="F">Section F</option>
                    <option value="G">Section G</option>
                    <option value="H">Section H</option>
                    <option value="I">Section I</option>
                    <option value="J">Section J</option>
                  </select>
                </div>
                <div className="mx-2">
                  <select
                    className="form-control"
                    name=""
                    value={selectedHouse}
                    id="selectedHouse"
                    onChange={(e) => {
                      setStudents([]);
                      setSelectedHouse(e.target.value);
                    }}
                  >
                    <option value={"all"}>All Houses</option>
                    {houses.map((house) => {
                      return <option value={house._id}>{house.name}</option>;
                    })}
                    <option value={"none"}>None Above</option>
                  </select>
                </div>
                <div className="mx-2">
                  <select
                    className="form-control"
                    name=""
                    // value={userType}
                    id="isRteStudent"
                    onChange={(e) => {
                      setStudents([]);
                      setIsRte(e.target.value);
                    }}
                  >
                    <option value="all">RTE/ Non RTE</option>
                    <option value="rte">RTE Students</option>
                    <option value="nrte">Non-RTE Students</option>
                  </select>
                </div>
                <div className="mx-2">
                  <select
                    className="form-control"
                    name=""
                    value={session}
                    onChange={(e) => {
                      setStudents([]);
                      setSession(e.target.value);
                    }}
                  >
                    {sessions.map((session) => {
                      return (
                        <option value={session.session_name}>
                          {session.session_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-12">
                  <div class="alert alert-info" role="alert">
                    <ul>
                      <li>
                        <b>Registered</b> - Students who paid equal or above
                        1500 INR.
                      </li>
                      <li>
                        <b>Promoted</b> -Students those were transferred from
                        the previous session, such as from 2022-23, will be
                        visible in this list or students paid less than 1500
                        INR.
                      </li>
                      <li>
                        <b>Enquired</b> - Student who came first time and did
                        enquiry only.
                      </li>
                      <li>
                        <b>Paid Enquired</b> - Student who came first time and
                        did enquiry and paid 200 or less than 1500 INR.
                      </li>
                      <li>
                        <b>Promoted Archive</b> - This list will only display
                        promoted students who were enrolled in the last session
                        (2022-23) and you promoted student into 23-24 and now
                        intend to leave the school and you issued them TC.
                      </li>
                      <li>
                        <b>Registered Archive</b> - This list will only display
                        registered students who were enrolled in the new session
                        (2023-24) and now intend to leave the school and you
                        issued them TC.
                      </li>
                      <li>
                        <b>Alumni</b> - Students are included in this list when
                        WeCan does not offer the next class and they are
                        subsequently promoted.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body student-list">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>ID</b>
                                </th>
                                <th>
                                  <b>SR. No</b>
                                </th>
                                <th>
                                  <b>Photo</b>
                                </th>
                                <th>
                                  <b>Student</b>
                                </th>
                                <th>
                                  <b>Phone</b>
                                </th>
                                <th>
                                  <div
                                    style={{
                                      whiteSpace: "normal", // or "break-spaces" depending on your requirements
                                      wordWrap: "break-word",
                                      width: "100px",
                                    }}
                                  >
                                    <b>Guardian</b>
                                  </div>
                                </th>
                                {/* <th>
                                  <b>Grade</b>
                                </th> */}
                                <th>
                                  <b>House</b>
                                </th>
                                {/* <th>
                                  <b>Section</b>
                                </th>
                                <th>
                                  <b>Status</b>
                                </th>
                                <th>
                                  <b>Note</b>
                                </th>
                                <th>
                                  <b>Fee</b>
                                </th> */}
                                {/* <th>
                                  <b>RTE student Attendance</b>
                                </th> */}
                                {userType === "studentArchive" ? (
                                  <th>
                                    <b>Action</b>
                                  </th>
                                ) : (
                                  ""
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {studentsToDisplay.map((student, key) => {
                                const serialNumber = startIndex + key;
                                return (
                                  <tr key={student?._id}>
                                    <td>{serialNumber + 1}</td>
                                    <td>{student?.sr_no}</td>
                                    <td>
                                      {student?.user_id?.profile_pic ? (
                                        <>
                                          <img
                                            width={"70px"}
                                            src={
                                              process.env.REACT_APP_BASE_URL +
                                              "/admin/images/" +
                                              student.user_id.profile_pic
                                            }
                                            alt={student?.user_id?.name}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            width={"70px"}
                                            src={placeholder}
                                          />
                                        </>
                                      )}
                                    </td>
                                    <td>
                                      <Link to={`${student.user_id._id}`}>
                                        {student?.user_id?.name} 
                                      </Link>
                                      <strong>
                                        {" "}
                                        - {student?.grade?.name}{" "}
                                        {student?.section
                                          ? ` (${student?.section})`
                                          : ""}
                                      </strong>
                                      <br></br>
                                      {student?.user_id?.status ==
                                        "promotedArchive" ||
                                      student?.user_id?.status ==
                                        "registeredArchive" ? (
                                        ""
                                      ) : (
                                        <>
                                          <span class="badge bg-success badge-sm  me-1 mb-1 mt-1 ml-10 text-white">
                                            <Link
                                              to={`/admin/collect-fee/${student.user_id._id}`}
                                            >
                                              <i
                                                class="icon icon-wallet ml-3"
                                                style={{ color: "white" }}
                                              />{" "}
                                              <span class="text-white">
                                                Collect fee
                                              </span>
                                            </Link>
                                          </span>
                                        </>
                                      )}
                                      <span class="badge bg-warning badge-sm  me-1 mb-1 mt-1 ml-10">
                                        {student?.user_id?.status
                                          ? student.user_id.status
                                              .charAt(0)
                                              .toUpperCase() +
                                            student.user_id.status
                                              .slice(1)
                                              .toLowerCase()
                                          : "N/A"}
                                        -{student?.session}
                                      </span>
                                      <span class="badge bg-info badge-sm  me-1 mb-1 mt-1 ml-10">
                                        {student?.note ? student?.note : "Old"}
                                      </span>
                                      <span class="badge bg-success badge-sm  me-1 mb-1 mt-1 ml-10 text-white">{student?.isRteStudent ? "RTE" : "Non-RTE"}</span>
                                      {student?.user_id?.transfer ? (
                                        <>
                                          <br></br>
                                          <br></br>
                                          <p>Transfered Student</p>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>{student?.user_id?.mobile}<br></br>
                                    <span class="badge bg-success badge-sm  me-1 mb-1 mt-1 ml-10 text-white">
                                            <Link
                                              to={`/admin/updatePassword/${student.user_id._id}`}
                                            >
                                           
                                              <span class="text-white">
                                                Update Password
                                              </span>
                                            </Link>
                                          </span>
                                    </td>
                                    <td>
                                      {" "}
                                      <div
                                        style={{
                                          whiteSpace: "normal", // or "break-spaces" depending on your requirements
                                          wordWrap: "break-word",
                                          width: "100px",
                                        }}
                                      >
                                        {student?.user_id?.father_name}
                                      </div>
                                    </td>
                                    {/* <td>{student?.grade?.name}</td> */}
                                    <td>{student?.house?.name}</td>
                                    {/* <td>{student?.section}</td>
                                    <td>{student?.user_id?.status}</td>

                                    <td>
                                      {student?.note ? student?.note : "Old"}
                                    </td>
                                    <td>
                                      <Link
                                        to={`/admin/collect-fee/${student.user_id._id}`}
                                      >
                                        <i class="icon icon-wallet ml-3" />
                                      </Link>
                                    </td> */}
                                    {/* <td>
                                      {student?.isRteStudent == true ? (
                                        <button
                                          className="btn btn-danger text-light"
                                          onClick={() =>
                                            fixRTEStudents(
                                              student?.user_id?._id
                                            )
                                          }
                                        >
                                          Show Student Attendance
                                        </button>
                                      ) : (
                                        ""
                                      )}
                                    </td> */}
                                    {userType === "studentArchive" ? (
                                      <button
                                        className="btn-danger text-light"
                                        onClick={() =>
                                          unArchiveStudent(
                                            student?.user_id?._id
                                          )
                                        }
                                      >
                                        UnArchive
                                      </button>
                                    ) : (
                                      <></>
                                    )}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Pagination
                    pageCount={Math.ceil(studentsCounts / itemsPerPage)} // Calculate the total number of pages based on the number of students and items per page
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName="pagination"
                    activeClassName="active"
                    initialPage={currentPage}
                  />
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Students;
