import React from "react";
import { Link } from "react-router-dom";
import {
  FaUserTie,
  FaRupeeSign,
  FaAward,
} from "react-icons/fa";

function SidebarForFinancialHead() {
  return (
    <>
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/grades"
        >
          <FaAward className="side-menu__icon" />

          <span className="side-menu__label">Manage Grades</span>
        </Link>
      </li>
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/reports/studentStrength"
        >
          <FaUserTie className="side-menu__icon" />

          <span className="side-menu__label">Student Strength Dashboard</span>
        </Link>
      </li>
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/paid_enquired"
        >
          <FaUserTie className="side-menu__icon" />

          <span className="side-menu__label">Paid Enquired Report</span>
        </Link>
      </li>
      <li className="slide">
        <a
          className="side-menu__item"
          data-bs-toggle="slide"
          href="javascript:void(0)"
        >
          <FaRupeeSign className="side-menu__icon" />
          <span className="side-menu__label">Finance Reports</span>
          <i className="angle fe fe-chevron-right" />
        </a>
        <ul className="slide-menu">
          <li className="panel sidetab-menu">
            <div className="panel-body tabs-menu-body p-0 border-0">
              <div className="tab-content">
                <div className="tab-pane" id="side9" role="tabpanel">
                  <ul className="sidemenu-list">
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/finance_report_one"
                      >
                        <span className="side-menu__label">
                        Students Fee Record
                        </span>
                      </Link>
                    </li>
                    <li className="slide">
                      <Link
                        className="slide-item"
                        to="/admin/finance_report_two"
                      >
                        <span className="side-menu__label">
                           Report 02
                        </span>
                      </Link>
                    </li>
                    <li className="slide">
                      <Link
                        className="slide-item"
                        to="/admin/finance_report_three"
                      >
                        <span className="side-menu__label">
                        Daily Fee Collection Record
                        </span>
                      </Link>
                    </li>

                    
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
   
    </>
  );
}

export default SidebarForFinancialHead;
