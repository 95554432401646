import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getToken,
  timezoneDateToddMMyyyy,
} from "../../utility/utilityFunctions";

const AddInventoryForm = () => {
  const data = {
    action: "",
    quantity: "",
    to_branch: "",
  };

  const [formData, setformData] = useState(data);
  const [screenData, setscreenData] = useState({
    branches: [],
    inventoryDetail: {},
    inventoryItemHistory: [],
  });
  const params = useParams();

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/get_manage_invantory_screen_data`,
        { item_id: params.id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        const { branches, inventoryDetail, inventoryItemHistory } =
          response.data;
        setscreenData({
          ...screenData,
          branches,
          inventoryDetail,
          inventoryItemHistory,
        });
      });
  }, []);

  const updateValue = (e) => {
    const field = e.target.id;
    let value = e.target.value;
    let isUpdatable = true;

    if (isUpdatable) {
      setformData({
        ...formData,
        [field]: value,
      });
    }
  };

  const updateInventory = () => {
    let finalData = {
      ...formData,
      item_id: params.id,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/inventory/update`,
        finalData,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response?.data.success) {
          window.notif({
            msg: "<b>Woohoo!!</b> Inventory Updated Successfully",
            type: "success",
          });

          setTimeout(() => {
            window.location.reload();
          }, 1000);
          // setformData(data);
        } else {
          window.notif({
            msg: response?.data.error,
            type: "error",
            position: "right",
          });
        }
      });
  };

  return (
    <>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-body">
            <form className="form-horizontal">
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Current Quantity</label>
                <div className="col-md-9">
                  {(screenData.inventoryDetail &&
                    screenData.inventoryDetail.currentQty) ||
                    0}
                </div>
              </div>

              <div className=" row mb-4">
                <label className="col-md-3 form-label">Select Action</label>
                <div className="col-md-9">
                  <select
                    className="form-control"
                    value={formData.action}
                    id="action"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  >
                    <option>Select Action</option>
                    <option value="opening_stock">Update Opening Stock</option>
                    <option value="purchase_item">Item Purchase</option>
                    {screenData.inventoryDetail &&
                    screenData.inventoryDetail.currentQty > 0 ? (
                      <option value="dispatch_item">Item Dispatch</option>
                    ) : (
                      <></>
                    )}
                    <option value="update_quantity">Update Quantity</option>
                  </select>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Quantity</label>
                <div className="col-md-9">
                  <input
                    type="number"
                    placeholder="Quantity"
                    className="form-control"
                    min={1}
                    value={formData.quantity}
                    id="quantity"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>

              {formData.action == "dispatch_item" ? (
                <>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">Branches</label>
                    <div className="col-md-9">
                      <select
                        className="form-control"
                        name=""
                        value={formData.to_branch}
                        id="to_branch"
                        onChange={(e) => {
                          updateValue(e);
                        }}
                      >
                        <option value={""}>Select Branch</option>
                        {screenData.branches.map((branch) => {
                          if (branch.name != "Jaipur") {
                            return (
                              <option key={branch._id} value={branch._id}>
                                {branch.name}
                              </option>
                            );
                          }
                        })}
                      </select>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className=" row mb-4">
                <div className="col-md-3">
                  <button
                    type="button"
                    class="btn btn-danger text-light"
                    onClick={() => {
                      updateInventory();
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              {screenData.inventoryItemHistory.length > 0 ? (
                <>
                  <table
                    className="table table-bordered border text-nowrap mb-0"
                    id="basic-edit"
                  >
                    <thead>
                      <tr>
                        <th>
                          <b>Inventory Item</b>
                        </th>
                        <th>
                          <b>Action</b>
                        </th>
                        <th>
                          <b>Qty</b>
                        </th>
                        <th>
                          <b>Dispatched From</b>
                        </th>
                        <th>
                          <b>Dispatched To</b>
                        </th>
                        <th>
                          <b>Date</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {screenData.inventoryItemHistory.map((inventoryItem) => {
                        return (
                          <tr key={inventoryItem.item_id}>
                            <td>{inventoryItem.item_id.name}</td>
                            <td>{inventoryItem.action}</td>
                            <td>{inventoryItem.quantity}</td>
                            <td>
                              {inventoryItem?.dispatched_from
                                ? inventoryItem?.dispatched_from.name
                                : "-"}
                            </td>
                            <td>
                              {inventoryItem?.dispatched_to
                                ? inventoryItem?.dispatched_to.name
                                : "-"}
                            </td>
                            <td>
                              {inventoryItem?.date
                                ? timezoneDateToddMMyyyy(inventoryItem?.date)
                                : "-"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              ) : (
                <>No Inventory History Found</>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddInventoryForm;
