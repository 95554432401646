import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import { getToken } from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";
import Pagination from "react-paginate";

const InactiveInventory = () => {
  const [inventory, setInventories] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [inventoryCounts, setInventoryCount] = useState();
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };
  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/inactiveInventory`,
        {
          search: search,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        const { inventory } = response.data;
        console.log("inventory:", inventory);
        setInventories(inventory);
      });
  }, [search]);
  let count = 1;
  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <GoBackButton />

              <input
                className="p-1 rounded-sm"
                type="text"
                name="search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                placeholder="Search"
              />

              <h1 className="page-title">Inactive Inventories</h1>
            </div>
            <div class="alert alert-info" role="alert">
              If you wish to manage the inactive inventories, please contact the
              administrator to activate them before proceeding with management.
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Sr. No.</b>
                                </th>
                                <th>
                                  <b>Inventory Item</b>
                                </th>
                                <th>
                                  <b>Specifications</b>
                                </th>
                                <th>
                                  <b>Qty</b>
                                </th>
                                {/* <th>
                                  <b>Manage</b>
                                </th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {inventory.map((inventoryItem) => {
                                return (
                                  <tr key={inventoryItem.item_id}>
                                    <td>{count++}</td>

                                    <td>{inventoryItem.name}</td>
                                    <td>{inventoryItem.specifications}</td>
                                    <td>{inventoryItem.qty}</td>
                                    {/* <td>
                                      <Link
                                        type="button"
                                        to={`/admin/manage-inventory/${inventoryItem.item_id}`}
                                        class="btn btn-primary text-light"
                                      >
                                        Manage
                                      </Link>
                                    </td> */}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* <Pagination
                    pageCount={Math.ceil(inventoryCounts / itemsPerPage)} // Calculate the total number of pages based on the number of students and items per page
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName="pagination"
                    activeClassName="active"
                    initialPage={currentPage}
                  /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InactiveInventory;
