const ButtonComponent = ({ onSubmit = {},customClass, text, loading = false, disabled }) => {
    return (
        <button type="submit" className={customClass ? customClass: "btn btn-primary"} disabled={disabled}>
            {!loading ? text : <>
                <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                />
                {text}
            </>
            }
        </button>
    )
}
export default ButtonComponent