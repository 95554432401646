import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import GoBackButton from "../../component/GoBackButton";

const House = () => {

  const [houses, setHouses] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/houses`)
      .then((response) => {
        console.log("response", response);
        const {houses} = response.data;
        setHouses(houses);
      });
  }, []);

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
            <GoBackButton />
              <h1 className="page-title">Houses</h1>
              <Link
                type="button"
                to="/admin/house/new"
                class="btn btn-danger text-light"
              >
                <i class="fe fe-user-plus"></i>&nbsp;Add House
              </Link>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                {/* <th><b>Id</b></th> */}
                                <th><b>House</b></th>
                              </tr>
                            </thead>
                            <tbody>
                              {houses.map((house) => {
                                return <tr key={house._id}>
                                {/* <td>{house._id}</td> */}
                                <td>{house.name}</td>
                              </tr>;
                              })}                              
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default House;
