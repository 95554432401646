import axios from "axios";
import React, { useEffect, useState } from "react";
import { getToken } from "../../utility/utilityFunctions";

const UpdateGeneralSettings = () => {
  const data = {
    teacher_arriving_time: "",
    teacher_return_time: "",
    half_day_time: "",
  };

  const [formData, setformData] = useState(data);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/getGeneralSettings`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { generalSettings } = response.data;
        setformData(generalSettings);
      });
  }, []);

  const updateValue = (e) => {
    const field = e.target.id;
    const value = e.target.value;
    setformData({
      ...formData,
      [field]: value,
    });
  };

  const updateGeneralSettings = () => {
    console.log(formData);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/updateGeneralSettings`, formData, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        console.log(response);
        if (response?.data.success) {
          window.notif({
            msg: "<b>Woohoo!!</b> Settings Successfully",
            type: "success",
          });
        } else {
          window.notif({
            msg: response?.data.error,
            type: "error",
            position: "center",
          });
        }
      });
  };

  return (
    <>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Fill following information</h4>
          </div>
          <div className="card-body">
            <form className="form-horizontal">
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Teacher Arriving Time</label>
                <div className="col-md-9">
                  <input
                    type="time"
                    className="form-control"
                    placeholder="Teacher Arriving Time"
                    value={formData?.teacher_arriving_time}
                    id="teacher_arriving_time"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>

              <div className=" row mb-4">
                <label className="col-md-3 form-label">Teacher Return Time</label>
                <div className="col-md-9">
                  <input
                    type="time"
                    className="form-control"
                    placeholder="Teacher Return Time"
                    value={formData?.teacher_return_time}
                    id="teacher_return_time"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>

              <div className=" row mb-4">
                <label className="col-md-3 form-label">Half Day Time</label>
                <div className="col-md-9">
                  <input
                    type="time"
                    className="form-control"
                    placeholder="Half Day Time"
                    value={formData?.half_day_time}
                    id="half_day_time"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>

              <div className=" row mb-4">
                <label className="col-md-3 form-label">First Half Start Time</label>
                <div className="col-md-9">
                  <input
                    type="time"
                    className="form-control"
                    placeholder="First Half Start Time"
                    value={formData?.first_half_start_time}
                    id="first_half_start_time"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>

              <div className=" row mb-4">
                <label className="col-md-3 form-label">First Half End Time</label>
                <div className="col-md-9">
                  <input
                    type="time"
                    className="form-control"
                    placeholder="First Half End Time"
                    value={formData?.first_half_end_time}
                    id="first_half_end_time"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>

              <div className=" row mb-4">
                <label className="col-md-3 form-label">Second Half Start Time</label>
                <div className="col-md-9">
                  <input
                    type="time"
                    className="form-control"
                    placeholder="Second Half Start Time"
                    value={formData?.second_half_start_time}
                    id="second_half_start_time"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>

              <div className=" row mb-4">
                <label className="col-md-3 form-label">Second Half End Time</label>
                <div className="col-md-9">
                  <input
                    type="time"
                    className="form-control"
                    placeholder="Second Half End Time"
                    value={formData?.second_half_end_time}
                    id="second_half_end_time"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>


              <div className=" row mb-4">
                <div className="col-md-3">
                  <button
                    type="button"
                    class="btn btn-danger text-light"
                    onClick={() => {
                      updateGeneralSettings();
                    }}
                  >
                    &nbsp;Update Settings
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateGeneralSettings;
