import axios from "axios";
import React, { useEffect, useState } from "react";
import { getToken } from "../../utility/utilityFunctions";

const AddInventoryItemForm = () => {
  const data = {
    name: "",
    category: "",
    specifications: ""
  };

  const [formData, setformData] = useState(data);
  const [inventoryCategories, setInventoryCategories] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/addInventoryItemScreenData`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        console.log("response", response);
        const { inventoryCategories } = response.data;
        setInventoryCategories(inventoryCategories);
      });
  }, []);

  const updateValue = (e) => {
    const field = e.target.id;
    const value = e.target.value;
    setformData({
      ...formData,
      [field]: value,
    });
  };

  const createInventoryItem = () => {
    console.log(formData);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/inventoryItem/new`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response?.data.success) {
          window.notif({
            msg: "<b>Woohoo!!</b> InventoryItem Created Successfully",
            type: "success",
          });

          setformData(data);
        } else {
          window.notif({
            msg: response?.data.error,
            type: "error",
            position: "center",
          });
        }
      });
  };

  return (
    <>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Fill following information</h4>
          </div>
          <div className="card-body">
            <form className="form-horizontal">
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Name</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    value={formData.name}
                    id="name"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Category</label>
                <div className="col-md-9">
                  <select
                    className="form-control"
                    value={formData.category}
                    id="category"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  >
                    <option>Select Category</option>
                    {inventoryCategories.map(inventoryCategory => <option value={inventoryCategory.slug}>{inventoryCategory.name}</option>)}
                  </select>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Specifications</label>
                <div className="col-md-9">
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Specifications"
                    value={formData.specifications}
                    id="specifications"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <div className="col-md-3">
                  <button
                    type="button"
                    class="btn btn-danger text-light"
                    onClick={() => {
                      createInventoryItem();
                    }}
                  >
                    <i class="fe fe-user-plus"></i>&nbsp;Add Inventory Item
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddInventoryItemForm;
