import axios from "axios";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar";
import { getToken } from "../../utility/utilityFunctions";
import { Link } from "react-router-dom";

function TeacherArchiveList() {
  const { id } = useParams();
  const [isRecordSaved, setIsRecordSaved] = useState(false);
  const [teachers, setTeacher] = useState([]);
  let count = 1;

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/admin/teacher/teacher_archive`;
    axios
      .post(
        apiUrl,
        {},
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        const { archiveTeacher } = response.data;
        setTeacher(archiveTeacher);
      });
  }, [isRecordSaved]);


  const moveToTeachersList = (teacher_id) => {
    if (window.confirm("You really want to unarchive this teacher ?")) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/admin/teacher/moveToTeachersList`,
          {
            id: teacher_id,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          if (response?.data.success) {
            window.notif({
              msg: "<b>Woohoo!!</b> Teacher Unarchived Successfully",
              type: "success",
            });
            
            setIsRecordSaved(!isRecordSaved);
          } else {
            window.notif({
              msg: response?.data.error,
              type: "error",
              position: "center",
            });
          }
        });
    }
  };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div
              style={{
                backgroundColor: "white",
                padding: "25px",
                marginBottom: "10px",
              }}
            >
              <div className="">
                <h1
                  className="page-title"
                  style={{ marginLeft: "12px", marginBottom: "15px" }}
                >
                  Archive Teachers List
                </h1>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="loader" style={{ display: "none" }}>
                <h2>Hold On !!</h2>
                <h3>We are generating report....</h3>
                <div className="spinner-border text-danger" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Sr. No</b>
                                </th>
                                <th>
                                  <b>Teacher</b>
                                </th>

                                <th>
                                  <b>Mobile</b>
                                </th>
                                {localStorage.getItem("user") &&
                                JSON.parse(localStorage.getItem("user"))
                                  .role === "admin" ? (
                                  <th>
                                    <b>Branch</b>
                                  </th>
                                ) : (
                                  ""
                                )}
                                <td>
                                  <b>Action</b>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {teachers.map((teacher) => {
                                return (
                                  <React.Fragment key={teacher._id}>
                                    {/* First <tr> for basic teacher information */}

                                    <tr>
                                      <td>{count++}</td>

                                      <td>{teacher?.name}</td>

                                      <td>{teacher?.mobile}</td>
                                      {localStorage.getItem("user") &&
                                      JSON.parse(localStorage.getItem("user"))
                                        .role === "admin" ? (
                                        <td>{teacher?.branch[0]?.name}</td>
                                      ) : (
                                        ""
                                      )}

                                      <td>
                                      <button
                                        className="btn ripple btn-success"
                                        type="button"
                                        onClick={() => {
                                          moveToTeachersList(teacher._id);
                                        }}
                                      >
                                        Unarchive
                                      </button>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TeacherArchiveList;
