import axios from "axios";
import React, { useEffect, useState } from "react";
import { getToken } from "../../utility/utilityFunctions";

var count = 0;
const UpdateCircular = () => {
  const data = {
    title: "",
    message: "",
    file: "",
  };
  
  const [formData, setformData] = useState(data);
  const [circulars, setCirculars] = useState([]);
  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_BASE_URL}/admin/circulars`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      const { circulars } = response.data;
      console.log("circulars", circulars);
      setCirculars(circulars);
    });
  }, []);

  const updateValue = (e) => {
    const field = e.target.id;
    const {value, files} = e.target;
    setformData({
      ...formData,
      [field]: files ? files[0] : value,
    });
  };

  const sendCircular = () => {
    let mandatoryFieldsFilled = ["title"];
    let fieldsNotFilled = {};
    mandatoryFieldsFilled.forEach((field) => {
      if (
        !formData[field] ||
        formData[field] == "" ||
        formData[field] == null ||
        formData[field] == undefined
      ) {
        fieldsNotFilled[field] = `${field} is mandatory`;
      }
    });

    if (Object.keys(fieldsNotFilled).length > 0) {
      Object.keys(fieldsNotFilled).forEach((field) => {
        window.notif({
          msg: fieldsNotFilled[field],
          type: "error",
        });
      });
    } else {
      document.getElementById("submitBtnId").disabled = true;
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/admin/circular/new`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then((response) => {
          document.getElementById("submitBtnId").disabled = false;
          if (response?.data.success) {
            window.notif({
              msg: "<b>Woohoo!!</b> Student Record Created Successfully",
              type: "success",
            });
            setformData(data);            
            const records = response.data.circulars;
            setCirculars(records);
          } else {
            window.notif({
              msg: response?.data.error,
              type: "error",
              position: "center",
            });
          }
          document.getElementById("submitBtnId").disabled = false;
        })
        .catch(() => {
          document.getElementById("submitBtnId").disabled = false;
        });
    }
  };

  return (
    <>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Fill following information</h4>
          </div>
          <div className="card-body">
            <form className="form-horizontal">
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Title</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Title"
                    value={formData?.title}
                    id="title"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>

              <div className=" row mb-4">
                <label className="col-md-3 form-label">Message</label>
                <div className="col-md-9">
                  <textarea
                    className="form-control"
                    placeholder="Message"
                    value={formData?.message}
                    id="message"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>

              <div className=" row mb-4">
                <label className="col-md-3 form-label">Document</label>
                <div className="col-md-9">
                  <input
                    type="file"
                    className="form-control"
                    id="file"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>

              <div className="col-md-2">
                <button
                  type="button"
                  className="btn btn-danger text-light"
                  id="submitBtnId"
                  onClick={() => {
                    sendCircular();
                  }}
                >
                  <i className="fe fe-user-plus"></i>&nbsp;Send Circular
                </button>
              </div>
            </form>
          </div>
        </div>



        <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>S.no</b>
                                </th>
                                <th>
                                  <b>Title</b>
                                </th>
                                <th>
                                  <b>Message</b>
                                </th>
                                <th>
                                  <b>Send By</b>
                                </th>
                                <th>
                                  <b>Date</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {circulars.map((circular) => {
                                return (
                                  <tr key={circulars._id}>
                                    <td>{count++}</td>
                                    <td>{circular.title}</td>
                                    <td>{circular.message}</td>
                                    <td>{circular.sendBy}</td>
                                    <td>{circular.createdAt}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>




      </div>
    </>
  );
};

export default UpdateCircular;
