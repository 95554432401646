import React from "react";
import { Link } from "react-router-dom";

import {
//   FaHouseUser,
//   FaUserTie,
  FaUsers,
  FaHeadphonesAlt,
//   FaBookOpen,
//   FaRupeeSign,
//   FaRegClock,
//   FaHandHoldingUsd,
//   FaRegClipboard,
//   FaUserSlash,
//   FaUserEdit,
//   FaRegEdit,
//   FaWhmcs,
//   FaRavelry,
//   FaArchway,
//   FaAward,
//   FaUserFriends,
//   FaUniversity,
//   FaGifts,
//   FaSearchengin,
//   FaRegListAlt,
//   FaRegNewspaper,
//   FaSlack,
//   FaAddressBook,
//   FaBroom,
//   FaChartBar,
} from "react-icons/fa";

function SidebarForCoordinator() {
  return (
    <>
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/students"
        >
          <FaUsers className="side-menu__icon" />

          <span className="side-menu__label">Students</span>
        </Link>
      </li>
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/students-follow-up"
        >
          <FaHeadphonesAlt className="side-menu__icon" />

          <span className="side-menu__label">Students Follow-Up</span>
        </Link>
      </li>
      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/settings"
        >
          <FaWhmcs className="side-menu__icon " />
          <span className="side-menu__label">Settings</span>
        </Link>
      </li> */}
    </>
  );
}

export default SidebarForCoordinator;
