import axios from "axios";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar";
import { getToken } from "../../utility/utilityFunctions";
import { Link } from "react-router-dom";

function NegativeFeeRecord() {
  const { id } = useParams();
  const [studnets, setStudnets] = useState([]);
  let count = 1;

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/admin/getNegativefeeAmountStudents`;
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { finalData } = response.data;
        setStudnets(finalData);
        console.log("finalData:", finalData);
      });
  }, []);

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div
              style={{
                backgroundColor: "white",
                padding: "25px",
                marginBottom: "10px",
              }}
            >
              <div className="">
                <h1
                  className="page-title"
                  style={{ marginLeft: "12px", marginBottom: "15px" }}
                >
                 Negative Fee Students List
                </h1>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="loader" style={{ display: "none" }}>
                <h2>Hold On !!</h2>
                <h3>We are generating report....</h3>
                <div className="spinner-border text-danger" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Sr. No</b>
                                </th>
                                <th>
                                  <b>Student</b>
                                </th>
                                <th>
                                  <b>Mobile</b>
                                </th>

                                <th>
                                  <b>Father</b>
                                </th>
                                <th>
                                  <b>Branch</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {studnets.map((student) => {
                                return (
                                  <React.Fragment key={student._id}>
                                    {/* First <tr> for basic teacher information */}

                                    <tr>
                                      <td>{count++}</td>

                                      <td>
                                        <Link
                                          to={`/admin/collect-fee/${student.student._id}`}
                                        >
                                          {student?.student.name}
                                        </Link>
                                      </td>

                                      <td>{student?.student?.mobile}</td>

                                      <td>{student?.student?.father_name}</td>
                                      <td>{student?.student?.branch[0].name}</td>

                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default NegativeFeeRecord;
