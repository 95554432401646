import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { getToken } from "../../utility/utilityFunctions";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import FormReciept from "../reciepts/FormReciept";
import AdmissionForm from "../admissionForm/AdmissionForm";
import ButtonComponent from "../../component/ButtonComponent";

const AddStudentForm = () => {
  // const [admission_feeForMsg,setAdmission_feeForMsg] = useState(0)
  const [showLoader, setShowLoader] = useState(false);
  // Define a state variable to store the API response
  const [recordExists, setRecordExists] = useState([]);
  const [nameExists, setNameExists] = useState([]);
  const [srExists, setSrExists] = useState([]);

  const [tutionfee, setTutionfee] = useState();
  const [admissionfee, setAdmissionfee] = useState();
  // const [session, setSession] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [selectedSession, setSelectedSession] = useState("");

  const validationSchema = yup.object({
    mobile: yup
      .string()
      .required("Please enter an phone number.")
      .max(10, "Maximum allowed character is 10")
      .matches(/^[0-9]\d{9}$/, "Please enter valid phone number."),
    dob: yup.string().required("Please enter your DOB."),
    session: yup.string().required("Please select session."),

    gender: yup.string().required("Please choose gender."),
    // payment_method: yup
    //   .string()
    //   .when(["form_fee_check"], {
    //     is: (formFeeCheck) =>
    //       formFeeCheck === true ,
    //     then: yup
    //       .string()
    //       .required("Payment method is required when not an RTE student"),
    //   }),
    // payment_method: yup.string().when("form_fee_check", {
    //   is: true, // Apply the validation when form_fee_check is true
    //   then: yup.string().required("Payment method is required when not an RTE student"),
    // }),
    grade: yup.string().required("Please choose correct grade."),
    address: yup.string().required("Please enter proper address."),
    father_name: yup.string().required("Please enter  father/guardian name."),
    father_email: yup.string().email("Please enter a valid email."),
    mother_email: yup.string().email("Please enter a valid email."),
    tution_fee_concession: yup.string().required("please enter Zero atleast"),
    admission_fee_concession: yup
      .string()
      .required("please enter Zero atleast"),
    transaction_id: yup.string().when(["payment_method"], {
      is: (paymentMethod) => ["online", "cheque"].includes(paymentMethod),
      then: () => yup.string().required("Transaction ID is required"),
    }),
  });
  const {
    register,
    setError,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      tution_fee_concession: 0,
      admission_fee_concession: 0,
    },
  });

  const form_fee_check = watch("form_fee");
  const studentFormData = watch();
  const payment_method_check = watch("payment_method");
  const isRteStudent_check = watch("isRteStudent");
  const profile_pic_checked = watch("profile_pic");
  // console.log("isRteStudent_check:", isRteStudent_check);
  // console.log("form_fee_check:", form_fee_check);
  const [profilePicInAdmissionForm, setProfilePicInAdmissionForm] = useState(
    "studentFormData:",
    studentFormData
  );
  const [screenData, setScreenData] = useState({
    grades: [],
    sessions: [],
  });
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/addUserData?session=${selectedSession}`, 
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { grades, sessions } = response.data;
        setScreenData({
          ...screenData,
          grades,
          sessions,
        });
      });
  }, [selectedSession]);
  useEffect(() => {
    if (studentFormData?.profile_pic?.[0]) {
      previewImage(studentFormData?.profile_pic?.[0]);
    }
  }, [profile_pic_checked]);

  function previewImage(event) {
    const file = event;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (event) {
      // const imgElement = document.getElementById("preview");
      // imgElement.src = event.target.result;
      setProfilePicInAdmissionForm(event.target.result);
    };
  }

  const downloadPDF = (filename) => {
    const input = document.getElementById("admission_form");
    html2canvas(input, { scale: 0.83 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save(`${filename}.pdf`);
    });
  };

  const downloadForm = () => {
    const input = document.getElementById("admission_form_for_user");
    html2canvas(input, { scale: 0.83 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);
      // pdf.save("studentform.pdf");
      pdf.save(`${studentFormData.name}_form_reciept.pdf`);
    });
  };

  console.log("errors", errors);

  const createStudent = (data) => {
    console.log("create student :", data);

    let concession = false;

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/student/checkconcession`,
        {
          grade: data.grade,
          admission_fee_concession: data.admission_fee_concession,
          tution_fee_concession: data.tution_fee_concession,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        const { tution_fee } = response.data;
        const { admission_fee } = response.data;
        if (response.data.success) {
          concession = true;

          // Proceed with the second API call here
          if (concession) {
            // console.log("enter in if condition to create student");
            data.role = "student";
            data.profile_pic = data.profile_pic[0];
            if (
              data.form_fee === true &&
              data.payment_method == "" &&
              !data.isRteStudent
            ) {
              setError("payment_method", {
                type: "custom",
                message: "Payment method is required when form fee is checked.",
              });
              const paymentMethodField =
                document.getElementById("payment_method");
              paymentMethodField?.focus();
              return;
            }
            setShowLoader(true);

            axios
              .post(
                `${process.env.REACT_APP_BASE_URL}/admin/student/new`,
                data,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${getToken()}`,
                  },
                }
              )
              .then((response) => {
                setShowLoader(false);

                if (response?.data.success) {
                  window.notif({
                    msg: "<b>Woohoo!!</b> Student Record Created Successfully",
                    type: "success",
                  });
                  reset();
                  if (studentFormData.form_fee) {
                    const reciptNumber = response.data?.reciptNumberObj?.name;
                    if (reciptNumber) {
                      document.getElementById("recieptNo1").innerText =
                        reciptNumber;
                      document.getElementById("recieptNo2").innerText =
                        reciptNumber;
                      downloadPDF(response.data?.reciptNumberObj?.slug);
                    }
                  }
                } else {
                  window.notif({
                    msg: response?.data.error,
                    type: "error",
                    position: "center",
                  });
                }
              });
          }
        } else {
          alert(
            `Your Concession amount is greater of real admission fee (${admission_fee}) and tution fee (${tution_fee})`
          );
        }
      });
  };

  // Function to make the API request
  const checkRecordExistsWithName = async (userName) => {
    try {
      // console.log("userName:", userName);
      // Make an API request to your server using Axios
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/getSameNameStudent?name=${userName}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      // Extract the data from the Axios response
      const { studentSameName } = response.data;
      // Update the state variable based on the API response
      setNameExists(studentSameName);
    } catch (error) {
      // Handle errors
      console.error("An error occurred:", error);
    }
  };

  // Function to make the API request
  const checkRecordExistsWishMobileNumber = async (mobileNumber) => {
    try {
      // Make an API request to your server using Axios
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/getSameNumberStudent?mobile=${mobileNumber}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      // Extract the data from the Axios response
      const { studentSameMobileNumber } = response.data;
      // Update the state variable based on the API response
      setRecordExists(studentSameMobileNumber);
    } catch (error) {
      // Handle errors
      console.error("An error occurred:", error);
    }
  };
  // Function to make the API request
  const checkRecordExistsWishSrNumber = async (SrNumber) => {
    try {
      // Make an API request to your server using Axios
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/getSameSrNumberStudent?SrNumber=${SrNumber}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      // Extract the data from the Axios response
      const { studentSameSrNumber } = response.data;
      // Update the state variable based on the API response
      setSrExists(studentSameSrNumber);
    } catch (error) {
      // Handle errors
      console.error("An error occurred:", error);
    }
  };
  return (
    <>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <h4 className="card-title">Fill following information</h4>
          </div>
          <div className="card-body">
            <form
              className="form-horizontal "
              onSubmit={handleSubmit(createStudent)}
            >
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Student Name<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    placeholder="Enter student name"
                    {...register("name")}
                    onChange={(e) => {
                      // Call the function to check if the record exists
                      checkRecordExistsWithName(e.target.value);
                    }}
                  />
                  {/* Conditional rendering for displaying recordExists */}
                  {nameExists.length > 0 && (
                    <div>
                      {/* Conditional rendering for displaying recordExists */}
                      {nameExists.length > 0 && (
                        <div>
                          <h2>Users present with this Name</h2>{" "}
                          {/* Heading added when condition is true */}
                          {nameExists.map((record, index) => (
                            <div
                              key={index}
                              style={{
                                border:
                                  "1px solid #ccc" /* Add a border to each record container */,
                                padding:
                                  "10px" /* Add some padding for spacing */,
                                marginBottom:
                                  "10px" /* Add margin between records */,
                              }}
                            >
                              <p>Name: {record?.name}</p>
                              <p>Mobile: {record?.mobile}</p>
                              <p>Role: {record?.role}</p>
                              <p>Status: {record?.status}</p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="example-session"
                  className="col-md-3 form-label"
                >
                  Study Session<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <select
                    className="form-control"
                    id="session" 
                    {...register("session")}
                    onChange={(e) => {
                      setSelectedSession(e.target.value);
                    }}
                  >
                    <option value={""}>Select Session</option>
                    {screenData.sessions.map((session) => (
                      <option value={session.session_name}>
                        {session.session_name}
                      </option>
                    ))}
                  </select>
                  <p>Select session in which student will study.</p>
                  <div className="form-error-div">
                    {errors.session?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.session?.message}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-md-3 form-label" htmlFor="example-email">
                  Email
                </label>
                <div className="col-md-9">
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    placeholder="Email"
                    {...register("email")}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Mobile<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    className="form-control"
                    type="tel"
                    id="mobile"
                    autoComplete="off"
                    placeholder="Mobile"
                    maxLength={10}
                    {...register("mobile")}
                    onChange={(e) => {
                      setValue("password", e.target.value);
                      // Call the function to check if the record exists
                      checkRecordExistsWishMobileNumber(e.target.value);
                    }}
                  />
                  <div className="form-error-div">
                    {errors.mobile?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.mobile?.message}
                  </div>
                  {/* Conditional rendering for displaying recordExists */}
                  {recordExists.length > 0 && (
                    <div>
                      {/* Conditional rendering for displaying recordExists */}
                      {recordExists.length > 0 && (
                        <div>
                          <h2>Users present with this mobile number</h2>{" "}
                          {/* Heading added when condition is true */}
                          {recordExists.map((record, index) => (
                            <div
                              key={index}
                              style={{
                                border:
                                  "1px solid #ccc" /* Add a border to each record container */,
                                padding:
                                  "10px" /* Add some padding for spacing */,
                                marginBottom:
                                  "10px" /* Add margin between records */,
                              }}
                            >
                              <p>Name: {record?.name}</p>
                              <p>Mobile: {record?.mobile}</p>
                              <p>Role: {record?.role}</p>
                              <p>Status: {record?.status}</p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Password</label>
                <div className="col-md-9">
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    placeholder="Passowrd"
                    {...register("password")}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-md-3 form-label">
                  DOB<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="date"
                    id="dob"
                    className="form-control"
                    placeholder="Date Of Birth"
                    {...register("dob")}
                    max={new Date().toISOString().split("T")[0]} // Set the max attribute to the current date
                  />
                  <div className="form-error-div">
                    {errors.dob?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.dob?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Gender<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <select
                    className="form-control"
                    id="gender"
                    {...register("gender")}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                  <div className="form-error-div">
                    {errors.gender?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.gender?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Grade<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <select
                    className="form-control"
                    id="grade"
                    {...register("grade")}
                  >
                    <option value={""}>Select Grade</option>

                    {screenData.grades.map((grade) => (
                      <option key={grade._id} value={grade._id}>
                        {grade.name} ({grade.branch.name})
                      </option>
                    ))}
                  </select>
                  <p>
                    If grade is not visible into this form then contact your
                    admin to create class for your center.
                  </p>
                  <div className="form-error-div">
                    {errors.grade?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.grade?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Father/Guardian Name<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    id="father_name"
                    placeholder="Father/Guardian Name"
                    {...register("father_name")}
                  />
                  <div className="form-error-div">
                    {errors.father_name?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.father_name?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Address<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <textarea
                    id="address"
                    className="form-control"
                    {...register("address")}
                  ></textarea>
                  <div className="form-error-div">
                    {errors.address?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.address?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Profile Picture</label>
                <div className="col-md-9">
                  <input
                    id="profile_pic"
                    className="form-control"
                    type="file"
                    {...register("profile_pic")}
                  />
                </div>
              </div>

              <div className=" row mb-4 d-flex align-items-center">
                <label className="col-md-3 form-label">Fill more options</label>
                <div class="material-switch col-md-9">
                  <input
                    id="form_fee"
                    type="checkbox"
                    // defaultValue="false"
                    {...register("form_fee")}
                  />
                  <label for="form_fee" class="label-success"></label>
                  <p>
                    Switch toggle to fill more infromation, If you will not fill
                    further inforamtion then this student will go into
                    "Enquired" status on click of Add Student button.
                  </p>
                </div>
              </div>
              {form_fee_check ? (
                <>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">SR No</label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="SR No"
                        id="sr_no"
                        {...register("sr_no")}
                        onChange={(e) => {
                          // Call the function to check if the record exists
                          checkRecordExistsWishSrNumber(e.target.value);
                        }}
                      />
                      {/* Conditional rendering for displaying recordExists */}
                      {srExists.length > 0 && (
                        <div>
                          {/* Conditional rendering for displaying recordExists */}
                          {srExists.length > 0 && (
                            <div>
                              <h2>Users present with this Sr. number</h2>{" "}
                              {/* Heading added when condition is true */}
                              {srExists.map((record, index) => (
                                <div
                                  key={index}
                                  style={{
                                    border:
                                      "1px solid #ccc" /* Add a border to each record container */,
                                    padding:
                                      "10px" /* Add some padding for spacing */,
                                    marginBottom:
                                      "10px" /* Add margin between records */,
                                  }}
                                >
                                  <p>Name: {record?.user_id?.name}</p>
                                  <p>Mobile: {record?.user_id?.mobile}</p>
                                  <p>Sr.number: {record?.sr_no}</p>
                                  <p>Status: {record?.user_id?.status}</p>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">
                      Father/Guardian Email
                    </label>
                    <div className="col-md-9">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Father/Guardian Email"
                        id="father_email"
                        {...register("father_email")}
                      />
                      <div className="form-error-div">
                        {errors.father_email?.message && (
                          <span>
                            <i className="bi bi-exclamation-circle-fill me-1"></i>
                          </span>
                        )}
                        {errors.father_email?.message}
                      </div>
                    </div>
                  </div>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">Mother's Name</label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Mother's Name"
                        id="mother_name"
                        {...register("mother_name")}
                      />
                    </div>
                  </div>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">
                      Mother's Email
                    </label>
                    <div className="col-md-9">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Mother's Email"
                        id="mother_email"
                        {...register("mother_email")}
                      />
                      <div className="form-error-div">
                        {errors.mother_email?.message && (
                          <span>
                            <i className="bi bi-exclamation-circle-fill me-1"></i>
                          </span>
                        )}
                        {errors.mother_email?.message}
                      </div>
                    </div>
                  </div>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">Mother Mobile</label>
                    <div className="col-md-9">
                      <input
                        className="form-control"
                        type="tel"
                        placeholder="Mother Mobile"
                        id="mother_mobile"
                        {...register("mother_mobile")}
                      />
                      <div className="form-error-div">
                        {errors.mother_mobile?.message && (
                          <span>
                            <i className="bi bi-exclamation-circle-fill me-1"></i>
                          </span>
                        )}
                        {errors.mother_mobile?.message}
                      </div>
                    </div>
                  </div>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">Blood Group</label>
                    <div className="col-md-9">
                      <select
                        className="form-control"
                        id="blood_group"
                        {...register("blood_group")}
                      >
                        <option value="">Select Blood Group</option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                      </select>
                    </div>
                  </div>
                  <div className=" row mb-4 d-flex align-items-center">
                    <label className="col-md-3 form-label">
                      Is RTE Student
                    </label>
                    <div class="material-switch col-md-9">
                      <input
                        id="isRteStudent"
                        type="checkbox"
                        // checked={formData.form_fee}
                        {...register("isRteStudent")}
                      />

                      <label for="isRteStudent" class="label-success"></label>
                    </div>
                    <div className="alert alert-info" role="alert">
                      <span className="text-danger">*</span>After creating the
                      student, please be aware that you cannot change the RTE or
                      Non-RTE selection, so make your choice carefully.
                    </div>
                  </div>
                  {isRteStudent_check ? (
                    <></>
                  ) : (
                    <>
                      <div className=" row mb-4">
                        <label className="col-md-3 form-label">
                          Form Fee Collect Mode
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <select
                            className="form-control"
                            id="payment_method"
                            {...register("payment_method")}
                            // onChange={(e) => {
                            //   setPaymentMode(e.target.value);
                            // }}
                          >
                            <option value="">Select Payment Mode</option>
                            <option value="cash">Cash</option>
                            <option value="online">Online</option>
                            <option value="cheque">Cheque</option>
                          </select>
                          <div className="form-error-div">
                            {errors.payment_method?.message && (
                              <span>
                                <i className="bi bi-exclamation-circle-fill me-1"></i>
                              </span>
                            )}
                            {errors.payment_method?.message}
                          </div>
                          <div className="mt-2">
                            {payment_method_check === "online" ||
                            payment_method_check === "cheque" ? (
                              <>
                                <input
                                  type="text"
                                  className="form-control"
                                  // onChange={(e) => {
                                  //   setTransactionId(e.target.value);
                                  // }}
                                  {...register("transaction_id")}
                                  placeholder="Enter Transaction ID"
                                />
                                <div className="form-error-div">
                                  {errors.transaction_id?.message && (
                                    <span>
                                      <i className="bi bi-exclamation-circle-fill me-1"></i>
                                    </span>
                                  )}
                                  {errors.transaction_id?.message}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">
                      Tution Fee Concession
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9 d-flex">
                      <input
                        type="number"
                        className="form-control"
                        disabled={isRteStudent_check ? true : false}
                        id="tution_fee_concession"
                        {...register("tution_fee_concession")}
                      />
                      <div className="form-error-div">
                        {errors.tution_fee_concession?.message && (
                          <span>
                            <i className="bi bi-exclamation-circle-fill me-1"></i>
                          </span>
                        )}
                        {errors.tution_fee_concession?.message}
                      </div>
                    </div>
                  </div>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">
                      Admission Fee Concession
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9 d-flex">
                      <input
                        type="number"
                        className="form-control"
                        min="0"
                        disabled={isRteStudent_check ? true : false}
                        id="admission_fee_concession"
                        {...register("admission_fee_concession")}
                      />

                      <div className="form-error-div">
                        {errors.admission_fee_concession?.message && (
                          <span>
                            <i className="bi bi-exclamation-circle-fill me-1"></i>
                          </span>
                        )}
                        {errors.admission_fee_concession?.message}
                      </div>
                    </div>
                  </div>
                  <div className=" row mb-4 d-flex align-items-center">
                    <label className="col-md-3 form-label">Is Boarding</label>
                    <div class="material-switch col-md-9">
                      <input
                        id="boarding"
                        type="checkbox"
                        // checked={formData.form_fee}
                        {...register("boarding")}
                      />
                      <label for="boarding" class="label-success"></label>
                    </div>
                  </div>

                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">Nationality</label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nationality"
                        id="nationality"
                        {...register("nationality")}
                      />
                    </div>
                  </div>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">Proposed DOE</label>
                    <div className="col-md-9">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Proposed DOE"
                        id="proposed_doe"
                        {...register("proposed_doe")}
                      />
                    </div>
                  </div>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">
                      Age At Proposed DOE
                    </label>
                    <div className="col-md-9">
                      <input
                        className="form-control"
                        type="number"
                        placeholder="Age At Proposed DOE"
                        id="age_at_proposed_doe"
                        {...register("age_at_proposed_doe")}
                      />
                    </div>
                  </div>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">Home Tel</label>
                    <div className="col-md-9">
                      <input
                        className="form-control"
                        type="number"
                        placeholder="Home Tel"
                        id="home_tel"
                        {...register("home_tel")}
                      />
                    </div>
                  </div>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">
                      Father/Guardian Occupation
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Father/Guardian Occupation"
                        id="father_occupation"
                        {...register("father_occupation")}
                      />
                    </div>
                  </div>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">
                      Father/Guardian Office Address
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Father/Guardian Office Address"
                        id="father_office_address"
                        {...register("father_office_address")}
                      />
                    </div>
                  </div>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">
                      Father/Guardian Office Tel
                    </label>
                    <div className="col-md-9">
                      <input
                        className="form-control"
                        type="number"
                        placeholder="Father/Guardian Office Tel"
                        id="father_office_tel"
                        {...register("father_office_tel")}
                      />
                    </div>
                  </div>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">
                      Mother Occupation
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Mother Occupation"
                        id="mother_occupation"
                        {...register("mother_occupation")}
                      />
                    </div>
                  </div>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">
                      Mother Office Address
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Mother Office Address"
                        id="mother_office_address"
                        {...register("mother_office_address")}
                      />
                    </div>
                  </div>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">
                      Mother Office Tel
                    </label>
                    <div className="col-md-9">
                      <input
                        className="form-control"
                        type="number"
                        placeholder="Mother Office Tel"
                        id="mother_office_tel"
                        {...register("mother_office_tel")}
                      />
                    </div>
                  </div>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">Aadhar Card</label>
                    <div className="col-md-9">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Aadhar Card"
                        id="aadhar_card"
                        {...register("aadhar_card")}
                      />
                    </div>
                  </div>
                  <div className=" row mb-4">
                    <label className="form-label">Siblings Information</label>
                    <div
                      className="col-md-12 d-flex"
                      style={{ "justify-content": "space-evenly" }}
                    >
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Sibling Name"
                          id="sibling_1_name"
                          {...register("sibling_1_name")}
                        />
                      </div>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Age"
                          id="sibling_1_age"
                          {...register("sibling_1_age")}
                        />
                      </div>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="School Attendant"
                          id="sibling_1_schoolAttendant"
                          {...register("sibling_1_schoolAttendant")}
                        />
                      </div>
                    </div>
                    <div
                      className="col-md-12 d-flex mt-5"
                      style={{ "justify-content": "space-evenly" }}
                    >
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Sibling Name"
                          id="sibling_2_name"
                          {...register("sibling_2_name")}
                        />
                      </div>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Age"
                          id="sibling_2_age"
                          {...register("sibling_2_age")}
                        />
                      </div>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="School Attendant"
                          id="sibling_2_schoolAttendant"
                          {...register("sibling_2_schoolAttendant")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className=" row mb-4">
                    <label className="col-md-3 form-label">
                      Previous School And Address
                    </label>
                    <div className="col-md-9">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Previous School And Address"
                        id="previous_school"
                        {...register("previous_school")}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className=" row mb-4 d-flex justify-content-between">
                <div className="col-md-2">
                  <ButtonComponent
                    customClass="btn btn-danger text-light"
                    text={
                      form_fee_check && !isRteStudent_check
                        ? "Add Paid Enquiry"
                        : "Add Student"
                    }
                    loading={showLoader}
                    disabled={showLoader}
                  />
                </div>

                {studentFormData.form_fee ? (
                  <>
                    <div className="col-md-2">
                      <button
                        type="button"
                        className="btn btn-primary text-light"
                        onClick={() => {
                          downloadForm();
                        }}
                      >
                        <i className="fe fe-user-plus"></i>&nbsp; Download Form
                      </button>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </form>
            {studentFormData.form_fee && !studentFormData.isRteStudent ? (
              <FormReciept formData={studentFormData} />
            ) : (
              <></>
            )}

            {studentFormData.form_fee ? (
              <>
                <h2 className="my-5">Student Form Preview</h2>
                <AdmissionForm
                  formData={studentFormData}
                  profilePicInAdmissionForm={profilePicInAdmissionForm}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddStudentForm;
