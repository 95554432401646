import React, { useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import placeholder from "../../assets/images/brand/placeholder.png";
import "../../App.css";
import {
  getTimeFromDateTime,
  getToken,  
} from "../../utility/utilityFunctions"; 
import GoBackButton from "../../component/GoBackButton";
import { getDate } from '../../helpers/DateHelper'

const TeacherAttendances = () => {
  const today = getDate();
  const tomorrow = getDate({addDate: {days: 1},Date:today});
  
  const [attendanceData, setAttendanceData] = useState([]);
  const [start_date, setStartDate] = useState(today);
  const [end_date, setEndDate] = useState(tomorrow);   
  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/teacher_attendances`,{
        start_date,
        end_date
      }, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { teacherAttendanceFinalData } = response.data.data;
          const { lateArrivals } = response.data.data;
          // console.log("lateArrivals:",lateArrivals)
        setAttendanceData(teacherAttendanceFinalData);
      });
  }, [start_date,end_date]); 

  const informedLeaveOrLate = (userId) => {
    const description = document.getElementById(`d${userId}`).value;
    if (description && description.trim() != "") {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/admin/inform_teacher_leave_or_late`,
          { userId, description },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          window.notif({
            msg: "Information marked Successfully",
            type: "success",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    } else {
      window.alert("Kindly provide a valid reason.");
    }
  };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <GoBackButton />
             
            </div>

            <div style={{ backgroundColor: "white", padding: "25px" }}>
              <div className="">
                <h1
                  className="page-title"
                  style={{ marginLeft: "12px", marginBottom: "15px" }}
                >
                  Teacher Attendance Report
                </h1>
              </div>

              <div className="row">                
                <div className="col-lg-6">
                  <label htmlFor="start_date">Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      var tomorrow = getDate({addDate: {days: 1},Date:e.target.value});
                      setEndDate(tomorrow);                      
                    }}
                    value={start_date}
                    name=""
                    id="start_date"
                  />
                </div>
                {/* <div className="col-lg-6">
                  <label htmlFor="end_date">End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                    value={end_date}
                    name=""
                    id="end_date"
                  />
                </div>  */}
              </div>
            </div>

            <div className="row col-xl-12 col-lg-12 mt-3">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        className="table table-bordered border text-nowrap mb-0"
                        id="basic-edit"
                      >
                        <tbody>
                          {attendanceData.map((data, index) => {
                            return (
                              <tr key={data._id}>
                                <td>
                                    {index+1}
                                </td>
                                <td>
                                  <div className="d-flex">
                                    <div
                                      style={{ width: "30%" }}
                                      className="d-flex justify-content-between align-items-center"
                                    >
                                      <div className="d-flex flex-column">
                                        <b className="mb-2">{data.name}</b>
                                        <b className="mb-2">{data.mobile}</b>
                                      </div>
                                      <div>
                                        {data.profile_pic ? (
                                          <>
                                            <img
                                              width={"100px"}
                                              src={
                                                process.env.REACT_APP_BASE_URL +
                                                "/admin/images/" +
                                                data.profile_pic
                                              }
                                              alt={data.name}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              width={"70px"}
                                              src={placeholder}
                                            />
                                          </>
                                        )}
                                      </div>
                                    </div>

                                    <div
                                      style={{ width: "20%" }}
                                      className="d-flex flex-column justify-content-center align-items-center"
                                    >
                                      {data?.records?.length ? (
                                         <>
                                         <div>
                                           <b>Punched At</b>
                                         </div>
                                         <ul>
                                           {data?.records?.length > 1 ? (
                                             <>
                                               <li style={{ fontSize: "12px" }}>
                                                 {getTimeFromDateTime(data.records[0].punch_datetime)}
                                               </li>
                                               <li style={{ fontSize: "12px" }}>
                                                 {getTimeFromDateTime(data.records[data.records.length - 1].punch_datetime)}
                                               </li>
                                             </>
                                           ) : (
                                             <li style={{ fontSize: "12px" }}>
                                               {getTimeFromDateTime(data.records[0].punch_datetime)}
                                             </li>
                                           )}
                                         </ul>
                                       </>
                                      ) : (
                                        <span className="badge bg-danger badge-sm  me-1 mb-1 mt-1 ml-10">
                                          Not Punched
                                        </span>
                                      )}
                                    </div>

                                    <div style={{ width: "50%" }}>
                                      {data?.description ? (
                                        <>
                                          <div>
                                            <h4 className="text-success">Informed !</h4>
                                            <h6>{data.description}</h6>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="row mb-4 d-flex flex-column align-items-center">
                                            <div>
                                              <textarea
                                                className="form-control"
                                                placeholder="Reason.."
                                                id={`d${data._id}`}
                                              ></textarea>
                                            </div>
                                            <div className="d-flex justify-content-end mt-3">
                                              <button
                                                className="btn btn-sm btn-primary"
                                                onClick={() => {
                                                  informedLeaveOrLate(data._id);
                                                }}
                                              >
                                                Informed
                                              </button>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherAttendances;
