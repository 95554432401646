import axios from "axios";
import * as XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../../component/Sidebar";
import {
  formatNumberIndianStyle,
  getToken,
} from "../../../utility/utilityFunctions";

const FinanceTwoReport = () => {
  const today = new Date().toISOString().substr(0, 10);
  const [Session, setSession] = useState("");
  const [sessions, setSessions] = useState([]);
  const [financeTwoReports, setFinanceTwoReports] = useState([]);
  const [percent, setPercent] = useState(50);
  const [filterClicked, setFilterClicked] = useState(0);
  const [totalAmountToBeCollected, setTotalAmountToBeCollected] = useState(0);
  const [totalAmountCollected, setTotalAmountCollected] = useState(0);
  const [totalAmountRemaining, setTotalAmountRemaining] = useState(0);

  useEffect(() => {
    document.getElementsByClassName("table-responsive")[0].style.display =
      "none";

    document.getElementsByClassName("loader")[0].style.display = "block";

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/financeTwoReportScreen`,
        {
          percent,
          Session
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        document.getElementsByClassName("loader")[0].style.display = "none";

        document.getElementsByClassName("table-responsive")[0].style.display =
          "block";
        const { finalData ,sessions} = response.data;
        setFinanceTwoReports(finalData);
        setSessions(sessions);

        let totalAmountToBeCollected = 0;
        let totalAmountCollected = 0;
        let totalAmountRemaining = 0;
        for (var data of finalData) {
          totalAmountToBeCollected +=
            data?.totalAmountOfBranchToBePaidAfterConcession;
          totalAmountCollected +=
            data?.totalAmountOfBranchPaidTillTodayAfterConcession;
          totalAmountRemaining += data?.remainingToCollect;
        }

        setTotalAmountToBeCollected(totalAmountToBeCollected);
        setTotalAmountCollected(totalAmountCollected);
        setTotalAmountRemaining(totalAmountRemaining);
      });
  }, [Session]);

  const handleExport = () => {
    const table = document.getElementById("myTable");
    const workbook = XLSX.utils.table_to_book(table);
    XLSX.writeFile(workbook, "finance.xlsx");
  };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>

            <div style={{ backgroundColor: "white", padding: "25px" }}>
              <div className="">
                <h1
                  className="page-title"
                  style={{ marginLeft: "12px", marginBottom: "15px" }}
                >
                  All Centers Finance Report
                </h1>
                <div className="col-md-10 d-flex flex-wrap">
                  <span className="my-1" style={{ marginRight: '15px' }}>More than</span>
                  <div style={{ marginRight: '15px' }}>
                  <input
                    disabled
                    type="number"
                    className="form-control"
                    value={percent}
                    onChange={(e) => {
                      setPercent(e.target.value);
                    }}
                    
                  />
                  </div>
                  <span className="my-1" style={{ marginRight: '15px' }}>% is due.</span>

                  <div className="" style={{ marginRight: '15px' }}>
                  <select
                    className="form-control"
                    name=""
                    value={Session}
                    id="Session"
                    onChange={(e) => {
                      setSession(e.target.value);
                    }}
                  >
                    <option value="">Select Session</option>
                    <option value="2022-2023">2022-2023</option>

                    {sessions.map((session) => {
                      return (
                        <option value={session.session_name}>
                          {session.session_name}
                        </option>
                      );
                    })}
                  </select>
                  </div>
                 
                 
                 
                </div>
                
              </div>

              <div className="mt-3" style={{ padding: "5px 12px" }}>
                <button
                  disabled
                  className="btn btn-sm btn-primary"
                  onClick={(e) => {
                    setFilterClicked((prevCount) => prevCount + 1);
                  }}
                >
                  <i class="side-menu__icon fe fe-filter" /> Filter
                </button>
                <button
                  className="btn btn-sm btn-green"
                  style={{ marginLeft: "5px" }}
                  onClick={handleExport}
                >
                  Export Data
                </button>
              </div>

              <div style={{ padding: "15px" }}>
                <div className="mt-3">
                  Total Records : {financeTwoReports.length}
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div class="loader" style={{ display: "none" }}>
                          <h2>Hold On !!</h2>
                          <h3>We are generating report....</h3>
                          <div class="spinner-border text-danger" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                        <div className="table-responsive">
                          {financeTwoReports.length > 0 ? (
                            <>
                              <table
                                className="table table-bordered border text-nowrap mb-0"
                                id="myTable"
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <b>Branch Name</b>
                                    </th>
                                    <th>
                                      <b>Amount to be collected </b>
                                      <div
                                        style={{
                                          fontSize: "10px",
                                          color: "blue",
                                        }}
                                      >
                                        After Deducting Concession
                                      </div>
                                    </th>
                                    <th>
                                      <b>Collected Amount</b>
                                    </th>
                                    <th>
                                      <b>Remaining Amount</b>
                                    </th>
                                    <th>
                                      <b>% Due</b>
                                    </th>
                                    <th>
                                      <b>% Collected</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {financeTwoReports.map((financeTwoReport) => {
                                    return (
                                      <tr key={financeTwoReport.branch_id}>
                                        <td>{financeTwoReport.name}</td>
                                        <td>
                                          &#8377;
                                          {formatNumberIndianStyle(
                                            financeTwoReport.totalAmountOfBranchToBePaidAfterConcession
                                          )}
                                        </td>
                                        <td>
                                          &#8377;
                                          {formatNumberIndianStyle(
                                            financeTwoReport.totalAmountOfBranchPaidTillTodayAfterConcession
                                          )}
                                        </td>
                                        <td>
                                          &#8377;
                                          {formatNumberIndianStyle(
                                            financeTwoReport.remainingToCollect
                                          )}
                                        </td>
                                        <td>
                                          {(
                                            (financeTwoReport.remainingToCollect /
                                              financeTwoReport.totalAmountOfBranchToBePaidAfterConcession) *
                                            100
                                          ).toFixed(2)}
                                          %
                                        </td>
                                        <td>
                                          {(
                                            100 -
                                            (financeTwoReport.remainingToCollect /
                                              financeTwoReport.totalAmountOfBranchToBePaidAfterConcession) *
                                              100
                                          ).toFixed(2)}
                                          %
                                        </td>
                                      </tr>
                                    );
                                  })}
                                  <tr>
                                    <td>
                                      <b>Total</b>
                                    </td>
                                    <td>
                                      &#8377;
                                      {formatNumberIndianStyle(
                                        totalAmountToBeCollected
                                      )}
                                    </td>
                                    <td>
                                      &#8377;
                                      {formatNumberIndianStyle(
                                        totalAmountCollected
                                      )}
                                    </td>
                                    <td>
                                      &#8377;
                                      {formatNumberIndianStyle(
                                        totalAmountRemaining
                                      )}
                                    </td>
                                    <td>
                                      {(
                                        (totalAmountRemaining /
                                          totalAmountToBeCollected) *
                                        100
                                      ).toFixed(2)}
                                      %
                                    </td>
                                    <td>
                                      {(
                                        (totalAmountCollected /
                                          totalAmountToBeCollected) *
                                        100
                                      ).toFixed(2)}
                                      %
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </>
                          ) : (
                            <h1>No record found.</h1>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinanceTwoReport;
