import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  // FaHouseUser,
  FaUserTie,
  FaUsers,
  FaHeadphonesAlt,
  FaBookOpen,
  FaRupeeSign,
  FaRegClock,
  FaHandHoldingUsd,
  FaRegClipboard,
  FaUserSlash,
  FaUserEdit,
  FaRegEdit,
  FaBroom,
  FaBuffer,
  FaUniversity,
  // FaWhmcs,
  // FaRavelry,
  // FaArchway,
  // FaAward,
  // FaUserFriends,
  // FaUniversity,
  // FaGifts,
  // FaSearchengin,
  // FaRegListAlt,
  // FaRegNewspaper,
  // FaSlack,
  // FaAddressBook,
  // FaBroom,
  // FaChartBar,
} from "react-icons/fa";

export default function SidebarForCentralHead() {
  return (
    <>
      {/* Student */}
      <li className="slide">
        <a
          className="side-menu__item"
          data-bs-toggle="slide"
          href="javascript:void(0)"
        >
          <FaUsers className="side-menu__icon" />

          <span className="side-menu__label">Student</span>
          <i className="angle fe fe-chevron-right" />
        </a>
        <ul className="slide-menu">
          <li className="panel sidetab-menu">
            <div className="panel-body tabs-menu-body p-0 border-0">
              <div className="tab-content">
                <div className="tab-pane" id="side9" role="tabpanel">
                  <ul className="sidemenu-list">
                    <li>
                      <Link className="slide-item" to="/admin/students">
                        <span className="side-menu__label">Students List</span>
                      </Link>
                    </li>
                    <li>
                      {(localStorage.getItem("user") &&
                        JSON.parse(localStorage.getItem("user")).role ===
                          "central_head") ||
                      JSON.parse(localStorage.getItem("user")).role ===
                        "coordinator" ? (
                        <>
                          <Link to="/admin/students/new" className="slide-item">
                            <span className="side-menu__label">
                              Add Student
                            </span>
                          </Link>
                        </>
                      ) : (
                        <></>
                      )}
                    </li>
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/students-follow-up"
                      >
                        <span className="side-menu__label">
                          Students Follow-Up
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/reports/studentStrength"
                      >
                        <span className="side-menu__label">
                          Student Strength Dashboard
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/admin/mark/missing-dob">
                        <span className="side-menu__label">Missing Dob</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/admin/mark/section">
                        <span className="side-menu__label">Update Section</span>
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        className="slide-item"
                        to="/admin/student_attendance_report"
                      >
                        <span className="side-menu__label">
                          Student Attendance Report
                        </span>
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
      {/*  */}
      {/* Attendance Report */}
      <li className="slide">
        <a
          className="side-menu__item"
          data-bs-toggle="slide"
          href="javascript:void(0)"
        >
          <FaRegEdit className="side-menu__icon " />

          <span className="side-menu__label">Attendance Report</span>
          <i className="angle fe fe-chevron-right" />
        </a>
        <ul className="slide-menu">
          <li className="panel sidetab-menu">
            <div className="panel-body tabs-menu-body p-0 border-0">
              <div className="tab-content">
                <div className="tab-pane" id="side9" role="tabpanel">
                  <ul className="sidemenu-list">
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/student_attendance_report"
                      >
                        <span className="side-menu__label">
                          Student Attendance Report
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/teacher_attendance_report"
                      >
                        <span className="side-menu__label">
                          Teacher Attendance Report
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
      {/*  */}
      {/* Fee correction */}
      <li className="slide">
        <a
          className="side-menu__item"
          data-bs-toggle="slide"
          href="javascript:void(0)"
        >
          <FaRupeeSign className="side-menu__icon" />

          <span className="side-menu__label">Fees Correction</span>
          <i className="angle fe fe-chevron-right" />
        </a>
        <ul className="slide-menu">
          <li className="panel sidetab-menu">
            <div className="panel-body tabs-menu-body p-0 border-0">
              <div className="tab-content">
                <div className="tab-pane" id="side9" role="tabpanel">
                  <ul className="sidemenu-list">
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/mark/feesCorrection"
                      >
                        <span className="side-menu__label">
                          Fees Correction
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/backSessionInvoices"
                      >
                        <span className="side-menu__label">2022 Invoices</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/greaterSessionStudents"
                      >
                        <span className="side-menu__label">
                          Wrong session students
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/getPaidRTEStudents"
                      >
                        <span className="side-menu__label">
                          Paid RTE Students
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
      {/*  */}

      {/* Master */}
      <li className="slide">
        <a
          className="side-menu__item"
          data-bs-toggle="slide"
          href="javascript:void(0)"
        >
          <FaUniversity className="side-menu__icon " />

          <span className="side-menu__label">Masters</span>
          <i className="angle fe fe-chevron-right" />
        </a>
        <ul className="slide-menu">
          <li className="panel sidetab-menu">
            <div className="panel-body tabs-menu-body p-0 border-0">
              <div className="tab-content">
                <div className="tab-pane" id="side9" role="tabpanel">
                  <ul className="sidemenu-list">
                    <li>
                      <Link className="slide-item" to="/admin/section/Create">
                        <span className="side-menu__label">
                          Update Sections
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
      {/*  */}
      {/* Teacher */}
      <li className="slide">
        <a
          className="side-menu__item"
          data-bs-toggle="slide"
          href="javascript:void(0)"
        >
          <FaUserTie className="side-menu__icon" />

          <span className="side-menu__label">Teacher</span>
          <i className="angle fe fe-chevron-right" />
        </a>
        <ul className="slide-menu">
          <li className="panel sidetab-menu">
            <div className="panel-body tabs-menu-body p-0 border-0">
              <div className="tab-content">
                <div className="tab-pane" id="side9" role="tabpanel">
                  <ul className="sidemenu-list">
                    <li>
                      <Link className="slide-item" to="/admin/teachers">
                        <span className="side-menu__label">Teachers List</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/admin/teachersLeave">
                        <span className="side-menu__label">Teacher Leaves</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/teachersattendances"
                      >
                        <span className="side-menu__label">
                          Teacher Attendances
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/teacherArchiveList"
                      >
                        <span className="side-menu__label">
                          Archive Teachers
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
      {/*  */}
      {/*Finance Reports*/}

      <li className="slide">
        <a
          className="side-menu__item"
          data-bs-toggle="slide"
          href="javascript:void(0)"
        >
          <FaRupeeSign className="side-menu__icon" />
          <span className="side-menu__label">Finance Reports</span>
          <i className="angle fe fe-chevron-right" />
        </a>
        <ul className="slide-menu">
          <li className="panel sidetab-menu">
            <div className="panel-body tabs-menu-body p-0 border-0">
              <div className="tab-content">
                <div className="tab-pane" id="side9" role="tabpanel">
                  <ul className="sidemenu-list">
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/finance_report_one"
                      >
                        <span className="side-menu__label">
                          Students Fee Record
                        </span>
                      </Link>
                    </li>

                    <li className="slide">
                      <Link
                        className="slide-item"
                        to="/admin/finance_report_three"
                      >
                        <span className="side-menu__label">
                          Daily Fee Collection Record
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/reports/studentStrength"
        >
          <FaUserTie className="side-menu__icon" />

          <span className="side-menu__label">Student Strength Dashboard</span>
        </Link>
      </li>
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/students"
        >
          <FaUsers className="side-menu__icon" />

          <span className="side-menu__label">Students</span>
        </Link>
      </li>
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/students-follow-up"
        >
          <FaHeadphonesAlt className="side-menu__icon" />

          <span className="side-menu__label">Students Follow-Up</span>
        </Link>
      </li> */}
      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/teachers"
        >
          <FaUserTie className="side-menu__icon" />

          <span className="side-menu__label">Teachers</span>
        </Link>
      </li> */}
      {/* Exam report */}
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/getExams"
        >
          <FaBuffer className="side-menu__icon" />
          <span className="side-menu__label">Exam Report</span>
        </Link>
      </li>
      {/*  */}
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/syllabus_preview"
        >
          <FaBookOpen className="side-menu__icon" />

          <span className="side-menu__label">Syllabus</span>
        </Link>
      </li>
      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/finance_report_one"
        >
          <FaRupeeSign className="side-menu__icon" />
          <span className="side-menu__label">Finance Report 01</span>
        </Link>
      </li>
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/finance_report_three"
        >
          <FaRupeeSign className="side-menu__icon" />

          <span className="side-menu__label">Finance Report 03</span>
        </Link>
      </li> */}

      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/teacherMissingSection"
        >
          <FaRegEdit className="side-menu__icon " />

          <span className="side-menu__label">Missing Secton Report</span>
        </Link>
      </li> */}

      {/* 
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/detactleaves"
        >
          <FaRegEdit className="side-menu__icon " />

          <span className="side-menu__label">Teachers Leave Report</span>
        </Link>
      </li> */}

      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/cleanliness-report"
        >
          <FaBroom className="side-menu__icon " />
          <span className="side-menu__label">Cleanliness Report</span>
        </Link>
      </li>

      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/circular"
        >
          <FaRegClock className="side-menu__icon " />
          <span className="side-menu__label">Circular</span>
        </Link>
      </li>

      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/collect-fee"
        >
          <FaHandHoldingUsd className="side-menu__icon" />

          <span className="side-menu__label">Collect Fee</span>
          <hr />
        </Link>
      </li>
      <li className="slide">
        <a
          className="side-menu__item"
          data-bs-toggle="slide"
          href="javascript:void(0)"
        >
          <FaRegClipboard className="side-menu__icon" />

          <span className="side-menu__label">Inventory</span>
          <i className="angle fe fe-chevron-right" />
        </a>
        <ul className="slide-menu">
          <li className="panel sidetab-menu">
            <div className="panel-body tabs-menu-body p-0 border-0">
              <div className="tab-content">
                <div className="tab-pane" id="side9" role="tabpanel">
                  <ul className="sidemenu-list">
                    <li>
                      <Link
                        className="slide-item"
                        to="/admin/manage-inventory"
                      >
                        <span className="side-menu__label">
                        Manage Inventory
                        </span>
                      </Link>
                    </li>

                    <li className="slide">
                      <Link
                        className="slide-item"
                        to="/admin/inactive-inventory"
                      >
                        <span className="side-menu__label">
                        Inactive Inventory
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/manage-inventory"
        >
          <FaRegClipboard className="side-menu__icon" />
          <span className="side-menu__label">Manage Inventory</span>
          <hr />
        </Link>
      </li>
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/inactive-inventory"
        >
          <FaRegClipboard className="side-menu__icon" />
          <span className="side-menu__label">Inactive Inventory</span>
          <hr />
        </Link>
      </li> */}
      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/teachersLeave"
        >
          <FaUserSlash className="side-menu__icon" />
          <span className="side-menu__label">Teacher Leaves</span>
          <hr />
        </Link>
      </li> */}
      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/teachersattendances"
        >
          <FaUserEdit className="side-menu__icon " />
          <span className="side-menu__label">Teacher Attendances</span>
          <hr />
        </Link>
      </li> */}
      <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/breakage-records"
        >
          <FaRegEdit className="side-menu__icon " />
          <span className="side-menu__label">Manage Breakage Records</span>
        </Link>
      </li>
      {/* <li className="slide">
        <Link
          className="side-menu__item has-link"
          data-bs-toggle="slide"
          to="/admin/settings"
        >
          <FaWhmcs className="side-menu__icon " />
          <span className="side-menu__label">Settings</span>
        </Link>
      </li> */}
    </>
  );
}
