import React, { useState } from "react";
import logo from "../../assets/images/brand/logo.png";
import axios from "axios";
import { Link, useNavigate ,useLocation } from "react-router-dom";

const ResetPassword = () => {
  const [userDetail, setUserDetail] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const location = useLocation();
  const { userEmail } = location.state || {};

  // Now you can access userDetail.email or any other properties
  const email = userEmail || "";
  const [isPassword, setIsPassword] = useState(true);

  const navigate = useNavigate();

  const resetPassword = () => {
    if (
      !userDetail.newPassword ||
      userDetail.newPassword === "" ||
      !userDetail.confirmPassword ||
      userDetail.confirmPassword === ""
    ) {
      window.notif({
        msg: "All fields are Mandatory",
        type: "error",
      });
    } else {
      console.log("userDetail:", userDetail);
      if (userDetail.newPassword !== userDetail.confirmPassword) {
        window.notif({
          msg: "Both feilds are not matched",
          type: "error",
        });
      } else {
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/resetpassword`, {email:email,password:userDetail.newPassword})
          .then((response) => {
            if (response.data.status) {
              window.notif({
                msg: "Password Reset Succfully",
                type: "succes",
              });
              navigate("/admin/login");
            } else {
              window.notif({
                msg: "Invalid Credentials",
                type: "error",
              });
            }
          })
          .catch((err) => {
            window.notif({
              msg: "Invalid Credentialsssss",
              type: "error",
            });
          });
      }
    }
  };

  return (
    <div className="page">
      <div className>
        <div className="col col-login mx-auto mt-7">
          <div className="text-center">
            <img
              style={{ width: "120px" }}
              src={logo}
              className="header-brand-img m-0"
              alt=""
            />
          </div>
        </div>
        <div className="container-login100">
          <div className="wrap-login100 p-6">
            <div className="login100-form validate-form">
              <span className="login100-form-title">Reset Password</span>

              <div className="wrap-input100 validate-input input-group">
                <input
                  className="input100 border-start-0 ms-0 form-control"
                  type="text"
                  placeholder="Enter New Password"
                  value={userDetail.newPassword}
                  onChange={(e) => {
                    setUserDetail({
                      ...userDetail,
                      newPassword: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="wrap-input100 validate-input input-group">
                <input
                  className="input100 border-start-0 ms-0 form-control"
                  type="text"
                  placeholder="Confirm Password"
                  value={userDetail.confirmPassword}
                  onChange={(e) => {
                    setUserDetail({
                      ...userDetail,
                      confirmPassword: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="container-login100-form-btn">
                <button
                  className="login100-form-btn btn-primary"
                  onClick={() => {
                    resetPassword();
                  }}
                >
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
