import axios from "axios";
import React, { useEffect, useState } from "react";
import { getToken } from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";

const AddGradeForm = () => {
  const data = {
    name: "",
    branch: "",
    activity_fee_1: "",
    activity_fee_2: "",
    activity_fee_3: "",
    tution_fee: "",
    admission_fee: "",
    common_grade: "",
    session: "",
  };

  const [formData, setformData] = useState(data);

  const [screenData, setScreenData] = useState({
    branches: [],
    commonGrades: [],
    sessions: [],
  });
  const [errors, setErrors] = useState({});
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/addUserData`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { branches, commonGrades, sessions } = response.data;
        setScreenData({
          ...screenData,
          branches,
          commonGrades,
          sessions,
        });
      });
  }, []);

  const updateValue = (e) => {
    const field = e.target.id;
    const value = e.target.value;

    setformData({
      ...formData,
      [field]: value,
    });
    // console.log("formData:",formData)
    if (errors[field]) {
      setErrors({
        ...errors,
        [field]: "",
      });
    }
  };
  const validateForm = () => {
    const newErrors = {};

    // Check if the Session field is empty
    if (!formData.session) {
      newErrors.session = "Session is required";
    }

    // Add more validation for other fields if needed

    return newErrors;
  };
  const createGrade = () => {
    const newErrors = validateForm();

    if (Object.keys(newErrors).length > 0) {
      // There are errors, do not proceed with submission
      setErrors(newErrors);
      return;
    }
    console.log("formData:", formData);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/grade/new`, formData, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        console.log(response);
        if (response?.data.success) {
          window.notif({
            msg: "<b>Woohoo!!</b> Grade Created Successfully",
            type: "success",
          });

          setformData(data);
        } else {
          window.notif({
            msg: response?.data.error,
            type: "error",
            position: "center",
          });
        }
      });
  };

  return (
    <>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Fill following information</h4>
          </div>
          <div className="card-body">
            <form className="form-horizontal">
              <div className="row mb-4">
                <label
                  htmlFor="example-session"
                  className="col-md-3 form-label"
                >
                  Session
                </label>
                <div className="col-md-9">
                  <select
                    className={`form-control ${
                      errors.session ? "is-invalid" : ""
                    }`}
                    id="session"
                    name="session"
                    value={formData.session}
                    onChange={(e) => updateValue(e)}
                  >
                    <option selected>Select Session</option>
                    {screenData.sessions.map((session) => (
                      <option value={session.session_name}>
                        {session.session_name}
                      </option>
                    ))}
                  </select>
                  {errors.session && (
                    <div className="invalid-feedback">{errors.session}</div>
                  )}
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Name</label>
                <div className="col-md-9">
                  <select
                    className="form-control"
                    value={formData.name}
                    id="name"
                    name="name"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  >
                    <option selected>Select Grade</option>
                    {screenData.commonGrades.map((grade) => {
                      return (
                        <option
                          key={grade._id}
                          value={`${grade.name}*${grade._id}`}
                        >
                          {grade.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Branch</label>
                <div className="col-md-9">
                  <select
                    className="form-control"
                    name=""
                    value={formData.branch}
                    id="branch"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  >
                    <option value="1">Select Branch</option>
                    {screenData.branches.map((branch) => {
                      return (
                        <option key={branch._id} value={branch._id}>
                          {branch.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Form Fee</label>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Form Fee"
                    value={formData.form_fee}
                    id="form_fee"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Admission Fee</label>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Admission Fee"
                    value={formData.admission_fee}
                    id="admission_fee"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Annual Fee - I</label>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Annual Fee I"
                    value={formData.activity_fee_1}
                    id="activity_fee_1"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Annual Fee - II</label>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Annual Fee II"
                    value={formData.activity_fee_2}
                    id="activity_fee_2"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Annual Fee - III</label>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Annual Fee III"
                    value={formData.activity_fee_3}
                    id="activity_fee_3"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>

              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Tuition Fee ( Per Month )
                </label>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Tuition Fee"
                    value={formData.tution_fee}
                    id="tution_fee"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <div className="col-md-3">
                  <button
                    type="button"
                    class="btn btn-danger text-light"
                    onClick={() => {
                      createGrade();
                    }}
                  >
                    <i class="fe fe-user-plus"></i>&nbsp;Add Grade
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddGradeForm;
