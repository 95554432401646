import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import {
  getToken,
  timezoneDateToddMMyyyy,
} from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";

const Chapters = () => {
  const [chapters, setChapters] = useState([]);
  const params = useParams();
  const [syllabus, setSyllabus] = useState([]);
  const [subject, setSubject] = useState([]);

  const syllabus_id = params.syllabusId;

  useEffect(() => {
    console.log("syllabus_id", syllabus_id);

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/chapters/`,
        { syllabus_id },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        const { syllabus } = response.data;
        console.log(syllabus,"syllabus");
        setSyllabus(syllabus);
        setChapters(syllabus.chapter);
        setSubject(syllabus.subject);
      });
  }, []);

  const deleteChapter = (id) => {
    if (window.confirm("Are you sure want to delete the exam ?")) {
      axios
        .delete(
          `${process.env.REACT_APP_BASE_URL}/admin/syllabus/${syllabus_id}/chapter/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          const { syllabus } = response.data;
          if (syllabus) {
            setChapters(syllabus.chapter);
            window.notif({
              msg: "Chapter Removed Successfully",
              type: "success",
            });
          } else {
            window.notif({
              msg: response?.data.error,
              type: "error",
              position: "center",
            });
          }
        });
    }
  };


  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <GoBackButton />
              <h1 className="page-title">Manage Chapters</h1>
              {localStorage.getItem("user") &&
              (JSON.parse(localStorage.getItem("user")).role ===
                "primary_academic_incharge" ||
                JSON.parse(localStorage.getItem("user")).role ===
                  "pre_primary_academic_incharge" ||
                JSON.parse(localStorage.getItem("user")).role ===

                  "middle_academic_incharge") ? (
                <Link
                  type="button"
                  to={`/admin/syllabus/${syllabus_id}/chapters/new`}
                  class="btn btn-danger text-light"
                >
                  <i class="fe fe-user-plus"></i>&nbsp;Add Chapter
                </Link>

              ) : (
                <></>
              )}
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                        <h3>Chapters of {syllabus?.common_grade?.name} ({subject?.name})</h3>
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Chapter Name</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {chapters.map((chapter) => {
                                return (
                                  <tr key={chapter.id}>
                                    <td>
                                      <Link
                                        to={`/admin/syllabus/${syllabus_id}/chapters/excercises/${chapter.id}`}
                                      >
                                        {chapter.name_of_ch}
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapters;
