// OTPScreen.js

import React, { useState , useRef, useEffect} from "react";
import logo from "../../assets/images/brand/logo.png";
import axios from "axios";
import { useNavigate ,useLocation } from "react-router-dom";

const OTPScreen = () => {
  const [otpDetail, setOtpDetail] = useState({
    otp: ["", "", "", ""],
  });
  const location = useLocation();
  const { userDetail } = location.state || {};

  // Now you can access userDetail.email or any other properties
  const userEmail = userDetail?.email || "";
  console.log("passed email:",userEmail);
  const inputRefs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);
  const navigate = useNavigate();

  const handleOtpChange = (index, value) => {
    const newOtp = [...otpDetail.otp];
    newOtp[index] = value;
    setOtpDetail({
      ...otpDetail,
      otp: newOtp,
    });

    // Move to the next input if a digit is entered
  if (value && index < inputRefs?.current?.length - 1) {
    inputRefs?.current[index + 1]?.current?.focus();
  }
  };

  const submitOtp = () => {
    const enteredOtp = otpDetail.otp.join("");
    console.log("enteredOtp:",enteredOtp);

    if (!enteredOtp || enteredOtp.length !== 4) {
      window.notif({
        msg: "A valid 4-digit OTP is mandatory",
        type: "error",
      });
    } else {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/otp/submitemailotp`, {
          otp: enteredOtp,
          email:userEmail
        })
        .then((response) => {
          if (response.data.status) {
            window.notif({
              msg: "OTP verified successfully",
              type: "success",
            });
            navigate("/reset-password", { state: { userEmail } });
          } else {
            window.notif({
              msg: "Invalid OTP",
              type: "error",
            });
          }
        })
        .catch(() => {
          window.notif({
            msg: "Invalid OTP",
            type: "error",
          });
        });
    }
  };
  useEffect(() => {
    // Focus the first input when the component mounts
    inputRefs?.current[0]?.current?.focus();
  }, []);
  return (
    <div className="page">
    <div className="container-login100">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="col col-login mx-auto mt-7 mb-4">
            <div className="text-center">
              <img
                style={{ width: "120px" }}
                src={logo}
                className="header-brand-img m-0"
                alt=""
              />
            </div>
          </div>
          <div className="wrap-login100 p-6">
            <div className="login100-form validate-form">
              <span className="login100-form-title">Submit OTP</span>
  
              <div className="wrap-input100 validate-input input-group">
                {otpDetail.otp.map((digit, index) => (
                  <input
                    key={index}
                    className="input10 border-start-0 ms-0 form-control"
                    type="text"
                    maxLength="1"
                    placeholder="*"
                    value={digit}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                  />
                ))}
              </div>
  
              <div className="container-login100-form-btn">
                <button
                  className="login100-form-btn btn-primary"
                  style={{ width: "50%" }}
                  onClick={() => {
                    submitOtp();
                  }}
                >
                  Submit OTP
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  

    
  );
};

export default OTPScreen;
