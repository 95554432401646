import React, { useState } from "react";
import logo from "../../assets/images/brand/logo.png";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const EmailScreen = () => {
  const [userDetail, setUserDetail] = useState({
    email: "",
  });

  const [isPassword, setIsPassword] = useState(true);

  const navigate = useNavigate();

  const sendOtp = () => {
    if (
      !userDetail.email ||
      userDetail.email === ""
    ) {
      window.notif({
        msg: "Email Mandatory",
        type: "error",
      });
    } else {
      console.log("userDetail:",userDetail);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/otp/emailsend`, userDetail)
        .then((response) => {
          if (response.data.status) {
            window.notif({
                msg: "OTP send succfully",
                type: "succes",
              });
            navigate("/admin/otpScreen", { state: { userDetail } });

          } else {
            window.notif({
              msg: "Invalid Credentials",
              type: "error",
            });
          }
        })
        .catch((err) => {
          window.notif({
            msg: "Invalid Credentialsssss",
            type: "error",
          });
        });
    }
  };

  return (
    <div className="page">
      <div className>
        <div className="col col-login mx-auto mt-7">
          <div className="text-center">
            <img
              style={{ width: "120px" }}
              src={logo}
              className="header-brand-img m-0"
              alt=""
            />
          </div>
        </div>
        <div className="container-login100">
          <div className="wrap-login100 p-6">
            <div className="login100-form validate-form">
            <span className="login100-form-title">Forgot Password</span>


              <div className="wrap-input100 validate-input input-group">
                <a
                  href="/admin/login"
                  className="input-group-text bg-white text-muted"
                  style={{ paddingLeft: "15px", paddingRight: "16px" }}
                >
                  <i className="fa fa-envelope" aria-hidden="true" />
                </a>
                <input
                  className="input100 border-start-0 ms-0 form-control"
                  type="email"
                  placeholder="Enter Email Address"
                  value={userDetail.email}
                  onChange={(e) => {
                    setUserDetail({
                      ...userDetail,
                      email: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="container-login100-form-btn">
                <button
                  className="login100-form-btn btn-primary"
                  onClick={() => {
                    sendOtp();
                  }}
                >
                  Send OTP
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailScreen;
