import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { getToken } from "../../utility/utilityFunctions";

import {
  getCurrentDate,
  getCurrentDateTime,
  getCurrentFullSession,
  getLoggedInUserBranchInfo,
  getSelectedOptionNameByID,
  digitToWords
} from "../../utility/utilityFunctions";
import "./FormReciept.css";

const FormReciept = ({ formData }) => {
  const [formFee , setForFee] = useState();
  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/gradesForFormData`,{grade_id:formData.grade}, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { formFee } = response.data;
        setForFee(formFee)
      });
  }, [formData.grade]);
  console.log("formData:",formData)
  const branchDetail = getLoggedInUserBranchInfo();  
  return (
    <>
      <div id="admission_form" className="formDiv mt-5">
        <div className="d-flex flex-column align-items-center">
          <h5 className="mb-1">Student Copy</h5>
          <h3 className="mb-1 f-w-600">{branchDetail.name}</h3>
          <p className="mb-1">{branchDetail.location}</p>
          <p className="mb-1">Ph : {branchDetail.phone}</p>
          <div className="d-flex w-100">
            <div className="w-33"></div>
            <div className="w-33 d-flex justify-content-center">
              FEE RECEIPT
            </div>
            <div className="w-33 d-flex justify-content-end">
              {/* SESSION : {"2023 - 2024"} */}
              SESSION : {formData?.session}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-5">
          <div>
            <div>
              <b>Name :</b> {formData.name}
            </div>
            <div>
              <b>Father/Guardian Name :</b> {formData.father_name}
            </div>
            <div>
              <b>Class :</b> {getSelectedOptionNameByID("grade")}
            </div>
          </div>
          {/* <div className="profile_image">Profile Image</div> */}
          <div>
            <div>
              <b>RCPT No : </b>
              <span id="recieptNo1"></span>
            </div>
            <div>
              <b>Date :</b> {getCurrentDate()}
            </div>
          </div>
        </div>
        <table border="1" className="mt-3">
          <tr>
            <th className="w-8 ta-c">S.No</th>
            <th>&nbsp;Description</th>
            <th className="w-12 ta-c">Amount</th>
          </tr>
          <tr>
            <td className="p-150-0 ta-c">1</td>
            <td className="p-150-0">
              <div style={{ paddingLeft: "5px" }}>Admission Form Fee</div>
            </td>
            {/* <td className="p-150-0 ta-c">&#8377;200</td> */}
            <td className="p-150-0 ta-c">&#8377;{formFee}</td>

          </tr>
          <tr>
            <td colspan="2" className="ta-r">
              NET AMOUNT&nbsp;
            </td>
            <td className="ta-c">&#8377;{formFee}</td>
          </tr>
        </table>
        <div className="mt-3">
          <div>
            {/* <div>Amt in Words: TWO HUNDRED ONLY</div> */}
            <div>Amt in Words: <span className="capital-text" style={{"text-transform": "capitalize"}}>{digitToWords(formFee)} Rupee Only</span></div>


          </div>
          <div className="d-flex justify-content-between">
            <div>Mode of Payment:  {formData?.payment_method?.charAt(0).toUpperCase() + formData?.payment_method?.slice(1)}</div>
            <div>For {branchDetail.name} School</div>
          </div>
          <div className="d-flex justify-content-between">
            <div></div>
            <div>Printed At {getCurrentDateTime()}</div>
            <div>Authorized Signatory</div>
          </div>
        </div>

        <hr
          style={{ height: "1px", border: "none", backgroundColor: "#333" }}
        />

        <div className="d-flex flex-column align-items-center">
          <h5 className="mb-1">Office Copy</h5>
          <h3 className="mb-1 f-w-600">{branchDetail.name}</h3>
          <p className="mb-1">{branchDetail.location}</p>
          <p className="mb-1">Ph : {branchDetail.phone}</p>
          <div className="d-flex w-100">
            <div className="w-33"></div>
            <div className="w-33 d-flex justify-content-center">
              FEE RECEIPT
            </div>
            <div className="w-33 d-flex justify-content-end">
              {/* SESSION : {"2023 - 2024"} */}
              SESSION : {formData?.session}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-5">
          <div>
            <div>
              <b>Name :</b> {formData.name}
            </div>
            <div>
              <b>Father/Guardian Name :</b> {formData.father_name}
            </div>
            <div>
              <b>Class :</b> {getSelectedOptionNameByID("grade")}
            </div>
          </div>
          {/* <div className="profile_image">Profile Image</div> */}
          <div>
            <div>
              <b>RCPT No : </b>
              <span id="recieptNo2"></span>
            </div>
            <div>
              <b>Date :</b> {getCurrentDate()}
            </div>
          </div>
        </div>
        <table border="1" className="mt-3">
          <tr>
            <th className="w-8 ta-c">S.No</th>
            <th>&nbsp;Description</th>
            <th className="w-12 ta-c">Amount</th>
          </tr>
          <tr>
            <td className="p-150-0 ta-c">1</td>
            <td className="p-150-0">
              <div style={{ paddingLeft: "5px" }}>Admission Form Fee</div>
            </td>
            <td className="p-150-0 ta-c">&#8377;{formFee}</td>
          </tr>
          <tr>
            <td colspan="2" className="ta-r">
              NET AMOUNT&nbsp;
            </td>
            <td className="ta-c">&#8377;{formFee}</td>
          </tr>
        </table>
        <div className="mt-3">
          <div>
            {/* <div>Amt in Words: TWO HUNDRED ONLY</div> */}
            <div>Amt in Words: <span className="capital-text" style={{"text-transform": "capitalize"}}>{digitToWords(formFee)} Rupee Only</span></div>


          </div>
          <div className="d-flex justify-content-between">
            <div>Mode of Payment:  {formData?.payment_method?.charAt(0).toUpperCase() + formData?.payment_method?.slice(1)}</div>
            <div>For {branchDetail.name} School</div>
          </div>
          <div className="d-flex justify-content-between">
            <div></div>
            <div>Printed At {getCurrentDateTime()}</div>
            <div>Authorized Signatory</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormReciept;
