import React, { useEffect, useState } from "react";
import logo from "../assets/images/brand/logo.png";
import logosmall from "../assets/images/brand/logo2.png";
import { Link } from "react-router-dom";
import "../App.css";
import SidebarForAdmin from "./sidebar/SidebarForAdmin";
import SidebarForCentralHead from "./sidebar/SidebarForCentralHead";
import SidebarForAccountant from "./sidebar/SidebarForAccountant";
import SidebarForCoordinator from "./sidebar/SidebarForCoordinator";
import SidebarForFinancialHead from "./sidebar/SidebarForFinancialHead";
import SidebarForAcademicIncharge from "./sidebar/SidebarForAcademicIncharge";

import {
  FaHouseUser,
  // FaUserTie,
  // FaUsers,
  // FaHeadphonesAlt,
  // FaBookOpen,
  // FaRupeeSign,
  // FaRegClock,
  // FaHandHoldingUsd,
  // FaRegClipboard,
  // FaUserSlash,
  // FaUserEdit,
  // FaRegEdit,
  FaWhmcs,
  // FaRavelry,
  // FaArchway,
  // FaAward,
  // FaUserFriends,
  // FaUniversity,
  // FaGifts,
  // FaSearchengin,
  // FaRegListAlt,
  // FaRegNewspaper,
  // FaSlack,
  // FaAddressBook,
  // FaBroom,
  // FaChartBar,
} from "react-icons/fa";

const Sidebar = () => {
  return (
    <>
      {/*APP-SIDEBAR*/}
      <div className="sticky">
        <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
        <div className="app-sidebar">
          <div className="side-header">
            <Link className="header-brand1" to="/admin">
              <img
                src={logo}
                className="header-brand-img desktop-logo"
                alt="logo"
              />
              <img
                src={logo}
                className="header-brand-img toggle-logo"
                alt="logo"
              />
              <img
                src={logosmall}
                className="header-brand-img light-logo"
                alt="logo"
              />
              <img
                src={logo}
                className="header-brand-img light-logo1"
                alt="logo"
              />
            </Link>
            {/* LOGO */}
          </div>
          <div className="main-sidemenu">
            <ul className="side-menu sidebar-overflow">
              <li className="slide">
                <Link
                  className="side-menu__item has-link"
                  data-bs-toggle="slide"
                  to="/admin"
                >
                  {/* <i className="side-menu__icon fe fe-home" /> */}
                  <FaHouseUser className="side-menu__icon" />
                  <span className="side-menu__label">Dashboard</span>
                </Link>
              </li>
              {/* {(localStorage.getItem("user") &&
                JSON.parse(localStorage.getItem("user")).role ===
                  "central_head") ||
              JSON.parse(localStorage.getItem("user")).role === "admin" ? (
                <>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/reports/studentStrength"
                    >
                      <FaUserTie className="side-menu__icon" />

                      <span className="side-menu__label">
                        Student Strength Dashboard
                      </span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/students"
                    >
                      <FaUsers className="side-menu__icon" />

                      <span className="side-menu__label">Students</span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/students-follow-up"
                    >
                      <FaHeadphonesAlt className="side-menu__icon" />

                      <span className="side-menu__label">
                        Students Follow-Up
                      </span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/teachers"
                    >
                      <FaUserTie className="side-menu__icon" />

                      <span className="side-menu__label">Teachers</span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/syllabus_preview"
                    >
                      <FaBookOpen className="side-menu__icon" />

                      <span className="side-menu__label">Syllabus</span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/finance_report_one"
                    >
                      <FaRupeeSign className="side-menu__icon" />
                      <span className="side-menu__label">
                        Finance Report 01
                      </span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/finance_report_three"
                    >
                      <FaRupeeSign className="side-menu__icon" />

                      <span className="side-menu__label">
                        Finance Report 03
                      </span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/circular"
                    >
                      <FaRegClock className="side-menu__icon " />
                      <span className="side-menu__label">Circular</span>
                    </Link>
                  </li>
                  
                </>
              ) : (
                <></>
              )} */}

              {localStorage.getItem("user") &&
              JSON.parse(localStorage.getItem("user")).role ===
                "coordinator" ? (
                <>
                <SidebarForCoordinator/>
                  {/* <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/students"
                    >
                      <FaUsers className="side-menu__icon" />

                      <span className="side-menu__label">Students</span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/students-follow-up"
                    >
                      <i className="side-menu__icon fe fe-home" />
                      <span className="side-menu__label">
                        Students Follow-Up
                      </span>
                    </Link>
                  </li> */}
                </>
              ) : (
                <></>
              )}

              {localStorage.getItem("user") &&
              JSON.parse(localStorage.getItem("user")).role === "accountant" ? (
                <>
                <SidebarForAccountant/>
                  {/* <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/students"
                    >
                      <FaUsers className="side-menu__icon" />

                      <span className="side-menu__label">Students</span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/collect-fee"
                    >
                      <FaHandHoldingUsd className="side-menu__icon" />
                      <span className="side-menu__label">Collect Fee</span>
                      <hr />
                    </Link>
                  </li> */}
                </>
              ) : (
                <></>
              )}

              {localStorage.getItem("user") &&
              JSON.parse(localStorage.getItem("user")).role ===
                "central_head" ? (
                <>
                <SidebarForCentralHead/>
                  {/* <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/collect-fee"
                    >
                      <FaHandHoldingUsd className="side-menu__icon" />

                      <span className="side-menu__label">Collect Fee</span>
                      <hr />
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/manage-inventory"
                    >
                      <FaRegClipboard className="side-menu__icon" />
                      <span className="side-menu__label">Manage Inventory</span>
                      <hr />
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/teachersLeave"
                    >
                      <FaUserSlash className="side-menu__icon" />
                      <span className="side-menu__label">Teacher Leaves</span>
                      <hr />
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/teachersattendances"
                    >
                      <FaUserEdit className="side-menu__icon " />
                      <span className="side-menu__label">
                        Teacher Attendances
                      </span>
                      <hr />
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/breakage-records"
                    >
                      <FaRegEdit className="side-menu__icon " />
                      <span className="side-menu__label">
                        Manage Breakage Records
                      </span>
                    </Link>
                  </li> */}
                </>
              ) : (
                <></>
              )}

              {localStorage.getItem("user") &&
              JSON.parse(localStorage.getItem("user")).role === "admin" ? (
                <>
                <SidebarForAdmin/>

                  {/* <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/roles"
                    >
                      <FaRavelry className="side-menu__icon " />
                      <span className="side-menu__label">Roles</span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/branch"
                    >
                      <FaArchway className="side-menu__icon " />
                      <span className="side-menu__label">Branches</span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/grades"
                    >
                      <FaAward className="side-menu__icon" />
                      <span className="side-menu__label">Grades</span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/users"
                    >
                      <FaUserFriends className="side-menu__icon" />
                      <span className="side-menu__label">User</span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/houses"
                    >
                      <FaUniversity className="side-menu__icon " />
                      <span className="side-menu__label">House</span>
                    </Link>
                  </li>

                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/generalSettings"
                    >
                      <FaWhmcs className="side-menu__icon " />

                      <span className="side-menu__label">General Settings</span>
                    </Link>
                  </li>

                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/teacherReport"
                    >
                      <FaRegEdit className="side-menu__icon fe fe-home" />
                      <span className="side-menu__label">Teacher Reports</span>
                    </Link>
                  </li>

                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/events"
                    >
                      <FaGifts className="side-menu__icon " />
                      <span className="side-menu__label">Events</span>
                    </Link>
                  </li> */}

                  {/* <li>
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/ksbhkjh"
                    >
                      <i className="side-menu__icon fe fe-home" />
                      <span className="side-menu__label">Reports</span>
                    </Link>

                    <div style={{ padding: "2px 45px" }}>
                      <ul>
                        <li>
                          <div>Finance</div>
                          <div style={{ padding: "2px 45px" }}>
                            <ul>
                              <li>One</li>
                              <li className="mt-2">Two</li>
                              <li className="mt-2">Three</li>
                            </ul>
                          </div>
                        </li>
                        <li className="mt-2">Student</li>
                      </ul>
                    </div>
                  </li> */}

                  {/* <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/finance_report_two"
                    >
                      <FaRupeeSign className="side-menu__icon" />

                      <span className="side-menu__label">
                        Finance Report 02
                      </span>
                    </Link>
                  </li>

                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/finance_report_three"
                    >
                      <FaRupeeSign className="side-menu__icon" />

                      <span className="side-menu__label">
                        Finance Report 03
                      </span>
                    </Link>
                  </li>

                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/student_report_one"
                    >
                      <FaRegEdit className="side-menu__icon fe fe-home" />

                      <span className="side-menu__label">
                        Student Report 01
                      </span>
                    </Link>
                  </li>

                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/student_reviews_report"
                    >
                      <FaSearchengin className="side-menu__icon " />
                      <span className="side-menu__label">Student Reviews</span>
                    </Link>
                  </li>

                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/teacher_reviews_report"
                    >
                      <FaSearchengin className="side-menu__icon " />
                      <span className="side-menu__label">Teacher Reviews</span>
                    </Link>
                  </li>

                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/student_attendance_report"
                    >
                      <FaRegEdit className="side-menu__icon " />
                      <span className="side-menu__label">
                        Student Attendance Report
                      </span>
                    </Link>
                  </li>

                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/teacher_attendance_report"
                    >
                      <FaRegEdit className="side-menu__icon " />

                      <span className="side-menu__label">
                        Teacher Attendance Report
                      </span>
                    </Link>
                  </li>

                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/breakage-records"
                    >
                      <FaRegListAlt className="side-menu__icon " />
                      <span className="side-menu__label">
                        Manage Breakage Records
                      </span>
                    </Link>
                  </li>

                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/inventory-items-category"
                    >
                      <FaRegNewspaper className="side-menu__icon" />
                      <span className="side-menu__label">
                        Inventory Category
                      </span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/inventory-items"
                    >
                      <FaSlack className="side-menu__icon " />
                      <span className="side-menu__label">Inventory Items</span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/subjects"
                    >
                      <FaAddressBook className="side-menu__icon " />
                      <span className="side-menu__label">Manage Subjects</span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/breakage-items-category"
                    >
                      <FaRegNewspaper className="side-menu__icon" />

                      <span className="side-menu__label">
                        Breakage Category
                      </span>
                    </Link>
                  </li>

                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/cleanliness-report"
                    >
                      <FaBroom className="side-menu__icon " />
                      <span className="side-menu__label">
                        Cleanliness Report
                      </span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/ch_targets"
                    >
                      <FaChartBar className="side-menu__icon " />
                      <span className="side-menu__label">
                        Manage CH Targets
                      </span>
                    </Link>
                  </li> */}
                </>
              ) : (
                <></>
              )}

              {localStorage.getItem("user") &&
              JSON.parse(localStorage.getItem("user"))?.role ===
                "financial_head" ? (
                <>
                <SidebarForFinancialHead/>
                  {/* <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/grades"
                    >
                      <i className="side-menu__icon fe fe-home" />
                      <span className="side-menu__label">Manage Grades</span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/reports/studentStrength"
                    >
                      <i className="side-menu__icon fe fe-home" />
                      <span className="side-menu__label">
                        Student Strength Dashboard
                      </span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/finance_report_one"
                    >
                      <i className="side-menu__icon fe fe-home" />
                      <span className="side-menu__label">
                        Finance Report 01
                      </span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/finance_report_two"
                    >
                      <FaRupeeSign className="side-menu__icon" />

                      <span className="side-menu__label">
                        Finance Report 02
                      </span>
                    </Link>
                  </li>

                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/finance_report_three"
                    >
                      <FaRupeeSign className="side-menu__icon" />

                      <span className="side-menu__label">
                        Finance Report 03
                      </span>
                    </Link>
                  </li> */}
                </>
              ) : (
                <></>
              )}

              {(localStorage.getItem("user") &&
                JSON.parse(localStorage.getItem("user")).role ===
                  "pre_primary_academic_incharge") ||
              JSON.parse(localStorage.getItem("user")).role ===
                "primary_academic_incharge" ||
              JSON.parse(localStorage.getItem("user")).role ===
                "middle_academic_incharge" ? (
                <>
                <SidebarForAcademicIncharge/>
                  {/* <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/syllabus"
                    >
                      <i className="side-menu__icon fe fe-home" />
                      <span className="side-menu__label">Syllabus</span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/exam"
                    >
                      <i className="side-menu__icon fe fe-home" />
                      <span className="side-menu__label">Manage Exams</span>
                    </Link>
                  </li>
                  <li className="slide">
                    <Link
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                      to="/admin/events"
                    >
                      <i className="side-menu__icon fe fe-home" />
                      <span className="side-menu__label">Manage Events</span>
                    </Link>
                  </li> */}
                </>
              ) : (
                <></>
              )}
              <li className="slide">
                <Link
                  className="side-menu__item has-link"
                  data-bs-toggle="slide"
                  to="/admin/settings"
                >
                  <FaWhmcs className="side-menu__icon " />
                  <span className="side-menu__label"> Account Settings</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/*/APP-SIDEBAR*/}
    </>
  );
};

export default Sidebar;
