import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import { getToken } from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";

const InventoryItemDetail = () => {
  const [inventoryItems, setInventoryItems] = useState({});
  const [branches, setBranches] = useState([]);

  let params = useParams();
  let id = params.id;

  useEffect(() => {
    console.log("getToken()", getToken());
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/inventoryItemsDetails`, {id}, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { inventoryBranchWise } = response.data;
        setInventoryItems(inventoryBranchWise);
        setBranches(Object.keys(inventoryBranchWise));
      });
  }, []);

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
            <GoBackButton />
              <h1 className="page-title">Inventory Item Details</h1>              
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Branch</b>
                                </th>
                                <th>
                                  <b>Count</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {branches.map((branch) => {
                                return (
                                  <tr key={""}>
                                    <td>{branch}</td>
                                    <td>{inventoryItems[branch]}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryItemDetail;