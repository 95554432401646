import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import {
  getToken,  
} from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";
const WithOutSrNumberStudents = () => {
  // let userInfo = localStorage.getItem("user");
  // userInfo = JSON.parse(userInfo);
  const [students, setStudents] = useState([]);
  const [search, setSearch] = useState("");
  // const [showDatesOfId, setShowDatesOfId] = useState(null);
  let count = 1;
  useEffect(() => {
    const loaderElement = document.getElementsByClassName("loader")[0];
    if (loaderElement) {
      // If the loader element exists, you can modify its display style
      loaderElement.style.display = "block"; // or "block" or any other desired value
    }
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/withoutSRnumberStudents`,

        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            // "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        const { withoutSRnumberStudents } = response.data;
        console.log("withoutSRnumberStudents:", withoutSRnumberStudents);
        setStudents(withoutSRnumberStudents);
        if (loaderElement) {
          // If the loader element exists, you can modify its display style
          loaderElement.style.display = "none"; // or "block" or any other desired value
        }
      });
  }, [search]);

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <GoBackButton />
              {/* <h1 className="page-title">Teachers</h1> */}
              <h3>WithOut Sr number students list</h3>
            </div>
            <div className="row ">
              <div class="col-sm-12">
                <div className="loader" style={{ display: "none" }}>
                  <h2>Hold On !!</h2>
                  <h3>We are generating report....</h3>
                  <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Sr. No</b>
                                </th>
                                <th>
                                  <b>Name</b>
                                </th>
                                <th>
                                  <b>Father</b>
                                </th>

                                <th>
                                  <b>Mobile</b>
                                </th>

                                <th>
                                  <b>Status</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {students.map((student) => {
                                return (
                                  <React.Fragment key={student?._id}>
                                    <tr>
                                      <td>{count++}</td>

                                      <td>
                                        <Link
                                          to={`/admin/students/${student?._id}`}
                                        >
                                          {student?.name}
                                        </Link>
                                      </td>
                                      <td>{student?.father_name}</td>
                                      <td>{student?.mobile}</td>
                                      <td>{student?.status.charAt(0).toUpperCase() + student?.status?.slice(1)}</td>

                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithOutSrNumberStudents;
