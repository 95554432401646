import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/images/brand/logo.png";
import userImage from "../assets/images/brand/user.jpg";
import UserContext from "../userContext";
import { getLoggedInUserInfo } from "../utility/utilityFunctions";
// import UserContext from "../userContext";

const Header = () => {
  const location = useLocation()?.pathname;
  const {logout} = useContext(UserContext);

  const navigate = useNavigate();
  useEffect(() => {
    if(!getLoggedInUserInfo()){
      navigate("/admin/login");
    }
  }, [])

  return location == "/admin/login" || location == "/reset-password" || location == "/admin/otpScreen" || location == "/admin/emailscreen"  ? (
    <></>
  ) : (
    <>
      <div className="app-header header sticky">
        <div className="container-fluid main-container">
          <div className="d-flex">
            <a
              aria-label="Hide Sidebar"
              className="app-sidebar__toggle"
              data-bs-toggle="sidebar"
              href="javascript:void(0)"
            />
            {/* sidebar-toggle*/}
            <a className="logo-horizontal " href="index.html">
              <img
                src={logo}
                className="header-brand-img desktop-logo"
                alt="logo"
              />
              <img
                src={logo}
                className="header-brand-img light-logo1"
                alt="logo"
              />
            </a>
            <div className="d-flex order-lg-2 ms-auto header-right-icons">
              <div className="navbar navbar-collapse responsive-navbar p-0">
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent-4"
                >
                  <div className="d-flex order-lg-2">
                    <div className="dropdown  d-flex shopping-cart">
                      <a
                        className="nav-link icon text-center"
                        data-bs-toggle="dropdown"
                      ></a>
                    </div>
                    {/* SIDE-MENU */}
                    <div className="dropdown d-flex profile-1">
                      <a
                        href="javascript:void(0)"
                        data-bs-toggle="dropdown"
                        className="nav-link leading-none d-flex"
                      >
                        <img
                          src={userImage}
                          alt="profile-user"
                          className="avatar  profile-user brround cover-image"
                        />
                      </a>
                        <div className="d-flex align-items-center">
                          {JSON.parse(localStorage.getItem("user"))?.name}
                          </div>
                      <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                        <div className="dropdown-divider m-0" />                       
                        <a className="dropdown-item" onClick={() => {logout()}}>
                          <i className="dropdown-icon fe fe-power"/> Logout
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;