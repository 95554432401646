import axios from "axios";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar";
import { getToken } from "../../utility/utilityFunctions";
import { Link } from "react-router-dom";

function SyllabusWithoutChaptes() {
  const { id } = useParams();
  const [syllabuses, setSyllabuses] = useState(null);
  let count = 1;

  useEffect(() => {
    const loaderElement = document.getElementsByClassName("loader")[0];
    if (loaderElement) {
      // If the loader element exists, you can modify its display style
      loaderElement.style.display = "block"; // or "block" or any other desired value
    }
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/admin/syllabusNotAdded`;
    axios
      .post(apiUrl,{}, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { syllabusNotAdded } = response.data;
        setSyllabuses(syllabusNotAdded);
        console.log("syllabusNotAdded", syllabusNotAdded);
        if (loaderElement) {
            // If the loader element exists, you can modify its display style
            loaderElement.style.display = "none"; // or "block" or any other desired value
          }
      });
  }, []);

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="col-sm-12 card pb-2 pt-2">
              <div className="col-sm-12">
                <div className="card">
                  <h1 className="page-title p-2">Syllabus list without chapters </h1>
                  {/* <div class="alert alert-info" role="alert">
                  Students those were promoted from last class and paid less than 3000 INR.
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row ">
            <div className="col-sm-4">
              <div className="loader" style={{ display: "none" }}>
                <h2>Hold On !!</h2>
                <h3>We are generating report....</h3>
                <div className="spinner-border text-danger" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
{syllabuses ? (<div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Sr. No</b>
                                </th>
                                <th>
                                  <b>Created By</b>
                                </th>
                                <th>
                                  <b>Mobile</b>
                                </th>

                               
                               
                                <th>
                                  <b>Role</b>
                                </th>
                                <th>
                                  <b>Subject</b>
                                </th>
                                <th>
                                  <b>Grade</b>
                                </th>
                                
                              </tr>
                            </thead>
                            <tbody>
                              {syllabuses.map((syllabuse) => {
                                return (
                                  <React.Fragment key={syllabuse._id}>
                                    {/* First <tr> for basic teacher information */}

                                    <tr>
                                      <td>{count++}</td>

                                      <td>
                                        {/* <Link
                                          to={`/admin/teachers/${teacher._id}`}
                                        > */}
                                          {syllabuse?.created_by?.name}
                                        {/* </Link> */}
                                      </td>

                                      <td>{syllabuse?.created_by?.mobile}</td>

                                      
                                      <td>{syllabuse?.created_by?.role}</td>
                                      <td>{syllabuse?.subject?.name}</td>
                                      <td>{syllabuse?.common_grade?.name}</td>


                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>) : ""}
            
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
}
export default SyllabusWithoutChaptes;
