import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import {
  getToken,
  timezoneDateToddMMyyyy,
} from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";

const Event = () => {
  const [events, setEvents] = useState([]);
  const [search, setSearch] = useState("");
  const [Session, setSession] = useState("");
  const [sessions, setSessions] = useState([]);
  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/events`,
        {
          search: search,
          Session: Session,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        const { events, sessions } = response.data;
        setEvents(events);
        setSessions(sessions);

        console.log("events:", events);
      });
  }, [search, Session]);

  const deleteEvent = async (eventId) => {
    if (window.confirm("Are you sure want to delete the event ?")) {
      axios
        .delete(`${process.env.REACT_APP_BASE_URL}/admin/event/${eventId}`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then((response) => {
          const { events } = response.data;
          setEvents(events);
        });
    }
  };
let count = 1;
  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <GoBackButton />

              <h1 className="page-title">Events</h1>
              {/*  */}
              <div className="col-md-6 d-flex justify-content-center align-items-center">
                <div className="mb-3 col-6">
                  {/* <label htmlFor="example-session">Session</label> */}

                  <select
                    className="form-control"
                    name=""
                    value={Session}
                    id="Session"
                    onChange={(e) => {
                      setSession(e.target.value);
                    }}
                  >
                    <option value="">Select Session</option>
                    {sessions.map((session) => {
                      return (
                        <option value={session.session_name}>
                          {session.session_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="mb-2 col-6">
                  <input
                    className="form-control"
                    type="text"
                    name="search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    placeholder="Search"
                  />
                </div>
              </div>

              {/*  */}
              <Link
                type="button"
                to="/admin/event/new"
                class="btn btn-danger text-light"
              >
                <i class="fe fe-user-plus"></i>&nbsp;Add Event
              </Link>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                              <th>
                                  <b>Sr. No</b>
                                </th>
                                <th>
                                  <b>Date</b>
                                </th>
                                <th>
                                  <b>Event</b>
                                </th>
                                <th>
                                  <b>Session</b>
                                </th>
                                <th>
                                  <b>Branches</b>
                                </th>
                                <th>
                                  <b>Grades</b>
                                </th>
                                <th>
                                  <b>Event For</b>
                                </th>
                                <th>
                                  <b>Action</b>
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {events.map((event) => {
                                return (
                                  <tr key={event._id}>
                                    <td>{count++}</td>
                                    <td>
                                      {timezoneDateToddMMyyyy(event.date)}
                                    </td>
                                    <td>
                                      <div
                                        style={{
                                          whiteSpace: "normal", // or "break-spaces" depending on your requirements
                                          wordWrap: "break-word",
                                          width: "100px",
                                        }}
                                      >
                                        {event.name}
                                      </div>
                                    </td>
                                    <td>{event.session}</td>
                                    <td>
                                      <div
                                        style={{
                                          whiteSpace: "normal", // or "break-spaces" depending on your requirements
                                          wordWrap: "break-word",
                                          width: "100px",
                                        }}
                                      >
                                        {event.for_all_branches
                                          ? "All Branches"
                                          : event.branches.map((branch) => {
                                              return branch.name + ", ";
                                            })}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        style={{
                                          whiteSpace: "normal", // or "break-spaces" depending on your requirements
                                          wordWrap: "break-word",
                                          width: "100px",
                                        }}
                                      >
                                        {event.for_all_grades
                                          ? "All Grades"
                                          : event.grades.map((grade) => {
                                              return grade.name + ", ";
                                            })}
                                      </div>
                                    </td>
                                    <td>
                                      {event?.eventFor == "T"
                                        ? "Teachers"
                                        : event?.eventFor == "S"
                                        ? "Students"
                                        : event?.eventFor == "TS"
                                        ? "Teachers And Students"
                                        : ""}
                                    </td>
                                    <td>
                                      <Link to={`${event?._id}`}>
                                        <button
                                          class="btn btn-primary text-light"
                                          style={{ marginLeft: "10px" }}
                                        >
                                          Edit
                                        </button>
                                      </Link>

                                      <button
                                        type="button"
                                        class="btn btn-danger text-light"
                                        style={{ marginLeft: "10px" }}
                                        onClick={() => deleteEvent(event._id)}
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Event;
