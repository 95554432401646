import { React } from "react";
import { Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Login from "../layout/login/Login";
import Dashboard from "../layout/dashboard/Dashboard";
import Role from "../layout/roles/Role";
import Users from "../layout/users/Users";
import Students from "../layout/students/Students";
import StudentDetail from "../layout/students/StudentDetail";
import Grade from "../layout/grades/Grade";
import Branch from "../layout/branches/Branch";
import House from "../layout/houses/House";
import Syllabus from "../layout/syllabus/Syllabus";
import Circular from "../layout/circular/Circluar";
import Chapters from "../layout/syllabus/Chapters";
import AddChapter from "../layout/syllabus/AddChapter";
import BreakageReport from "../layout/breakageReport/breakageReport";
import BreakageRecordDetail from "../layout/breakageReport/BreakageRecordDetail";
import CleanlinessReport from "../layout/CleanlinessReport/CleanlinessReport";
import CHTarget from "../layout/chtargets/CHTarget";
import TeachersLeave from "../layout/teacherLeavesManagment/TeachersLeave";
import Setting from "../layout/settings/Settings";
import SyllabusPreview from "../layout/syllabusPreview/SyllabusPreview";
import TeacherAttendances from "../layout/teacherAttendances/TeacherAttendances";
import TeacherLateAttendances from "../layout/teacherAttendances/TeacherLateAttendances";
import StudentsFollowUp from "../layout/StudentsFollowUp/StudentsFollowUp";
import TeacherReport from "../layout/Reports/TeacherReport";
import FinanceOneReport from "../layout/Reports/ReportOne/FinanceOne";
import FinanceTwoReport from "../layout/Reports/ReportOne/FinanceTwo";
import FinanceThreeReport from "../layout/Reports/ReportOne/FinanceThree";
import PaidEnquired from "../layout/Reports/ReportOne/PaidEnquired";
import StudentOneReport from "../layout/Reports/Student/StudentOne";
import StudentAttendanceReport from "../layout/Reports/Attendance/StudentAttendance";
import StudentReviewReport from "../layout/Reports/Student/StudentReview";
import TeacherReviewReport from "../layout/Reports/Student/TeacherReview";
import TeacherAttendanceReport from "../layout/Reports/Attendance/TeacherAttendance";
import ChapterExcercise from "../layout/syllabus/ChapterExcercise";
import CollectFee from "../layout/collectfee/CollectFee";
import Teachers from "../layout/teachers/Teachers";
import TeacherDetail from "../layout/teachers/TeacherDetail";
import Subject from "../layout/subjects/Subject";
import Event from "../layout/events/Event";
// import EditEventForm from '../layout/events/EditEventForm';
import EditEvent from "../layout/events/EditEvent";
import Exam from "../layout/exam/Exam";
import ExamDetails from "../layout/exam/ExamDetails";
import InventoryItem from "../layout/inventoryItems/InventoryItem";
import InventoryItemDetail from "../layout/inventoryItems/InventoryItemDetail";
import Inventory from "../layout/inventory/Inventory";
import InventoryCategory from "../layout/inventoryCategory/InventoryCategory";
import BreakageCategory from "../layout/breakageCategory/breakageCategory";
import GeneralSettings from "../layout/generalSettings/GeneralSettings";
import CollectFeeDetail from "../layout/collectFeeDetail/CollectFeeDetail";
import AddUser from "../layout/users/AddUser";
import AddRole from "../layout/roles/AddRole";
import AddGrade from "../layout/grades/AddGrade";
import AddStudent from "../layout/students/AddStudent";
import AddBranch from "../layout/branches/AddBranch";
import AddHouse from "../layout/houses/AddHouse";
import AddSyllabus from "../layout/syllabus/AddSyllabus";
import AddTeacher from "../layout/teachers/AddTeacher";
import AddSubject from "../layout/subjects/AddSubject";
import AddEvent from "../layout/events/AddEvent";
import AddExam from "../layout/exam/AddExam";
import AddInventoryItem from "../layout/inventoryItems/AddInventoryItem";
import AddInventory from "../layout/inventory/AddInventory";
import AddInventoryCategory from "../layout/inventoryCategory/AddInventoryCategory";
import AddBreakageCategory from "../layout/breakageCategory/AddBreakageCategory";
import StudentStrengthReport from "../layout/Reports/Student/StudentStrengthReport";
import Mark from "../layout/mark/Mark";
import MarkDetail from "../layout/mark/MarkDetail";
import MissingDob from "../layout/mark/MissingDob";
import Section from "../layout/mark/Section";
import Student from "../layout/mark/Student";
import TeacherMissingSection from "../layout/teachersReportMissingSection/TeacherMissingSection";
import TeacherSameGradeSectionSub from "../layout/teachersSameGradeSubAndSection/TeacherSameGradeSectionSub";
import FeesDetails from "../layout/mark/FeesDetails";

import FeesRecord from "../layout/mark/FeesRecord";
import DetactLeaves from "../layout/detactLeave/DetactLeaves";
import TeacherLeaveDetail from "../layout/detactLeave/TeacherLeaveDetail";
import FeesCorrection from "../layout/mark/FeesCorrection";

import Appraisal from "../layout/mark/Appraisal";

import ChangeStatus from "../layout/students/ChangeStatus";
// import Protected from '../component/Protected';

import CreateSection from "../layout/Section/Create";
import SessionManager from "../layout/session/SessionManager";

import LessAmountStudent from "../layout/lessAmountStudent/LessAmountStudnet";
import BackSessionStudents from "../layout/backSessionStudents/BackSessionStudents";
import UpComingSessionStudents from "../layout/backSessionStudents/UpComingSessionStudents";
import StudentsDetailsGradeWise from "../layout/mark/StudentsDetailsGradeWise";
import DashboardSectionUpdate from "../layout/Section/DashboardSectionUpdate";
import NegativeFeeRecord from "../layout/negativeFeeReport/NegativeFeeRecord";
import TeacherWithoutPaycode from "../layout/teacherWithoutPaycode/TeacherWithoutPaycode";
import AllStudentsPaidLessAmount from "../layout/AllStudentsPaidLessAmount/AllStudentsPaidLessAmount";
import TeacherAssignedHomeWork from "../layout/teachersAssignedWork/TeacherAssignedWork";
import TeacherAssignedClassWork from "../layout/teachersAssignedWork/TeacherAssignedClassWork";
import TeacherAssignedActivity from "../layout/teachersAssignedWork/TeacherAssignedActivity";
import StudentExamMark from "../layout/studentExamMarks/StudentExamMarks";
import StudentStudentSWithMarks from "../layout/studentExamMarks/StudentSWithMarks";
import EditSession from "../layout/session/EditSession";
import TeacherAppraisalPoints from "../layout/teacherAppraisalPoints/TeacherAppraisalPoints";
import AbsentStudents from "../layout/adminDashboard/AbsentStudents";
import StudentsRemark from "../layout/adminDashboard/StudentsRemark";
import AbsentTeachers from "../layout/adminDashboard/AbsentTeachers";
import StudentsAppreciation from "../layout/adminDashboard/StudentsAppreciation";
import LateArrivalTeachers from "../layout/adminDashboard/LateArrivalTeachers";
import SyllabusWithoutEndDate from "../layout/adminDashboard/SyllabusWithoutEndDate";
import SyllabusWithoutChaptes from "../layout/adminDashboard/SyllabusWithOutChapters";
import DueFeeStudents from "../layout/adminDashboard/DueFeeStudents";
import TeacherSyllabusForCopyChecking from "../layout/teachersAssignedWork/TeacherSyllabusForCopyChecking";
import CopyCheckingDetail from "../layout/teachersAssignedWork/CopyCheckingDetail";
import TeacherArchiveList from "../layout/teachers/TeacherArchiveList";
import AppraisalSettingForm from "../layout/teachers/TeacherAppraisal";
import WithOutSrNumberStudents from "../layout/withSrNumbersStudents/WithOutSrNumberStudents";
import DuplicateInvoiceStudents from "../layout/duplicateInvoiceStudents/DuplicateInvoiceStudents";
import DuplicateInvoiceDetail from "../layout/duplicateInvoiceStudents/DuplicateInvoiceDetail";
import CentralHeadLeaves from "../layout/teacherLeavesManagment/CentralHeadLeaves";
import BackSessionInvoices from "../layout/collectFeeDetail/BackSessionInvoices";
import GreaterSessionStudents from "../layout/students/GreaterSessionStudents";
import DeletedInvoices from "../layout/duplicateInvoiceStudents/DeletedInvoices";
import PaidRTEStudents from "../layout/duplicateInvoiceStudents/PaidRTEStudents";
import EditUser from "../layout/users/EditUser";
import UpdatePassword from "../layout/settings/UpdatePassword";
import InactiveInventory from "../layout/inventory/InactiveInventories";
import EmailScreen from "../layout/login/EmailScreen";
import OTPScreen from "../layout/login/OTPScreen";
import ResetPassword from "../layout/login/ResetPassword";
import StudentMark from "../layout/students/StudentMark";

export default function AppRoutes() {
  const isAuthenticated = () => {
    // Check if the JWT token and user details are present in localStorage
    const jwtToken = localStorage.getItem("jwt");
    const user = localStorage.getItem("user");
    // console.log("user-->", user);
    // Return true if both the token and user details are present
    return user && jwtToken;
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          isAuthenticated() ? (
            <Navigate to="/admin" />
          ) : (
            <Navigate to="/admin/login" />
          )
        }
      />
      <Route path="/admin/emailscreen" element={<EmailScreen />} />
      <Route path="/admin/otpScreen" element={<OTPScreen />} />
      <Route path="/reset-password" element={<ResetPassword />} />


      <Route
        path="/admin/login"
        element={isAuthenticated() ? <Navigate to="/admin" /> : <Login />}
      />

      {isAuthenticated() ? (
        <>
          <Route path="/admin" element={<Dashboard />} />

          <Route path="/admin/roles" Component={Role} />
          <Route path="/admin/users" Component={Users} />
          <Route path="/admin/students" Component={Students} />
          <Route path="/admin/students/:id" Component={StudentDetail} />
          <Route path="/admin/grades" Component={Grade} />
          <Route path="/admin/branch" Component={Branch} />
          <Route path="/admin/houses" Component={House} />
          <Route path="/admin/syllabus" Component={Syllabus} />
          <Route path="/admin/circular" Component={Circular} />
          <Route
            path="/admin/syllabus/:syllabusId/chapters"
            Component={Chapters}
          />
          <Route
            path="/admin/syllabus/:syllabusId/chapters/new"
            Component={AddChapter}
          />
          <Route path="/admin/breakage-records" Component={BreakageReport} />
          <Route
            path="/admin/breakage-record/:id"
            Component={BreakageRecordDetail}
          />
          <Route
            path="/admin/cleanliness-report"
            Component={CleanlinessReport}
          />
          <Route path="/admin/ch_targets" Component={CHTarget} />
          <Route path="/admin/teachersLeave" Component={TeachersLeave} />
          <Route path="/admin/centralHeadLeave" Component={CentralHeadLeaves} />

          <Route path="/admin/settings" Component={Setting} />
          <Route path="/admin/syllabus_preview" Component={SyllabusPreview} />
          <Route
            path="/admin/teachersattendances"
            Component={TeacherAttendances}
          />
          <Route
            path="/admin/teacherslateattendances"
            Component={TeacherLateAttendances}
          />
          <Route
            path="/admin/students-follow-up"
            Component={StudentsFollowUp}
          />
          <Route path="/admin/teacherReport" Component={TeacherReport} />
          <Route
            path="/admin/finance_report_one"
            Component={FinanceOneReport}
          />
          <Route
            path="/admin/finance_report_two"
            Component={FinanceTwoReport}
          />
          <Route
            path="/admin/finance_report_three"
            Component={FinanceThreeReport}
          />
          <Route path="/admin/paid_enquired" Component={PaidEnquired} />

          <Route
            path="/admin/student_report_one"
            Component={StudentOneReport}
          />
          <Route
            path="/admin/student_attendance_report"
            Component={StudentAttendanceReport}
          />
          <Route
            path="/admin/student_reviews_report"
            Component={StudentReviewReport}
          />
          <Route
            path="/admin/teacher_reviews_report"
            Component={TeacherReviewReport}
          />

          <Route
            path="/admin/teacher_attendance_report"
            Component={TeacherAttendanceReport}
          />

          <Route
            path="/admin/syllabus/:syllabusId/chapters/excercises/:chapterId"
            Component={ChapterExcercise}
          />
          <Route path="/admin/collect-fee" Component={CollectFee} />
          <Route path="/admin/teachers" Component={Teachers} />
          <Route path="/admin/teachers/:id" Component={TeacherDetail} />

          <Route path="/admin/subjects" Component={Subject} />
          <Route path="/admin/events" Component={Event} />
          <Route path="/admin/events/:id" Component={EditEvent} />

          <Route path="/admin/exam" Component={Exam} />
          <Route path="/admin/exam/:id" Component={ExamDetails} />
          <Route path="/admin/inventory-items" Component={InventoryItem} />
          <Route
            path="/admin/inventory-items/:id"
            Component={InventoryItemDetail}
          />
          <Route path="/admin/manage-inventory" Component={Inventory} />
          <Route
            path="/admin/inactive-inventory"
            Component={InactiveInventory}
          />

          <Route
            path="/admin/inventory-items-category"
            Component={InventoryCategory}
          />
          <Route
            path="/admin/breakage-items-category"
            Component={BreakageCategory}
          />
          <Route path="/admin/generalSettings" Component={GeneralSettings} />

          <Route path="/admin/collect-fee/:id" Component={CollectFeeDetail} />
          <Route path="/admin/users/new" Component={AddUser} />
          <Route path="/admin/users/:id" Component={EditUser} />

          <Route path="admin/role/new" Component={AddRole} />
          <Route path="/admin/grade/new" Component={AddGrade} />
          <Route path="/admin/students/new" Component={AddStudent} />
          <Route path="/admin/branch/new" Component={AddBranch} />
          <Route path="/admin/house/new" Component={AddHouse} />
          <Route path="/admin/syllabus/new" Component={AddSyllabus} />
          <Route path="/admin/teachers/new" Component={AddTeacher} />
          <Route path="/admin/subject/new" Component={AddSubject} />
          <Route path="/admin/event/new" Component={AddEvent} />
          <Route path="/admin/exam/new" Component={AddExam} />
          <Route path="/admin/InventoryItem/new" Component={AddInventoryItem} />
          <Route path="/admin/manage-inventory/:id" Component={AddInventory} />
          <Route
            path="/admin/inventoryCategory/new"
            Component={AddInventoryCategory}
          />
          <Route
            path="/admin/breakageCategory/new"
            Component={AddBreakageCategory}
          />

          <Route
            path="/admin/reports/studentStrength"
            Component={StudentStrengthReport}
          />

          <Route path="/admin/mark" Component={Mark} />

          <Route
            path="/admin/mark/mark-detail/:exam/:grade/:subject"
            Component={MarkDetail}
          />

          <Route
            path="/admin/:grade/:status/:role/:branch/:session?"
            Component={StudentsDetailsGradeWise}
          />
          <Route path="/admin/mark/missing-dob" Component={MissingDob} />
          <Route path="/admin/mark/section" Component={Section} />
          <Route path="/admin/mark/gstStudent" Component={Student} />
          <Route
            path="/admin/teacherMissingSection"
            Component={TeacherMissingSection}
          />
          <Route
            path="/admin/teacherMissingSection/:id"
            Component={TeacherDetail}
          />
          <Route
            path="/admin/teachersamegradesection"
            Component={TeacherSameGradeSectionSub}
          />
          <Route
            path="/admin/teachersamegradesection/:id"
            Component={TeacherDetail}
          />
          <Route path="/admin/mark/feesRecord" Component={FeesRecord} />
          <Route path="/admin/mark/feesCorrection" Component={FeesCorrection} />
          <Route path="/admin/mark/fees-detail/:id" Component={FeesDetails} />
          <Route path="/admin/mark/attendance-log/:id" Component={Appraisal} />

          {/* for leave revert that detatct by cron */}
          <Route path="/admin/detactleaves" Component={DetactLeaves} />
          <Route path="/admin/leavedetact/:id" Component={TeacherLeaveDetail} />

          {/* for change student sattus  */}
          <Route path="/admin/changestauts/:id" Component={ChangeStatus} />

          <Route path="/admin/section/create" Component={CreateSection} />

          {/* for sessionManager */}
          <Route path="/admin/sessionManager" Component={SessionManager} />
          <Route path="/admin/sessionManager/:id" Component={EditSession} />

          {/* for less Amount students */}
          <Route
            path="/admin/lessamountstudent"
            Component={LessAmountStudent}
          />

          {/* for get backsession students 2022-2023 */}
          <Route
            path="/admin/backSessionStudents"
            Component={BackSessionStudents}
          />
          {/* for get backsession students 2024-2025 */}
          <Route
            path="/admin/upComingSessionStudents"
            Component={UpComingSessionStudents}
          />
          {/* for getting without section students */}
          <Route
            path="/admin/WithoutSectionStudnets"
            Component={DashboardSectionUpdate}
          />
          {/* for getting negative fee students */}
          <Route
            path="/admin/negativeFeeStudents"
            Component={NegativeFeeRecord}
          />
          {/* for getting negative fee students */}
          <Route
            path="/admin/teachersWithoutPaycode"
            Component={TeacherWithoutPaycode}
          />
          {/* get students that paid fee less than 3000/only */}
          <Route
            path="/admin/AllStudentsPaidLessAmount"
            Component={AllStudentsPaidLessAmount}
          />
          {/* get teachers assigned home work */}
          <Route
            path="/admin/getTeacherAssignedHomeWork/:id"
            Component={TeacherAssignedHomeWork}
          />
          {/* get teachers assigned class work */}
          <Route
            path="/admin/getTeacherAssignedClassWork/:id"
            Component={TeacherAssignedClassWork}
          />
          {/* get teachers assigned Activity */}
          <Route
            path="/admin/getTeacherAssignedActivity/:id"
            Component={TeacherAssignedActivity}
          />
          {/* get exams */}
          <Route path="/admin/getExams" Component={StudentExamMark} />
          {/* get exam detail */}
          <Route
            path="/admin/getStudentMarks/:exam_id/:common_grade/:subject"
            Component={StudentStudentSWithMarks}
          />
          {/* get teacher Appraisal points*/}
          <Route
            path="/admin/teacherAppraisalPoints/:id"
            Component={TeacherAppraisalPoints}
          />
          {/* get absent stduents for admin*/}
          <Route path="/admin/absentStudents" Component={AbsentStudents} />
          {/* get remarks of stduents for admin*/}
          <Route path="/admin/studentsRemark" Component={StudentsRemark} />
          {/* get absent teachers for admin*/}
          <Route path="/admin/absentTeachers" Component={AbsentTeachers} />
          {/* get StudentsAppreciation for admin*/}
          <Route
            path="/admin/studentsAppreciation"
            Component={StudentsAppreciation}
          />
          {/* get lateArriving teachers  for admin*/}
          <Route
            path="/admin/lateArrivalTeachers"
            Component={LateArrivalTeachers}
          />
          {/* get syllabus without end date   for admin*/}
          <Route
            path="/admin/syllabusWithoutEndDate"
            Component={SyllabusWithoutEndDate}
          />
          {/* get syllabus without chapters*/}
          <Route
            path="/admin/syllabusWithoutChaptes"
            Component={SyllabusWithoutChaptes}
          />
          {/* get students with due fee*/}
          <Route
            path="/admin/getStudentsDueFeeStudents"
            Component={DueFeeStudents}
          />
          {/* get teacher syllabus for copy checking*/}
          <Route
            path="/admin/getSyllabusForCopyChecking/:teacher_id"
            Component={TeacherSyllabusForCopyChecking}
          />
          <Route
            path="/admin/getSyllabusForCopyChecking/:teacher_id/:subject/:common_grade/:section/:syllabus_id"
            Component={CopyCheckingDetail}
          />
          {/* teacher Archive list */}
          <Route
            path="/admin/teacherArchiveList"
            Component={TeacherArchiveList}
          />
          {/* teacher AppraisalSettingForm */}
          <Route
            path="/admin/appraisalSettingForm/:id"
            Component={AppraisalSettingForm}
          />
          {/* students without sr number*/}
          <Route
            path="/admin/withOutSrNumberStudents"
            Component={WithOutSrNumberStudents}
          />
          {/* students with duplicate invoices*/}
          <Route
            path="/admin/duplicateInvoiceStudents"
            Component={DuplicateInvoiceStudents}
          />
          {/* student with duplicate invoices detail*/}
          <Route
            path="/admin/duplicateInvoiceDetail/:id"
            Component={DuplicateInvoiceDetail}
          />

          {/* student with backs session invoices*/}
          <Route
            path="/admin/backSessionInvoices"
            Component={BackSessionInvoices}
          />
          {/* student greater session*/}
          <Route
            path="/admin/greaterSessionStudents"
            Component={GreaterSessionStudents}
          />
          {/* student deleted invoices*/}
          <Route path="/admin/getDeletedInvoices" Component={DeletedInvoices} />
          {/* student paid Rte students*/}
          <Route path="/admin/getPaidRTEStudents" Component={PaidRTEStudents} />

          {/* Update student or teacher password*/}
          <Route path="/admin/updatePassword/:id" Component={UpdatePassword} />

          <Route path="/admin/student-mark" Component={StudentMark} />
        </>
      ) : (
        ""
      )}
    </Routes>
  );
}
