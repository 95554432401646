import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import GoBackButton from "../../component/GoBackButton";

const Grade = () => {
  const [branches, setBranches] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(false);
  const [branch, setBranch] = useState("all");

  const data = {
    name: "",
    branch: {
      name: "",
    },
    activity_fee_1: "",
    activity_fee_2: "",
    activity_fee_3: "",
    tution_fee: "",
    admission_fee: "",
    form_fee: "",
  };

  const [grades, setGrade] = useState([]);
  const [formData, setformData] = useState(data);

  const updateValue = (e) => {
    const field = e.target.id;
    const value = e.target.value;
    setformData({
      ...formData,
      [field]: value,
    });
  };

  const updateGrade = () => {
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/admin/grades`, formData)
      .then((response) => {
        if (response?.data.success) {
          window.notif({
            msg: "<b>Woohoo!!</b> Grade Updated Successfully",
            type: "success",
          });
          setformData(data);

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          window.notif({
            msg: response?.data.error,
            type: "error",
            position: "center",
          });
        }
      });
  };

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/grades`, {
        selectedSession,
        branch,
      })
      .then((response) => {
        console.log("response", response);
        const { grades, sessions, branches } = response.data;
        setGrade(grades);
        setSessions(sessions);
        setBranches(branches);
      });
  }, [branch, selectedSession]);

  const getTotalAmount = (fee_1, fee_2, fee_3, admsn_fee, tutn_fee) => {
    let activity_fee_1 = fee_1 ? parseInt(fee_1) : 0;
    let activity_fee_2 = fee_2 ? parseInt(fee_2) : 0;
    let activity_fee_3 = fee_3 ? parseInt(fee_3) : 0;
    let admission_fee = admsn_fee ? parseInt(admsn_fee) : 0;
    let tution_fee = tutn_fee ? parseInt(tutn_fee) * 12 : 0;

    let total =
      activity_fee_1 +
      activity_fee_2 +
      activity_fee_3 +
      admission_fee +
      tution_fee;
    console.log("total", total);
    return total;
  };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <GoBackButton />
            
              <Link
                type="button"
                to="/admin/grade/new"
                class="btn btn-danger text-light"
              >
                <i class="fe fe-user-plus"></i>&nbsp;Add Grade
              </Link>
            </div>
            <div> 
            <h1 className="page-title">Grades and Fee Structure</h1>
              <p>This grade and fee structure section will give you ability to set fees acording to grade for specific branch and session.</p></div>
            <div className="row">
              <div className="mb-3 col-6">
              <label
                  htmlFor="example-session"
                  className="col-6 form-label"
                >
                  Acadmic Session
                </label>
                <select
                  className="form-control"
                  name=""
                  value={selectedSession}
                  id="selectedSession"
                  onChange={(e) => {
                    setSelectedSession(e.target.value);
                  }}
                >
                  <option value="">Select Session</option>
                  {sessions.map((session) => {
                    return (
                      <option value={session.session_name}>
                        {session.session_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="mb-3 col-6">
              <label
                  htmlFor="example-session"
                  className="col-6 form-label"
                >
                  Branch
                </label>
                <select
                  className="form-control"
                  name=""
                  value={branch}
                  id="branch"
                  onChange={(e) => {
                    setBranch(e.target.value);
                  }}
                >
                  <option value="all">All Branches</option>
                  {branches.map((branch) => (
                    <option value={branch._id.toString()}>{branch.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                {/* <th>
                                  <b>ID</b>
                                </th> */}
                                <th>
                                  <b>Grade</b>
                                </th>
                                <th>
                                  <b>Branch</b>
                                </th>
                                <th>
                                  <b>Annual - I</b>
                                </th>
                                <th>
                                  <b>Annual - II</b>
                                </th>
                                <th>
                                  <b>Annual - III</b>
                                </th>
                                <th>
                                  <b>Tuition Fee</b>
                                </th>
                                <th>
                                  <b>Admission Fee</b>
                                </th>
                                <th>
                                  <b>Total Amount</b>
                                </th>
                                <th>
                                  <b>Action</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {grades.map((grade) => {
                                return (
                                  <tr key={grade._id}>
                                    {/* <td>{grade._id}</td> */}
                                    <td>{grade.name}</td>
                                    <td>{grade.branch.name}</td>
                                    <td>&#8377;{grade.activity_fee_1}</td>
                                    <td>&#8377;{grade.activity_fee_2}</td>
                                    <td>&#8377;{grade.activity_fee_3}</td>
                                    <td>&#8377;{grade.tution_fee}</td>
                                    <td>&#8377;{grade.admission_fee}</td>
                                    <td>
                                      &#8377;
                                      {getTotalAmount(
                                        grade.activity_fee_1,
                                        grade.activity_fee_2,
                                        grade.activity_fee_3,
                                        grade.admission_fee,
                                        grade.tution_fee
                                      )}
                                    </td>
                                    <td>
                                      <a
                                        class="modal-effect btn btn-sm btn-primary"
                                        onClick={() => {
                                          setformData(grade);
                                        }}
                                        data-bs-toggle="modal"
                                        href="#modaldemo8"
                                      >
                                        Edit
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="modaldemo8">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">
                {formData.branch.name} - {formData.name}
              </h6>
              <button
                aria-label="Close"
                className="btn-close"
                data-bs-dismiss="modal"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Form Fee</label>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Form Fee"
                    value={formData.form_fee}
                    id="form_fee"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Admission Fee</label>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Admission Fee"
                    value={formData.admission_fee}
                    id="admission_fee"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Annual Fee - I</label>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Annual Fee I"
                    value={formData.activity_fee_1}
                    id="activity_fee_1"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Annual Fee - II</label>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Annual Fee II"
                    value={formData.activity_fee_2}
                    id="activity_fee_2"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Annual Fee - III</label>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Annual Fee III"
                    value={formData.activity_fee_3}
                    id="activity_fee_3"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Tuition Fee</label>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Tuition Fee"
                    value={formData.tution_fee}
                    id="tution_fee"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-danger"
                onClick={() => {
                  updateGrade();
                }}
              >
                Update Grade
              </button>{" "}
              <button className="btn btn-light" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Grade;
