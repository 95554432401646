import React from "react";
import {
  getCurrentDate,
  getCurrentDateTime,
  getCurrentFullSession,
  getLoggedInUserBranchInfo,
  getSelectedOptionNameByID,
} from "../../utility/utilityFunctions";
import "../../App.css";
import placeholder from "../../assets/images/brand/placeholder.png";

const AdmissionForm = ({ formData, profilePicInAdmissionForm }) => {
  console.log("profilePicInAdmissionForm", profilePicInAdmissionForm);
  const branchDetail = getLoggedInUserBranchInfo();

  function getFormattedDate(dateString) {
    const date = new Date(dateString);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const formattedDate = date.toLocaleDateString("en-US", options);
    console.log(formattedDate); // Output: "March 31, 2023"
    return formattedDate;
  }
  
  return (
    <>
      <div>
      <div id="admission_form_for_user" className="formDiv my-2">
        <div className="d-flex" style={{"marginTop" : "190px"}}>
          <div style={{ width: "90%", lineHeight: "2" }}>
            <div className="d-flex">
              <div className="d-flex align-items-center">
                <label style={{ "margin-right": "10px" }}>Day Boarding</label>
                <span>
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    checked={formData.boarding}
                  />
                </span>
              </div>
              <div className="d-flex align-items-center">
                <label style={{ "margin-right": "10px" }}>Day Scholar</label>
                <span>
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    checked={!formData.boarding}
                  />
                </span>
              </div>
            </div>
            <div>
              <label htmlFor="name" style={{ width: "7%" }}>
                Name
              </label>
              <input
                type="text"
                className="student-form-input"
                style={{ width: "92%" }}
                value={formData.name}
              />
            </div>
            <div className="d-flex justify-content-between">
              <label htmlFor="name" style={{ width: "7%" }}>
                Sex
              </label>
              <div
                className="d-flex justify-content-between"
                style={{ width: "30%" }}
              >
                <div className="d-flex align-items-center">
                  <label style={{ "margin-right": "10px" }}>Male</label>
                  <span>
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      checked={formData.gender == "male"}
                    />
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <label style={{ "margin-right": "10px" }}>Female</label>
                  <span>
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      checked={formData.gender == "female"}
                    />
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <label style={{ "margin-right": "10px" }}>Other</label>
                  <span>
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      checked={formData.gender == "other"}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="applied_for" style={{ width: "19%" }}>
                Grade Applied For
              </label>
              <input
                type="text"
                className="student-form-input"
                style={{ width: "80%" }}
                value={getSelectedOptionNameByID("grade")}
              />
            </div>
          </div>
          <div>
            <img
              width={"200px"}
              src={
                profilePicInAdmissionForm
                  ? profilePicInAdmissionForm
                  : placeholder
              }
              id="preview"
            />
          </div>
        </div>
        <div className="d-flex mt-2">
          <div style={{ width: "50%" }}>
            <label htmlFor="name" style={{ width: "20%" }}>
              Date Of Birth
            </label>
            <input
              type="text"
              className="student-form-input"
              style={{ width: "80%" }}
              value={ formData.dob ? getFormattedDate(formData.dob) : ""}
            />
          </div>
          <div style={{ width: "50%" }}>
            <label htmlFor="name" style={{ width: "20%" }}>
              Nationality
            </label>
            <input
              type="text"
              className="student-form-input"
              style={{ width: "80%" }}
              value={formData.nationality}
            />
          </div>
        </div>
        <div className="d-flex mt-2">
          <div style={{ width: "50%" }}>
            <label htmlFor="name" style={{ width: "35%" }}>
              Proposed date of entry
            </label>
            <input
              type="text"
              className="student-form-input"
              style={{ width: "65%" }}
              value={formData.proposed_doe ? getFormattedDate(formData.proposed_doe) : ""}
            />
          </div>
          <div style={{ width: "50%" }}>
            <label htmlFor="name" style={{ width: "50%" }}>
              Age at proposed date of entry
            </label>
            <input
              type="text"
              className="student-form-input"
              style={{ width: "45%" }}
              value={formData.age_at_proposed_doe}
            />
          </div>
        </div>
        <div className="d-flex mt-2">
          <div style={{ width: "100%" }}>
            <label htmlFor="name" style={{ width: "15%" }}>
              Permanent Address
            </label>
            <input
              type="text"
              className="student-form-input"
              style={{ width: "85%" }}
              value={formData.address}
            />
          </div>
        </div>
        <div className="d-flex mt-2">
          <div style={{ width: "100%" }}>
            <label htmlFor="name" style={{ width: "15%" }}>
              Home Tel.
            </label>
            <input
              type="text"
              className="student-form-input"
              style={{ width: "85%" }}
              value={formData.home_tel}
            />
          </div>
        </div>
        <div className="mt-5" style={{ lineHeight: "2" }}>
          <b>Family Details:</b>
          <div className="mt-2">
            <label htmlFor="applied_for" style={{ width: "14%" }}>
              Name Of Father/Guardian
            </label>
            <input
              type="text"
              className="student-form-input"
              style={{ width: "86%" }}
              value={formData.father_name}
            />
          </div>
          <div className="mt-2">
            <label htmlFor="applied_for" style={{ width: "10%" }}>
              Occupation
            </label>
            <input
              type="text"
              className="student-form-input"
              style={{ width: "90%" }}
              value={formData.father_occupation}
            />
          </div>
          <div className="mt-2">
            <label htmlFor="applied_for" style={{ width: "11%" }}>
              Address (Off.)
            </label>
            <input
              type="text"
              className="student-form-input"
              style={{ width: "89%" }}
              value={formData.father_office_address}
            />
          </div>
          <div className="d-flex mt-2">
            <div style={{ width: "40%" }}>
              <label htmlFor="name" style={{ width: "14%" }}>
                Email
              </label>
              <input
                type="text"
                className="student-form-input"
                style={{ width: "80%" }}
                value={formData.father_email}
              />
            </div>
            <div style={{ width: "30%" }}>
              <label htmlFor="name" style={{ width: "20%" }}>
                Off. Tel
              </label>
              <input
                type="text"
                className="student-form-input"
                style={{ width: "80%" }}
                value={formData.father_office_tel}
              />
            </div>
            <div style={{ width: "30%" }}>
              <label htmlFor="name" style={{ width: "20%" }}>
                Mobile
              </label>
              <input
                type="text"
                className="student-form-input"
                style={{ width: "80%" }}
                value={formData.mobile}
              />
            </div>
          </div>
          <div className="mt-2">
            <label htmlFor="applied_for" style={{ width: "14%" }}>
              Name Of Mother
            </label>
            <input
              type="text"
              className="student-form-input"
              style={{ width: "86%" }}
              value={formData.mother_name}
            />
          </div>
          <div className="mt-2">
            <label htmlFor="applied_for" style={{ width: "10%" }}>
              Occupation
            </label>
            <input
              type="text"
              className="student-form-input"
              style={{ width: "90%" }}
              value={formData.mother_occupation}
            />
          </div>
          <div className="mt-2">
            <label htmlFor="applied_for" style={{ width: "11%" }}>
              Address (Off.)
            </label>
            <input
              type="text"
              className="student-form-input"
              style={{ width: "89%" }}
              value={formData.mother_office_address}
            />
          </div>
          <div className="d-flex mt-2">
            <div style={{ width: "40%" }}>
              <label htmlFor="name" style={{ width: "14%" }}>
                Email
              </label>
              <input
                type="text"
                className="student-form-input"
                style={{ width: "80%" }}
                value={formData.mother_email}
              />
            </div>
            <div style={{ width: "30%" }}>
              <label htmlFor="name" style={{ width: "20%" }}>
                Off. Tel
              </label>
              <input
                type="text"
                className="student-form-input"
                style={{ width: "80%" }}
                value={formData.mother_office_tel}
              />
            </div>
            <div style={{ width: "30%" }}>
              <label htmlFor="name" style={{ width: "20%" }}>
                Mobile
              </label>
              <input
                type="text"
                className="student-form-input"
                style={{ width: "80%" }}
                value={formData.mother_mobile}
              />
            </div>
          </div>
        </div>
        <div className="mt-5" style={{ lineHeight: "2" }}>
          <b>Brother's/Sister's Details:</b>
          <div className="d-flex justify-content-around mt-2">
            <div
              style={{ width: "30%" }}
              className="d-flex flex-column align-items-center"
            >
              <label htmlFor="">Names</label>
              <div style={{ width: "100%" }}>
                <input
                  style={{ width: "100%" }}
                  className="student-form-input"
                  type="text"
                  value={formData.sibling_1_name}
                />
              </div>
              <div style={{ width: "100%" }}>
                <input
                  style={{ width: "100%" }}
                  className="student-form-input"
                  type="text"
                  value={formData.sibling_2_name}
                />
              </div>
            </div>
            <div
              style={{ width: "30%" }}
              className="d-flex flex-column align-items-center"
            >
              <label htmlFor="">Age</label>
              <div style={{ width: "100%" }}>
                <input
                  style={{ width: "100%" }}
                  className="student-form-input"
                  type="text"
                  value={formData.sibling_1_age}
                />
              </div>
              <div style={{ width: "100%" }}>
                <input
                  style={{ width: "100%" }}
                  className="student-form-input"
                  type="text"
                  value={formData.sibling_2_age}
                />
              </div>
            </div>
            <div
              style={{ width: "30%" }}
              className="d-flex flex-column align-items-center"
            >
              <label htmlFor="">School Attended</label>
              <div style={{ width: "100%" }}>
                <input
                  style={{ width: "100%" }}
                  className="student-form-input"
                  type="text"
                  value={formData.sibling_1_schoolAttendant}
                />
              </div>
              <div style={{ width: "100%" }}>
                <input
                  style={{ width: "100%" }}
                  className="student-form-input"
                  type="text"
                  value={formData.sibling_2_schoolAttendant}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5" style={{ lineHeight: "2" }}>
          <b>Applicant's Details:</b>
          <div className="mt-2">
            <label htmlFor="applied_for" style={{ width: "35%" }}>
              Name and address of previous school attended
            </label>
            <input
              type="text"
              value={formData.previous_school}
              className="student-form-input"
              style={{ width: "65%" }}
            />
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default AdmissionForm;
