import axios from "axios";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar";
import { getToken, getCurrentFullSession } from "../../utility/utilityFunctions";
import { Link } from "react-router-dom";

function StudentExamMark() {
  const { id } = useParams();
  const [exams, setExams] = useState(null);
  const [session, setSession] = useState(getCurrentFullSession());
  const [sessions, setSessions] = useState([]);
  let count = 1;

  useEffect(() => {
    const loaderElement = document.getElementsByClassName("loader")[0];
    if (loaderElement) {
      // If the loader element exists, you can modify its display style
      loaderElement.style.display = "block"; // or "block" or any other desired value
    }
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/admin/getStudentMarks`;
    axios
      .post(apiUrl, {
        session
      },{
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { allStudentMarkWithExam, sessions } = response.data;
        setExams(allStudentMarkWithExam);
        setSessions(sessions);
        // console.log("allStudentMarkWithExam", allStudentMarkWithExam);
        if (loaderElement) {
          // If the loader element exists, you can modify its display style
          loaderElement.style.display = "none"; // or "block" or any other desired value
        }
      });
  }, [session]);

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="col-sm-12 card pb-2 pt-2">
              <div className="col-sm-12">
                <div className="page-header">
                  <h1 className="page-title p-2">Exams List</h1>
                  {/* <div class="alert alert-info" role="alert">
                    Students those were promoted from last class and paid less
                    than 3000 INR.
                  </div> */}
                  <div className="col-sm-3 mx-2">
                    <select
                      className="form-control"
                      name=""
                      value={session}
                      onChange={(e) => {
                        setSession(e.target.value);
                      }}
                    >
                      {sessions.map((session) => {
                        return (
                          <option value={session.session_name}>
                            {session.session_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-sm-4">
                <div className="loader" style={{ display: "none" }}>
                  <h2>Hold On !!</h2>
                  <h3>We are generating report....</h3>
                  <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              {exams ? (
                <div className="row ">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="table-responsive">
                              <table
                                className="table table-bordered border text-nowrap mb-0"
                                id="basic-edit"
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <b>Sr. No</b>
                                    </th>
                                    <th>
                                      <b>Exam</b>
                                    </th>
                                    <th>
                                      <b>Exam Marks</b>
                                    </th>
                                    <th>
                                      <b>Grade</b>
                                    </th>

                                    <th>
                                      <b>Subject</b>
                                    </th>
                                    <th>
                                      <b>Students</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {exams.map((exam) => {
                                    return (
                                      <React.Fragment key={exam._id}>
                                        {/* First <tr> for basic teacher information */}

                                        <tr>
                                          <td>{count++}</td>

                                          <td>
                                            <Link
                                              to={`/admin/getStudentMarks/${exam?.exam_id?._id}/${exam?.common_grade._id}/${exam?.subject_id._id}`}
                                            >
                                              {exam?.exam_id?.name}
                                            </Link>
                                          </td>

                                          <td>{exam?.total_marks}</td>

                                          <td>{exam?.common_grade?.name}</td>
                                          <td>{exam?.subject_id?.name}</td>
                                          <td>{exam?.students.length}</td>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default StudentExamMark;
