import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  getToken,
  timezoneDateToddMMyyyy,
} from "../../utility/utilityFunctions";
import { getDate } from "../../helpers/DateHelper";

const EditEventForm = () => {
  const [eventFor, setEventFor] = useState("");

  const params = useParams();
  const [eventName, setEventName] = useState("");
  const [date, setDate] = useState("");
  const [branches, setBranches] = useState([]);
  const [grades, setGrades] = useState([]);
  const [description, setDescription] = useState("");
  const [isHoliday, setIsHoliday] = useState(false);
  const [screenData, setScreenData] = useState({
    grades: [],
    branches: [],
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/eventAddScreen`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { branches, grades } = response.data;
        setScreenData({
          ...screenData,
          grades,
          branches,
        });
      });

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/event/${params.id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { event } = response.data;
console.log("response.data:",event)
        setEventName(event[0].name);
        
        const date = new Date(event[0].date);

        // Get the year, month, and day components
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(date.getDate()).padStart(2, "0");

        // Create the formatted date string in "yyyy-MM-dd" format
        const formattedDate = `${year}-${month}-${day}`;
        //
        console.log("formattedDate:", formattedDate);

        setDate(formattedDate);

        setBranches(event[0].branches);
        if (event.for_all_branches) {
          setBranches(["all_branches"]);
        }
        setGrades(event[0].grades);
        setDescription(event[0].description);
        setEventFor(event[0].eventFor)
        setIsHoliday(event[0].isHoliday);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      id: params.id,
      name: eventName,
      date,
      branches: branches.includes("all_branches") ? [] : branches,
      grades: grades.includes("all_grades") ? [] : grades,
      description,
      for_all_branches: branches.includes("all_branches") ? true : false,
      for_all_grades: grades.includes("all_grades") ? true : false,
      eventFor: eventFor,
      isHoliday,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/event/edit`, data, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        if (response?.data.success) {
          window.notif({
            msg: "<b>Woohoo!!</b> Event Updated Successfully",
            type: "success",
          });
        } else {
          window.notif({
            msg: response?.data.error,
            type: "error",
            position: "center",
          });
        }
      });
  };

  const handleBranchesChange = (e) => {
    const options = e.target.options;
    const selectedBranches = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedBranches.push(options[i].value);
      }
    }
    setBranches(selectedBranches);
  };

  const handleGradesChange = (e) => {
    const options = e.target.options;
    const selectedGrades = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedGrades.push(options[i].value);
      }
    }
    setGrades(selectedGrades);
  };
  const handleEventForChange = (e) => {
    setEventFor(e.target.value);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="eventName">Event Name</label>
        <input
          type="text"
          className="form-control"
          id="eventName"
          value={eventName}
          onChange={(e) => setEventName(e.target.value)}
          required
        />
      </div>
      <div className="d-flex">
        <div className="form-group col-md-3">
          <label htmlFor="date">Date</label>
          <input
            type="date"
            className="form-control"
            id="date"
            // min={new Date().toISOString().split("T")[0]}
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="branches">Branches</label>
          <select
            multiple
            className="form-control"
            id="branches"
            value={branches}
            onChange={handleBranchesChange}
            required
          >
            <option value="all_branches" selected>
              All Branches
            </option>
            {screenData.branches.map((branch) => (
              <option value={branch._id}>{branch.name}</option>
            ))}
          </select>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="grades">Grades</label>
          <select
            multiple
            className="form-control"
            id="grades"
            value={grades}
            onChange={handleGradesChange}
            required
          >
            <option value="all_grades" selected>
              All Grades
            </option>
            {screenData.grades.map((grade) => (
              <option value={grade._id}>{grade.name}</option>
            ))}
          </select>
        </div>
        {/* eventFor */}
        <div className="form-group col-md-2">
          <label htmlFor="eventFor">Event For</label>
          <select
            className="form-control"
            id="eventFor"
            value={eventFor}
            onChange={handleEventForChange}
            required
          >
            <option value="">Select Event For</option>
            <option value="T">Teachers</option>
            <option value="S">Students</option>
            <option value="TS">Teachers&Students</option>
          </select>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="isHoliday">Is Holiday</label>
          <div class="material-switch col-md-9">
            <input
              id="form_fee"
              name="form_fee"
              type="checkbox"
              checked={isHoliday}
              onChange={(e) => {
                setIsHoliday(e.target.checked);
              }}
            />
            <label for="form_fee" class="label-success"></label>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="description">Description</label>
        <textarea
          className="form-control"
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <button type="submit" className="btn btn-primary">
        Update Event
      </button>
    </form>
  );
};
export default EditEventForm;
