import axios from "axios";
import * as XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../../component/Sidebar";
import { getToken, timezoneDateToddMMyyyy } from "../../../utility/utilityFunctions";

const StudentReviewReport = () => {
  const today = new Date().toISOString().substr(0, 10);

  const [studentOneReports, setStudentReviewReports] = useState([]);

  const [branches, setBranches] = useState([]);
  const [reviewCount, setReviewCount] = useState(1);
  const [commonGrades, setCommonGrades] = useState([]);

  const [branch, setBranch] = useState("all");
  const [grade, setGrade] = useState("all");
  const [reviewType, setReviewType] = useState("R");

  const [totalRecords, setRecords] = useState(0);

  const [filterClicked, setFilterClicked] = useState(0);

  const [start_date, setStartDate] = useState(today);
  const [end_date, setEndDate] = useState(today);

  const [showDatesOfId, setShowDatesOfId] = useState(null);

  useEffect(() => {
    document.getElementsByClassName("table-responsive")[0].style.display =
      "none";

    document.getElementsByClassName("loader")[0].style.display = "block";

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/studentReviewReportScreen`,
        {
          branch,
          grade,
          reviewCount,
          start_date,
          end_date,
          reviewType,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        document.getElementsByClassName("loader")[0].style.display = "none";

        document.getElementsByClassName("table-responsive")[0].style.display =
          "block";
        const { studentOneReport, branches, commonGrades } = response.data;
        setBranches(branches);
        setCommonGrades(commonGrades);
        setStudentReviewReports(studentOneReport);
        setRecords(studentOneReport?.length ? studentOneReport?.length : 0);
      });
  }, [filterClicked]);

  const handleExport = () => {
    const table = document.getElementById("myTable");
    const workbook = XLSX.utils.table_to_book(table);
    XLSX.writeFile(workbook, "student.xlsx");
  };

  // const onUpdate = (e) => {
  //   const key = e.target.id;
  //   const value = e.target.value;
  //   console.log(key, value);
  // };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>

            <div style={{ backgroundColor: "white", padding: "25px" }}>
              <div className="">
                <h1
                  className="page-title"
                  style={{ marginLeft: "12px", marginBottom: "15px" }}
                >
                  Student Reviews Report
                </h1>
                <div className="col-md-8 d-flex flex-wrap">
                  <div className="">
                    <select
                      className="form-control"
                      name=""
                      value={branch}
                      id="branch"
                      onChange={(e) => {
                        console.log(e.target);
                        setBranch(e.target.value);
                      }}
                    >
                      <option value="all">All Branches</option>
                      {branches.map((branch) => (
                        <option value={branch._id.toString()}>
                          {branch.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="">
                    <select
                      className="form-control"
                      name=""
                      value={grade}
                      id="grade"
                      // disabled={}
                      onChange={(e) => {
                        setGrade(e.target.value);
                      }}
                    >
                      <option value="all">All Grades</option>
                      {commonGrades.map((data) => (
                        <option value={data._id}>{data.name}</option>
                      ))}
                    </select>
                  </div>

                  <div className="">
                    <select
                      className="form-control"
                      name=""
                      value={reviewType}
                      id="reviewType"
                      onChange={(e) => {
                        setReviewType(e.target.value);
                      }}
                    >
                      <option value="R">Remarks</option>
                      <option value="A">Appreciations</option>
                    </select>
                  </div>

                  <div className="">
                    <input
                      type="number"
                      className="form-control"
                      name="reviewCount"
                      value={reviewCount}
                      placeholder="Review Count"
                      id="reviewCount"
                      onChange={(e) => {
                        setReviewCount(e.target.value);
                      }}
                    >
                    </input>
                  </div>

                </div>
              </div>

              <div className="mt-3">
                <div className="col-md-12 d-flex">
                  <div className="col-md-6">
                    <label htmlFor="start_date">Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                      value={start_date}
                      name=""
                      id="start_date"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="end_date">End Date</label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => {
                        setEndDate(e.target.value);
                      }}
                      value={end_date}
                      name=""
                      id="end_date"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-3" style={{ padding: "5px 12px" }}>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={(e) => {
                    setFilterClicked((prevCount) => prevCount + 1);
                  }}
                >
                  <i className="side-menu__icon fe fe-filter" /> Filter
                </button>
                <button
                  className="btn btn-sm btn-green"
                  style={{ marginLeft: "5px" }}
                  onClick={handleExport}
                >
                  Export Data
                </button>
              </div>

              <div style={{ padding: "15px" }}>
                <div className="mt-3">Total Records : {totalRecords}</div>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="loader" style={{ display: "none" }}>
                          <h2>Hold On !!</h2>
                          <h3>We are generating report....</h3>
                          <div
                            className="spinner-border text-danger"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                        <div className="table-responsive">
                          {totalRecords > 0 ? (
                            <>
                              <h1 className="my-5">Filtered Results</h1>
                              <table
                                className="table table-bordered border text-nowrap mb-0"
                                id="myTable"
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <b>Name</b>
                                    </th>
                                    <th>
                                      <b>Father/Guardian Name</b>
                                    </th>
                                    <th>
                                      <b>Mobile</b>
                                    </th>
                                    <th>
                                      <b>Branch</b>
                                    </th>
                                    <th>
                                      <b>Grade</b>
                                    </th>
                                    <th>
                                      <b>Count</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {studentOneReports.map((studentOneReport) => {
                                    return (
                                      <tr key={studentOneReport._id}>
                                        <td>
                                          <Link
                                            target="blank"
                                            to={`/admin/students/${studentOneReport._id}`}
                                          >
                                            {studentOneReport.name}
                                          </Link>
                                        </td>
                                        <td>{studentOneReport.father_name}</td>
                                        <td>{studentOneReport.mobile}</td>
                                        <td>{studentOneReport.branch}</td>
                                        <td>{studentOneReport.grade}</td>
                                        <td>
                                          <a
                                            href="#"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setShowDatesOfId( (showDatesOfId === "data" + studentOneReport?._id)?null: "data" + studentOneReport?._id);
                                            }}
                                          >
                                            {studentOneReport.count} 
                                          </a>
                                          {showDatesOfId === "data" + studentOneReport?._id && (
                                            <>
                                              <p></p>
                                              {studentOneReport?.history.map((history) => {
                                                return (
                                                  <p className="mb-0">{history?.date ? timezoneDateToddMMyyyy(history.date) : ""} - {history?.review}</p>
                                                );
                                              })}
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </>
                          ) : (
                            <h1>No record found.</h1>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentReviewReport;
