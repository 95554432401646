import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import {
  getCurrentSession,
  getToken,
  timezoneDateToddMMyyyy,
} from "../../utility/utilityFunctions";
import placeholder from "../../assets/images/brand/placeholder.png";
import GoBackButton from "../../component/GoBackButton";
const LessAmountStudent = () => {
  const [students, setStudent] = useState([]);
  const [showDatesOfId, setShowDatesOfId] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state
  let count = 1;
  useEffect(() => {
    const loaderElement = document.getElementsByClassName("loader")[0];
    if (loaderElement) {
      // If the loader element exists, you can modify its display style
      loaderElement.style.display = "block"; // or "block" or any other desired value
    }
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/getlessAmountStudent`,

        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            // "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        const { studentData } = response.data;
        console.log("studentData:", studentData);
        setStudent(studentData);
        if (loaderElement) {
          // If the loader element exists, you can modify its display style
          loaderElement.style.display = "none"; // or "block" or any other desired value
        }
      });
  },[]);

  const promteStudent = () => {
    setLoading(true); // Set loading to true when promoting students
    if (window.confirm("You really want to promote students ?")) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/admin/setStatusOfPromoted`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          if (response?.data.success) {
            window.notif({
              msg: "<b>Woohoo!!</b> Student Promoted Successfully",
              type: "success",
            });
          } else {
            window.notif({
              msg: response?.data.error,
              type: "error",
              position: "center",
            });
          }
        })
        .finally(() => {
          setLoading(false); // Reset loading to false after receiving the response
        });
    }
  };
  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <GoBackButton />
              {/* <h1 className="page-title">Teachers</h1> */}
            </div>
            <div className="row ">
              <div class="col-sm-12">
                <div className="loader" style={{ display: "none" }}>
                  <h2>Hold On !!</h2>
                  <h3>We are generating report....</h3>
                  <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Sr. No</b>
                                </th>

                                <th>
                                  <b>Name</b>
                                </th>

                                <th>
                                  <b> Father Name</b>
                                </th>
                                <th>
                                  <b>Phone</b>
                                </th>
                                <th>
                                  <b>Grade</b>
                                </th>
                                <th>
                                  <b>Branch</b>
                                </th>
                                <th>
                                  <b>Total Paid</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {students.map((student) => {
                                return (
                                  <React.Fragment key={student?.student_id}>
                                    {/* First <tr> for basic teacher information */}

                                    <tr>
                                      <td>{count++}</td>

                                      <td>
                                        <Link
                                          to={`/admin/collect-fee/${student?.student_id}`}
                                        >
                                          {student?.student_name}
                                        </Link>
                                      </td>
                                      <td>{student?.father_name}</td>
                                      <td>{student.mobile}</td>
                                      <td>{student.student_grade}</td>
                                      <td>{student.branch}</td>

                                      <td>{student.total_paid}</td>
                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                            </tbody><br></br>
                            <div className="col-md-2">
                        <button
                          type="button"
                          className="btn ripple btn-danger"
                          onClick={() => {
                            promteStudent();
                          }}
                        >
                           {loading ? "Promoting..." : "Promote Students"}
                          
                        </button>
                      </div>
                          </table>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LessAmountStudent;
