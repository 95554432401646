import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "react-paginate";

import Sidebar from "../../component/Sidebar";
import {
  getCurrentSession,
  getToken,
  timezoneDateToddMMyyyy,
} from "../../utility/utilityFunctions";
import placeholder from "../../assets/images/brand/placeholder.png";
import GoBackButton from "../../component/GoBackButton";
const Teachers = () => {
  const [teachersCounts, setTeachersCounts] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [teachers, setTeachers] = useState([]);
  const [search, setSearch] = useState("");
  const [showDatesOfId, setShowDatesOfId] = useState(null);
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const [teacherDetail, setTeacherDetail] = useState(null);
  const navigate = useNavigate();
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };
  const [branch, setBranch] = useState("all");
  const [branches, setBranches] = useState([]);
 // let count = 1;
  useEffect(() => {
    const loaderElement = document.getElementsByClassName("loader")[0];
    if (loaderElement) {
      // If the loader element exists, you can modify its display style
      loaderElement.style.display = "block"; // or "block" or any other desired value
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/teachers`,
        {
          search: search,
          currentPage, // Add currentPage here
          itemsPerPage,
          branch,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            // "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        const { teachers, totalTeachers, branches } = response.data;
        console.log("teachers:", teachers);
        setTeachersCounts(totalTeachers);
        setTeachers(teachers);
        setBranches(branches);

        if (loaderElement) {
          // If the loader element exists, you can modify its display style
          loaderElement.style.display = "none"; // or "block" or any other desired value
        }
      });
  }, [search, currentPage, branch]);
  // After you set your students data in the first useEffect
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const teachersToDisplay = teachers;
  const [count, setCount] = useState(1);
  const setLeaves = () => {
    console.log("teacherId:", selectedTeacherId);
    if (window.confirm("You really want to save leaves ?")) {
      const medical_leaves = document.getElementById("medical_leaves").value;
      const casual_leaves = document.getElementById("casual_leaves").value;
      console.log("medical_leaves:", medical_leaves);
      console.log("casual_leaves:", casual_leaves);

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/admin/teacher/updateLeaves`,
          {
            CL: casual_leaves,
            ML: medical_leaves,
            user_id: selectedTeacherId,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          document.getElementById("transfer-popup-close-btn").click();
          window.notif({
            msg: "<b>Woohoo!!</b> teacher leaves assigned Successfully",
            type: "success",
          });
          navigate(`/admin/teachers/`);
        });
    }
  };
  console.log("teacherDetail:", teacherDetail);
  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <GoBackButton />
              {/* <h1 className="page-title">Teachers</h1> */}
              <div className="col-md-4 d-flex align-items-center">
                {" "}
                <input
                  className="form-control flex-grow-1"
                  type="text"
                  name="search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  placeholder="Search"
                />
                {localStorage.getItem("user") &&
                JSON.parse(localStorage.getItem("user")).role === "admin" ? (
                  <>
                    <div className="mx-2 flex-shrink-0">
                      <select
                        className="form-control"
                        name=""
                        value={branch}
                        id="branch"
                        onChange={(e) => {
                          setBranch(e.target.value);
                        }}
                      >
                        <option value="all">All Branches</option>
                        {branches.map((branch) => (
                          <option value={branch._id.toString()}>
                            {branch.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <Link
                type="button"
                to="/admin/teachers/new"
                class="btn btn-danger text-light"
              >
                <i class="fe fe-user-plus"></i>&nbsp;Add Teacher
              </Link>
            </div>
            <div className="row ">
              <div class="col-sm-12">
                <div className="loader" style={{ display: "none" }}>
                  <h2>Hold On !!</h2>
                  <h3>We are generating report....</h3>
                  <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Sr. No</b>
                                </th>
                                <th>
                                  <b>Profile Pic</b>
                                </th>
                                <th>
                                  <b>Name</b>
                                </th>
                                <th>
                                  <b>Phone</b>
                                </th>
                                {localStorage.getItem("user") &&
                                JSON.parse(localStorage.getItem("user"))
                                  .role === "admin" ? (
                                  <>
                                    <th>
                                      <b>Branch</b>
                                    </th>
                                  </>
                                ) : (
                                  <></>
                                )}
                                <th>
                                  <b>DOJ</b>
                                </th>
                                <th>
                                  <b>Total Srocre</b>
                                </th>
                                <th>
                                  <b>Received Score</b>
                                </th>
                                <th>
                                  <b>Class Teacher</b>
                                </th>
                                <th>
                                  <b>Subjects</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                            {teachersToDisplay.map((teacher, index) => {
                              const serialNumber = startIndex + index+1;
                                return (
                                  <React.Fragment key={teacher?._id}>
                                    {/* First <tr> for basic teacher information */}

                                    <tr>
                                      <td>{serialNumber}</td>
                                      <td>
                                        {teacher.user_id.profile_pic ? (
                                          <>
                                            <img
                                              width={"70px"}
                                              src={
                                                process.env.REACT_APP_BASE_URL +
                                                "/admin/images/" +
                                                teacher.user_id.profile_pic
                                              }
                                              alt={teacher.user_id.name}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              width={"70px"}
                                              src={placeholder}
                                            />
                                          </>
                                        )}
                                      </td>
                                      {/* <td>
                                        <Link to={`${teacher?.user_id._id}`}>
                                          <b>{teacher?.user_id.name}</b>
                                        </Link><br></br><br></br>
                                        <Link to={`/admin/getTeacherAssignedHomeWork/${teacher?.user_id._id}`}>
                                          Assigned HW
                                        </Link>
                                        <br></br><br></br>
                                        <Link to={`/admin/getTeacherAssignedClassWork/${teacher?.user_id._id}`}>
                                          Assigned CW
                                        </Link>
                                        <br></br><br></br>
                                        <Link to={`/admin/getTeacherAssignedActivity/${teacher?.user_id._id}`}>
                                          Assigned Activity
                                        </Link>
                                      </td> */}
                                      <td>
                                        <Link to={`${teacher?.user_id._id}`}>
                                          <b>{teacher?.user_id.name}</b>
                                        </Link>
                                        <br />

                                        <ul>
                                          <li>
                                            <span class="badge bg-success badge-sm  me-1 mb-1 mt-1 ml-10 text-white">
                                              <Link
                                                to={`/admin/getTeacherAssignedHomeWork/${teacher?.user_id._id}`}
                                              >
                                                <span class="text-white">
                                                  Assigned HW
                                                </span>
                                              </Link>
                                            </span>
                                          </li>
                                          <li>
                                            <span class="badge bg-warning badge-sm  me-1 mb-1 mt-1 ml-10 text-white">
                                              <Link
                                                to={`/admin/getTeacherAssignedClassWork/${teacher?.user_id._id}`}
                                              >
                                                <span class="text-white">
                                                  Assigned CW
                                                </span>
                                              </Link>
                                            </span>
                                          </li>
                                          <li>
                                            <span class="badge bg-info badge-sm  me-1 mb-1 mt-1 ml-10 text-white">
                                              <Link
                                                to={`/admin/getTeacherAssignedActivity/${teacher?.user_id._id}`}
                                              >
                                                <span class="text-white">
                                                  Assigned Activity
                                                </span>
                                              </Link>
                                            </span>
                                          </li>
                                          <li>
                                            <span class="badge bg-success badge-sm  me-1 mb-1 mt-1 ml-10 text-white">
                                              <Link
                                                to={`/admin/getSyllabusForCopyChecking/${teacher?.user_id._id}`}
                                              >
                                                <span class="text-white">
                                                  Copy Checking
                                                </span>
                                              </Link>
                                            </span>
                                          </li>
                                          {localStorage.getItem("user") &&
                                          JSON.parse(
                                            localStorage.getItem("user")
                                          ).role === "admin" ? (
                                            <>
                                              <br></br>
                                              <button
                                                type="button"
                                                class="btn btn-green"
                                                data-bs-toggle="modal"
                                                data-bs-target="#input-modal"
                                                data-bs-whatever="@mdo"
                                                onClick={() => {
                                                  setSelectedTeacherId(
                                                    teacher?.user_id._id
                                                  ); // Set the selected teacher's ID
                                                }}
                                              >
                                                Manage Leaves
                                              </button>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </ul>
                                      </td>

                                      <td>
                                        {teacher?.user_id.mobile}
                                        <br></br>{" "}
                                        <span class="badge bg-success badge-sm  me-1 mb-1 mt-1 ml-10 text-white">
                                          <Link
                                            to={`/admin/updatePassword/${teacher?.user_id._id}`}
                                          >
                                            <span class="text-white">
                                              Update Password
                                            </span>
                                          </Link>
                                        </span>
                                        <br></br>
                                        {/* <span
                                          class="badge bg-success badge-sm  me-1 mb-1 mt-1 ml-10 text-white"
                                          type="button"
                                          data-bs-toggle="modal"
                                          data-bs-target="#input-modal2"
                                          data-bs-whatever="@mdo"
                                          onClick={() => {
                                            setTeacherDetail(teacher); // Set the selected teacher's ID
                                          }}
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                          }}
                                        >
                                          Teacher Profile
                                        </span> */}
                                      </td>
                                      {localStorage.getItem("user") &&
                                      JSON.parse(localStorage.getItem("user"))
                                        .role === "admin" ? (
                                        <>
                                          <td>
                                            {teacher?.user_id?.branch[0]?.name}
                                          </td>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      <td>
                                        {teacher?.doj
                                          ? timezoneDateToddMMyyyy(teacher?.doj)
                                          : ""}
                                      </td>
                                      <td>
                                        {teacher?.points
                                          ? teacher.points.filter(
                                              (obj) =>
                                                obj.session ===
                                                getCurrentSession()
                                            )[0]?.total_points_given
                                          : ""}
                                      </td>
                                      <td>
                                        {teacher?.points
                                          ? teacher.points.filter(
                                              (obj) =>
                                                obj.session ===
                                                getCurrentSession()
                                            )[0]?.remaining_points
                                          : ""}
                                      </td>

                                      <td>
                                        {teacher?.class_teacher
                                          ? teacher.class_teacher &&
                                            teacher.section
                                            ? `${
                                                teacher.class_teacher
                                              }(${teacher.section.toUpperCase()})`
                                            : `${teacher.class_teacher}(Section not allotted)`
                                          : ""}
                                      </td>
                                      <td
                                        dangerouslySetInnerHTML={{
                                          __html: teacher?.subjects,
                                        }}
                                      ></td>
                                      <td>
                                        <button
                                          className="btn btn-green"
                                          onClick={() => {
                                            setShowDatesOfId(
                                              "data" + teacher?.user_id._id
                                            );
                                          }}
                                        >
                                          Show
                                        </button>
                                        {localStorage.getItem("user") &&
                                        JSON.parse(localStorage.getItem("user"))
                                          .role === "admin" ? (
                                          <>
                                            <br />
                                            <br />
                                            <a
                                              target="_blank"
                                              className="btn btn-blue"
                                              href={`/admin/mark/attendance-log/${teacher?.user_id._id}`}
                                            >
                                              View Attendance Log
                                            </a>
                                            <br />
                                            <br />
                                            {/* <a
                                              target="_blank"
                                              className="btn btn-yellow"
                                              href={`/admin/appraisalSettingForm/${teacher?.user_id._id}`}
                                            >
                                             Appraisal setting
                                            </a> */}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </td>
                                    </tr>

                                    {/* Second <tr> for additional information */}
                                    {teacher?.points_data.length > 0 &&
                                      teacher?.points_data.length &&
                                      showDatesOfId &&
                                      showDatesOfId ===
                                        "data" + teacher?.user_id._id && (
                                        <tr>
                                          <td colSpan="9">
                                            {teacher?.points_data.length > 0 ? (
                                              <ul
                                                id={
                                                  "data" + teacher?.user_id._id
                                                }
                                              >
                                                {teacher?.points_data
                                                  ?.reverse()
                                                  .map((data) => (
                                                    <li key={"i" + count}>
                                                      {data.description} -{" "}
                                                      {timezoneDateToddMMyyyy(
                                                        data.createdAt
                                                      )}{" "}
                                                      - {data.points_deducted}
                                                    </li>
                                                  ))}
                                              </ul>
                                            ) : (
                                              <p>No information</p>
                                            )}
                                          </td>
                                        </tr>
                                      )}
                                  </React.Fragment>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Pagination
                    pageCount={Math.ceil(teachersCounts / itemsPerPage)} // Calculate the total number of pages based on the number of students and items per page
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName="pagination"
                    activeClassName="active"
                    initialPage={currentPage}
                  />
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="input-modal">
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">Manage Teacher Leaves</h6>

              <button
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="transfer-popup-close-btn"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              <form>
                <div className="form-group col-md-12">
                  <label htmlFor="casual_leaves">Casual Leaves</label>
                  <input
                    type="number"
                    className="form-control"
                    id="casual_leaves"
                    required
                    placeholder="Casual Leaves"
                  />
                </div>
                <div className="form-group col-md-12">
                  <label htmlFor="medical_leaves">Medical Leaves</label>
                  <input
                    type="number"
                    className="form-control"
                    id="medical_leaves"
                    required
                    placeholder="Medical Leaves"
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                className="btn ripple btn-success"
                type="button"
                onClick={() => {
                  setLeaves();
                }}
              >
                Submit
              </button>
              <button
                className="btn ripple btn-danger"
                data-bs-dismiss="modal"
                type="button"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="input-modal2">
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">Teacher Profile</h6>

              <button
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="transfer-popup-close-btn"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div
              className="modal-body"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="form-group col-md-12">
                {teacherDetail?.user_id?.profile_pic ? (
                  <>
                    {" "}
                    <div style={{ textAlign: "center" }}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          width={"100px"}
                          height={"100px"}
                          src={
                            process.env.REACT_APP_BASE_URL +
                            "/admin/images/" +
                            teacherDetail?.user_id?.profile_pic
                          }
                          alt={teacherDetail?.user_id?.name}
                          style={{
                            margin: "0 auto",
                            display: "block",
                            borderRadius: "50%", // This sets the border radius to make the image round
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <div style={{ display: "inline-block" }}>
                        <img
                          width={"100px"}
                          height={"100px"}
                          src={placeholder}
                          style={{
                            margin: "0 auto",
                            display: "block",
                            borderRadius: "50%", // This sets the border radius to make the image round
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}

                <br></br>
                <div style={{ textAlign: "left" }}>
                  <label htmlFor="casual_leaves">
                   
                    <b> Name:-</b> {teacherDetail?.user_id?.name}
                  </label>
                  <div
                    className="modal-footer"
                    style={{ marginBottom: "-25px" }}
                  ></div>

                  <label htmlFor="casual_leaves">
                    <b>Gender:-</b> {teacherDetail?.gender}
                  </label>
                  <div
                    className="modal-footer"
                    style={{ marginBottom: "-25px" }}
                  ></div>

                  <label htmlFor="medical_leaves">
                    <b>Mobile:- </b> {teacherDetail?.user_id?.mobile}{" "}
                  </label>
                  <div
                    className="modal-footer"
                    style={{ marginBottom: "-25px" }}
                  ></div>

                  <label htmlFor="casual_leaves">
                    <b>Email:- </b> {teacherDetail?.user_id?.email}
                  </label>
                  <div
                    className="modal-footer"
                    style={{ marginBottom: "-25px" }}
                  ></div>

                  <label htmlFor="casual_leaves">
                    <b>DOB:- </b>
                    {teacherDetail?.user_id?.dob
                      ? timezoneDateToddMMyyyy(teacherDetail?.user_id?.dob)
                      : ""}
                  </label>
                  <div
                    className="modal-footer"
                    style={{ marginBottom: "-25px" }}
                  ></div>

                  <label htmlFor="casual_leaves">
                    <b>DOJ:- </b>
                    {teacherDetail?.doj
                      ? timezoneDateToddMMyyyy(teacherDetail?.doj)
                      : ""}
                  </label>
                  <div
                    className="modal-footer"
                    style={{ marginBottom: "-25px" }}
                  ></div>

                  <label htmlFor="casual_leaves">
                    <b>paycode:- </b>
                    {teacherDetail?.paycode}
                  </label>
                  <div
                    className="modal-footer"
                    style={{ marginBottom: "-25px" }}
                  ></div>

                  <label htmlFor="casual_leaves">
                    <b>Class Teacher:- </b>

                    {teacherDetail?.class_teacher
                      ? teacherDetail.class_teacher && teacherDetail.section
                        ? `${
                            teacherDetail.class_teacher
                          }(${teacherDetail.section.toUpperCase()})`
                        : `${teacherDetail.class_teacher}(Section not allotted)`
                      : ""}
                  </label>
                  <div
                    className="modal-footer"
                    style={{ marginBottom: "-25px" }}
                  ></div>

                  <label htmlFor="casual_leaves">
                    <b>Subjects:- </b>

                    <span
                      style={{
                        display: "block",
                        marginLeft: "45px", // Adjust the margin as needed
                      }}
                      dangerouslySetInnerHTML={{
                        __html: teacherDetail?.subjects,
                      }}
                    ></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Teachers;
