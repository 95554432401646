import axios from "axios";
import React, { useEffect, useState } from "react";
import { getToken } from "../../utility/utilityFunctions";

const AddInventoryCategoryForm = () => {
  const data = {
    name: "",
    slug: ""
  };

  const [formData, setformData] = useState(data);

  function createSlugFromName(name) {
    const slug = name
      .replace(/[^\w\s-]/g, '') // remove special characters
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '_') // replace spaces with hyphens
      .replace(/-+/g, '_'); // remove any double hyphens
  
    return slug;
  }
    
  const updateValue = (e) => {
    const field = e.target.id;
    const value = e.target.value;

    if(field == "name"){
      formData.slug = createSlugFromName(value);
    }
    setformData({
      ...formData,
      [field]: value,
    });
  };

  const createInventoryCategory = () => {
    console.log(formData);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/inventoryCategory/new`, formData, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        console.log(response);
        if (response?.data.success) {
          window.notif({
            msg: "<b>Woohoo!!</b> Inventory Category Created Successfully",
            type: "success",
          });

          setformData(data);
        } else {
          window.notif({
            msg: response?.data.error,
            type: "error",
            position: "center",
          });
        }
      });
  };

  return (
    <>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Fill following information</h4>
          </div>
          <div className="card-body">
            <form className="form-horizontal">
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Name</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    value={formData.name}
                    id="name"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Slug</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Slug"
                    value={formData.slug}
                    id="slug"
                    disabled
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <div className="col-md-3">
                  <button
                    type="button"
                    class="btn btn-danger text-light"
                    onClick={() => {
                      createInventoryCategory();
                    }}
                  >
                    <i class="fe fe-user-plus"></i>&nbsp;Add Inventory Category
                  </button>
                </div>
              </div>           
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddInventoryCategoryForm;
