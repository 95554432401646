import React from 'react'
import Sidebar from '../../component/Sidebar'
import AddExamForm from './AddExamForm'
import GoBackButton from '../../component/GoBackButton'

const AddExam = () => {
  return (
    <>
    <Sidebar />
    <div className="main-content app-content mt-0">
      <div className="side-app">
        <div className="main-container container-fluid">
          <div className="page-header"></div>
          <div className="page-header">
          <GoBackButton />
            <h1 className="page-title">Add Exam</h1>
          </div>
          <AddExamForm />
          <div className="row ">
            <div className="col-md-12"></div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default AddExam