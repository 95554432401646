import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import {
  getToken,
  timezoneDateToddMMyyyy,
  getCurrentFullSession
} from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";

const SyllabusPreview = () => {
  const [syllabuses, setSyllabuses] = useState([]);
  const [Session, setSession] = useState(getCurrentFullSession());
  const [sessions, setSessions] = useState([]);
  const [search, setSearch] = useState("");


  useEffect(() => {
    const loaderElement = document.getElementsByClassName("loader")[0]; 
    if (loaderElement) {
      loaderElement.style.display = "block";
    }
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/syllabuses`,{
        search:search,
        Session
      }, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { syllabuses, sessions } = response.data;
        setSyllabuses(syllabuses);
        setSessions(sessions);
        if (loaderElement) {
          loaderElement.style.display = "none";
        }
      });
  }, [Session,search]);
  
  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
            <GoBackButton />

            <input className="p-1 rounded-sm" type="text" name="search" value={search} onChange={(e) => {
                
                setSearch(e.target.value);
              }} placeholder="Search" />


              <div className="col-md-4 d-flex">
                <div className="">
                  <select
                    className="form-control"
                    name=""
                    value={Session}
                    id="session"
                    onChange={(e) => {
                      setSession(e.target.value);
                    }}
                  >
                    {sessions.map((session) => {
                      return (
                        <option value={session.session_name}>
                          {session.session_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="row">
              <div class="col-sm-4">
                              <div className="loader" style={{ display: "none" }}>
                              <h2>Hold On !!</h2>
                              <h3>We are generating report....</h3>
                              <div
                                className="spinner-border text-danger"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div> 
                          </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Grade</b>
                                </th>
                                <th>
                                  <b>Subject</b>
                                </th>
                                <th>
                                  <b>Added By</b>
                                </th> 
                              </tr>
                            </thead>
                            <tbody>
                              {syllabuses.map((syllabus) => {
                                return (
                                  <tr key={syllabus._id}>
                                    <td>
                                      <Link to={`/admin/syllabus/${syllabus._id}/chapters`}>
                                        {syllabus.common_grade.name}
                                      </Link>
                                    </td>
                                    <td>{syllabus?.subject?.name}</td>
                                    <td>{syllabus?.created_by?.name}</td>                                    
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SyllabusPreview;
