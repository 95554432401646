import React from "react";
import Sidebar from "../../component/Sidebar";
import AddTeachertForm from "./AddTeachertForm";
import GoBackButton from "../../component/GoBackButton";

const AddTeacher = () => {
  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
            <GoBackButton />
              <h1 className="page-title">Add Teacher</h1>
            </div>
            <AddTeachertForm />
            <div className="row ">
              <div className="col-md-12"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTeacher;
