import React, { useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar";
import axios from "axios"; 
import "../../App.css";
import {   
  getToken,
  timezoneDateToddMMyyyy,
} from "../../utility/utilityFunctions"; 
import GoBackButton from "../../component/GoBackButton";

const StudentsFollowUp = () => {
  const [studentsFollowUpData, setStudentsFollowUpData] = useState([]);
  const [enquiryClosed, setEnquiryClosed] = useState(0);
  const [enquirySession, setEnquirySession] = useState('all');
  let count = 0;
  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/student_follow_up_records`,
        {"enquiry_closed": enquiryClosed, "enquiry_session": enquirySession},
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        const { studentData } = response.data;
        console.log(studentData);
        setStudentsFollowUpData(studentData);
      });
  }, [enquiryClosed, enquirySession]);

  const saveRemark = (userId) => {
    const description = document.getElementById(`d${userId}`).value;
    if (description && description.trim() != "") {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/admin/saveStudentFollowupRemark`,
          { userId, description },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          window.notif({
            msg: "Information marked Successfully",
            type: "success",
          });
        });
    } else {
      window.alert("Kindly provide a valid remark.");
    }
  };

  const madeCall = (userId) => {
    if (window.confirm("Are you sure you made a call ?")) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/admin/updatePhoneCountStudentFollowup`,
          { userId },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          window.notif({
            msg: "Call Count Added Successfully",
            type: "success",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    }
  };

  const closeQuery = (userId) => {
    if (window.confirm("Are you sure want to close the query ?")) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/admin/closeStudentFollowup`,
          { userId },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          window.notif({
            msg: "Call Count Added Successfully",
            type: "success",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    }
  };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="col-sm-12">
              <div className="card p-4">
                {/* <GoBackButton /> */}
                <div className="row">
                  <div className="col-sm-6">
                    <h1 className="page-title p-2">Student Follow Up</h1>
                  </div>
                  <div className="col-sm-3">
                    <select
                      className="form-control"
                      name=""                     
                      id="enquiry_status"
                      onChange={(e) => {                       
                        setEnquiryClosed(parseInt(e.target.value));
                      }}
                    >
                      <option value={"0"}>Enquiry Open</option> 
                      <option value={"1"}>Enquiry Closed</option> 
                    </select>
                  </div>
                  <div className="col-sm-3">
                    <select
                      className="form-control"
                      name=""                     
                      id="student_session"
                      onChange={(e) => {                       
                        setEnquirySession(e.target.value);
                      }}
                    >
                      <option value="all">All Session</option> 
                      <option value="2023-2024">2023-2024</option> 
                      <option value="2024-2025">2024-2025</option> 
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      className="table table-bordered border text-nowrap mb-0"
                      id="basic-edit"
                    >
                      <tbody>
                        {studentsFollowUpData?.map((data, index) => {
                          return (
                            <tr key={data._id}>
                              <td>
                                {index+1}
                              </td>
                              <td>
                                <div className="d-flex">
                                  <div
                                    style={{ width: "20%", overflow: "auto" }}
                                    className="d-flex justify-content-between"
                                  >
                                    <div className="d-flex flex-column">
                                      <b className="mb-2">{data.name}</b>                                       
                                      <b
                                        className="mb-2"
                                        style={{ fontSize: "12px" }}
                                      >
                                        {data.mobile}
                                      </b>
                                      {data.email ? (
                                        <b
                                          className="mb-2"
                                          style={{ fontSize: "12px" }}
                                        >
                                          {data.email}
                                        </b>
                                      ) : (
                                        <></>
                                      )}
                                      <b
                                        className="mb-2"
                                        style={{ fontSize: "12px" }}
                                      >
                                        {data.address}
                                      </b>
                                      <span className="mb-2"
                                        style={{ fontSize: "12px" }}>
                                        Enquiry Started On : {timezoneDateToddMMyyyy(data.createdAt)}
                                      </span>
                                    </div>
                                  </div> 
                                  <div
                                    style={{
                                      width: "20%",
                                      padding: "0 10px",
                                    }}
                                    className="d-flex flex-column"
                                  >
                                    {data?.studentsfollowups[0]?.called?.length ? (
                                      <>
                                        <div>
                                          <b>Called At</b>
                                        </div>
                                        <ul>
                                          {data?.studentsfollowups[0]?.called
                                            .reverse()
                                            .map((record) => (
                                              <li
                                                style={{ fontSize: "12px" }}
                                              >
                                                {
                                                  timezoneDateToddMMyyyy(record)
                                                }
                                              </li>
                                            ))}
                                        </ul>
                                      </>
                                    ) : (
                                      <span className="badge bg-danger badge-sm">
                                        Not called Yet
                                      </span>
                                    )}
                                  </div>

                                  <div
                                    style={{
                                      width: "50%",
                                      padding: "0 15px",
                                    }}
                                  >
                                    <textarea
                                      className="form-control"
                                      placeholder="Remarks.."
                                      id={`d${data._id}`}
                                      style={{ height: "100%" }}
                                      value={data?.studentsfollowups[0]?.description || ""}
                                      onChange={(event) => {
                                        const updatedData =
                                          studentsFollowUpData.map((item) => {
                                            if (item._id === data._id) {
                                              console.log("item", item);
                                              return {
                                                ...item,                                                  
                                                studentsfollowups: [{
                                                  ...item?.studentsfollowups[0]  || {},
                                                  description : event.target.value
                                                }]                                                    
                                              };
                                            }
                                            return item;
                                          });
                                        setStudentsFollowUpData(updatedData);
                                      }}
                                    ></textarea>
                                  </div>

                                  <div className="d-flex flex-column">
                                    <button
                                      className="btn btn-sm btn-primary"
                                      onClick={() => {
                                        saveRemark(data._id);
                                      }}
                                    >
                                      Save Remark
                                    </button>

                                    <button
                                      className="btn btn-sm btn-success my-1"
                                      onClick={() => {
                                        madeCall(data._id);
                                      }}
                                    >
                                      Made a Call
                                    </button>

                                    <button
                                      className="btn btn-sm btn-primary my-1"
                                      onClick={() => {
                                        closeQuery(data._id);
                                      }}
                                    >
                                      Close Query
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-12"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentsFollowUp;
