import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import { getToken } from "../../utility/utilityFunctions";

const BreakageCategory = () => {
  const [breakageCategories, setBreakageCategories] = useState([]);

  useEffect(() => {
    console.log("getToken()", getToken());
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/breakageCategories`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { breakageCategories } = response.data;
        setBreakageCategories(breakageCategories);
      });
  }, []);

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <h1 className="page-title">Breakage Categories</h1>
              <Link
                type="button"
                to="/admin/breakageCategory/new"
                class="btn btn-danger text-light"
              >
                <i class="fe fe-user-plus"></i>&nbsp;Add Breakage Category
              </Link>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Breakage Category</b>
                                </th>
                                <th>
                                  <b>Slug</b>
                                </th>
                                <th>
                                  <b>Days Needed To Resolve</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {breakageCategories.map((breakageCategory) => {
                                return (
                                  <tr key={breakageCategory._id}>
                                    <td>{breakageCategory.name}</td>
                                    <td>{breakageCategory.slug}</td>
                                    <td>{breakageCategory.days_to_resolve}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BreakageCategory;
