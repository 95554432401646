import axios from "axios";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar";
import { getToken } from "../../utility/utilityFunctions";
import { Link } from "react-router-dom";

function StudentsAppreciation() {
  const { id } = useParams();
  const [studnets, setStudnets] = useState(null);
  let count = 1;

  useEffect(() => {
    const loaderElement = document.getElementsByClassName("loader")[0];
    if (loaderElement) {
      // If the loader element exists, you can modify its display style
      loaderElement.style.display = "block"; // or "block" or any other desired value
    }
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/admin/studentsAppreciationDetail`;
    axios
      .post(
        apiUrl,
        {},
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        const { studentsAppreciation } = response.data;
        setStudnets(studentsAppreciation);
        console.log("studentsAppreciation", studentsAppreciation);
        if (loaderElement) {
          // If the loader element exists, you can modify its display style
          loaderElement.style.display = "none"; // or "block" or any other desired value
        }
      });
  }, []);

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="col-sm-12 card pb-2 pt-2">
              <div className="col-sm-12">
                <div className="card">
                  <h1 className="page-title p-2"> Today's Students Appreciation</h1>
                  {/* <div class="alert alert-info" role="alert">
                  Students those were promoted from last class and paid less than 3000 INR.
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-sm-4">
                <div className="loader" style={{ display: "none" }}>
                  <h2>Hold On !!</h2>
                  <h3>We are generating report....</h3>
                  <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              {studnets ? (
                <div className="row ">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="table-responsive">
                              <table
                                className="table table-bordered border text-nowrap mb-0"
                                id="basic-edit"
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <b>Sr. No</b>
                                    </th>
                                    <th>
                                      <b>Student</b>
                                    </th>
                                    <th>
                                      <b>Mobile</b>
                                    </th>
                                    <th>
                                      <b>Father</b>
                                    </th>
                                    <th>
                                      <b>Grade</b>
                                    </th>
                                    <th>
                                      <b>Section</b>
                                    </th>
                                    <th>
                                      <b>Branch</b>
                                    </th>
                                    <th>
                                      <b>Review</b>
                                    </th>
                                    <th>
                                      <b>Given By</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {studnets?.map((studnet, index) => (
                                    <React.Fragment
                                      key={studnet.singleRewiew?.studentData?._id}
                                    >
                                      {/* First <tr> for basic student information */}
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                          <Link
                                            to={`/admin/students/${studnet.singleRewiew?.studentData?._id}`}
                                          >
                                            {studnet?.singleRewiew?.studentData?.user_id?.name}
                                          </Link>
                                        </td>
                                        <td>{studnet?.singleRewiew?.studentData?.user_id?.mobile}</td>
                                        <td>
                                          {studnet?.singleRewiew?.studentData?.user_id?.father_name}
                                        </td>
                                        <td>
                                          {studnet?.singleRewiew?.studentData?.grade?.name}
                                        </td>
                                        <td>
                                          {studnet?.singleRewiew?.studentData?.section}
                                        </td>
                                        <td>
                                          {
                                            studnet?.teacher?.branch[0].name
                                          }
                                        </td>
                                        <td>
                                          {studnet?.singleRewiew?.studentReviewArrayyyyy?.map(
                                            (review) => (
                                              <div key={review?.review}>
                                               
                                                      <p>
                                                        
                                                        {review?.review}
                                                      </p>
                                                 
                                              </div>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {
                                            studnet?.teacher?.name
                                          }
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default StudentsAppreciation;
