import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import {
  getToken,
  timezoneDateToddMMyyyy,
  getCurrentFullSession
} from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";

const Mark = () => {
  const [exam, setMarks] = useState([]); 
  const [branchList, setbranchList] = useState([]); 
  const [selectedBranch, setSelectedBranch] = useState('');
  const [session, setSession] = useState(getCurrentFullSession());
  const [sessions, setSessions] = useState([]);

  const handleBranchChange = (event) => {
    setSelectedBranch(event.target.value);
  };

  const queryParams = selectedBranch ? `?selBranch=${selectedBranch}&session=${session}` : `?session=${session}`;
  
  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/admin/student-mark`;
    const urlWithParams = `${apiUrl}${queryParams}`; // Construct URL with query params
    const loaderElement = document.getElementsByClassName("loader")[0]; 
    if (loaderElement) {
      // If the loader element exists, you can modify its display style
      loaderElement.style.display = "block"; // or "block" or any other desired value
    }
    axios
      .get(urlWithParams, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { exam, sessions } = response.data;
        setMarks(exam);
        setSessions(sessions);
  
        const { branchList } = response.data;
        setbranchList(branchList);
        if (loaderElement) {
          // If the loader element exists, you can modify its display style
          loaderElement.style.display = "none"; // or "block" or any other desired value
        }
      });
  }, [selectedBranch, queryParams, session]); 
  
  return (
    
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
                <GoBackButton />

              <div className="col-md-4">
                <h4 className="text-end">
                    Student Mark
                </h4>
              </div> 
            </div>
            
            <div class="row ">
            <div class="col-md-12">
                <div class="row">
                  <div class="col-lg-12">
                      <div class="card">
                        <div class="card-body row">
                        {(localStorage.getItem("user") &&
                        JSON.parse(localStorage.getItem("user")).role ===
                        "admin")  ? (
                          <div className="col-sm-4">
                            
                              <label htmlFor="branchFilter">Filter by Branch:</label>
                              <select class="form-control"
                                  id="branchFilter"
                                  name="branchFilter"
                                  value={selectedBranch}
                                  onChange={handleBranchChange}
                                >
                                  <option value="">Select</option>
                                  {branchList.map(branch => (
                                    <option key={branch._id} value={branch._id}>
                                      {branch.name}
                                    </option>
                                  ))}
                                </select>
                            
                          </div>
                          ) : (
                            <></>
                          )}

                          <div className="col-sm-4">
                            
                              <label htmlFor="session">Filter by Session:</label>
                              <select class="form-control"
                                  id="session"
                                  name="session"
                                  value={session}
                                  onChange={(e) => {
                                    setSession(e.target.value);
                                  }}
                                >
                                  {/* <option value="">Select Session</option> */}
                                  {sessions.map((session) => {
                                    return (
                                      <option value={session.session_name}>
                                        {session.session_name}
                                      </option>
                                    );
                                  })}
                                </select>
                            
                          </div>
                        </div>
                      </div>
                  </div> 
                </div>
            </div>
          </div>
            <div className="row ">
              
              <div class="col-sm-12">
                  <div className="loader" style={{ display: "none" }}>
                  <h2>Hold On !!</h2>
                  <h3>We are generating report....</h3>
                  <div
                    className="spinner-border text-danger"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div> 
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Test Name</b>
                                </th> 
                                <th>
                                  <b>Grade</b>
                                </th> 
                              </tr>
                            </thead>
                            <tbody>
                            {exam?.map((markKay) => {
                                return (
                                  <tr> 
                                    <td>{markKay.name}</td> 
                                    <td>
                                    {markKay.grades.map(grade => (
                                        <div key={grade._id}> 
                                            {markKay.subjects.map(subject => (
                                              
                                              <a
                                              key={subject._id}
                                              href={`/admin/mark/mark-detail/${markKay._id}/${grade._id}/${subject._id}`} // Replace with your desired URL structure
                                              style={{ textDecoration: 'underline', color: 'black' }}
                                            >
                                              <p>{grade.name}-{subject.name}</p>
                                            </a>


                                            ))} 
                                        </div>
                                      ))}
                                    </td>  
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mark;
