import axios from "axios";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar";
import { getToken } from "../../utility/utilityFunctions";
import { Link } from "react-router-dom";

function BackSessionInvoices() {
  const { id } = useParams();
  const [invoices, setInvoices] = useState([]);
  let count = 1;

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/admin/getBckSessionInvoice`;
    axios
      .post(
        apiUrl,
        {},
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        const { invoices } = response.data;
        setInvoices(invoices);
        console.log("invoices:",invoices)
      });
  }, []);

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div
              style={{
                backgroundColor: "white",
                padding: "25px",
                marginBottom: "10px",
              }}
            >
              <div className="">
                <h1
                  className="page-title"
                  style={{ marginLeft: "12px", marginBottom: "15px" }}
                >
                 2022 invoices List
                </h1>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="loader" style={{ display: "none" }}>
                <h2>Hold On !!</h2>
                <h3>We are generating report....</h3>
                <div className="spinner-border text-danger" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Sr. No</b>
                                </th>
                                <th>
                                  <b>Invoice</b>
                                </th>

                                <th>
                                  <b>Student</b>
                                </th>
                                <th>
                                  <b>Amount</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {invoices.map((student) => {
                                return (
                                  <React.Fragment key={student._id}>
                                    {/* First <tr> for basic teacher information */}

                                    <tr>
                                      <td>{count++}</td>

                                      <td> <a
                                    target="blank"
                                    href={
                                      process.env.REACT_APP_BASE_URL +
                                      "/admin/invoice/" +
                                      student.slug
                                    }
                                  >
                                    {student.name}
                                  </a></td>

                                      <td><Link
                                        to={`/admin/students/${student?.user?._id}`}
                                      >{student?.user?.name}</Link></td>
                                      <td>{student?.amount}</td>
                                    
                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default BackSessionInvoices;
