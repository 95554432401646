import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import {
  getCurrentSession,
  getToken,
  timezoneDateToddMMyyyy,
} from "../../utility/utilityFunctions";
import placeholder from "../../assets/images/brand/placeholder.png";
import GoBackButton from "../../component/GoBackButton";
const TeacherSameGradeSectionSub = () => {
  let userInfo = localStorage.getItem("user");
  userInfo = JSON.parse(userInfo);
  const [teachers, setTeachers] = useState([]);
  const [search, setSearch] = useState("");
  const [showDatesOfId, setShowDatesOfId] = useState(null);
  let count = 1;
  useEffect(() => {
    const loaderElement = document.getElementsByClassName("loader")[0];
    if (loaderElement) {
      // If the loader element exists, you can modify its display style
      loaderElement.style.display = "block"; // or "block" or any other desired value
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/teachersAssignedSameClassSection`,
        {
          search: search,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            // "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        const { uniqueFlattenedRepeatedSylbbs } = response.data;
        console.log("uniqueFlattenedRepeatedSylbbs:",uniqueFlattenedRepeatedSylbbs)
        setTeachers(uniqueFlattenedRepeatedSylbbs);
        if (loaderElement) {
          // If the loader element exists, you can modify its display style
          loaderElement.style.display = "none"; // or "block" or any other desired value
        }
      });
  }, [search]);

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <GoBackButton />
              {/* <h1 className="page-title">Teachers</h1> */}
              <h3>Teachers Assigned Same grade , Subject and Section</h3>
              <input
                className="p-1 rounded-sm"
                type="text"
                name="search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                placeholder="Search"
              />
            </div>
            <div className="row ">
              <div class="col-sm-12">
                <div className="loader" style={{ display: "none" }}>
                  <h2>Hold On !!</h2>
                  <h3>We are generating report....</h3>
                  <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Sr. No</b>
                                </th>
                                <th>
                                  <b>Teacher</b>
                                </th>
                                <th>
                                  <b>Subject</b>
                                </th>
                               
                                <th>
                                  <b>grade</b>
                                </th>

                                <th>
                                  <b>Section</b>
                                </th>
                               
                              </tr>
                            </thead>
                            <tbody>
                              {teachers.map((teacher) => {
                                return (
                                  <React.Fragment key={teacher.teacherId}>
                                    {/* First <tr> for basic teacher information */}
                                    {1 ? (
                                      <tr>
                                        <td>{count++}</td>
                                        
                                        <td>
                                          <Link to={`${teacher?.teacherId}`}>
                                            {teacher?.teacher}
                                          </Link>
                                        </td>
                                     
                                        <td>{teacher?.subject}</td>

                                        <td>{teacher?.grade}</td>
                                        <td>{teacher?.section}</td>
                                       

                                      
                                      </tr>
                                    ) : (
                                      ""
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherSameGradeSectionSub;
