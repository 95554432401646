import React from 'react';

const GoBackButton = () => {
  const goBack = () => {
    window.history.back();
  };

  return (
    <button className='btn btn-sm btn-danger' onClick={goBack}>
      Back
    </button>
  );
};

export default GoBackButton;
