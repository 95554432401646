import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";

const Branch = () => {

  const [branches, setBranches] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/branches`)
      .then((response) => {
        console.log("response", response);
        const {branches} = response.data;
        setBranches(branches);
      });
  }, []);

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <h1 className="page-title">Branch</h1>
              <Link
                type="button"
                to="/admin/branch/new"
                class="btn btn-danger text-light"
              >
                <i class="fe fe-user-plus"></i>&nbsp;Add Branch
              </Link>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th><b>Name</b></th>
                                <th><b>Phone</b></th>
                                <th><b>Email</b></th>
                                <th><b>Location</b></th>
                              </tr>
                            </thead>
                            <tbody>
                              {branches.map((branch) => {
                                return <tr key={branch._id}>
                                <td>{branch.name}</td>
                                <td>{branch.phone}</td>
                                <td>{branch.email}</td>
                                <td>{branch.location}</td>
                              </tr>;
                              })}                              
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Branch;
