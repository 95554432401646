import axios from "axios";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import {
  getToken,
  timezoneDateToddMMyyyy,
} from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";

function FeesRecord() {
  const { id } = useParams();
  const [studentData, setStudents] = useState([]);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/admin/mark/feesCorrection`;
    const loaderElement = document.getElementsByClassName("loader")[0];
    if (loaderElement) {
      // If the loader element exists, you can modify its display style
      loaderElement.style.display = "block"; // or "block" or any other desired value
    }
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { studentData } = response.data;
        console.log(studentData);
        setStudents(studentData);

        if (loaderElement) {
          // If the loader element exists, you can modify its display style
          loaderElement.style.display = "none"; // or "block" or any other desired value
        }
      });
  }, []);

  const deleteFee = (feeId, RequestType) => {
    var message = `Are you sure you want to Delete fees for student ?`;
    if (RequestType == "Reject")
      message = `Are you sure you want to Reject fees for student ?`;
    const shouldCancel = window.confirm(message);

    if (shouldCancel) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/admin/mark/deleteFee`,
          {
            feeId: feeId,
            RequestType: RequestType,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          window.notif({
            msg: "<b>Woohoo!!</b> Record updated successfully",
            type: "success",
          });
          window.location.reload();
        })
        .catch((error) => {
          // Handle error if necessary
          console.error(error);
        });
    } else {
      console.log("Cancelled");
    }
  };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div
              style={{
                backgroundColor: "white",
                padding: "25px",
                marginBottom: "10px",
              }}
            >
              <div className="">
                <h1
                  className="page-title"
                  style={{ marginLeft: "12px", marginBottom: "15px" }}
                >
                  CORRECTED FEES LOGS
                </h1>
              </div>
            </div>
            <div class="col-sm-4">
              <div className="loader" style={{ display: "none" }}>
                <h2>Hold On !!</h2>
                <h3>We are generating report....</h3>
                <div className="spinner-border text-danger" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Sr No.</b>
                                </th>
                                <th>
                                  <b>CH Reason</b>
                                </th>
                                <th>
                                  <b>Admin Reason</b>
                                </th>
                                <th>
                                  <b>Name</b>
                                </th>
                                <th>
                                  <b>Father Name</b>
                                </th>
                                <th>
                                  <b>Mobile</b>
                                </th>
                                <th>
                                  <b>Updated At</b>
                                </th>
                                <th>
                                  <b>Grade</b>
                                </th>
                                <th>
                                  <b>Section</b>
                                </th>
                                {/* <th>
                                  <b>Action</b>
                                </th>   */}
                              </tr>
                            </thead>
                            <tbody>
                              {studentData?.length > 0 ? (
                                studentData.map((kay) => (
                                  <tr key={kay._id}>
                                    <td>{kay.student.sr_no}</td>
                                    <td>
                                      {" "}
                                      <div
                                        style={{
                                          whiteSpace: "normal", // or "break-spaces" depending on your requirements
                                          wordWrap: "break-word",
                                          width: "100px",
                                        }}
                                      >
                                        {kay?.fee?.reason}
                                      </div>
                                    </td>
                                    <td>
                                      {" "}
                                      <div
                                        style={{
                                          whiteSpace: "normal", // or "break-spaces" depending on your requirements
                                          wordWrap: "break-word",
                                          width: "100px",
                                        }}
                                      >
                                        {kay?.adminReason}
                                      </div>
                                    </td>

                                    <td>
                                      <Link
                                        to={`/admin/students/${kay?.student?.user_id}`}
                                      >
                                        {kay.user.name}
                                      </Link>
                                    </td>
                                    <td>{kay.user.father_name}</td>
                                    <td>{kay.user.mobile}</td>
                                    <td>
                                      {kay?.fee?.updatedAt &&
                                        new Date(
                                          kay.fee.updatedAt
                                        ).toLocaleDateString("en-GB", {
                                          day: "2-digit",
                                          month: "2-digit",
                                          year: "numeric",
                                        })}
                                    </td>
                                    <td>{kay.grade.name}</td>
                                    <td>{kay.student.section}</td>
                                    {/* <td>
          <Link target="_blank" to={`/admin/collect-fee/${kay.fee._id}`}>
            <i className="icon icon-wallet ml-3" />
          </Link>
          &nbsp;|&nbsp;
          <a href="#" onClick={(e) => {
            e.preventDefault();
            deleteFee(kay.fee._id, 'Delete');
          }}>
            Delete
          </a>
          &nbsp;|&nbsp;
          <a href="#" onClick={(e) => {
            e.preventDefault();
            deleteFee(kay.fee._id, 'Reject');
          }}>
            Reject
          </a>
        </td> */}
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="8">No results found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeesRecord;
