import axios from "axios";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar";
import { getToken } from "../../utility/utilityFunctions";
import { Link } from "react-router-dom";

function TeacherAppraisalPoints() {
  const { id } = useParams();
  const [teacher, setTeacher] = useState(null);

 
  const params = useParams();

  let count = 1;

  useEffect(() => {
    const loaderElement = document.getElementsByClassName("loader")[0];
    if (loaderElement) {
      // If the loader element exists, you can modify its display style
      loaderElement.style.display = "block"; // or "block" or any other desired value
    }
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/teacher/teacher_appraisal_points`;
    axios
      .post(
        apiUrl,
        {
          user_id: params.id,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        const {teacherAppraisalPoints } =
          response.data;
          setTeacher(teacherAppraisalPoints);

       
        if (loaderElement) {
          // If the loader element exists, you can modify its display style
          loaderElement.style.display = "none"; // or "block" or any other desired value
        }
      });
  }, []);
  function formatDateToDDMMYYYY(dateString) {
    if (!dateString) {
        return "No date available";
    }
    
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0'); // Add leading zero if needed
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    
    return `${day}-${month}-${year}`;
}
  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="col-sm-12 card pb-2 pt-2">
              <div className="col-sm-12">
                <div className="card">
                <h1 className="page-title p-2">Teacher Appraisal Points</h1>

                  {/* <div class="alert alert-info" role="alert">
                  Students those were promoted from last class and paid less than 3000 INR.
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-sm-4">
                <div className="loader" style={{ display: "none" }}>
                  <h2>Hold On !!</h2>
                  <h3>We are generating report....</h3>
                  <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              {1 ? (
                <div className="row ">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="table-responsive">
                              {/* <h1>Assigned Activity Work</h1> */}

                              <table
                                className="table table-bordered border text-nowrap mb-0"
                                id="basic-edit"
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <b>Sr.No</b>
                                    </th>
                                    <th>
                                      <b>Session</b>
                                    </th>
                                    <th>
                                      <b>Point Type</b>
                                    </th>
                                    <th>
                                      <b>Date</b>
                                    </th>
                                    <th>
                                      <b>Points</b>
                                    </th>
                                    <th>
                                      <b>Leaves</b>
                                    </th>
                                    <th>
                                      <b>Note</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {teacher?.map((teach) => {
                                    return (
                                      <React.Fragment key={teach?._id}>
                                        {/* First <tr> for basic teacher information */}

                                        <tr>
                                          <td>{count++}</td>
                                          <td>{teach.session}</td>
                                          <td>{teach?.pointtype}</td>
                                          <td>{teach?.date}</td>
                                          <td>{teach?.points}</td>
                                            <td>{teach?.leaves}</td>
                                            <td>{teach?.note}</td>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TeacherAppraisalPoints;
