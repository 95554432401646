import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState } from "react";
import { useEffect } from "react";
import { getToken } from "../../utility/utilityFunctions";
import React from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import placeholder from "../../assets/images/brand/placeholder.png";
import ButtonComponent from "../../component/ButtonComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parse, isValid } from "date-fns";
const AddTeacherDetailForm = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  //
  const validationSchema = yup.object({
    email: yup.string().email("Please enter a valid email address"), // Add email validation

    mobile: yup
      .string()
      .required("Please enter an phone number.")
      .max(12, "Maximum allowed character is 12")
      .matches(/^[0-9]\d{9}$/, "Please enter valid phone number."),
    paycode: yup.string().required("please enter paycode"),
    gender: yup.string().required("please select gender"),
    role: yup.string().required("please define role"),

    address: yup.string(),
    qualification: yup.string(),
    experience: yup.string(),
    designation: yup.string(),
    doj: yup.string().required("please enter date of birth"),
    blood_group: yup.string(),
    // teacher_arriving_time: yup
    //   .string()
    //   .required("please define teacher arriving time"),
    // teacher_return_time: yup
    //   .string()
    //   .required("please define teacher return time"),
    teachingSubjects: yup.array().of(
      yup.object().shape({
        grade: yup.string().required("Please select grade"),
        subject: yup.string().required("Please select subject"),
        section: yup.string().required("please select section"),
      })
    ),
  });
  const {
    register,
    reset,
    watch,
    setError,
    getValues,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const {
    fields: subjectsfields,
    append,
    remove,
  } = useFieldArray({ name: "teachingSubjects", control });
  const [allSubjects, setAllSubjects] = useState([]);
  const [allGrades, setAllGrades] = useState([]);
  //
  const dob = watch("dob");
  const params = useParams();
  const navigate = useNavigate();

  const [teacherArrivingTime, setTeacherArrivingTime] = useState(
    new Date().setHours(9, 0)
  );
  const [teacherReturnTime, setTeacherReturnTime] = useState(
    new Date().setHours(9, 0)
  );

  const handleArrivingTimeChange = (date) => {
    if (date instanceof Date && !isNaN(date.getTime())) {
      setTeacherArrivingTime(date);
    } else {
      console.error("Invalid date");
    }
  };

  const handleReturnTimeChange = (date) => {
    if (date instanceof Date && !isNaN(date.getTime())) {
      setTeacherReturnTime(date);
    } else {
      console.error("Invalid date");
    }
  };

  const archive = () => {
    if (window.confirm("You really want to archive teacher ?")) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/admin/student/archiveStudent`,
          {
            user_id: params.id,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          window.notif({
            msg: "<b>Woohoo!!</b> Teacher Archived Successfully",
            type: "success",
          });
          navigate(`/admin/teachers/`);
        });
    }
  };

  const TeacherWorks = () => {
    if (1) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/teacher/teacherWork`,
          {
            user_id: params.id,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          // console.log("TeacherWorks:", response.data);
          window.notif({
            msg: "<b>Woohoo!!</b> Teacher Archived Successfully",
            type: "success",
          });
          // navigate(`/admin/teachers/`);
        });
    }
  };

  const [isRecordSaved, setIsRecordSaved] = useState(false);
  const [profilePicInAdmissionForm, setProfilePicInAdmissionForm] =
    useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/teacher/${params.id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { teacher, grades, subjects } = response.data;
        setAllSubjects(subjects);
        setAllGrades(grades);
        setProfilePicInAdmissionForm(
          teacher.profile_pic
            ? process.env.REACT_APP_BASE_URL +
                "/admin/images/" +
                teacher.profile_pic
            : placeholder
        );

        function formatDateToDdMmYyyyDOJ(inputDate) {
          const date = new Date(inputDate);
          const year = date.getUTCFullYear();
          const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
          const day = date.getUTCDate().toString().padStart(2, '0');
          return `${year}-${month}-${day}`;
        }
        const formatteddoj = formatDateToDdMmYyyyDOJ(teacher.doj);
        teacher.doj = formatteddoj;

        // console.log("teacher:", teacher);
        function formatDateToDdMmYyyy(inputDate) {
          const date = new Date(inputDate);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          return `${year}-${month}-${day}`;
        }
        if (
          teacher.dob !== "NaN-NaN-NaN" &&
          teacher.dob !== null &&
          teacher.dob !== undefined
        ) {
          // console.log("enter in condition");
          const formatteddob = formatDateToDdMmYyyy(teacher.dob);

          teacher.dob = formatteddob;
        }

        // Parse the time string
        const parsedTime = parse(
          teacher.teacher_arriving_time,
          "h:mm a",
          new Date()
        );
        if (isValid(parsedTime)) {
          // Set the parsed time as the default time
          setTeacherArrivingTime(parsedTime);
        }
        const teacher_r_t = parse(
          teacher.teacher_return_time,
          "h:mm a",
          new Date()
        );
        // Set the parsed time as the default time
        if (isValid(teacher_r_t)) {
          // Set the parsed time as the default time
          setTeacherReturnTime(teacher_r_t);
        }

        reset(teacher);
      });
  }, [isRecordSaved]);

  const updateTeacher = (eData) => {
    eData.profile_pic = eData.profile_pic[0];
    setShowLoader(true);

    eData.teacher_arriving_time = document.getElementById(
      "teacher_arriving_time"
    ).value;
    eData.teacher_return_time = document.getElementById(
      "teacher_return_time"
    ).value;

    //console.log(eData.teacher_arriving_time);
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/admin/teacher`,
        {
          ...eData,
          id: params.id,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        setTimeout(() => {
          setShowLoader(false);
        }, 1000);

        if (response?.data.success) {
          window.notif({
            msg: "<b>Woohoo!!</b> Teacher Record Updated Successfully",
            type: "success",
          });

          setIsRecordSaved(!isRecordSaved);
        } else {
          window.notif({
            msg: response?.data.error,
            type: "error",
            position: "center",
          });
        }
      });
  };

  // Add this code to format the current date
  const currentDate = new Date();
  const formattedDate = `${currentDate.getFullYear()}-${String(
    currentDate.getMonth() + 1
  ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;
  // Function to toggle the accordion state
  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const parmanetRemove = (i, subject, grade, section) => {
    if (window.confirm("You really want to remove this?")) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/admin/teacher/removeGrade`,
          {
            id: params.id,
            subject: subject,
            grade: grade,
            section: section,
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          remove(i);
        });
    }
  };

  return (
    <>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <div className="col-xl-2 px-3 px-xl-1 m-0">
              <img width={"100px"} src={profilePicInAdmissionForm} />
              <br></br><br></br>
                  <span class="badge bg-success badge-sm  me-1 mb-1 mt-1 ml-10 text-white">
                    <Link to={`/admin/updatePassword/${params.id}`}>
                      <span class="text-white">Update Password</span>
                    </Link>
                  </span>
            </div>
            {/* <div><button  className="btn ripple btn-danger"
                    type="button"
                    onClick={() => {
                      TeacherWorks();
                    }}>teacher Assigend Works</button></div> */}
            <div>
              <button className="btn bg-warning text-white ">
                <a
                  href={`/admin/getTeacherAssignedHomeWork/${params.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "white" }}
                >
                  Assigned HW
                </a>
              </button>
              <button className="btn bg-info text-white mx-2">
                <a
                  href={`/admin/getTeacherAssignedClassWork/${params.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "white" }}
                >
                  Assigned CW
                </a>
              </button>
              <button className="btn bg-success text-white ">
                <a
                  href={`/admin/getTeacherAssignedActivity/${params.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "white" }}
                >
                  Assigned Activity
                </a>
              </button>
              {/* <button className="btn btn-danger text-white mx-2">
    <a href={`/admin/teacherAppraisalPoints/${params.id}`} target="_blank" rel="noopener noreferrer" style={{color:"white"}}>
      Appraisal points
    </a>
  </button> */}
            </div>
          </div>
          <div className="card-body">
            <form
              className="form-horizontal"
              onSubmit={handleSubmit(updateTeacher)}
            >
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Name<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    id="name"
                    {...register("name")}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-md-3 form-label" htmlFor="example-email">
                  Email
                </label>
                <div className="col-md-9">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    id="email"
                    {...register("email")}
                  />
                  <div className="form-error-div">
                    {errors.email?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.email?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Mobile<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Mobile"
                    id="mobile"
                    {...register("mobile")}
                  />
                  <div className="form-error-div">
                    {errors.mobile?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.mobile?.message}
                  </div>
                </div>
              </div>
              {/* <div className=" row mb-4">
                <label className="col-md-3 form-label">Password</label>
                <div className="col-md-9">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Passowrd"
                    id="password"
                    {...register("password")}
                  />
                </div>
              </div> */}
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Paycode<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Paycode"
                    id="paycode"
                    {...register("paycode")}
                  />
                  <div className="form-error-div">
                    {errors.paycode?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.paycode?.message}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-md-3 form-label">DOB</label>
                <div className="col-md-9">
                  <DatePicker
                    className="form-control"
                    placeholderText="Date Of Birth"
                    id="dob"
                    maxDate={new Date()} // Set the maxDate prop to the current date
                    selected={watch("dob") ? new Date(watch("dob")) : null} // Parse the 'dob' value to a Date object
                    onChange={(date) => setValue("dob", date)} // Use setValue to update the 'dob' field
                    dateFormat="dd-MM-yyyy" // Set the desired date format
                  />
                  <div className="form-error-div">
                    {errors.dob?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.dob?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Gender<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <select
                    className="form-control"
                    id="gender"
                    {...register("gender")}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                  <div className="form-error-div">
                    {errors.gender?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.gender?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Role<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <select
                    className="form-control"
                    id="role"
                    {...register("role")}
                  >
                    <option value="teacher">Teacher</option>
                  </select>
                  <div className="form-error-div">
                    {errors.role?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.role?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Address</label>
                <div className="col-md-9">
                  <textarea
                    className="form-control"
                    id="address"
                    placeholder="Address"
                    {...register("address")}
                  ></textarea>
                  <div className="form-error-div">
                    {errors.address?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.address?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Profile Picture</label>
                <div className="col-md-9">
                  <input
                    className="form-control"
                    type="file"
                    id="profile_pic"
                    {...register("profile_pic")}
                  />
                </div>
              </div>

              <div className=" row mb-4">
                <label className="col-md-3 form-label">Qualification</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Qualification"
                    id="qualification"
                    {...register("qualification")}
                  />
                  <div className="form-error-div">
                    {errors.qualification?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.qualification?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Experience</label>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Experience"
                    id="experience"
                    {...register("experience")}
                  />
                  <div className="form-error-div">
                    {errors.experience?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.experience?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Designation</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Designation"
                    id="designation"
                    {...register("designation")}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-md-3 form-label">
                  DOJ<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <DatePicker
                    className="form-control"
                    placeholderText="Date Of Joining"
                    id="doj"
                    maxDate={new Date()} // Set the maxDate prop to the current date
                    selected={watch("doj") ? new Date(watch("doj")) : null} // Parse the 'dob' value to a Date object
                    onChange={(date) => setValue("doj", date)} // Use setValue to update the 'dob' field
                    dateFormat="dd-MM-yyyy" // Set the desired date format
                    disabled
                  />
                  <div className="form-error-div">
                    {errors.doj?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.doj?.message}
                  </div>
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Blood Group</label>
                <div className="col-md-9">
                  <select
                    className="form-control"
                    id="blood_group"
                    {...register("blood_group")}
                  >
                    <option value="">Select Blood Group</option>
                    <option value="A+">A+</option>
                    <option value="A-">A-</option>
                    <option value="B+">B+</option>
                    <option value="B-">B-</option>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                    <option value="AB+">AB+</option>
                    <option value="AB-">AB-</option>
                  </select>
                </div>
              </div>

              <div className=" row mb-4">
                <label className="col-md-3 form-label">Class Teacher</label>
                <div className="col-md-9 d-flex">
                  <select
                    className="form-control col-md-4 grade"
                    id="class_teacher"
                    {...register("class_teacher")}
                  >
                    <option value={""}>None</option>
                    {allGrades.map((grade) => (
                      <option key={grade._id} value={grade._id}>
                        {grade.name} ({grade.branch.name})
                      </option>
                    ))}
                  </select>

                  <select
                    className="form-select"
                    id="section"
                    {...register("section")}
                  >
                    <option value="">Choose Section</option>
                    <option value="a">A</option>
                    <option value="b">B</option>
                    <option value="c">C</option>
                    <option value="d">D</option>
                  </select>
                </div>
              </div>

              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Teacher Arriving Time<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <DatePicker
                    id="teacher_arriving_time"
                    {...register("teacher_arriving_time")}
                    name="teacher_arriving_time"
                    onChange={handleArrivingTimeChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    selected={teacherArrivingTime}
                  />

                  <div className="form-error-div">
                    {errors.teacher_arriving_time?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.teacher_arriving_time?.message}
                  </div>
                </div>
              </div>

              <div className=" row mb-4">
                <label className="col-md-3 form-label">
                  Teacher Return Time<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <DatePicker
                    id="teacher_return_time"
                    {...register("teacher_return_time")}
                    name="teacher_return_time"
                    onChange={handleReturnTimeChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    selected={teacherReturnTime}
                  />

                  <div className="form-error-div">
                    {errors.teacher_return_time?.message && (
                      <span>
                        <i className="bi bi-exclamation-circle-fill me-1"></i>
                      </span>
                    )}
                    {errors.teacher_return_time?.message}
                  </div>
                </div>
              </div>
              <div className="row mb-4" id="0">
                <label className="col-md-3 form-label">Teaching Subjects</label>
                <div className="col-md-9">
                  {subjectsfields.map((item, i) => {
                    const subjectValue = item?.subject;
                    const gradeValue = item?.grade;
                    const sectionValue = item?.section;
                    const sessionValue = item?.session;
                    // const isFieldDisabled = subjectValue || gradeValue || sectionValue || sessionValue;
                    const isFieldDisabled = false;
                    return (
                      <>
                        <div className="mb-3 row" key={item.id}>
                          <div className="col-md-3">
                            <label className="form-label">Grade</label>

                            <select
                              className="form-select"
                              {...register(`teachingSubjects.${i}.grade`)}
                              disabled={isFieldDisabled}
                            >
                              <option value="">Choose Grade</option>
                              {allGrades.map((grade, key) => (
                                <option value={grade._id} key={key}>
                                  {grade.name} ({grade.branch.name})
                                </option>
                              ))}
                            </select>
                            <div className="form-error-div">
                              {errors.teachingSubjects?.[i]?.grade?.message && (
                                <span>
                                  <i className="bi bi-exclamation-circle-fill me-1"></i>
                                </span>
                              )}
                              {errors.teachingSubjects?.[i]?.grade?.message}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <label className="form-label">Subject</label>
                            <select
                              className="form-select"
                              {...register(`teachingSubjects.${i}.subject`)}
                              disabled={isFieldDisabled}
                            >
                              <option value="">Choose...</option>
                              {allSubjects.map((subject, key) => (
                                <option value={subject._id} key={key}>
                                  {subject.name}
                                </option>
                              ))}
                            </select>
                            <div className="form-error-div">
                              {errors.teachingSubjects?.[i]?.subject
                                ?.message && (
                                <span>
                                  <i className="bi bi-exclamation-circle-fill me-1"></i>
                                </span>
                              )}
                              {errors.teachingSubjects?.[i]?.subject?.message}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <label className="form-label">Section</label>
                            <select
                              className="form-select"
                              {...register(`teachingSubjects.${i}.section`)}
                              disabled={isFieldDisabled}
                            >
                              <option value="">Choose Section</option>
                              <option value="A">A</option>
                              <option value="B">B</option>
                              <option value="C">C</option>
                              <option value="D">D</option>
                            </select>
                            <div className="form-error-div">
                              {errors.teachingSubjects?.[i]?.section
                                ?.message && (
                                <span>
                                  <i className="bi bi-exclamation-circle-fill me-1"></i>
                                </span>
                              )}
                              {errors.teachingSubjects?.[i]?.section?.message}
                            </div>
                          </div>
                          {/* <div className="col-md-3">
                            <label className="form-label">Section</label>
                            <select
                              className="form-select"
                              {...register(`teachingSubjects.${i}.session`)}
                              disabled={isFieldDisabled}
                            >
                              <option value="">Choose Session</option>
                              <option value="2023">2023</option>
                              <option value="2024">2024</option>
                            </select>
                            <div className="form-error-div">
                              {errors.teachingSubjects?.[i]?.session
                                ?.message && (
                                <span>
                                  <i className="bi bi-exclamation-circle-fill me-1"></i>
                                </span>
                              )}
                              {errors.teachingSubjects?.[i]?.session?.message}
                            </div>
                          </div> */}
                          <div className="col-md-1">
                            <i
                              className="fa fa-minus-circle text-danger mb-4 mt-6"
                              style={{ fontSize: "20px", float: "right" }}
                              onClick={() => parmanetRemove(i, item?.subject, item?.grade, item?.section)}
                            ></i>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    append({ section: "" });
                  }}
                >
                  Add Teaching Subject
                </button>
              </div>
              <br></br>
              <div className=" row mb-4 justify-content-end">
                <div className="col-md-2">
                  <ButtonComponent
                    customClass="btn btn-green"
                    text="
                      Update Teacher"
                    loading={showLoader}
                    disabled={showLoader}
                  />
                </div>
              </div>
              <div>
                <hr
                  style={{
                    backgroundColor: "black",
                    height: "2px", // Set the height to control the thickness of the line
                    border: "none",
                    margin: "10px 0", // Adjust the margin as needed for spacing
                  }}
                />
              </div>
              <div onClick={toggleAccordion} className="accordion-header">
                <h4>
                  <span style={{ marginRight: "10px" }}>
                    Other Options, Use only in special cases ( Archive Teacher )
                  </span>
                  <i
                    className={`fa ${
                      isAccordionOpen ? "fa-chevron-up" : "fa-chevron-down"
                    }`}
                  ></i>
                </h4>
              </div>
              <br></br>
              {isAccordionOpen ? (
                <div className="col-md-2">
                  <button
                    className="btn ripple btn-danger"
                    type="button"
                    onClick={() => {
                      archive();
                    }}
                  >
                    Archive Teacher
                  </button>
                </div>
              ) : (
                ""
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTeacherDetailForm;
