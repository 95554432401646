import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../../component/Sidebar";
import { getToken, getCurrentFullSession } from "../../../utility/utilityFunctions";

const StudentStrengthReport = () => {
  const [studentStrengthReports, setStudentStrengthReports] = useState([]);
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState("all");
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(getCurrentFullSession());
  const [totalRecords, setRecords] = useState(0);
  // let [totalStudents,setTotalstudents] = useState(0)
  let userInfo = localStorage.getItem("user");
  userInfo = JSON.parse(userInfo);

  useEffect(() => {
    // document.getElementsByClassName("table-responsive")[0].style.display =
    //   "none";
    // document.getElementsByClassName("loader")[0].style.display = "block";
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/branches`,
        {
          branch,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        // document.getElementsByClassName("loader")[0].style.display = "none";

        // document.getElementsByClassName("table-responsive")[0].style.display =
        //   "block";
        const { branches } = response.data;
        setBranches(branches);
      });
  }, []);

  useEffect(() => {
    document.getElementsByClassName("table-responsive")[0].style.display =
      "none";
    document.getElementsByClassName("loader")[0].style.display = "block";
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/studentStrengthReport`,
        {
          branch,
          selectedSession
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        document.getElementsByClassName("loader")[0].style.display = "none";
        document.getElementsByClassName("table-responsive")[0].style.display = "block";
        let studentData = response.data.data;
        setStudentStrengthReports(studentData);
        setRecords(studentData?.length ? studentData?.length : 0);
        setSessions(response.data?.sessions);
      });
  }, [branch, selectedSession]);

  let Registered = 0;
  let Promoted = 0;
  let registeredArchive = 0;
  let promotedArchive = 0;
  let PaidEnquired = 0;
  let Enquired = 0;
  let studentArchive = 0;
  let completed = 0;
  let inactive = 0;
  return (
    <>
      <Sidebar />
      <div className="main-content app-content">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            {/* Header Area */}
            <div className="card p-2">
              <div className="row">
                <div className="mb-3 col-9">
                  <h1 className="page-title p-2">Student's Strength Report</h1>
                </div>
                <div className="mb-3 col-3">
                  <select
                    className="form-control"
                    name=""
                    value={selectedSession}
                    id="Session"
                    onChange={(e) => {
                      setSelectedSession(e.target.value);
                    }}
                  >
                    {/* <option value="">Select Session</option> */}
                    {sessions.map((session) => {
                      return (
                        <option value={session.session_name}>
                          {session.session_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="alert alert-info" role="alert">
                -Into this list you can see all students under different status.
                This list will help you to count all students on page.<br></br>
                -If you'd like to view an overview of students for a specific status, please click on the count for that status.
              </div>
            </div>
            {/* Header Area */}
            <div className="card">
              {userInfo?.role == "financial_head" || userInfo?.role == "admin"? (
                <select
                  className="form-control"
                  name=""
                  value={branch}
                  id="branch"
                  onChange={(e) => {
                    console.log(e.target);
                    setBranch(e.target.value);
                  }}
                >
                  <option value="all">All Branches</option>
                  {branches.map((branch) => (
                    <option value={branch._id.toString()}>{branch.name}</option>
                  ))}
                </select>
              ) : (
                ""
              )}
            </div>
            <div className="row ">
              <div className="col-md-12 mt-3">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="loader" style={{ display: "none" }}>
                          <h2>Hold On !!</h2>
                          <h3>We are generating report....</h3>
                          <div
                            className="spinner-border text-danger"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                        <div className="table-responsive">
                          {totalRecords > 0 ? (
                            <>
                              <table
                                className="table table-bordered border text-nowrap mb-0"
                                id="myTable"
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <b>Sr. No</b>
                                    </th>
                                    <th>
                                      <b>Grade</b>
                                    </th>
                                    <th>
                                      <b>Branch</b>
                                    </th>
                                    <th>
                                      <b>Registered</b>
                                    </th>
                                    <th>
                                      <b>Promoted</b>
                                    </th>
                                    <th>
                                      <b>Total</b>
                                    </th>
                                    <th>
                                      <b>Paid Enquired</b>
                                    </th>
                                    <th>
                                      <b>Enquired</b>
                                    </th>
                                    <th>
                                      <b>Registered Archive</b>
                                    </th>
                                    <th>
                                      <b>Student Archive</b>
                                    </th>
                                    <th>
                                      <b>Promoted Archive</b>
                                    </th>
                                    <th>
                                      <b>Alumni</b>
                                    </th>
                                    <th>
                                      <b>Inactive</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {studentStrengthReports.map(
                                    (studentData, key) => {
                                        // Registered += studentData.total_registered;
                                        Registered += studentData.registered;
                                        // Promoted += studentData.total_promoted;
                                        Promoted += studentData.promoted;
                                        // registeredArchive += studentData.registeredArchive;
                                        registeredArchive += studentData.registeredArchive;
                                        promotedArchive += studentData.promotedArchive;                                    
                                        PaidEnquired += studentData?.paidEenquired;
                                        Enquired += studentData?.enquired;
                                        studentArchive += studentData?.studentArchive;
                                        completed += studentData?.completed;
                                        inactive += studentData?.inactive;
                                      return (
                                        <tr key={key}>
                                          <td>{key + 1}</td>
                                          <td>{studentData.grade_name}</td>
                                          <td>{studentData.branch_name}</td>
                                          <td>
                                            <a
                                              target="_blank"
                                              href={`/admin/${
                                                studentData.grade_name
                                              }/${"registered"}/${"student"}/${studentData.branch_id}/${selectedSession}`} // Replace with your desired URL structure
                                              style={{ color: "blue" }}
                                            >
                                              {/* {studentData.total_registered} */}
                                              {studentData.registered}

                                            </a>
                                          </td>
                                          <td>
                                            <a
                                              target="_blank"
                                              href={`/admin/${
                                                studentData.grade_name
                                              }/${"promoted"}/${"student"}/${studentData.branch_id}/${selectedSession}`} // Replace with your desired URL structure
                                              style={{ color: "blue" }}
                                            >
                                              {/* {studentData.total_promoted} */}
                                              {studentData.promoted}

                                            </a>
                                          </td>
                                          <td>
                                          <a
                                              target="_blank"
                                              href={`/admin/${
                                                studentData.grade_name
                                              }/${"RegisteredPromoted"}/${"student"}/${studentData.branch_id}/${selectedSession}`} // Replace with your desired URL structure
                                              style={{ color: "blue" }}
                                            >
                                            {/* {studentData.total_registered +
                                              studentData.total_promoted} */}
                                                    {studentData.registered + studentData.promoted}</a>
                                          </td>
                                          <td>
                                            <a
                                              target="_blank"
                                              href={`/admin/${
                                                studentData.grade_name
                                              }/${"paid-enquired"}/${"student"}/${studentData.branch_id}/${selectedSession}`} // Replace with your desired URL structure
                                              style={{ color: "blue" }}
                                            >
                                              {/* {studentData.total_registered} */}
                                              {studentData.paidEenquired}

                                            </a>
                                          </td>
                                          <td>
                                            <a
                                              target="_blank"
                                              href={`/admin/${
                                                studentData.grade_name
                                              }/${"enquired"}/${"student"}/${studentData.branch_id}/${selectedSession}`} // Replace with your desired URL structure
                                              style={{ color: "blue" }}
                                            > 
                                              {studentData.enquired}

                                            </a>
                                          </td> 
                                          <td>
                                            <a
                                              target="_blank"
                                              href={`/admin/${
                                                studentData.grade_name
                                              }/${"registeredArchive"}/${"studentArchive"}/${studentData.branch_id}/${selectedSession}`} // Replace with your desired URL structure
                                              style={{ color: "blue" }}
                                            >
                                              {studentData.registeredArchive}
                                            </a>
                                          </td>
                                          <td>
                                            <a
                                              target="_blank"
                                              href={`/admin/${
                                                studentData.grade_name
                                              }/${"studentArchive"}/${"studentArchive"}/${studentData.branch_id}/${selectedSession}`} // Replace with your desired URL structure
                                              style={{ color: "blue" }}
                                            >
                                              {studentData.studentArchive}
                                            </a>
                                          </td>
                                          <td>
                                            <a
                                              target="_blank"
                                              href={`/admin/${
                                                studentData.grade_name
                                              }/${"promotedArchive"}/${"studentArchive"}/${studentData.branch_id}/${selectedSession}`} // Replace with your desired URL structure
                                              style={{ color: "blue" }}
                                            >
                                              {studentData.promotedArchive}
                                            </a>
                                          </td>
                                          <td>
                                            <a
                                              target="_blank"
                                              href={`/admin/${
                                                studentData.grade_name
                                              }/${"completed"}/${"student"}/${studentData.branch_id}/${selectedSession}`} // Replace with your desired URL structure
                                              style={{ color: "blue" }}
                                            >
                                              {studentData.completed}
                                            </a>
                                          </td>
                                          <td>
                                            <a
                                              target="_blank"
                                              href={`/admin/${
                                                studentData.grade_name
                                              }/${"inactive"}/${"student"}/${studentData.branch_id}/${selectedSession}`} // Replace with your desired URL structure
                                              style={{ color: "blue" }}
                                            >
                                              {studentData.inactive}
                                            </a>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                                {/* <tfoot>
                                  <div>total students{totalStudents}</div>
                                </tfoot> */}
                              </table>
                              <table style={{ marginTop: '20px' }} className="table table-bordered border text-nowrap mb-0">
                                  <thead>
                                    <tr>
                                      <th className="page-title">Category</th>
                                      <th className="page-title">Count</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <td className="page-title">Total Student Registered</td>
                                    <td>{Registered}</td>
                                  </tr>
                                  <tr>
                                    <td className="page-title">Total Student Promoted</td>
                                    <td>{Promoted}</td>
                                  </tr>
                                  <tr style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }} >
                                    <td className="page-title">Current Active</td>
                                    <td>{Registered+Promoted}</td>
                                  </tr>
                                  <tr>
                                    <td className="page-title">Registered Archive</td>
                                    <td>{registeredArchive}</td>
                                  </tr>
                                  <tr>
                                    <td className="page-title">Student Archive</td>
                                    <td>{studentArchive}</td>
                                  </tr>
                                  <tr>
                                    <td className="page-title">Promoted Archive</td>
                                    <td>{promotedArchive}</td>
                                  </tr>
                                  <tr>
                                    <td className="page-title">Paid Enquired</td>
                                    <td>{PaidEnquired}</td>
                                  </tr>
                                  <tr>
                                    <td className="page-title">Enquired</td>
                                    <td>{Enquired}</td>
                                  </tr>
                                  <tr>
                                    <td className="page-title">Alumni</td>
                                    <td>{completed}</td>
                                  </tr>
                                  <tr>
                                    <td className="page-title">Inactive</td>
                                    <td>{inactive}</td>
                                  </tr>
                                  <tr style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                                    <td className="page-title">Total Student</td>
                                    <td>{Registered + Promoted + registeredArchive + studentArchive + promotedArchive + PaidEnquired + Enquired + completed + inactive}</td>
                                  </tr>
                                </tbody>

                                </table>

                            </>
                          ) : (
                            <h1>No record found.</h1>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentStrengthReport;
