import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../component/Sidebar";

const AddBranchForm = () => {
  const data = {
    name: "",
    email: "",
    phone: "",
    location: ""
  };

  const [formData, setformData] = useState(data);
  const navigate = useNavigate();
  
  const updateValue = (e) => {
    const field = e.target.id;
    const value = e.target.value;
    setformData({
      ...formData,
      [field]: value,
    });
  };

  const createBranch = () => {
    console.log(formData);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/branch/new`, formData)
      .then((response) => {
        console.log(response);
        if (response?.data.success) {
          window.notif({
            msg: "<b>Woohoo!!</b> Branch Created Successfully",
            type: "success",
          });
          setformData(data);
        } else {
          window.notif({
            msg: response?.data.error,
            type: "error",
            position: "center",
          });
        }
      }).catch(err => {
        console.log("err", err);
        // navigate("/admin/login");
      });
  };

  return (
    <>
    <Sidebar />
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Fill following information</h4>
          </div>
          <div className="card-body">
            <form className="form-horizontal">
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Name</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    value={formData.name}
                    id="name"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Email</label>
                <div className="col-md-9">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={formData.slug}
                    id="email"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Mobile</label>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="mobile"
                    id="phone"
                    value={formData.phone}
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <label className="col-md-3 form-label">Location</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Location"
                    value={formData.location}
                    id="location"
                    onChange={(e) => {
                      updateValue(e);
                    }}
                  />
                </div>
              </div>
              <div className=" row mb-4">
                <div className="col-md-3">
                  <button
                    type="button"
                    class="btn btn-danger text-light"
                    onClick={() => {
                      createBranch();
                    }}
                  >
                    <i class="fe fe-user-plus"></i>&nbsp;Add Branch
                  </button>
                </div>
              </div>           
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBranchForm;
