import axios from "axios";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar";
import { getToken } from "../../utility/utilityFunctions";
import { Link } from "react-router-dom";

function GreaterSessionStudents() {
  const { id } = useParams();
  const [students, setStudents] = useState([]);
  let count = 1;

  useEffect(() => {
    const loaderElement = document.getElementsByClassName("loader")[0];
    if (loaderElement) {
      // If the loader element exists, you can modify its display style
      loaderElement.style.display = "block"; // or "block" or any other desired value
    }
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/admin/getGreaterSessionStudents`;
    axios
      .post(
        apiUrl,
        {},
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        const { finalData } = response.data;
        setStudents(finalData);
        console.log("finalData:", finalData);
        if (loaderElement) {
            // If the loader element exists, you can modify its display style
            loaderElement.style.display = "none"; // or "block" or any other desired value
          }
      });
  }, []);

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div
              style={{
                backgroundColor: "white",
                padding: "25px",
                marginBottom: "10px",
              }}
            >
              <div className="">
                <h1
                  className="page-title"
                  style={{ marginLeft: "12px", marginBottom: "15px" }}
                >
                  Students wrong session
                </h1>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="loader" style={{ display: "none" }}>
                <h2>Hold On !!</h2>
                <h3>We are generating report....</h3>
                <div className="spinner-border text-danger" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
{students.length ?   <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Sr. No</b>
                                </th>
                                <th>
                                  <b>Students</b>
                                </th>

                                <th>
                                  <b>Mobile</b>
                                </th>
                                <th>
                                  <b>Father</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {students.map((student) => {
                                return (
                                  <React.Fragment key={student._id}>
                                    {/* First <tr> for basic teacher information */}

                                    <tr>
                                      <td>{count++}</td>

                                      <td>
                                        <Link
                                          to={`/admin/students/${student?._id}`}
                                        >
                                          {student?.name}
                                        </Link>
                                      </td>

                                      <td>{student?.mobile}</td>
                                      <td>{student?.father_name}</td>
                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> : ""}
          
          </div>
        </div>
      </div>
    </>
  );
}
export default GreaterSessionStudents;
