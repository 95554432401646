import React, { useState } from "react";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import { Link } from "react-router-dom";
import { getToken } from "../../utility/utilityFunctions";
import placeholder from "../../assets/images/brand/placeholder.png";

const CollectFee = () => {
  const [name, setName] = useState("");
  const [students, setStudents] = useState([]);

  const searchStudent = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/search-student`,
        { name : name.trim(),
          // father_name: fatherName.trim(), },
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response?.data.success) {
          const { students } = response.data;
          setStudents(students);
        } else {
          window.notif({
            msg: response?.data.error,
            type: "error",
            position: "center",
          });
        }
      });
  };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <h1 className="page-title">Collect Fee</h1>
            </div>

            <div className="row ">


              <div className="col-md-12 d-flex">
                <input
                  type="text"
                  className="form-control dropdown-toggle display-6"
                  id="typehead"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder="Enter Mobile Number or Father Name ..."
                />

              
                <button
                  className="btn px-0 pt-2 bg-danger text-light p-5"
                  onClick={() => {
                    searchStudent();
                  }}
                >
                  <i class="fe fe-search" aria-hidden="true"></i>
                </button>
              </div>
            </div>

            {students.length ? (
              <div className="row mt-5">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table
                              className="table table-bordered border text-nowrap mb-0"
                              id="basic-edit"
                            >
                              <thead>
                                <tr>
                                <th>
                                    <b>SR.No</b>
                                  </th>
                                <th>
                                    <b>Image</b>
                                  </th>
                                  <th>
                                    <b>Name</b>
                                  </th>
                                  <th>
                                    <b>Grade</b>
                                  </th>
                                  <th>
                                    <b>Father/Guardian Name</b>
                                  </th>
                                  <th>
                                    <b>Mobile</b>
                                  </th>
                                  <th>
                                    <b>Collect Fee</b>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {students.map((student) => {
                                  return (
                                    <tr key={student._id}>
                                      <td>{student?.sr_no ? student?.sr_no : "-"}</td>
                                      <td>{student.profile_pic ?
                                      <>
                                      <img
                                        width={"70px"}
                                        src={
                                          process.env.REACT_APP_BASE_URL +
                                          "/admin/images/" +
                                          student.profile_pic
                                        }
                                        alt={student.name}
                                      />
                                      </> : <><img width={"70px"} src={placeholder} />
                                      </>}</td>
                                      <td>
                                        <Link
                                          to={`/admin/collect-fee/${student._id}`}
                                        >
                                          {student.name}
                                        </Link>
                                      </td>
                                      <td>{student.grade}</td>
                                      <td>{student.father_name}</td>
                                      <td>{student.mobile}</td>
                                      <td>
                                        <Link
                                          to={`/admin/collect-fee/${student._id}`}
                                        >
                                          <i class="icon icon-wallet ml-2" />
                                          <span>Collect</span>
                                        </Link>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center mt-5">No Data Found</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectFee;
