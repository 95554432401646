import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import {
  getToken,
  timezoneDateToddMMyyyy,
  getCurrentFullSession,
} from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";

const Exam = () => {
  const [exams, setExams] = useState([]);
  const [session, setSession] = useState(getCurrentFullSession());
  const [sessions, setSessions] = useState([]);
  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/exams`,{ session }, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { exams ,sessions} = response.data;
        setExams(exams);
        setSessions(sessions);

        console.log("exams:",exams)
      });
  }, [session]);

  const deleteEvent = async (eventId) => {
    if (window.confirm("Are you sure want to delete the exam ?")) {
      axios
        .delete(`${process.env.REACT_APP_BASE_URL}/admin/exam/${eventId}`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then((response) => {
          const { exams } = response.data;
          setExams(exams);
        });
    }
  };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <GoBackButton />
              <div className="col-md-4 d-flex">
                <div className="mb-3 col-6">
                  {/* <select
                    className="form-control"
                    name=""
                    value={Session}
                    id="session"
                    onChange={(e) => {
                      setSession(e.target.value);
                    }}
                  >
                    <option value="2022">Session 2022 - 2023</option>
                    <option value="2023">Session 2023 - 2024</option>
                  </select> */}

                  <label htmlFor="example-session" className="col-6 form-label">
                    Session
                  </label>

                  <select
                    className="form-control"
                    name=""
                    value={session}
                    id="Session"
                    onChange={(e) => {
                      setSession(e.target.value);
                    }}
                  >
                    {/* <option value="">Select Session</option> */}
                    {sessions.map((session) => {
                      return (
                        <option value={session.session_name}>
                          {session.session_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <h1 className="page-title">Exams</h1>
              <Link
                type="button"
                to="/admin/exam/new"
                class="btn btn-danger text-light"
              >
                <i class="fe fe-user-plus"></i>&nbsp;Add Exam
              </Link>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Date</b>
                                </th>
                                <th>
                                  <b>Exam</b>
                                </th>
                                <th>
                                  <b>Session</b>
                                </th>
                                <th>
                                  <b>Branches</b>
                                </th>
                                <th>
                                  <b>Grades</b>
                                </th>
                                <th>
                                  <b>Subjects</b>
                                </th>
                                <th>
                                  <b>Created By</b>
                                </th>
                                <th>
                                  <b>Action</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {exams.map((exam) => {
                                return (
                                  <tr key={exam._id}>
                                    <td>{timezoneDateToddMMyyyy(exam.date)}</td>
                                    <td>{exam.name}</td>
                                    <td>{exam?.session}</td>

                                    <td>
                                      <div
                                        style={{
                                          whiteSpace: "normal", // or "break-spaces" depending on your requirements
                                          wordWrap: "break-word",
                                          width: "100px",
                                        }}
                                      >
                                        {exam.branches.map((branch) => {
                                          return branch.name + ", ";
                                        })}
                                      </div>
                                    </td>
                                    <td>
                                      {exam.grades.map((grade) => {
                                        return grade.name + ", ";
                                      })}
                                    </td>
                                    <td>
                                      {exam.subjects.map((subject) => {
                                        return subject.name + ", ";
                                      })}
                                    </td>
                                    <td>{exam?.created_by?.name}</td>
                                    <td>
                                      <Link to={`${exam?._id}`}>
                                        <button class="btn btn-primary text-light m-2">
                                          Edit
                                        </button>
                                      </Link>
                                      <button
                                        type="button"
                                        class="btn btn-danger text-light"
                                        onClick={() => deleteEvent(exam._id)}
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Exam;
