import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  addTokenInCookies,
  removeCookies,
  getToken,
} from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";
import Sidebar from "../../component/Sidebar";
import { useParams, useNavigate, Link } from "react-router-dom";
const UpdatePassword = () => {
  const data = {
    name: "",
    password: "",
    mobile: "",
    status: "",
  };
  const [isPassword, setIsPassword] = useState(true);

  const [formData, setformData] = useState(data);
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/toUpdateUserDetail/${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        console.log("response", response);
        const { UserData } = response.data;
        setformData(UserData);
      });
  }, []);

  const updateSetting = () => {
    console.log("formData:",formData)
    if (formData.password.length > 0 && formData.password.length < 10 || formData.password =="") {
      window.notif({
        msg: "Password must be equal/greater than 10 characters.",
        type: "error",
        position: "center",
      });
      return false;
    }

    if (formData.mobile.length > 0 && formData.mobile.length < 10) {
      window.notif({
        msg: "Please 10 characters.",
        type: "error",
        position: "center",
      });
      return false;
    }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/updateUserPwd`, formData, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
        
          window.notif({
            msg: "Settings Updated Successfully..",
            type: "success",
            position: "right",
          });
        //   navigate('/admin/students')
        navigate(-1);
        } else {
          window.notif({
            msg: response.data.message,
            type: "error",
          });
        }
      });
  };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <GoBackButton />
              <h1 className="page-title">Settings</h1>
            </div>
            <div className="col-xl-12 col-lg-12">
              <div className="card">
                <div className="card-header">
                  {/* <h4 className="card-title">Fill following information</h4> */}
                  <span className="badge bg-secondary badge-sm  me-1 mb-1 mt-1 ml-10">
                    {formData?.status[0]?.toUpperCase() +
                      formData?.status?.slice(1)?.toLowerCase()}
                  </span>
                </div>
                <div className="card-body">
                  <form className="form-horizontal">
                    <div className=" row mb-4">
                      <label className="col-md-3 form-label">Name</label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          id="name"
                          value={formData.name}
                          onChange={(e) => {
                            setformData({
                              ...formData,
                              name: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className=" row mb-4">
                      <label className="col-md-3 form-label">Mobile</label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mobile"
                          id="mobile"
                          value={formData.mobile}
                          onChange={(e) => {
                            setformData({
                              ...formData,
                              mobile: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="row mb-4">
                      <label className="col-md-3 form-label">
                        New Password
                      </label>
                      <div className="col-md-9 d-flex align-items-center">
                        <input
                          type={isPassword ? "password" : "text"}
                          className="form-control"
                          placeholder="New Password"
                          id="password"
                          value={formData.password}
                          onChange={(e) => {
                            setformData({
                              ...formData,
                              password: e.target.value,
                            });
                          }}
                        />
                        <span
                          className=""
                          style={{ position: "absolute", right: "20px" }}
                        >
                          <i
                            className={`zmdi ${
                              !isPassword ? "zmdi-eye" : "zmdi-eye-off"
                            }`}
                            aria-hidden="true"
                            onClick={() => {
                              setIsPassword(!isPassword);
                            }}
                          />
                        </span>
                      </div>
                    </div>

                    <div className=" row mb-4">
                      <div className="col-md-3">
                        <button
                          type="button"
                          class="btn btn-danger text-light"
                          onClick={() => {
                            updateSetting();
                          }}
                        >
                          <i class="fe fe-user-plus"></i>&nbsp;Update
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePassword;
