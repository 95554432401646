import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link, Navigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import axios from "axios";
import GoBackButton from "../../component/GoBackButton";
import Sidebar from "../../component/Sidebar";
import { getToken } from "../../utility/utilityFunctions";

const EditSession = () => {
  const { register, handleSubmit, setValue, reset } = useForm();
  const [ sessions, setSession ] = useState(null);
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/getSessions/${params.id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { session } = response.data;
        console.log("session", session);
        setSession(session);
         // Set the form field values here
         setValue("session_name", session.session_name); // Replace "session_name" with your actual field name
         setValue("default_session", session.default_session); 
         setValue("default_session_admission", session.default_session_admission); 

      });
  }, [params.id, setValue]);
  // Handle form submission
  const onSubmit = async (data) => {
    data.id = sessions._id
    console.log("data:", data);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/sessionupdate`,
        data,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`, // Add Authorization header
          },
        }
      );

      if (response?.data.success) {
        window.notif({
          msg: "<b>Woohoo!!</b> User Record Created Successfully",
          type: "success",
        });
        // Reset the form after successful submission
        navigate('/admin/sessionManager')
        // reset();
      } else {
        window.notif({
          msg: response?.data.error,
          type: "error",
          position: "center",
        });
      }
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };
let count = 1;
  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <GoBackButton />
              <h1 className="page-title">Manage Session</h1>
            </div>
            <div className="col-xl-12 col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Fill following information</h4>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mb-4">
                      <label className="col-md-3 form-label">Session:</label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          placeholder=" eg. 2022-2023"
                          {...register("session_name", { required: true })}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <label className="col-md-3 form-label">Default:</label>
                      <div className="col-md-9">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          {...register("default_session")}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <label className="col-md-3 form-label">Default_Admission:</label>
                      <div className="col-md-9">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          {...register("default_session_admission")}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-3">
                        <button
                          type="submit"
                          className="btn btn-danger text-light"
                        >
                          <i className="fe fe-user-plus"></i>&nbsp;Update
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSession;
