import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useParams, useNavigate, Link } from "react-router-dom";
import GoBackButton from "../../component/GoBackButton";
import Sidebar from "../../component/Sidebar";
import { useState } from "react";
import { useEffect } from "react";
import { getToken } from "../../utility/utilityFunctions";
import axios from "axios";

function AppraisalSettingForm() {
  const [teacherData, setTeacherData] = useState({});
  const { handleSubmit, register, setValue } = useForm();
  const params = useParams();
  // const { id } = params;

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/teacherAppraisals/${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        const { teacherData } = response.data;
        console.log("response.data:", response.data);
        if (teacherData) {
          setTeacherData(teacherData);
        } else {
          console.error("No teacher data found in the response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching teacher data:", error);
      });
  }, []);

  const onSubmit = (data) => {
    // Handle form submission here
    console.log("form data:", data);

    // Make a POST request to update teacher appraisal data
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/teacherAppraisals/update/${params.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        if (response?.data.status) {
          window.notif({
            msg: "<b>Woohoo!!</b> Teacher Record Updated Successfully",
            type: "success",
          });
      
          // Reload the page after a delay (e.g., 1 second)
          setTimeout(() => {
            window.location.reload();
          }, 1000); // Adjust the delay time as needed
        }
      })
      .catch((error) => {
        // Handle error, e.g., show an error message
        console.error("Error updating teacher appraisal:", error);
      });
  };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <GoBackButton />
              <h1 className="page-title">Teacher Appraisal</h1>
            </div>
            <div className="card-body">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="form-horizontal"
              >
                <input type="hidden" name="user_id" value={params.id} />
                {/* Add your @csrf field here */}

                {/* Arrival Time */}
                <div className="row">
                  <div className="col-md-9 text-blue">
                    <label className="form-label" htmlFor="first_name">
                      Arrival Time
                    </label>
                  </div>
                </div>

                {/* Arrival Time Rows */}
                <div className="row">
                  {Array.from({ length: 3 }, (_, index) => (
                    <div className="col-md-4" key={index}>
                      {Array.isArray(teacherData?.timing) &&
                        teacherData.timing.length > index && (
                          <>
                            <div className="mb-3">
                              <label
                                className="form-label col-md-6"
                                htmlFor={`timing[${index}].start_time`}
                              >
                                Start Timing
                              </label>
                              <input
                                type="time"
                                className="form-control"
                                {...register(`timing[${index}].start_time`)}
                                defaultValue={
                                  teacherData?.timing[index]?.start_time || ""
                                }
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                className="form-label col-md-6"
                                htmlFor={`timing[${index}].end_time`}
                              >
                                End Timing
                              </label>
                              <input
                                type="time"
                                className="form-control"
                                {...register(`timing[${index}].end_time`)}
                                defaultValue={
                                  teacherData?.timing[index]?.end_time || ""
                                }
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                className="form-label col-md-3"
                                htmlFor={`timing[${index}].point`}
                              >
                                Points
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...register(`timing[${index}].point`)}
                                defaultValue={
                                  teacherData?.timing[index]?.point || ""
                                }
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                className="form-label col-md-3"
                                htmlFor={`timing[${index}].leave`}
                              >
                                Leave
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...register(`timing[${index}].leave`)}
                                defaultValue={
                                  teacherData?.timing[index]?.leave || ""
                                }
                              />
                            </div>
                          </>
                        )}
                    </div>
                  ))}
                </div>

                {/* Strength Blow Condition */}
                <div className="row">
                  <div className="col-md-12 text-blue">
                    <label className="form-label col-md-3" htmlFor="first_name">
                      Strength Blow Condition
                    </label>
                  </div>
                </div>

                {/* Strength Blow Condition Rows */}
                <div className="row">
                  {Object.entries(teacherData?.strength_blow || {}).map(
                    ([key, value]) => (
                      <div className="col-md-4" key={key}>
                        <div className="mb-1">
                          <label
                            className="form-label col-md-6"
                            htmlFor={`strength_blow[${key}]`}
                          >
                            {key}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            {...register(`strength_blow[${key}]`)}
                            defaultValue={value || ""}
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>

                {/* Strength Above Condition */}
                <div className="row">
                  <div className="col-md-12 text-blue">
                    <label className="form-label col-md-3" htmlFor="first_name">
                      Strength Above Condition
                    </label>
                  </div>
                </div>

                {/* Strength Above Condition Rows */}
                {/* <div className="row">
                  {Object.entries(teacherData?.strength_above || {}).map(
                    ([key, value]) => (
                      <div className="col-md-4" key={key}>
                        <div className="mb-1">
                          <label
                            className="col-md-6 form-label"
                            htmlFor={`strength_above[${key}]`}
                          >
                            {key}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            {...register(`strength_above[${key}]`)}
                            defaultValue={value || ""}
                          />
                        </div>
                      </div>
                    )
                  )}
                </div> */}
                <div className="row">
                  {Object.entries(teacherData?.strength_above || {}).map(
                    ([key, value]) => (
                      <div className="col-md-4" key={key}>
                        <div className="mb-1">
                          <label
                            className="col-md-6 form-label"
                            htmlFor={`strength_above[${key}]`}
                          >
                            {key}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            {...register(`strength_above[${key}]`)}
                            defaultValue={
                              value === undefined || value === null ? "" : value
                            }
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>

                {/* Withdrawals */}
                <div className="row">
                  <div className="col-md-12 text-blue">
                    <label className="form-label col-md-3" htmlFor="first_name">
                      Withdrawals
                    </label>
                  </div>
                </div>

                {/* Withdrawals Rows */}
                <div className="row">
                  {Object.entries(teacherData?.withdraw_at || {}).map(
                    ([key, value]) => (
                      <div className="col-md-4" key={key}>
                        <div className="mb-1">
                          <label
                            className="col-md-6 form-label"
                            htmlFor={`withdraw_at[${key}]`}
                          >
                            {key}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            {...register(`withdraw_at[${key}]`)}
                            defaultValue={value || ""}
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>

                {/* Withdraw Below */}
                <div className="row">
                  <div className="col-md-12 text-blue">
                    <label className="form-label col-md-3" htmlFor="first_name">
                      WITHDRAWALS BELOW CONDITION
                    </label>
                  </div>
                </div>

                {/* Withdraw Below Rows */}
                <div className="row">
                  {Object.entries(teacherData?.withdraw_below || {}).map(
                    ([key, value]) => (
                      <div className="col-md-4" key={key}>
                        <div className="mb-1">
                          <label
                            className="col-md-6 form-label"
                            htmlFor={`withdraw_below[${key}]`}
                          >
                            {key}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            {...register(`withdraw_below[${key}]`)}
                            defaultValue={value || ""}
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>

                {/* Leaves */}
                <div className="row">
                  <div className="col-md-12 text-blue">
                    <label className="form-label col-md-3" htmlFor="first_name">
                      Leaves
                    </label>
                  </div>
                </div>

                {/* Leaves Rows */}
                <div className="row">
                  {Object.entries(teacherData?.Leaves || {}).map(
                    ([key, value]) => (
                      <div className="col-md-4" key={key}>
                        <div className="mb-1">
                          <label
                            className="col-md-6 form-label"
                            htmlFor={`Leaves[${key}]`}
                          >
                            {(() => {
                              switch (key) {
                                case "NYY":
                                  return "LEAVES NO, INFORMED YES, APPROVED YES";
                                case "NNN":
                                  return "LEAVES NO, INFORMED NO, APPROVED NO";
                                case "YNN":
                                  return "LEAVES YES, INFORMED NO, APPROVED NO";
                                case "NYN":
                                  return "LEAVES NO, INFORMED YES, APPROVED NO";
                                default:
                                  return key;
                              }
                            })()}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            {...register(`Leaves[${key}]`)}
                            defaultValue={value || ""}
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>

                {/* First Column */}
                <div className="row">
                  {/* No Leave Event */}
                  <div className="col-md-12 text-blue">
                    <label className="form-label col-md-3" htmlFor="first_name">
                      OTHERS
                    </label>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label
                        className="form-label col-md-6"
                        htmlFor="no_leave_event"
                      >
                        No Leave Event
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("no_leave_event")}
                        defaultValue={teacherData?.no_leave_event || ""}
                      />
                    </div>
                  </div>

                  {/* Copy Checking */}
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label
                        className="form-label col-md-6"
                        htmlFor="copychecking"
                      >
                        Copy Checking
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("copychecking")}
                        defaultValue={teacherData?.copychecking || ""}
                      />
                    </div>
                  </div>
                </div>
                {/* Tasks column */}
                <div className="row">
                  {/* CW */}
                  <div className="col-md-12 text-blue">
                    <label className="form-label col-md-3" htmlFor="first_name">
                      Chapter Tasks
                    </label>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label className="form-label col-md-6" htmlFor="cw">
                        CW
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        {...register("CW")}
                        defaultValue={teacherData?.CW }
                      />
                    </div>
                  </div>

                  {/* HW */}
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label className="form-label col-md-6" htmlFor="hw">
                        HW
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        {...register("HW")}
                        defaultValue={teacherData?.HW }
                      />
                    </div>
                  </div>
                  {/* Activity*/}
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label className="form-label col-md-6" htmlFor="activity">
                        Activity
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        {...register("Activity")}
                        defaultValue={teacherData?.Activity }
                      />
                    </div>
                  </div>
                </div>
                {/* Second Column */}
                <div className="row">
                  <div className="col-md-12 text-blue">
                    <label className="form-label col-md-3" htmlFor="first_name">
                      Resultes
                    </label>
                  </div>
                  {/* Result (70-100) */}
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label className="form-label" htmlFor="result[70-100]">
                        GRATER 70%
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("result.70-100")}
                        defaultValue={teacherData?.result?.["70-100"] }
                      />
                    </div>
                  </div>

                  {/* Result (0-70) */}
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label className="form-label" htmlFor="result[0-70]">
                        LESS 70%
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("result.0-70")}
                        defaultValue={teacherData?.result?.["0-70"] }
                      />
                    </div>
                  </div>
                </div>

                {/* Third Column */}
                <div className="row">
                  <div className="col-md-12 text-blue">
                    <label className="form-label col-md-3" htmlFor="first_name">
                      CH-Resultes
                    </label>
                  </div>
                  {/* Ch Result (70-100) */}
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label className="form-label" htmlFor="ch_result[70-100]">
                        GRATER 70%
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("ch_result?.70-100")}
                        defaultValue={teacherData?.ch_result?.["70-100"] }
                      />
                    </div>
                  </div>
                  {/* Ch Result (0-70) */}
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label className="form-label " htmlFor="ch_result[0-70]">
                        LESS 70%
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("ch_result?.0-70")}
                        defaultValue={teacherData?.ch_result?.["0-70"]}
                      />
                    </div>
                  </div>

                  {/* Add more fields here */}
                </div>

                {/* Submit Button */}
                <div className="mt-4">
                  <button type="submit" className="btn btn-primary">
                    Save Changes
                  </button>
                </div>
              </form>
            </div>

            <div className="row ">
              <div className="col-md-12"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppraisalSettingForm;
