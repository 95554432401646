// const moment = require('moment')
import moment from 'moment';

export function getDate(prams = {format: "YYYY-MM-DD",addDate: {}}) {
    var format = "YYYY-MM-DD";
    let date = moment();
    if(prams.Date) {
        date = moment(prams.Date);
    }
    if(prams.addDate) {
        date = date.add(prams.addDate)
    }

    if(prams.format) {
        format = prams.format;
    }
    date = date.format(format)    
    return date;
};