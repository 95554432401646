import React, { useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import "../../App.css";
import {
  getToken,
  timezoneDateToddMMyyyy,
} from "../../utility/utilityFunctions";
import { Link } from "react-router-dom";
import GoBackButton from "../../component/GoBackButton";

const TeachersLeave = () => {
  const [leavesData, setleavesData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/teacher_leaves`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        console.log("response", response);
        const { finalArray } = response.data;
        setleavesData(finalArray);
      });
  }, []);

  const leaveAction = (status, leaveId, teacherId) => {
    if (window.confirm(`Are you sure want to ${status} the request ?`)) {
      if(status == "deny"){
        var reason = prompt("Please enter your reason to deny","defaul reason");
        console.log("reason:",reason)
        if(!reason){
          return ;
        }
      }
       // Capture leaveId and teacherId before the if statement
    const leaveIdToSend = leaveId;
    const teacherIdToSend = teacherId;
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/admin/teacher_leaves_action`,
          {
            status,
            leaveId: leaveIdToSend,
            teacherId: teacherIdToSend,
            reason,

          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          window.location.reload();
        });
    }
  };

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
            <GoBackButton />
              <h1 className="page-title">Teacher Leaves</h1>
            </div>
            <div className="col-xl-12 col-lg-12">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        className="table table-bordered border text-nowrap mb-0"
                        id="basic-edit"
                      >
                        <tbody>
                          {leavesData.map((data, index) => {
                            if (data?.user) {
                            return (
                              <tr key={data?.user?._id}>
                                <td>
                                    {index+1}
                                </td>
                                <td>
                                  <div className="d-flex">
                                    <div
                                      style={{
                                        width: "50%",
                                        "line-height": "1.8",
                                      }}
                                    >
                                      {data.leave_type == "c" ? (
                                        <span className="badge bg-secondary badge-sm  me-1 mb-1 mt-1 ml-10">
                                          Casual
                                        </span>
                                      ) : (
                                        <span className="badge bg-primary badge-sm  me-1 mb-1 mt-1 ml-10">
                                          Medical
                                        </span>
                                      )}
                                      <br />
                                      <b>From : </b>{" "}
                                      {timezoneDateToddMMyyyy(data.leave_from)}
                                      <br />
                                      <b>To : </b>
                                      {timezoneDateToddMMyyyy(data.leave_to)}
                                      <br />
                                      <b>Applied On : </b>
                                      {timezoneDateToddMMyyyy(data.applied_on)}
 
                                      <br />
                                      <div>
                                        <b>Reason :</b>
                                        <span>{data.description}</span>
                                      </div>
                                      <br/>
                                      {data.is_approved == null ? (
                                        <span className="badge bg-danger badge-sm  me-1 mb-1 mt-1 ml-10">
                                          Waiting
                                        </span>
                                      ) : data.is_approved == false ? (
                                        <span className="badge bg-warning badge-sm  me-1 mb-1 mt-1 ml-10">
                                          Unapproved
                                        </span>
                                      ) : (
                                        <span className="badge bg-success badge-sm  me-1 mb-1 mt-1 ml-10">
                                          Approved
                                        </span>
                                      )}
                                      
                                    </div>
                                    <div
                                      style={{ width: "30%" }}
                                      className="d-flex flex-column justify-center align-items-center"
                                    >
                                      <b className="mb-2">{data.user?.name}</b>
                                      {data.user.profile_pic ? (
                                        <>
                                          <img
                                        width={"70px"}
                                        src={
                                          process.env.REACT_APP_BASE_URL +
                                          "/admin/images/" +
                                          (data.user.profile_pic || 'default-image.jpg')
                                        }
                                        alt={data.user?.name}
                                        onError={(e) => {
                                          e.target.src = process.env.REACT_APP_BASE_URL+ '/admin/images/default-image.png'; // Replace 'default-image.jpg' with the path to your default image
                                        }}
                                      />
                                        </>
                                      ) : (
                                        <>
                                         <img
                                      src={process.env.REACT_APP_BASE_URL + '/admin/images/default-image.png'}
                                      width="70px"
                                    />

                                        </>
                                      )}
                                      <br />
                                    </div>
                                    {data.is_approved == null ? (
                                      <>
                                        <div className="action-btns">
                                          <div class="d-flex justify-content-between">
                                            <button
                                              type="button"
                                              className="btn-circle-success"
                                              onClick={() => {
                                                leaveAction(
                                                  "approve",
                                                  data.id,
                                                  data?.user?._id
                                                );
                                              }}
                                            >
                                              <i class="icon icon-like"></i>
                                            </button>
                                            <button
                                              type="button"
                                              className="btn-circle-close"
                                              onClick={() => {
                                                leaveAction(
                                                  "deny",
                                                  data.id,
                                                  data?.user?._id
                                                );
                                              }}
                                            >
                                              <i class="icon icon-dislike"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {data.is_approved ? (
                                          <>
                                            <div
                                              className="d-flex flex-column justify-content-center align-items-center"
                                              style={{ width: "20%" }}
                                            >
                                              <h4>Approved On</h4>
                                              <h5>
                                                {timezoneDateToddMMyyyy(
                                                  data.approved_on
                                                )}
                                              </h5>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                           <div
                                              className="d-flex flex-column justify-content-center align-items-center"
                                              style={{ width: "20%" }}
                                            >
                                              <h4>Declined On</h4>
                                              <h5>
                                                {timezoneDateToddMMyyyy(
                                                  data.approved_on
                                                )}
                                              </h5>
                                            </div>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                                                }else{
                                                  return null; // or a placeholder element if needed
                                                }
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeachersLeave;
