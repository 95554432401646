import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import { getToken } from "../../utility/utilityFunctions";
import GoBackButton from "../../component/GoBackButton";

const TeacherReport = () => {
  const [teacherReports, setTeacherReports] = useState([]);
  const [branch, setBranch] = useState("all");
  const [session, setSession] = useState(2023);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/teacherReports`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { teacherReports } = response.data;
        setTeacherReports(teacherReports);
      });

      
  }, [branch]);

  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
            <GoBackButton />
              <div className="col-md-6 d-flex">
                <div className="">
                  <select
                    className="form-control"
                    name=""
                    value={branch}
                    id="branch"
                    onChange={(e) => {
                      setBranch(e.target.value);
                    }}
                  >
                    <option value="all">All Branches</option>
                    <option value="amritpuri">Amritpuri</option>
                    <option value="wecanhigh">
                      WeCan High
                    </option>
                  </select>
                </div>

                <div className="">
                  <select
                    className="form-control"
                    name=""
                    value={session}
                    id="session"
                    onChange={(e) => {
                      setSession(e.target.value);
                    }}
                  >
                    <option value="2023">Session 2023 - 2024</option>
                  </select>
                </div>
              </div>

              <h1 className="page-title">Teachers Report</h1>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            className="table table-bordered border text-nowrap mb-0"
                            id="basic-edit"
                          >
                            <thead>
                              <tr>
                                <th>
                                  <b>Name</b>
                                </th>
                                <th>
                                  <b>Branch</b>
                                </th>
                                <th>
                                  <b>Appraisal</b>
                                </th>
                                <th>
                                  <b>Half Days</b>
                                </th>
                                <th>
                                  <b>Full Days</b>
                                </th>
                                <th>
                                  <b>Irregular Timings</b>
                                </th>
                                <th>
                                  <b>Not assign task on time</b>
                                </th>
                                <th>
                                  <b>Times informed</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {teacherReports.map((teacherReport) => {
                                return (
                                  <tr key={teacherReport._id}>
                                    <td>{teacherReport.name}</td>
                                    <td>{teacherReport.slug}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherReport;
