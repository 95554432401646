import axios from "axios";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar";
import { getToken } from "../../utility/utilityFunctions";
import { Link } from "react-router-dom";

function CopyCheckingDetail() {
  const { teacher_id ,subject,common_grade,section,syllabus_id } = useParams();
//   const [teacher, setTeacher] = useState(null);

  const [teacherSyllabuses, setTeacherSyllabuses] = useState(null);
 
  const params = useParams();

  let count = 1;

  useEffect(() => {
    const loaderElement = document.getElementsByClassName("loader")[0];
    if (loaderElement) {
      // If the loader element exists, you can modify its display style
      loaderElement.style.display = "block"; // or "block" or any other desired value
    }
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/teacher/teacherSyllabusForCopyCheckingDetail`;
    axios
      .post(
        apiUrl,
        {
            teacher_id: params.teacher_id,
          subject: params.subject,
          common_grade: params.common_grade,
          section: params.section,
          syllabus_id: params.syllabus_id,

        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        const {finalCopyCheckingDetail } =
          response.data;
        //   setTeacher(teacherUserData);
          setTeacherSyllabuses(finalCopyCheckingDetail);
       

        if (loaderElement) {
          // If the loader element exists, you can modify its display style
          loaderElement.style.display = "none"; // or "block" or any other desired value
        }
      });
  }, []);
  function formatDate(inputDateStr) {
    const date = new Date(inputDateStr);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }
  return (
    <>
      <Sidebar />
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="col-sm-12 card pb-2 pt-2">
              <div className="col-sm-12">
                <div className="card">
                <h1 className="page-title p-2"> Copy checking detail</h1>

                  {/* <div class="alert alert-info" role="alert">
                  Students those were promoted from last class and paid less than 3000 INR.
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-sm-4">
                <div className="loader" style={{ display: "none" }}>
                  <h2>Hold On !!</h2>
                  <h3>We are generating report....</h3>
                  <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              {1 ? (
                <div className="row ">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="table-responsive">
                              {/* <h1>Assigned HomeWork</h1> */}

                              <table
                                className="table table-bordered border text-nowrap mb-0"
                                id="basic-edit"
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <b>Sr.No</b>
                                    </th>
                                    <th>
                                      <b> Chapter No.</b>
                                    </th>
                                    <th>
                                      <b> Chapter</b>
                                    </th>
                                    <th>
                                      <b>Chacking Date</b>
                                    </th>
                                    <th>
                                      <b>Checked On</b>
                                    </th>
                                    <th>
                                      <b>Copy Check</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {teacherSyllabuses?.map((teacherSyllabuse) => {
                                    return (
                                      <React.Fragment key={teacherSyllabuse}>
                                        {/* First <tr> for basic teacher information */}

                                        <tr>
                                          <td>{count++}</td>

                                         
                                          <td>{teacherSyllabuse?.ch_no}</td>
                                          <td> <div
                                        style={{
                                          whiteSpace: "normal", // or "break-spaces" depending on your requirements
                                          wordWrap: "break-word",
                                          width: "100px",
                                        }}
                                      >{teacherSyllabuse?.name_of_ch}</div></td>
                                          <td>{teacherSyllabuse?.copy_checking ? formatDate(teacherSyllabuse.copy_checking) : ""}</td>
                                          <td>{teacherSyllabuse?.assigned_on ? formatDate(teacherSyllabuse.assigned_on) : ""}</td>

                                          <td>{teacherSyllabuse?.iscopycheck ?  "Checked" : "Not Checked"
                                          }</td>

                                         
                                        </tr>
                                      </React.Fragment>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CopyCheckingDetail;
