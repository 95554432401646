import React from 'react'
import Sidebar from '../../component/Sidebar'
import AddStudentDetailForm from './StudentEditForm'

import GoBackButton from '../../component/GoBackButton'

const StudentDetail = () => {
  return (
    <>
    <Sidebar />
    <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
            <GoBackButton />
              <h1 className="page-title">Edit Student</h1>
            </div>
            <AddStudentDetailForm />
            <div className="row ">
              <div className="col-md-12"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StudentDetail