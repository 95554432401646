import React, { useEffect, useState } from "react";
import axios from "axios";
import { getToken } from "../../utility/utilityFunctions";

const AddEventForm = () => {
  const [eventFor, setEventFor] = useState(""); 
  const [eventName, setEventName] = useState("");
  const [date, setDate] = useState("");
  const [branches, setBranches] = useState([]);
  const [grades, setGrades] = useState([]);
  const [description, setDescription] = useState("");
  const [isHoliday, setIsHoliday] = useState(false);
  const [session, setSession] = useState("");

  const [screenData, setScreenData] = useState({
    grades: [],
    branches: [],
    sessions:[]
  });

  

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/eventAddScreen`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        const { branches, grades ,sessions} = response.data;
        setScreenData({
          ...screenData,
          grades,
          branches,
          sessions
        });
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
   

    const data = {
      name: eventName,
      date,
      branches : branches.includes("all_branches") ? [] : branches,
      grades : grades.includes("all_grades") ? [] : grades,
      description,
      for_all_branches: branches.includes("all_branches") ? true : false,
      for_all_grades: grades.includes("all_grades") ? true : false,
      eventFor :eventFor,
      isHoliday,
      session:session
    };
console.log("data:",data)
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/event/new`, data, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        console.log(response);
        if (response?.data.success) {
          window.notif({
            msg: "<b>Woohoo!!</b> Event Created Successfully",
            type: "success",
          });
          setEventName("");
          setDate("");
          setDescription("");
        } else {
          window.notif({
            msg: response?.data.error,
            type: "error",
            position: "center",
          });
        }
      });
  };

  const handleBranchesChange = (e) => {
    const options = e.target.options;
    const selectedBranches = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedBranches.push(options[i].value);
      }
    }
    setBranches(selectedBranches);
  };

  const handleGradesChange = (e) => {
    const options = e.target.options;
    const selectedGrades = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedGrades.push(options[i].value);
      }
    }
    setGrades(selectedGrades);
  };

  const handleEventForChange = (e) => {
    setEventFor(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex">
        <div className="form-group col-md-9">
        <label htmlFor="eventName">Event Name</label>
        <input
          type="text"
          className="form-control"
          id="eventName"
          value={eventName}
          onChange={(e) => setEventName(e.target.value)}
          required
        />
        </div>
      
         <div className="form-group col-md-3">
        <label htmlFor="subjects">Session</label>
          <select
            className="form-control"
            id="session"
            value={session}
            onChange={(e) => {
              console.log("Selected value:", e.target.value);
              setSession(e.target.value);
            }}
            required
          >
             <option value="" selected>
              Select Session
            </option>
            {screenData.sessions.map((session) => (
              <option value={session.session_name}>{session.session_name}</option>
            ))}
            {/* <option value="" selected>
              Select Session
            </option>
            <option value="2022">2022</option> 
            <option value="2023">2023</option> */}
          </select>
        </div>
      </div>
      <div className="d-flex">
        <div className="form-group col-md-3">
          <label htmlFor="date">Date</label>
          <input
            type="date"
            className="form-control"
            id="date"
            // min={ new Date().toISOString().split('T')[0] }
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="branches">Branches</label>
          <select
            multiple
            className="form-control"
            id="branches"
            value={branches}
            onChange={handleBranchesChange}
            required
          >
            <option value="all_branches" selected>
              All Branches
            </option>
            {screenData.branches.map((branch) => (
              <option value={branch._id}>{branch.name}</option>
            ))}
          </select>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="grades">Grades</label>
          <select
            multiple
            className="form-control"
            id="grades"
            value={grades}
            onChange={handleGradesChange}
            required
          >
            <option value="all_grades" selected>
              All Grades
            </option>
            {screenData.grades.map((grade) => (
              <option value={grade._id}>{grade.name}</option>
            ))}
          </select>
        </div>
         {/* eventFor */}
      <div className="form-group col-md-2">
        <label htmlFor="eventFor">Event For</label>
        <select
          className="form-control"
          id="eventFor"
          value={eventFor}
          onChange={handleEventForChange}
         required
          
        >
          <option value="">Select Event For</option>
          <option value="T">Teachers</option>
          <option value="S">Students</option>
          <option value="TS">Teachers&Students</option>
        </select>
        
      </div>
        <div className="form-group col-md-3">
          <label htmlFor="isHoliday">Is Holiday</label>
          <div class="material-switch col-md-9">
            <input
              id="form_fee"
              name="form_fee"
              type="checkbox"
              onChange={(e) => {
                setIsHoliday(e.target.checked)
              }}              
            />
            <label for="form_fee" class="label-success"></label>
          </div>
        </div>
     
      </div>
     
      <div className="form-group">
        <label htmlFor="description">Description</label>
        <textarea
          className="form-control"
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <button type="submit" className="btn btn-primary">
        Create Event
      </button>
    </form>
  );
};
export default AddEventForm;