import React, { useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import "../../App.css";
import placeholder from "../../assets/images/brand/placeholder.png";
import {
  getToken,
  timezoneDateToddMMyyyy,
} from "../../utility/utilityFunctions";


import GoBackButton from '../../component/GoBackButton'

const CollectFeeDetail = () => {
  const params = useParams();
  const [Session, setSession] = useState("");
  const [sessions, setSessions] = useState([]);
  const [siblings, setSiblings] = useState([]);

  const [student, setStudent] = useState({});
  const [fee, setFee] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [paymentMode, setPaymentMode] = useState("");
  const [feeType, setFeeType] = useState([]);
  const [transactionId, setTransactionId] = useState("");
  const [depositeAmount, setDepositeAmount] = useState(0);

  const qtrs = ["one", "two", "three", "four"];

  const cancelFee = (studentId, Session) => {
    const checkboxElement = document.getElementById(`studentId`);
    const isChecked = checkboxElement.checked;
    if (isChecked) {
      const shouldCancel = window.confirm(
        `Are you sure you want to cancel invoice ${studentId}?`
      );

      if (shouldCancel) {
        //console.log(studentId);
        var reason = prompt("Please enter your reason to cancel fee");
        console.log("reason:", reason);
        if (!reason) {
          return;
        }
        if (reason) {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/admin/mark/cancelFee`,
              {
                studentId: studentId,
                Session: Session,
                is_correction: true,
                reason: reason,
              },
              {
                headers: {
                  Authorization: `Bearer ${getToken()}`,
                },
              }
            )
            .then((response) => {
              window.notif({
                msg: "<b>Woohoo!!</b> Record updated successfully",
                type: "success",
              });
              window.location.reload();
            })
            .catch((error) => {
              // Handle error if necessary
              console.error(error);
            });
        }
      } else {
        console.log("Cancelled");
      }
    }
  };

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/studentDetail`, {
        id: params.id,
        Session,
      })
      .then((response) => {
        if (response?.data.success) {
          const { student, fee, invoices, sessions, sinling } = response.data;
          // console.log("fee", fee);
          setSiblings(sinling);
          // console.log("fee", fee);
          setStudent(student);
          setFee(fee);
          setInvoices(invoices);
          setSessions(sessions);
        } else {
          window.notif({
            msg: response?.data.error,
            type: "error",
            position: "center",
          });
        }
      });
  }, [Session]);

  const collectCash = () => {
    if (!feeType || feeType == null || feeType == "" || feeType == []) {
      window.notif({
        msg: "Select a fee type.",
        type: "error",
      });
    } else if (!paymentMode) {
      window.notif({
        msg: "Select a payment mode.",
        type: "error",
      });
    } else {
      if (
        window.confirm(
          `You are depositing  ${feeType}. Do you want to continue?`
        )
      ) {
        console.log("paymentMode:", paymentMode);

        axios
          .post(`${process.env.REACT_APP_BASE_URL}/admin/payFee`, {
            id: params.id,
            paymentMode,
            transactionId,
            feeType: feeType,
            depositeAmount,
          })
          .then((response) => {
            if (response?.data.success) {
              window.location.reload();
            } else {
              window.notif({
                msg: response?.data.error,
                type: "error",
                position: "center",
              });
            }
          });
      }
    }
  };

  const dateConvert = (dateString) => {
    let date = new Date(dateString);
    let formattedDate = date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  let invoiceCount = 0;
  console.log("student:", student.dob);

  function formatDate(dateString) {
    if (!dateString) return ""; // Handle cases where dateString is empty or undefined

    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-IN", options);
  }
  console.log("student:", student);
  return (
    <>
      <Sidebar />
      <style>
        {`
          .strike-through {
            text-decoration: line-through;
          }
        `}
      </style>
      <div className="main-content app-content mt-0">
        <div className="side-app">
          <div className="main-container container-fluid">
            <div className="page-header"></div>
            <div className="page-header">
              <div className="col-sm-12">
                <GoBackButton />
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 d-flex">
                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-header d-flex justify-content-between">
                      {/* <b>Student Details</b> */}
                      {student.isRteStudent ? (
                        <span className="badge bg-danger badge-sm  me-1 mb-1 mt-1 ml-10">
                          RTE Student
                        </span>
                      ) : (
                        <span className="badge bg-success badge-sm  me-1 mb-1 mt-1 ml-10">
                          Non-RTE Student
                        </span>
                      )}
                      {student.status == "paid-enquired" ? (
                        <span className="badge bg-warning badge-sm  me-1 mb-1 mt-1 ml-10">
                          Paid Enquired
                        </span>
                      ) : student.status === "promoted" ? (
                        <span className="badge bg-primary badge-sm  me-1 mb-1 mt-1 ml-10">
                          Promoted
                        </span>
                      ) : student.status === "promotedArchive" ? (
                        <span className="badge bg-primary badge-sm  me-1 mb-1 mt-1 ml-10">
                          Promoted Archive
                        </span>
                      ) : student.status === "registeredArchive" ? (
                        <span className="badge bg-primary badge-sm  me-1 mb-1 mt-1 ml-10">
                          Registered Archive
                        </span>
                      ) : student.status === "completed" ? (
                        <span className="badge bg-primary badge-sm  me-1 mb-1 mt-1 ml-10">
                          Alumni
                        </span>
                      ) : (
                        <span className="badge bg-success badge-sm  me-1 mb-1 mt-1 ml-10">
                          Registered
                        </span>
                      )}
                    </div>
                    <div className="card-body">
                      <div className="d-flex justify-content-center my-2">
                        {student.profile_pic ? (
                          <>
                            <img
                              width={"100px"}
                              src={
                                process.env.REACT_APP_BASE_URL +
                                "/admin/images/" +
                                student.profile_pic
                              }
                              alt={student.name}
                            />
                          </>
                        ) : (
                          <>
                            <img width={"100px"} src={placeholder} />
                          </>
                        )}
                      </div>

                      <div>
                        <span>
                          <b>Name : </b>
                        </span>
                        {student.name}
                      </div>
                      <div>
                        <span>
                          <b>Gender : </b>
                        </span>
                        {student?._doc?.gender}
                      </div>
                      <div className="mt-2">
                        <span>
                          <b>Father Name : </b>
                        </span>
                        {student.father_name}
                      </div>
                      <div className="mt-2">
                        <span>
                          <b>Branch : </b>
                        </span>
                        {typeof student.branch_id === "object"
                          ? student.branch_id[0].name
                          : ""}
                      </div>
                      <div className="mt-2">
                        <span>
                          <b>Grade : </b>
                        </span>
                        {student.grade}
                      </div>
                      <div className="mt-2">
                        <span>
                          <b>Section : </b>
                        </span>
                        {student?._doc?.section}
                      </div>
                      <div className="mt-2">
                        <span>
                          <b>Sr.No. : </b>
                        </span>
                        {student?._doc?.sr_no}
                      </div>
                      <div className="mt-2">
                        <span>
                          <b>Mobile : </b>
                        </span>
                        {student.mobile}
                      </div>
                      <div className="mt-2">
                        <span>
                          <b>DOB : </b>
                        </span>
                        {formatDate(student?.dob)}
                      </div>
                      {/* <div className="mt-2">
                        <span>
                          <b>Status : </b>
                        </span>
                        {student?.status?.charAt(0).toUpperCase() + student?.status?.slice(1)}
                      </div> */}
                      <Link to={`/admin/students/${student._id}`}>
                        {" "}
                        <span className="badge bg-danger badge-sm  me-1 mb-1 mt-1 ml-10">
                          Go to Profile
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <b>{`Class ${student.grade} Fee Plan`}</b>
                    </div>
                    <div className="card-body p-5px">
                      <table className="table table-bordered border text-nowrap">
                        <thead>
                          <tr className="table-header-clr">
                            <th className="text-capitalize">
                              <b>Type</b>
                            </th>
                            <th className="text-capitalize">
                              <b>Actual</b>
                            </th>
                            <th className="text-capitalize">
                              <b>Concession</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {student.isStudentOld ? (
                            <></>
                          ) : (
                            <tr>
                              <td>Admission</td>
                              <td>
                                &#8377;
                                {student.admission_fee}
                              </td>
                              <td>
                                &#8377;
                                {fee.admission_fee?.concession}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td>Tuition</td>
                            <td>
                              &#8377;
                              {student.tution_fee}/month
                              <div
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "11px",
                                }}
                                className="text-danger"
                              >
                                Total : &#8377;{12 * student.tution_fee}
                              </div>
                            </td>
                            <td>
                              &#8377;
                              {fee.tution_fee_concession}
                            </td>
                          </tr>
                          <tr>
                            <td>Annual I</td>
                            <td>
                              &#8377;
                              {student.activity_fee_1}
                            </td>
                            <td>&#8377;0</td>
                          </tr>
                          <tr>
                            <td>Annual II</td>
                            <td>
                              &#8377;
                              {student.activity_fee_2}
                            </td>
                            <td>&#8377;0</td>
                          </tr>
                          <tr>
                            <td>Annual III</td>
                            <td>
                              &#8377;
                              {student.activity_fee_3}
                            </td>
                            <td>&#8377;0</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <b>Fee Summary</b>
                    </div>
                    <div className="card-body p-5px">
                      <table className="table table-bordered border text-nowrap">
                        <thead>
                          <tr className="table-header-clr">
                            <th className="text-capitalize">
                              <b>Total Amount</b>
                            </th>
                            <th className="text-capitalize">
                              <b>Total Paid</b>
                            </th>
                            <th className="text-capitalize">
                              <b>Remaining</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              &#8377;{fee.amount_to_be_paid_after_concession}
                            </td>
                            <td>
                              &#8377;
                              {fee.total_paid}
                            </td>
                            <td>
                              &#8377;
                              {fee.amount_to_be_paid_after_concession -
                                fee.total_paid}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {siblings?.length ? (
                    <div className="card">
                      <div className="card-header">
                        <b>Siblings</b>
                      </div>
                      <div className="card-body p-5px">
                        <table className="table table-bordered border text-nowrap">
                          <thead>
                            <tr className="table-header-clr">
                              <th className="text-capitalize">
                                <b>Name</b>
                              </th>
                              <th className="text-capitalize">
                                <b>Grade</b>
                              </th>
                              {/* <th className="text-capitalize">
                              <b>Section</b>
                            </th> */}
                              <th className="text-capitalize">
                                <b>Status</b>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {siblings?.map((sibling) => (
                              <tr key={sibling?._id}>
                                <td>{sibling?.name}</td>
                                <td>{sibling?.grade}</td>
                                {/* <td>{sibling?.section}</td> */}
                                <td>
                                  {sibling?.status.charAt(0).toUpperCase() +
                                    sibling?.status.slice(1)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {student.status === "promotedArchive" ||
                  student.status === "registeredArchive" ? (
                    ""
                  ) : (
                    <div className="card">
                      <div className="card-header">
                        <b>Cancel Fee</b> :
                        <label
                          style={{ marginLeft: "20px" }}
                          className="custom-control custom-checkbox"
                        >
                          <input
                            id="studentId"
                            type="checkbox"
                            className="custom-control-input"
                            onClick={() => cancelFee(student._id, fee.session)}
                            disabled={fee.is_correction}
                            checked={fee.is_correction}
                          />
                          <span className="custom-control-label"></span>
                        </label>
                      </div>
                      <div className="card-header">
                        If you have collected wrong fee then select this
                        checkbox and request admin to fix.
                      </div>
                    </div>
                  )}

                  <div
                    className="card-header"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      style={{ marginRight: "15px" }}
                      // className="form-control"
                    >
                      <b>Invoices</b>
                    </div>
                    <div className="" style={{ marginRight: "15px" }}>
                      <select
                        className="form-control"
                        name=""
                        value={Session}
                        id="Session"
                        onChange={(e) => {
                          setSession(e.target.value);
                        }}
                      >
                        <option value="">Select Session</option>
                        <option value="2022-2023">2022-2023</option>

                        {sessions.map((session) => {
                          return (
                            <option value={session.session_name}>
                              {session.session_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  {invoices.length > 0 ? (
                    <div className="card">
                      <div className="card-body p-5px">
                        <table className="table table-bordered border text-nowrap">
                          <thead>
                            <tr className="table-header-clr">
                              <th className="text-capitalize">
                                <b>receipt</b>
                              </th>
                              <th className="text-capitalize">
                                <b>Date</b>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {invoices.map((invoice) => (
                              <tr
                                className={
                                  invoice.is_correction ? "strike-through" : ""
                                }
                              >
                                <td className="ellipsis">
                                  <a
                                    target="blank"
                                    href={
                                      process.env.REACT_APP_BASE_URL +
                                      "/admin/invoice/" +
                                      invoice.slug
                                    }
                                  >
                                    {invoice.name}
                                  </a>
                                </td>
                                <td>{dateConvert(invoice.createdAt)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-lg-8">
                  <div className="card">
                    <div className="card-header d-flex justify-content-between">
                      <b>Enrollment Session( {student?._doc?.session} )</b>
                      <b>
                        Fee Session ( {fee.session} - {fee.session + 1} )
                      </b>
                      {fee.amount_to_be_paid_after_concession -
                        fee.total_paid ==
                      0 ? (
                        <>
                          <i
                            className="ion-checkmark-circled text-success"
                            style={{ fontSize: "20px" }}
                          ></i>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="card-body">
                      <table className="table table-bordered border text-nowrap">
                        <thead>
                          <tr className="table-header-clr">
                            <th className="text-capitalize">
                              <b>Fee Type</b>
                            </th>
                            <th className="text-capitalize">
                              <b>Total Amount</b>
                            </th>
                            <th className="text-capitalize">
                              <b>Paid</b>
                            </th>
                            <th className="text-capitalize">
                              <b>Due</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {student.isStudentOld ? (
                            <></>
                          ) : (
                            <tr
                              className={
                                fee.admission_fee
                                  ?.amount_to_be_paid_after_concession -
                                  fee.admission_fee?.total_amount_paid ==
                                0
                                  ? "bg-full-paid"
                                  : "bg-not-paid"
                              }
                            >
                              <td>Admission Fee</td>
                              <td>
                                &#8377;
                                {
                                  fee.admission_fee
                                    ?.amount_to_be_paid_after_concession
                                }
                              </td>
                              <td>
                                &#8377;
                                {fee.admission_fee?.total_amount_paid || 0}
                              </td>
                              <td>
                                &#8377;
                                {fee.admission_fee
                                  ?.amount_to_be_paid_after_concession -
                                  fee.admission_fee?.total_amount_paid || 0}
                              </td>
                            </tr>
                          )}

                          <tr
                            className={
                              fee.activity_fee_1
                                ?.amount_to_be_paid_after_concession -
                                fee.activity_fee_1?.total_amount_paid ==
                              0
                                ? "bg-full-paid"
                                : "bg-not-paid"
                            }
                          >
                            <td>Annual Fee - I</td>
                            <td>
                              &#8377;
                              {
                                fee.activity_fee_1
                                  ?.amount_to_be_paid_after_concession
                              }
                            </td>
                            <td>
                              &#8377;
                              {fee.activity_fee_1?.total_amount_paid || 0}
                            </td>
                            <td>
                              &#8377;
                              {fee.activity_fee_1
                                ?.amount_to_be_paid_after_concession -
                                fee.activity_fee_1?.total_amount_paid || 0}
                            </td>
                          </tr>
                          <tr
                            className={
                              fee.activity_fee_2
                                ?.amount_to_be_paid_after_concession -
                                fee.activity_fee_2?.total_amount_paid ==
                              0
                                ? "bg-full-paid"
                                : "bg-not-paid"
                            }
                          >
                            <td>Annual Fee - II</td>
                            <td>
                              &#8377;
                              {
                                fee.activity_fee_2
                                  ?.amount_to_be_paid_after_concession
                              }
                            </td>
                            <td>
                              &#8377;
                              {fee.activity_fee_2?.total_amount_paid || 0}
                            </td>
                            <td>
                              &#8377;
                              {fee.activity_fee_2
                                ?.amount_to_be_paid_after_concession -
                                fee.activity_fee_2?.total_amount_paid || 0}
                            </td>
                          </tr>
                          <tr
                            className={
                              fee.activity_fee_3
                                ?.amount_to_be_paid_after_concession -
                                fee.activity_fee_3?.total_amount_paid ==
                              0
                                ? "bg-full-paid"
                                : "bg-not-paid"
                            }
                          >
                            <td>Annual Fee - III</td>
                            <td>
                              &#8377;
                              {
                                fee.activity_fee_3
                                  ?.amount_to_be_paid_after_concession
                              }
                            </td>
                            <td>
                              &#8377;
                              {fee.activity_fee_3?.total_amount_paid || 0}
                            </td>
                            <td>
                              &#8377;
                              {fee.activity_fee_3
                                ?.amount_to_be_paid_after_concession -
                                fee.activity_fee_3?.total_amount_paid || 0}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div className="table-responsive">
                        <table
                          className="table table-bordered border text-nowrap mb-0"
                          id="basic-edit"
                        >
                          <thead>
                            <tr>
                              <th className="text-capitalize table-header-clr">
                                <b>Tuition Fee</b>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {fee?.monthly
                              ? qtrs.map(function (qtrName, index) {
                                  let color = "";
                                  switch (qtrName) {
                                    case "one":
                                      color = "#daf0ff";
                                      break;
                                    case "two":
                                      color = "#ffe5ea";
                                      break;
                                    case "three":
                                      color = "#ffffc7";
                                      break;
                                    case "four":
                                      color = "#beffe9";
                                      break;
                                  }
                                  color = "#fff";

                                  return (
                                    <tr key={index}>
                                      <td
                                        className="text-capitalize months"
                                        style={{ backgroundColor: color }}
                                      >
                                        {/* Qtr table starts */}
                                        <div className="f-w-600 mb-2">
                                          Qtr - {qtrName}
                                        </div>
                                        <div>
                                          <table className="table table-bordered border text-nowrap mb-0 bg-white">
                                            <thead>
                                              <tr className="table-header-clr">
                                                <th className="text-capitalize">
                                                  <b>Month</b>
                                                </th>
                                                <th className="text-capitalize">
                                                  <b>Total Amount</b>
                                                </th>
                                                <th className="text-capitalize">
                                                  <b>Paid</b>
                                                </th>
                                                <th className="text-capitalize">
                                                  <b>Due</b>
                                                </th>
                                              </tr>
                                            </thead>

                                            <tbody>
                                              {fee?.monthly
                                                ? Object.keys(fee.monthly).map(
                                                    function (key, index) {
                                                      if (
                                                        fee.monthly[key].qtr ===
                                                        qtrName
                                                      ) {
                                                        return (
                                                          <tr
                                                            key={index}
                                                            className={
                                                              fee.monthly[key]
                                                                ?.amount_to_be_paid_after_concession -
                                                                fee.monthly[key]
                                                                  .total_amount_paid ==
                                                              0
                                                                ? "bg-full-paid"
                                                                : "bg-not-paid"
                                                            }
                                                          >
                                                            <td className="text-capitalize months">
                                                              <span>{key}</span>
                                                            </td>
                                                            <td>
                                                              &#8377;
                                                              {
                                                                fee.monthly[key]
                                                                  ?.amount_to_be_paid_after_concession
                                                              }
                                                            </td>
                                                            <td>
                                                              {fee.monthly[key]
                                                                .is_concession_month ? (
                                                                <>
                                                                  <span className="badge bg-warning badge-sm  me-1 mb-1 mt-1">
                                                                    Concession
                                                                  </span>
                                                                </>
                                                              ) : (
                                                                <>
                                                                  &#8377;
                                                                  {fee.monthly[
                                                                    key
                                                                  ]
                                                                    .total_amount_paid ||
                                                                    0}
                                                                </>
                                                              )}
                                                            </td>
                                                            <td>
                                                              {fee.monthly[key]
                                                                .is_concession_month ? (
                                                                <>
                                                                  <span className="badge bg-warning badge-sm  me-1 mb-1 mt-1">
                                                                    Concession
                                                                  </span>
                                                                </>
                                                              ) : (
                                                                <>
                                                                  &#8377;
                                                                  {fee.monthly[
                                                                    key
                                                                  ]
                                                                    ?.amount_to_be_paid_after_concession -
                                                                    fee.monthly[
                                                                      key
                                                                    ]
                                                                      .total_amount_paid ||
                                                                    0}
                                                                </>
                                                              )}
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    }
                                                  )
                                                : ""}
                                            </tbody>
                                          </table>
                                        </div>
                                        {/* Qtr table ends */}
                                      </td>
                                    </tr>
                                  );
                                })
                              : ""}
                          </tbody>
                        </table>
                        {localStorage.getItem("user") &&
                        JSON.parse(localStorage.getItem("user")).role ===
                          "admin" ? (
                          <>
                            <div class="alert alert-info" role="alert">
                              As an administrator, you are not authorized to
                              collect student fees.
                            </div>
                          </>
                        ) : student.isRteStudent ? (
                          <div class="alert alert-info" role="alert">
                            You can't collect the fee for RTE students.
                          </div>
                        ) : !student?._doc?.sr_no ? (
                          <div class="alert alert-info" role="alert">
                            Please update the student's SR number before
                            collecting the fee.
                            <span>
                              <Link to={`/admin/students/${student._id}`}>
                                <span className="badge bg-danger badge-sm me-1 mb-1 mt-1 ml-1">
                                  Go to Profile
                                </span>
                              </Link>
                            </span>
                          </div>
                        ) : !student?._doc?.section ||
                          student?._doc?.section == "Select Section" ? (
                          <div class="alert alert-info" role="alert">
                            Please update the student's section before
                            collecting the fee.
                            <span>
                              <Link to={`/admin/students/${student._id}`}>
                                <span className="badge bg-danger badge-sm me-1 mb-1 mt-1 ml-1">
                                  Go to Profile
                                </span>
                              </Link>
                            </span>
                          </div>
                        ) : student?.status === "promotedArchive" ||
                          student?.status === "registeredArchive" ? (
                          <div class="alert alert-info" role="alert">
                            You can not collect fee of Promoted Archive and
                            Registered Archive students. This module is already
                            under discission with Admin so do not even try to
                            reroll student. Soon you will get option to
                            unarchive student.
                          </div>
                        // ) : student?._doc?.session !==
                        //   fee.session + "-" + (fee.session + 1) ? (
                        //   <div class="alert alert-info" role="alert">
                        //     The student's enrollment session and fee session are
                        //     different, therefore, you can't collect fees for
                        //     this student. Please cancel fee and contact admin
                        //     for futher action.
                        //   </div>
                        ) : (
                          <>
                            <div className="my-4 d-flex">
                              <label className="col-md-3 form-label">
                                Select Fee Type
                              </label>
                              <select
                                name=""
                                multiple
                                className="form-control mh-150"
                                onChange={(e) => {
                                  setFeeType(
                                    Array.from(
                                      e.target.selectedOptions,
                                      (item) => item.value
                                    )
                                  );
                                }}
                                id="fee_type"
                              >
                                <option selected disabled>
                                  Select Fee Type
                                </option>
                                {student.isStudentOld ? (
                                  <></>
                                ) : (
                                  <option value="admission_fee">
                                    Admission Fee
                                  </option>
                                )}
                                <option value="annual_fee_one">
                                  Annual Fee - I
                                </option>
                                <option value="annual_fee_two">
                                  Annual Fee - II
                                </option>
                                <option value="annual_fee_three">
                                  Annual Fee - III
                                </option>
                                <option value="tution_fee" selected>
                                  Tuition Fee
                                </option>
                              </select>
                            </div>
                            <div className="my-4 d-flex">
                              <label className="col-md-3 form-label">
                                Deposit Amount
                              </label>
                              <div className="col-md-9">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Total amount"
                                  value={depositeAmount}
                                  onChange={(e) => {
                                    setDepositeAmount(e.target.value);
                                  }}
                                  style={{
                                    WebkitAppearance:
                                      "none" /* For webkit browsers like Chrome */,
                                    MozAppearance:
                                      "textfield" /* For Firefox */,
                                    margin: 0,
                                  }}
                                />
                              </div>
                            </div>
                            <select
                              name=""
                              className="form-control mt-5"
                              onChange={(e) => {
                                setPaymentMode(e.target.value);
                              }}
                              id="payment_mode"
                              required // Add the required attribute
                            >
                              <option value="" selected disabled>
                                Select Fee Collection Mode
                              </option>
                              <option value="cash">Cash</option>
                              <option value="online">Online</option>
                              <option value="cheque">Cheque</option>
                            </select>

                            <div className="mt-2">
                              {paymentMode === "online" ||
                              paymentMode === "cheque" ? (
                                <input
                                  type="text"
                                  className="form-control"
                                  name=""
                                  id=""
                                  onChange={(e) => {
                                    setTransactionId(e.target.value);
                                  }}
                                  placeholder="Enter Transaction ID"
                                />
                              ) : (
                                <></>
                              )}

                              <button
                                type="button"
                                className="btn btn-danger text-light mt-2"
                                onClick={() => {
                                  collectCash();
                                }}
                              >
                                <i className="fe fe-user-plus"></i>&nbsp;Collect
                                Amount
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex">
                <div className="card">
                  <div className="card-header">Transaction History</div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        className="table table-bordered border text-nowrap my-2"
                        id="basic-edit"
                      >
                        <thead>
                          <tr>
                            <th>
                              <b>Fee Type</b>
                            </th>
                            <th>
                              <b>Date</b>
                            </th>
                            <th>
                              <b>Amount</b>
                            </th>
                            <th>
                              <b>Mode</b>
                            </th>
                            <th>
                              <b>Transaction ID</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(fee).map((feeType) => {
                            if (
                              feeType == "admission_fee" ||
                              feeType == "activity_fee_1" ||
                              feeType == "activity_fee_2" ||
                              feeType == "activity_fee_3"
                            ) {
                              var admission_fee = fee[feeType];
                              if (admission_fee?.payment_details?.length > 0) {
                                return admission_fee.payment_details.map(
                                  (feee) => {
                                    return (
                                      <tr key={feee.paid_on}>
                                        {/* <td>{feeType}</td> */}
                                        <td>
                                          {feeType === "activity_fee_1"
                                            ? "Annual fee I"
                                            : feeType === "activity_fee_2"
                                            ? "Annual fee II"
                                            : feeType === "activity_fee_3"
                                            ? "Annual fee III"
                                            : feeType
                                                ?.charAt(0)
                                                ?.toUpperCase() +
                                              feeType?.slice(1)}
                                        </td>
                                        <td>{dateConvert(feee.paid_on)}</td>
                                        <td> &#8377; {feee.amount_paid}</td>
                                        <td>{feee.payment_mode}</td>
                                        <td>{feee.transaction_id || "Nill"}</td>
                                      </tr>
                                    );
                                  }
                                );
                              }
                            }
                            if (feeType == "monthly") {
                              var monthly = fee[feeType];
                              var months = Object.keys(monthly);
                              return months.map((month) => {
                                if (monthly[month].payment_details.length > 0) {
                                  return monthly[month].payment_details.map(
                                    (feee) => {
                                      console.log("month:", month);
                                      return (
                                        <tr key={feee.paid_on}>
                                          <td>
                                            {month?.charAt(0)?.toUpperCase() +
                                              month?.slice(1)}
                                          </td>
                                          <td>{dateConvert(feee.paid_on)}</td>
                                          <td> &#8377; {feee.amount_paid}</td>
                                          <td>{feee.payment_mode}</td>
                                          <td>
                                            {feee.transaction_id || "Nill"}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  );
                                }
                              });
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectFeeDetail;
